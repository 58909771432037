import ToursList from "../components/tours/ToursList";

const Tours = () => {
  return (
    <div className="mx-auto max-w-7xl space-y-6">
      <h2>Tour</h2>
      <ToursList />
    </div>
  );
};

export default Tours;
