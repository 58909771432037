import { format } from "date-fns";
import { it } from "date-fns/locale";
import { Link } from "react-router-dom";
import { capitalize } from "../../lib/utils";
import Icon from "../ui/Icon";
import { StatusBadge } from "../ui/StatusBadge";

export const mobileColumns = [
  {
    id: "main",
    header: "Risultati",
    accessorFn: (row) =>
      `${row.organizer.firstName} ${row.organizer.lastName} ${row.reference}`,
    cell: ({ row }) => {
      const booking = row.original;
      return (
        <Link to={`/bookings/${booking._id}`}>
          <div className="flex items-center gap-1.5 py-3 pl-3">
            <div className="flex grow flex-col justify-between gap-1.5">
              <div className="flex items-center justify-between">
                <div className="font-medium">
                  {booking.organizer.firstName} {booking.organizer.lastName}
                </div>
                <StatusBadge status={booking.status} />
              </div>
              <div className="flex items-center justify-between">
                <div>
                  {capitalize(booking.tour?.name || "N/A")} -{" "}
                  {booking.boat?.name || "N/A"}
                </div>
                <div>
                  {capitalize(
                    format(new Date(booking.date), "EEE dd MMM", {
                      locale: it,
                    }),
                  )}
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-1.5">
                  <Icon name="Users" className="h-3 w-3 text-gray-400" />
                  {booking.participants.adults + booking.participants.kids + 1}
                </div>
                <div>{booking.reference}</div>
              </div>
            </div>
            <div>
              <Icon name="ChevronRight" className="h-5 w-5 text-gray-300" />
            </div>
          </div>
        </Link>
      );
    },
  },
  {
    id: "tour",
    accessorFn: (row) => row.tour.name,
    header: () => {
      return <span className="sr-only" />;
    },
    cell: ({ row }) => {
      const booking = row.original;
      return <span className="sr-only" />;
    },
  },
  {
    id: "boat",
    accessorFn: (row) => row.boat._id,
    header: () => {
      return <span className="sr-only" />;
    },
    cell: ({ row }) => {
      const booking = row.original;
      return <span className="sr-only" />;
    },
  },
];
