import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/Dialog";
import { calculateTimezonedDate, capitalize } from "../../../lib/utils";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "../../ui/UseToast";
import Icon from "../../ui/Icon";
import { format } from "date-fns";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/Select";
import { standardBookingTours } from "../new/Standard";
import { Calendar } from "../../ui/Calendar";
import { Button } from "../../ui/Button";
import { it } from "date-fns/locale";

const ModifyDateAndTour = ({ booking, callbackFn }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(new Date(booking.date));
  const [isLoadingTours, setIsLoadingTours] = useState(false);
  const [tours, setTours] = useState();
  const [selectedTour, setSelectedTour] = useState(booking.tour.name);

  const api = useAxiosPrivate();

  function modifyDate() {
    let update = {};

    const currentBookingDate = calculateTimezonedDate(new Date(booking.date));

    if (currentBookingDate !== booking.date) {
      update.date = calculateTimezonedDate(date);
    }

    if (selectedTour !== booking.tour._id) {
      let tour = tours.find((tour) => tour.name === selectedTour);
      update.tour = tour._id;
      update.tourName = tour.name;
    }

    setIsLoading(true);
    api
      .patch(`/bookings/${booking._id}`, update)
      .then((response) => {
        callbackFn();
        toast({
          description: "✔️ Modifica salvata.",
          variant: "confirmed",
        });
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Qualcosa è andato storto...",
          description: "❌ La modifica non è andata a buon fine.",
          variant: "destructive",
        });
      })
      .finally(() => {
        setIsLoading(false);
        setOpen(false);
      });
  }

  useEffect(() => {
    const fetchTours = () => {
      setIsLoadingTours(true);
      api
        .get("/tours")
        .then((res) => {
          setTours(res.data);
          const foundItem = res.data.find(
            (item) => item.name === booking.tour.name,
          );
          setSelectedTour(foundItem.name);
        })
        .catch((err) => {
          console.error(err);
          toast({
            title: "Errore durante il caricamento dei tour",
          });
        })
        .finally(() => {
          setIsLoadingTours(false);
        });
    };

    fetchTours();
  }, []);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <button className="focus-visible:ring-ring ring-offset-background inline-flex h-10 items-center justify-between rounded-md bg-slate-700 px-4 py-2 text-sm font-medium text-white transition-colors hover:bg-slate-800 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">
          <Icon name="Calendar" className="h-4 w-4 text-gray-300" />
          Cambia data e tour
          <div />
        </button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Modifica la data</DialogTitle>
          <DialogDescription>
            Modifica la data di questa prenotazione.
          </DialogDescription>
        </DialogHeader>
        <div className="flex w-full flex-col justify-center space-y-3 rounded border border-gray-200 p-3">
          <div className="grid lg:grid-cols-3">
            <div>
              <div className="text-sm">Tour originale:</div>
              <div className="text-lg font-medium">
                {capitalize(booking.tour.name)}
              </div>
            </div>
            <div className="hidden items-center justify-center lg:flex">
              <Icon name="ArrowRight" className="h-8 w-8 text-gray-200" />
            </div>
            <div>
              <div className="text-sm">Nuovo Tour:</div>
              <Select
                disabled={isLoadingTours}
                value={selectedTour}
                onValueChange={setSelectedTour}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Tour" />
                </SelectTrigger>
                <SelectContent>
                  {standardBookingTours.map((item) => (
                    <SelectItem key={item.value} value={item.value}>
                      {item.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
          <hr />
          <div className="grid lg:grid-cols-3">
            <div>
              <div className="text-sm">Data originale:</div>
              <div className="text-lg font-medium">
                {capitalize(
                  format(new Date(booking.date), "EEEE, dd MMM", {
                    locale: it,
                  }),
                )}
              </div>
            </div>
            <div className="hidden items-center justify-center lg:flex">
              <Icon name="ArrowRight" className="h-8 w-8 text-gray-200" />
            </div>
            <div>
              <div className="text-sm">Nuova data:</div>
              <div className="text-lg font-medium">
                {date
                  ? capitalize(
                      format(new Date(date), "EEEE, dd MMM", { locale: it }),
                    )
                  : "Nessuna selezionata"}
              </div>
            </div>
          </div>
          <Calendar
            mode="single"
            selected={date}
            onSelect={setDate}
            className="flex justify-center"
            initialMonth={new Date(booking.date)}
          />
        </div>
        <DialogFooter>
          <Button onClick={() => setOpen(false)} variant="secondary">
            Cancella
          </Button>
          <Button
            disabled={!date || isLoadingTours || isLoading}
            onClick={modifyDate}
          >
            Conferma
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ModifyDateAndTour;
