import MainLogo from "../img/MainLogo.png";

const Loading = () => {
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center gap-6">
      <div>
        <img
          className="h-20 animate-bounce object-contain lg:h-48"
          src={MainLogo}
          alt="VergaNautic"
        />
      </div>
      <div className="animate-pulse text-lg font-semibold text-gray-700 ease-in-out">
        Caricamento...
      </div>
    </div>
  );
};

export default Loading;
