import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import DisplayListItem from "../ui/DisplayListItem";
import UsersListBlock from "../ui/UsersListBlock";
import { toast } from "../ui/UseToast";

const UsersList = () => {
  const [users, setUsers] = useState();
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const getUsers = () => {
      axiosPrivate
        .get("/users")
        .then((response) => {
          setUsers(response.data);
        })
        .catch((err) => {
          console.error(err);
          toast({
            title: "Problema con il caricamento dei dati dal server. Riprovare",
            variant: "destructive",
          });
        });
    };

    getUsers();
  }, []);

  return (
    <div className="grid gap-3 md:grid-cols-2">
      {/* Gestione */}
      <div className="flex flex-col gap-1.5">
        <div>
          <h3>Gestione</h3>
        </div>
        {!users ? (
          <div>
            <ul className="rounded border border-gray-300">
              <DisplayListItem.Skeleton />
              <DisplayListItem.Skeleton />
              <DisplayListItem.Skeleton />
              <DisplayListItem.Skeleton />
            </ul>
          </div>
        ) : users?.["Admin"]?.length ? (
          <UsersListBlock users={users["Admin"]} />
        ) : (
          <div className="flex items-center justify-center rounded border border-gray-300">
            <p className="text-xs text-gray-400">
              Nessun utente in questa categoria.
            </p>
          </div>
        )}
      </div>
      {/* Segreteria */}
      <div className="flex flex-col gap-1.5">
        <div>
          <h3>Segreteria</h3>
        </div>
        {!users ? (
          <div>
            <ul className="rounded border border-gray-300">
              <DisplayListItem.Skeleton />
              <DisplayListItem.Skeleton />
              <DisplayListItem.Skeleton />
              <DisplayListItem.Skeleton />
            </ul>
          </div>
        ) : users?.["Editor"]?.length ? (
          <UsersListBlock users={users["Editor"]} />
        ) : (
          <div className="flex items-center justify-center rounded border border-gray-300">
            <p className="text-xs text-gray-400">
              Nessun utente in questa categoria.
            </p>
          </div>
        )}
      </div>
      {/* Skipper */}
      <div className="flex flex-col gap-1.5">
        <div>
          <h3>Skipper</h3>
        </div>
        {!users ? (
          <div>
            <ul className="rounded border border-gray-300">
              <DisplayListItem.Skeleton />
              <DisplayListItem.Skeleton />
              <DisplayListItem.Skeleton />
              <DisplayListItem.Skeleton />
            </ul>
          </div>
        ) : users?.["Skipper"]?.length ? (
          <UsersListBlock users={users["Skipper"]} />
        ) : (
          <div className="flex items-center justify-center rounded border border-gray-300">
            <p className="text-xs text-gray-400">
              Nessun utente in questa categoria.
            </p>
          </div>
        )}
      </div>
      {/* Tour Operator */}
      <div className="flex flex-col gap-1.5">
        <div>
          <h3>Tour Operator</h3>
        </div>
        {!users ? (
          <div>
            <ul className="rounded border border-gray-300">
              <DisplayListItem.Skeleton />
              <DisplayListItem.Skeleton />
              <DisplayListItem.Skeleton />
              <DisplayListItem.Skeleton />
            </ul>
          </div>
        ) : users?.["TourOperator"]?.length ? (
          <UsersListBlock users={users["TourOperator"]} />
        ) : (
          <div className="flex items-center justify-center rounded border border-gray-300 p-3">
            <p className="text-xs text-gray-400">
              Nessun utente in questa categoria.
            </p>
          </div>
        )}
      </div>
      {/* Utenti Vari */}
      <div className="col-span-2 flex flex-col gap-1.5">
        <div>
          <h3>Utenti</h3>
        </div>
        {!users ? (
          <div>
            <ul className="rounded border border-gray-300">
              <DisplayListItem.Skeleton />
              <DisplayListItem.Skeleton />
              <DisplayListItem.Skeleton />
              <DisplayListItem.Skeleton />
            </ul>
          </div>
        ) : users?.["User"]?.length ? (
          <UsersListBlock users={users["User"]} />
        ) : (
          <div className="flex items-center justify-center rounded border border-gray-300 p-3">
            <p className="text-xs text-gray-400">
              Nessun utente in questa categoria.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UsersList;
