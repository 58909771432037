import React, { useState } from "react";
import { capitalize, cn } from "../../lib/utils";
import { buttonVariants } from "../ui/Button";
import { Calendar } from "../ui/Calendar";
import EmptyState from "../ui/EmptyState";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/Popover";
import { toast } from "../ui/UseToast";

import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { format } from "date-fns";
import { it } from "date-fns/locale";
import Icon from "../ui/Icon";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/Table";
const { addDays, subDays } = require("date-fns");

const DataTable = ({
  columns,
  data,
  date,
  handleDateChange,
  boat,
  handleBoatChange,
  tourOperators,
  boats,
}) => {
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [columnFilters, setColumnFilters] = useState([]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      columnFilters,
      globalFilter,
    },
  });
  const handleResetFilters = () => {
    setColumnFilters([]);
    return toast({
      title: "Filtri azzerati.",
    });
  };

  const handleAddDay = () => {
    if (!date) {
      date = new Date();
    }
    const newDate = addDays(date, 1);
    handleDateChange(newDate);
  };

  const handleSubDay = () => {
    if (!date) {
      date = new Date();
    }
    const newDate = subDays(date, 1);
    handleDateChange(newDate);
  };

  const handleTourOperatorFilterChange = (event) => {
    console.log(event.target.value);
    table.getColumn("tourOperator")?.setFilterValue(event.target.value);
  };

  return (
    <div className="w-full">
      <div className="flex items-center justify-between border-b border-gray-200 pb-3">
        <div className="flex items-center gap-1.5 text-slate-700">
          <div className="flex h-full items-center rounded border border-gray-400">
            <button onClick={() => handleSubDay()} className="p-1.5">
              <Icon name="ChevronLeft" className="h-4 w-4 text-slate-700" />
            </button>
            <Popover>
              <PopoverTrigger asChild>
                <button
                  className={cn(
                    "w-[200px] px-4 py-2 text-center text-sm text-black",
                  )}
                >
                  {date ? (
                    capitalize(format(date, "PPP", { locale: it }))
                  ) : (
                    <span>Data</span>
                  )}
                </button>
              </PopoverTrigger>
              <PopoverContent className="mt-3 w-auto p-0">
                <Calendar
                  mode="single"
                  selected={date}
                  onSelect={handleDateChange}
                />
              </PopoverContent>
            </Popover>

            <button onClick={() => handleAddDay()} className="p-1.5">
              <Icon name="ChevronRight" className="h-4 w-4 text-slate-700" />
            </button>
          </div>
          <div className="flex items-center">
            <Icon
              name="Search"
              className="absolute ml-3 h-4 w-4 text-gray-400"
            />
            <input
              placeholder="Cerca per nome o riferimento"
              value={globalFilter ?? ""}
              onChange={(event) => setGlobalFilter(String(event.target.value))}
              className="w-[260px] rounded border border-gray-400 py-2 pl-9 pr-4 text-sm font-normal focus:outline-none focus:ring focus:ring-blue-600"
            />
          </div>
          <select
            value={table.getColumn("tour")?.getFilterValue() ?? ""}
            onChange={(event) =>
              table.getColumn("tour")?.setFilterValue(event.target.value)
            }
            className={cn(
              "w-32 rounded border border-gray-400 px-4 py-2 text-sm focus:outline-none focus:ring focus:ring-blue-600",
            )}
          >
            <option value="">Tour: Tutti</option>
            <option value="tremiti">Tremiti</option>
            <option value="costa">Costa</option>
            <option value="croazia">Croazia</option>
            <option value="notturno">Notturno</option>
          </select>
          <div className="relative flex items-center">
            <Icon
              name="Sailboat"
              className="absolute ml-3 h-4 w-4 text-gray-400"
            />
            <select
              value={boat ?? ""}
              onChange={handleBoatChange}
              className={cn(
                "rounded border border-gray-400 py-2 pl-9 pr-4 text-sm focus:outline-none focus:ring focus:ring-blue-600",
              )}
            >
              <option value="">Mezzo: Tutti</option>
              {boats?.map((item) => (
                <option key={item.value} value={item._id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <select
            className={cn(
              "rounded border border-gray-400 px-4 py-2 text-sm focus:outline-none focus:ring focus:ring-blue-600",
            )}
            value={table.getColumn("tourOperator")?.getFilterValue() ?? ""}
            onChange={handleTourOperatorFilterChange}
          >
            <option value="">
              {tourOperators ? "TO: Nessuno" : "Caricamento..."}
            </option>
            {tourOperators?.map((tourOperator) => {
              return (
                <option key={tourOperator._id} value={tourOperator._id}>
                  {tourOperator.name}
                </option>
              );
            })}
          </select>
        </div>
        <div>
          {!!columnFilters.length && (
            <button
              disabled={columnFilters.length === 0}
              onClick={handleResetFilters}
              className={cn(
                "flex items-center gap-1.5 font-medium text-gray-400 transition-colors hover:text-slate-700",
              )}
            >
              <Icon name="Trash" className="h-4 w-4" />
              Azzera filtri
            </button>
          )}
        </div>
      </div>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow
              key={headerGroup.id}
              className="border-slate-700 uppercase"
            >
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                className="h-24 p-0 text-center"
              >
                <EmptyState title="Nessun risultato" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className="flex items-center justify-end space-x-2 py-4">
        <button
          className={buttonVariants({ variant: "link" })}
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Precedente
        </button>
        <button
          className={buttonVariants({ variant: "link" })}
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Successiva
        </button>
      </div>
    </div>
  );
};

export default DataTable;
