import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "../../ui/Dialog";
import { Button, buttonVariants } from "../../ui/Button";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "../../ui/UseToast";
import { useNavigate } from "react-router-dom";

const DeleteRental = ({ data, callbackFn }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const api = useAxiosPrivate();
  const navigate = useNavigate();

  const deleteRental = () => {
    setIsLoading(true);
    api
      .patch(`/rentals/${data._id}`, { $set: { deleted: true, status: 0 } })
      .then(() => {
        toast({
          title: "Noleggio eliminato con successo",
        });
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "Errore durante l'eliminazione. Riprovare",
        });
      })
      .finally(() => {
        setIsLoading(false);
        setOpen(false);
        callbackFn();
      });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <button className={buttonVariants({ variant: "destructive" })}>
          Rimuovi noleggio
        </button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>Elimina questa noleggio</DialogHeader>
        Sei sicuro di voler eliminare questa noleggio?
        <DialogFooter>
          <Button
            onClick={() => setOpen(false)}
            disabled={isLoading}
            variant="outline"
          >
            Annulla
          </Button>
          <Button
            variant="destructive"
            disabled={isLoading}
            onClick={() => deleteRental()}
          >
            Conferma
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteRental;
