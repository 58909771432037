const LoadingState = () => {
  return (
    <div className="items-center justify-center py-32">
      <div className="flex items-center space-x-2">
        <div className="border-primary h-6 w-6 animate-spin rounded-full border-4 border-t-transparent" />
        <span className="text-primary">Caricamento...</span>
      </div>
    </div>
  );
};

export default LoadingState;
