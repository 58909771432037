import { Link } from "react-router-dom";
import { getInitials } from "../../lib/utils";
import Icon from "./Icon";
import Skeleton from "./Skeleton";

const DisplayListItem = ({ title, caption, href }) => {
  return (
    <Link to={href}>
      <div className="flex flex-row items-center gap-2 p-2 transition-colors first:rounded-t last:rounded-b hover:bg-gray-100">
        <div className="p-1">
          <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
            <span className="text-xs font-medium leading-none text-white">
              {getInitials(title)}
            </span>
          </span>
        </div>
        <div className="flex flex-1 flex-col justify-center p-1">
          <div className="truncate text-sm font-medium">{title}</div>
          <div className="truncate text-xs text-gray-400">{caption}</div>
        </div>
        <div className="p-1">
          <Icon name="ChevronRight" className="h4 w-4" />
        </div>
      </div>
    </Link>
  );
};

DisplayListItem.Skeleton = function DisplayListItemSkeleton() {
  return (
    <div className="flex flex-row items-center gap-2 p-2 transition-colors first:rounded-t last:rounded-b hover:bg-gray-100">
      <div className="p-1">
        <Skeleton className="h-8 w-8 rounded-full" />
      </div>
      <div className="flex flex-1 flex-col justify-center p-1">
        <Skeleton className="mb-1 h-[20px] max-w-[75px]" />
        <Skeleton className="h-[16px] max-w-[50px]" />
      </div>
      <div className="p-1">
        <Icon name="ChevronRight" className="h4 w-4" />
      </div>
    </div>
  );
};

export default DisplayListItem;
