import { format } from "date-fns";
import { EnlargedCalendar } from "../../../ui/EnlargedCalendar/Index";
import { cn } from "../../../../lib/utils";
import { it } from "date-fns/locale";

const DatePicker = ({ value, onValueChange, className }, props) => {
  return (
    <div className={cn("flex flex-col items-center gap-3", className)}>
      <div className="text-center">
        <p className="text-lg font-medium">Data selezionata:</p>
        <p className="font-bold text-blue-600">
          {value
            ? format(new Date(value), "EEEE dd MMM, yyyy", { locale: it })
            : "Nessuna"}
        </p>
      </div>
      <EnlargedCalendar
        mode="single"
        selected={value}
        onSelect={onValueChange}
      />
    </div>
  );
};

export { DatePicker };
