import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import TourView from "./TourView";
import { toast } from "../ui/UseToast";

const TourPage = () => {
  const { id } = useParams();
  const [tour, setTour] = useState();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const getTour = () => {
      axiosPrivate
        .get(`/tours/${id}`)
        .then((response) => {
          console.log(response.data);
          setTour(response.data);
        })
        .catch((err) => {
          console.error(err);
          toast({
            title: "Problema con il caricamento dei dati dal server. Riprovare",
            variant: "destructive",
          });
        });
    };

    getTour();
  }, [id]);

  return tour ? <TourView tour={tour} /> : "Loading...";
};

export default TourPage;
