import Breadcrumb from "../components/ui/Breadcrumb";
import NewStandardBooking from "../components/bookings/new/Standard";

const NewBookingPage = () => {
  return (
    <div className="mx-auto max-w-7xl space-y-6">
      <Breadcrumb />
      <h2>Inserisci una nuova prenotazione</h2>
      <NewStandardBooking />
    </div>
  );
};

export default NewBookingPage;
