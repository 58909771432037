import { Controller, FormProvider, useForm } from "react-hook-form";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/Card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/Select";
import { addDays, startOfDay } from "date-fns";
import { Label } from "../../ui/Label";
import Checkbox from "../../ui/Checkbox";
import { calculateTimezonedDate } from "../../../lib/utils";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "../../ui/UseToast";
import { useLocation, useNavigate } from "react-router-dom";
import { ParticipantsSelect } from "./components/ParticipantsSelect";
import { DatePicker } from "./components/SingleDatePicker";
import { BoatsRadioGroup } from "./components/StandardBoatsRadioGroup";
import { Overview } from "./components/StandardOverview";
import { Input } from "../../ui/Input";
import { ROLES } from "../../../config/roles";
import useAuth from "../../../hooks/useAuth";

const TourOperatorSelect = ({ value, onValueChange }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  const api = useAxiosPrivate();

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      api
        .get("/touroperators")
        .then((res) => setData(res.data))
        .catch((err) => {
          console.error(err);
          toast({ title: "Errore durante il caricamento dei T.O." });
        })
        .finally(() => setIsLoading(false));
    };

    fetchData();
  }, []);

  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger>
        <SelectValue placeholder="Nessun T.O." />
      </SelectTrigger>
      <SelectContent>
        {data?.map((item) => (
          <SelectItem key={item._id} value={item._id}>
            {item.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export const standardBookingTours = [
  {
    name: "Tremiti",
    value: "tremiti",
  },
  {
    name: "Costa",
    value: "costa",
  },
  {
    name: "Notturno",
    value: "notturno",
  },
  {
    name: "Croazia",
    value: "croazia",
  },
];

const ExclusiveCheckBox = ({ value, onValueChange }) => {
  return (
    <div className="flex items-center gap-2">
      <Checkbox checked={value} onCheckedChange={onValueChange} />
      <Label>Esclusiva</Label>
    </div>
  );
};

const TourSelect = ({ value, onValueChange }) => {
  return (
    <div className="space-y-3">
      <h2 className="text-lg font-medium tracking-tight">1. Tour</h2>
      <Select value={value} onValueChange={onValueChange}>
        <SelectTrigger>
          <SelectValue placeholder="Tour" />
        </SelectTrigger>
        <SelectContent>
          {standardBookingTours.map((item) => (
            <SelectItem key={item.value} value={item.value}>
              {item.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

const NewStandardBooking = () => {
  const [isLoading, setIsLoading] = useState(false);
  const tomorrow = addDays(startOfDay(new Date()), 1);
  const [selectedBoat, setSelectedBoat] = useState();

  const form = useForm({
    defaultValues: {
      tour: "tremiti",
      date: tomorrow,
      participants: {
        adults: 2,
        kids: 0,
      },
      exclusive: false,
    },
  });

  const date = form.watch("date");
  const tour = form.watch("tour");
  const participants = form.watch("participants");
  const exclusive = form.watch("exclusive");

  const { auth } = useAuth();

  const api = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = (data) => {
    data.date = calculateTimezonedDate(data.date);
    setIsLoading(true);
    api
      .post("/bookings", data)
      .then((response) => {
        toast({
          title: "✔️ Prenotazione creata, accesso in corso...",
          variant: "confirmed",
        });
        window.setTimeout(function () {
          navigate(`/bookings/${response.data._id}`, {
            state: { from: location },
            replace: true,
          });
        }, 500);
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "⚠️ Prenotazione non creata",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Card>
          <CardHeader className="grid gap-6 lg:grid-cols-3">
            <CardTitle className="col-span-2">Nuova prenotazione</CardTitle>
          </CardHeader>
          <CardContent className="grid gap-6 lg:grid-cols-3">
            {[ROLES.Tech, ROLES.Admin, ROLES.Editor].some((role) =>
              auth?.roles.includes(role),
            ) && (
              <div className="col-span-2 grid gap-3 lg:grid-cols-2">
                <div className="space-y-2">
                  <Label>Tour Operator (opz.)</Label>
                  <Controller
                    name="tourOperator"
                    control={form.control}
                    render={({ field }) => (
                      <TourOperatorSelect
                        value={field.value}
                        onValueChange={field.onChange}
                      />
                    )}
                  />
                </div>
                <div className="space-y-2">
                  <Label>Referenza T.O. (opz.)</Label>
                  <Input {...form.register("tourOperatorReference")} />
                </div>
              </div>
            )}
            <div className="col-span-2 grid grid-cols-2 gap-6 lg:gap-y-12">
              <div className="space-y-3">
                <Controller
                  name="tour"
                  control={form.control}
                  render={({ field }) => (
                    <TourSelect
                      value={field.value}
                      onValueChange={field.onChange}
                    />
                  )}
                />
                <Controller
                  name="exclusive"
                  control={form.control}
                  render={({ field }) => (
                    <ExclusiveCheckBox
                      value={field.value}
                      onValueChange={field.onChange}
                    />
                  )}
                />
              </div>
              <div className="space-y-3">
                <h2 className="text-lg font-medium tracking-tight">
                  2. Partecipanti
                </h2>
                <Controller
                  name="participants"
                  control={form.control}
                  render={({ field }) => (
                    <ParticipantsSelect
                      value={field.value}
                      onValueChange={field.onChange}
                    />
                  )}
                />
              </div>
              <Controller
                name="date"
                control={form.control}
                render={({ field }) => (
                  <DatePicker
                    className="col-span-2"
                    value={field.value}
                    onValueChange={field.onChange}
                  />
                )}
              />
              <Controller
                name="boat"
                control={form.control}
                render={({ field }) => (
                  <BoatsRadioGroup
                    className="col-span-2"
                    value={field.value}
                    onValueChange={field.onChange}
                    date={date}
                    tour={tour}
                    participants={participants}
                    exclusive={exclusive}
                    setSelectedBoat={setSelectedBoat}
                  />
                )}
              />
            </div>

            <div className="flex h-full w-full flex-col border-l pl-3">
              {date && tour && selectedBoat && (
                <Overview isLoading={isLoading} selectedBoat={selectedBoat} />
              )}
            </div>
          </CardContent>
        </Card>
      </form>
    </FormProvider>
  );
};

export default NewStandardBooking;
