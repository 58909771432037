import React from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useLogout from "../../hooks/useLogout";
import { getInitials } from "../../lib/utils";

const UserBlock = () => {
  const { auth } = useAuth();
  const displayName = auth?.displayName;

  const navigate = useNavigate();
  const logout = useLogout();

  const signOut = async () => {
    await logout();
    navigate("/");
  };

  return (
    <div className="flex flex-shrink-0 p-4">
      <div className="block w-full flex-shrink-0">
        <div className="flex items-center">
          <div>
            <Link to={`/users/${auth?.id}`}>
              <span className="inline-flex h-9 w-9 items-center justify-center rounded-full bg-gray-500 ring-white ring-offset-1 transition hover:ring-1">
                <span className="text-xs font-medium leading-none text-white">
                  {getInitials(
                    `${displayName?.firstName} ${displayName?.lastName}`,
                  )}
                </span>
              </span>
            </Link>
          </div>
          <div className="ml-2 p-1">
            <p className="text-sm font-medium text-white">
              {displayName?.firstName}
            </p>
            <button
              className="cursor-buttonointer text-xs font-medium text-gray-500 transition hover:text-white"
              onClick={signOut}
            >
              Log out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

UserBlock.Mobile = function UserBlockMobile() {
  const { auth } = useAuth();
  const displayName = auth?.displayName;

  return (
    <Link to={`/users/${auth?.id}`}>
      <span className="inline-flex h-9 w-9 items-center justify-center rounded-full bg-gray-500">
        <span className="text-xs font-medium leading-none text-white">
          {getInitials(`${displayName?.firstName} ${displayName?.lastName}`)}
        </span>
      </span>
    </Link>
  );
};

export default UserBlock;
