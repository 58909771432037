import { endOfMonth, format, startOfMonth, subMonths } from "date-fns";
import { it } from "date-fns/locale";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/ui/Breadcrumb";
import { buttonVariants } from "../../components/ui/Button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../components/ui/Collapsible";
import Icon from "../../components/ui/Icon";
import Loading from "../../components/ui/Loading";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/Popover";
import { Calendar } from "../../components/ui/RangeCalendar";
import { toast } from "../../components/ui/UseToast";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { capitalize, cn } from "../../lib/utils";

const SkippersReportPage = () => {
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [skippersReport, setSkippersReport] = useState();
  const [period, setPeriod] = useState("last_month");

  const lastMonth = subMonths(new Date(), 1);
  const firstDateOfLastMonth = startOfMonth(lastMonth);
  const lastDateOfLastMonth = endOfMonth(lastMonth);

  const [skippersReportRange, setSkippersReportRange] = useState({
    from: firstDateOfLastMonth,
    to: lastDateOfLastMonth,
  });
  const [skippersReportFilters, setSkippersReportFilters] = useState({
    startDate: format(new Date(firstDateOfLastMonth), "yyyy-MM-dd"),
    endDate: format(new Date(lastDateOfLastMonth), "yyyy-MM-dd"),
  });

  const handleSkipperReportRangeChange = (newValue) => {
    if (newValue) {
      setSkippersReportRange(newValue);
      if (newValue.from && newValue.to) {
        setSkippersReportFilters({
          ...skippersReportFilters,
          startDate: format(new Date(newValue.from), "yyyy-MM-dd"),
          endDate: format(new Date(newValue.to), "yyyy-MM-dd"),
        });
      }
    } else {
      setSkippersReportRange();
      setSkippersReportFilters();
    }
  };

  async function getSkippersReports() {
    setIsLoading(true);
    setSkippersReport();
    try {
      toast({
        title: "Creazione report in corso...",
        description: "⌛ Attendere prego.",
      });
      const response = await axiosPrivate.get("/reports/skippers", {
        params: skippersReportFilters,
      });
      setSkippersReport(response.data);
      toast({
        description: "✔️ Report generato correttamente.",
        variant: "confirmed",
      });
    } catch (err) {
      console.log(err);
      toast({
        title: "Qualcosa è andato storto...",
        description:
          "❌ Non è stato possibile generare un report per gli skipper.",
        variant: "destructive",
      });
    }
    setIsLoading(false);
  }

  const resetSkippersReportFilters = () => {
    setSkippersReportRange();
    setSkippersReportFilters();
  };

  const handleSelectPeriod = (e) => {
    setPeriod(e.target.value);
    if (e.target.value === "last_month") {
      const lastMonth = subMonths(new Date(), 1);
      const firstDateOfLastMonth = startOfMonth(lastMonth);
      const lastDateOfLastMonth = endOfMonth(lastMonth);

      setSkippersReportRange({
        from: firstDateOfLastMonth,
        to: lastDateOfLastMonth,
      });

      setSkippersReportFilters({
        startDate: format(new Date(firstDateOfLastMonth), "yyyy-MM-dd"),
        endDate: format(new Date(lastDateOfLastMonth), "yyyy-MM-dd"),
      });
    }
    if (e.target.value === "month_start") {
      const firstDateOfThisMonth = startOfMonth(new Date());
      const today = new Date();
      setSkippersReportRange({
        from: firstDateOfThisMonth,
        to: today,
      });

      setSkippersReportFilters({
        startDate: format(new Date(firstDateOfThisMonth), "yyyy-MM-dd"),
        endDate: format(new Date(today), "yyyy-MM-dd"),
      });
    }
    if (e.target.value === "june") {
      const firstDateOfJune = startOfMonth(new Date("2023-06-01"));
      const lastDateOfJune = endOfMonth(new Date("2023-06-01"));
      setSkippersReportRange({
        from: firstDateOfJune,
        to: lastDateOfJune,
      });

      setSkippersReportFilters({
        startDate: format(new Date(firstDateOfJune), "yyyy-MM-dd"),
        endDate: format(new Date(lastDateOfJune), "yyyy-MM-dd"),
      });
    }
    if (e.target.value === "season_start") {
      const firstDateOfSeason = startOfMonth(new Date("2023-06-01"));
      const today = new Date();
      setSkippersReportRange({
        from: firstDateOfSeason,
        to: today,
      });

      setSkippersReportFilters({
        startDate: format(new Date(firstDateOfSeason), "yyyy-MM-dd"),
        endDate: format(new Date(today), "yyyy-MM-dd"),
      });
    }
  };

  useEffect(() => {
    getSkippersReports();
  }, []);

  return (
    <div className="mx-auto max-w-7xl space-y-6">
      <Breadcrumb />
      <div className="flex items-center justify-between">
        <div>
          <h2>Report sugli Skipper</h2>
        </div>
      </div>
      <div className="space-y-3">
        <div>Report su tutte le uscite degli skipper.</div>
        <div className="flex items-center gap-1.5">
          <select
            onChange={handleSelectPeriod}
            value={period}
            className="flex items-center gap-1 rounded border border-gray-200 px-2 py-1 text-xs font-medium"
          >
            <option value="">Personalizzato</option>
            <option value="month_start">Da inizio mese</option>
            <option value="season_start">Da inizio stagione</option>
            <option value="last_month">Ultimo mese</option>
            <option value="june">Giu 2023</option>
          </select>
          <Popover>
            <PopoverTrigger asChild>
              <button className="flex items-center gap-1 rounded border border-gray-200 px-2 py-1 text-xs font-medium">
                <Icon name="Calendar" className="h-3 w-3 text-gray-400" />
                {!skippersReportRange?.from &&
                  !skippersReportRange?.to &&
                  "Periodo"}
                {skippersReportRange?.from &&
                  format(new Date(skippersReportRange.from), "MMM dd", {
                    locale: it,
                  })}
                {skippersReportRange?.from && skippersReportRange?.to && " - "}
                {skippersReportRange?.to &&
                  format(new Date(skippersReportRange.to), "MMM dd", {
                    locale: it,
                  })}
              </button>
            </PopoverTrigger>
            <PopoverContent className="mt-3 w-auto p-0">
              <Calendar
                mode="range"
                selected={skippersReportRange}
                onSelect={handleSkipperReportRangeChange}
              />
              <div className="flex items-center gap-1.5 px-3 pb-3">
                <button
                  disabled={isLoading || !skippersReportFilters}
                  onClick={() => getSkippersReports()}
                  className={cn("flex-1", buttonVariants({ size: "sm" }))}
                >
                  {isLoading ? (
                    <Icon name="Loader" className="h-4 w-4 animate-spin" />
                  ) : (
                    "Genera"
                  )}
                </button>
                <button
                  onClick={() => resetSkippersReportFilters()}
                  className={cn(
                    "flex-1",
                    buttonVariants({ variant: "outline", size: "sm" }),
                  )}
                >
                  Azzera
                </button>
              </div>
            </PopoverContent>
          </Popover>
        </div>
        {isLoading && <Loading />}
        {skippersReport && (
          <div className="space-y-6">
            {skippersReport.map((item) => {
              return (
                <Collapsible
                  key={item.skipper?.displayName.firstname}
                  className="rounded border border-gray-200"
                >
                  <CollapsibleTrigger>
                    <div className="flex items-center justify-between p-3">
                      <div className="flex items-center gap-1.5">
                        <div className="font-medium">
                          {item.skipper?.displayName.firstName} (
                          {item.sorties.length})
                        </div>
                        <div className="flex items-center gap-1.5 text-gray-400">
                          {item.tourCounts.map((elem, index) => (
                            <div key={index}>
                              {capitalize(elem.tour)} ({elem.count})
                              {index !== item.tourCounts.length - 1 ? "," : ""}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div>
                        <Icon name="ChevronDown" className="h-4 w-4" />
                      </div>
                    </div>
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <div className="space-y-1.5 bg-gray-50 p-3 text-sm lg:col-span-2">
                      {item.sorties
                        .sort((a, b) => new Date(a.date) - new Date(b.date))
                        .map((sortie) => {
                          return (
                            <div
                              key={sortie.date}
                              className="flex items-center gap-3"
                            >
                              <div className="w-16">
                                {capitalize(
                                  format(new Date(sortie.date), "dd MMM yy", {
                                    locale: it,
                                  }),
                                )}
                              </div>
                              <div className="flex w-24 items-center gap-1.5 rounded border border-gray-200 p-1.5">
                                <Icon
                                  name="Sailboat"
                                  className="h-3 w-3 text-gray-400"
                                />
                                <div className="text-xs font-medium">
                                  {sortie.boat.name}
                                </div>
                              </div>
                              <div className="flex w-24 items-center gap-1.5 rounded border border-gray-200 p-1.5">
                                <Icon
                                  name={sortie.tour.icon}
                                  className="h-3 w-3 text-gray-400"
                                />
                                <div className="text-xs font-medium uppercase">
                                  {capitalize(sortie.tour.name)}
                                </div>
                              </div>

                              <Link
                                target="_blank"
                                rel="noopener noreferrer"
                                to={`/bookings?date=${format(new Date(sortie.date), "yyyy-MM-dd")}&boat=${sortie.boat._id}`}
                                className="flex items-center gap-1.5 font-medium text-blue-500"
                              >
                                Visualizza prenotazioni
                                <Icon name="ExternalLink" className="h-3 w-3" />
                              </Link>
                            </div>
                          );
                        })}
                    </div>
                  </CollapsibleContent>
                </Collapsible>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SkippersReportPage;
