import { RadioGroupIndicator } from "@radix-ui/react-radio-group";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import Breadcrumb from "../components/ui/Breadcrumb";
import { buttonVariants } from "../components/ui/Button";
import { Calendar } from "../components/ui/Calendar";
import { RadioGroup, RadioGroupItem } from "../components/ui/RadioGroup";
import { toast } from "../components/ui/UseToast";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { cn } from "../lib/utils";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "../components/ui/Select";
import { Input } from "../components/ui/Input";

const baseSchema = z.object({
  type: z.enum(["related", "general"]),
  title: z.string().optional(),
  description: z.string().optional(),
  amount: z.string().nonempty("Ammontare richiesto"),
  quantity: z.string().optional(),
  boat: z.string().optional(),
  paidForBy: z.string().optional(),
  settled: z.boolean().optional(),
});

const schema = baseSchema
  .refine((data) => data.type !== "related" || data.boat, {
    message: "Selezionare un'imbarcazione",
    path: ["boat"],
  })
  .refine((data) => data.type !== "general" || data.paidForBy, {
    message: "Selezionare un utente",
    path: ["paidForBy"],
  })
  .refine((data) => data.type !== "general" || data.title, {
    message: "Il titolo è richiesto",
    path: ["title"],
  });

const NewExpense = () => {
  const { auth } = useAuth();
  const currentUserId = auth?.id;
  const axiosPrivate = useAxiosPrivate();
  const [searchParams] = useSearchParams();
  const [type, setType] = useState(
    searchParams.get("type") === "related" ? "related" : "general",
  );
  const [boats, setBoats] = useState([]);
  const [users, setUsers] = useState([]);
  const [date, setDate] = useState(
    searchParams.get("date") ? new Date(searchParams.get("date")) : new Date(),
  );
  const [port, setPort] = useState("foce");

  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      type,
      amount: "",
      quantity: "",
      boat: "",
      paidForBy: "",
      settled: false,
    },
  });

  useEffect(() => {
    const getAvailableBoats = async () => {
      try {
        const response = await axiosPrivate.get("/boats");
        setBoats(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    if (type === "related") {
      getAvailableBoats();
    }
  }, [axiosPrivate, type]);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get("/users");
        const usersList = [
          ...response.data["Admin"],
          ...response.data["Editor"],
          ...response.data["Skipper"],
        ];
        setUsers(usersList);
      } catch (err) {
        console.log(err);
      }
    };
    getUsers();
  }, [axiosPrivate]);

  const onSubmit = async (data) => {
    data.date = format(date, "yyyy-MM-dd");
    data.amount = parseFloat(data.amount.replace(",", "."));
    data.submittedBy = currentUserId;
    if (type === "related") {
      data.boat = data.boat;
      data.quantity = parseFloat(data.quantity.replace(",", "."));
    } else {
      data.paidForBy = data.paidForBy;
      delete data.boat;
    }
    data.type = type;

    try {
      const response = await axiosPrivate.post("/expenses", data);
      toast({
        title: "✔️ Spesa aggiunta",
        variant: "confirmed",
      });
      navigate(`/expenses/${response.data._id}`);
    } catch (err) {
      console.error(err);
      return toast({
        title: "Errore nell'aggiunta della spesa",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  return (
    <div className="mx-auto max-w-7xl space-y-6">
      <Breadcrumb />
      <h2>Inserisci una spesa</h2>
      <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <RadioGroup
              value={field.value}
              onValueChange={(value) => {
                field.onChange(value);
                setType(value);
              }}
              className="divide-y divide-gray-200"
            >
              <div className="flex items-start gap-3 py-3">
                <RadioGroupItem
                  id="general"
                  value="general"
                  className="flex h-4 w-4 items-center justify-center rounded-full border border-gray-200"
                >
                  <RadioGroupIndicator className="h-full w-full rounded-full bg-blue-500 p-1">
                    <div className="h-full w-full rounded-full bg-white" />
                  </RadioGroupIndicator>
                </RadioGroupItem>
                <label htmlFor="general" className="space-y-1.5">
                  <div className="font-medium leading-4">Varie</div>
                  <div className="text-sm text-gray-400">
                    Registra una spesa di categoria generale.
                  </div>
                </label>
              </div>
              <div className="flex items-start gap-3 py-3">
                <RadioGroupItem
                  id="related"
                  value="related"
                  className="flex h-4 w-4 items-center justify-center rounded-full border border-gray-200"
                >
                  <RadioGroupIndicator className="h-full w-full rounded-full bg-blue-500 p-1">
                    <div className="h-full w-full rounded-full bg-white" />
                  </RadioGroupIndicator>
                </RadioGroupItem>
                <label htmlFor="related" className="space-y-1.5">
                  <div className="font-medium leading-4">Carburante</div>
                  <div className="text-sm text-gray-400">
                    Registra una spesa relativa ad un&apos;uscita.
                  </div>
                </label>
              </div>
            </RadioGroup>
          )}
        />

        {type === "related" && (
          <div className="space-y-3">
            <div className="font-medium">Seleziona l&apos;imbarcazione</div>
            <Controller
              name="boat"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select value={field.value} onValueChange={field.onChange}>
                  <SelectTrigger className={cn(error && "border-red-500")}>
                    <SelectValue placeholder="Imbarcazione" />
                  </SelectTrigger>
                  <SelectContent>
                    {boats?.map((boat) => (
                      <SelectItem key={boat._id} value={boat._id}>
                        {boat.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            />
          </div>
        )}

        {type === "general" && (
          <div className="space-y-3">
            <div className="font-medium">Titolo</div>
            <Controller
              name="title"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  value={field.value}
                  onChange={field.onChange}
                  className={cn(error && "border-red-600")}
                  autoComplete="off"
                  placeholder="Titolo"
                />
              )}
            />
            <div className="font-medium">Descrizione</div>
            <textarea
              autoComplete="off"
              className="w-full rounded border border-gray-300 px-2 py-1 text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
              placeholder="Descrizione"
              rows={7}
              {...register("description")}
            />
          </div>
        )}

        <div className="space-y-3">
          <div className="font-medium">Data della transazione</div>
          <div className="flex justify-center">
            <Calendar
              mode="single"
              selected={date}
              onSelect={setDate}
              numberOfMonths={1}
            />
          </div>
        </div>

        <div className="space-y-3">
          <div className="font-medium">Dettagli</div>
          <div className="grid divide-x divide-gray-200 lg:grid-cols-3">
            <div className="flex flex-col items-center gap-3">
              <div className="text-center">Ammontare</div>
              <div className="flex grow items-center justify-center text-2xl">
                <Controller
                  name="amount"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <div className="flex grow items-center justify-center text-2xl">
                      <CurrencyInput
                        decimalsLimit={2}
                        allowNegativeValue={false}
                        min={0}
                        placeholder="0,00"
                        className={cn(
                          "w-24 border-b border-gray-200 text-center focus:outline-none",
                          error && "border-red-500",
                        )}
                        intlConfig={{ locale: "it-IT" }}
                        {...field}
                      />
                    </div>
                  )}
                />
                €
              </div>
            </div>

            {type === "related" && (
              <div className="flex flex-col items-center gap-3">
                <div className="text-center">Quantit&agrave;</div>
                <div className="flex grow items-center justify-center text-2xl">
                  <CurrencyInput
                    decimalsLimit={0}
                    allowNegativeValue={false}
                    min={0}
                    placeholder="0.00"
                    className="w-24 border-b border-gray-200 text-center focus:outline-none"
                    intlConfig={{ locale: "it-IT" }}
                    {...register("quantity")}
                  />
                  <div>L</div>
                </div>
              </div>
            )}

            {type === "general" && (
              <div className="space-y-3 p-3">
                <div className="text-center">Anticipato da</div>
                <Controller
                  name="paidForBy"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Select value={field.value} onValueChange={field.onChange}>
                      <SelectTrigger className={cn(error && "border-red-500")}>
                        <SelectValue placeholder="Utente" />
                      </SelectTrigger>
                      <SelectContent>
                        {users?.map((user) => (
                          <SelectItem key={user._id} value={user._id}>
                            {user.displayName.firstName}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  )}
                />
              </div>
            )}

            {type === "related" && (
              <div className="flex flex-col items-center gap-3">
                <div className="text-center">Porto</div>
                <Controller
                  name="port"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      className="flex w-full grow items-center justify-evenly"
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <div className="flex items-start gap-3 py-3">
                        <RadioGroupItem
                          value="foce"
                          className="flex h-4 w-4 items-center justify-center rounded-full border border-gray-200"
                        >
                          <RadioGroupIndicator className="h-full w-full rounded-full bg-blue-500 p-1">
                            <div className="h-full w-full rounded-full bg-white" />
                          </RadioGroupIndicator>
                        </RadioGroupItem>
                        <label htmlFor="foce" className="font-medium leading-4">
                          Foce
                        </label>
                      </div>
                      <div className="flex items-start gap-3 py-3">
                        <RadioGroupItem
                          value="rodi"
                          className="flex h-4 w-4 items-center justify-center rounded-full border border-gray-200"
                        >
                          <RadioGroupIndicator className="h-full w-full rounded-full bg-blue-500 p-1">
                            <div className="h-full w-full rounded-full bg-white" />
                          </RadioGroupIndicator>
                        </RadioGroupItem>
                        <label htmlFor="rodi" className="font-medium leading-4">
                          Rodi
                        </label>
                      </div>
                    </RadioGroup>
                  )}
                />
              </div>
            )}

            {type === "general" && (
              <div className="flex items-center justify-center">
                <div className="flex items-center gap-1.5">
                  <input type="checkbox" {...register("settled")} />
                  Saldato all&apos;anticipatore
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-end">
            <button type="submit" className={buttonVariants()}>
              Conferma e crea
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewExpense;
