import classNames from "classnames";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import { Link } from "react-router-dom";
import { capitalize, formatCurrency } from "../../lib/utils";
import CroatianFlag from "../images/CroatianFlag";
import Icon from "../ui/Icon";
import { StatusBadge } from "../ui/StatusBadge";

export const columns = [
  {
    id: "attentionRequired",
    cell: ({ row }) => {
      const booking = row.original;

      return (
        <div className="flex w-16 items-center justify-center gap-2">
          {booking.tour.name === "croazia" && (
            <CroatianFlag className="h-4 w-4" />
          )}
          {booking.tour.name === "costa" && (
            <Icon name="Mountain" className="h-4 w-4" />
          )}
          {!!booking.notes.fromPassenger?.description.length && (
            <div>
              <Icon name="AlertCircle" className="h-4 w-4 text-red-500" />
            </div>
          )}
          {booking.exclusive && (
            <div>
              <Icon name="Gem" className="h-4 w-4 text-blue-400" />
            </div>
          )}
          {booking.night && (
            <div>
              <Icon name="MoonStar" className="h-4 w-4 text-slate-700" />
            </div>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "reference",
    header: "RIF.",
    cell: ({ row }) => {
      return <div className="w-8">{row.original.reference}</div>;
    },
  },
  {
    accessorKey: "date",
    header: "Data",
    cell: ({ row }) => {
      const booking = row.original;
      return (
        <div>
          {capitalize(
            format(new Date(booking.date), "EEE dd MMM", { locale: it }),
          )}
        </div>
      );
    },
  },
  {
    id: "tour",
    accessorFn: (row) => row.tour.type,
    header: "Tour",
    cell: ({ row }) => {
      const booking = row.original;
      return <div className="w-10">{capitalize(booking.tour.name)}</div>;
    },
  },
  {
    accessorFn: (row) => `${row.organizer.firstName} ${row.organizer.lastName}`,
    id: "organizer",
    header: "Nominativo",
    cell: ({ row }) => {
      const booking = row.original;
      return (
        <div>
          {[booking.organizer.firstName, booking.organizer.lastName].join(" ")}
        </div>
      );
    },
  },
  {
    id: "participants",
    header: "Pers.",
    cell: ({ row }) => {
      const booking = row.original;
      return (
        <div className="inline-flex items-center gap-1">
          <Icon name="Users" className="h-4 w-4 text-gray-400" />
          {booking.participants.adults + booking.participants.kids + 1}
        </div>
      );
    },
  },
  {
    id: "boat",
    header: "Barca",
    accessorFn: (row) => row.boat._id,
    cell: ({ row }) => {
      const booking = row.original;
      return (
        <div className="truncate">
          {booking.boat ? booking.boat.name : "Non assegnato"}
        </div>
      );
    },
  },
  {
    id: "port",
    header: "Porto",
    accessorFn: (row) => row.port?._id,
    cell: ({ row }) => {
      const booking = row.original;
      return (
        <div className="uppercase">
          {booking.port ? booking.port.name : "Non assegnato"}
        </div>
      );
    },
  },
  {
    id: "skipper",
    accessorFn: (row) => row.tour.skipper,
    header: "Skipper",
    cell: ({ row }) => {
      const booking = row.original;
      return booking.skipper
        ? booking.skipper.displayName.firstName
        : "Non assegnato";
    },
  },
  {
    accessorFn: (row) => `${row.status}`,
    id: "status",
    header: "Stato",
    cell: ({ row }) => {
      const booking = row.original;
      return <StatusBadge status={booking.status} />;
    },
  },
  {
    id: "tourOperator",
    accessorFn: (row) => `${row.tourOperator?._id}`,
    header: "Canale",
    cell: ({ row }) => {
      const booking = row.original;
      return (
        <div className="flex items-center gap-1.5">
          {!!booking.tourOperator && (
            <span
              className={classNames(
                booking.tourOperator.name === "garganogroup"
                  ? "bg-orange-700"
                  : "bg-amber-600",
                "inline-flex items-center gap-1 truncate rounded px-1 text-xs font-semibold uppercase leading-5 text-white transition",
              )}
            >
              <Icon name="Briefcase" className="h-3 w-3" />
              {booking.tourOperator.name}
            </span>
          )}
          {booking.online && (
            <span className="inline-flex items-center gap-1 truncate rounded bg-emerald-800 px-1 text-xs font-semibold uppercase leading-5 text-white transition">
              <Icon name="Globe" className="h-3 w-3" />
              ONLINE
            </span>
          )}
          {!booking.tourOperator && !booking.online && (
            <span className="inline-flex items-center gap-1 truncate rounded bg-sky-800 px-1 text-xs font-semibold uppercase leading-5 text-white transition">
              <Icon name="LampDesk" className="h-3 w-3" />
              UFFICIO
            </span>
          )}
        </div>
      );
    },
  },
  {
    id: "accounting",
    header: "Saldo",
    cell: ({ row }) => {
      const booking = row.original;

      return (
        <div className="text-sm">
          {formatCurrency.format(booking.paymentInfo.balance)} /{" "}
          {formatCurrency.format(booking.paymentInfo.totalAmount)}
        </div>
      );
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const booking = row.original;

      return (
        <div className="flex flex-row items-center justify-end">
          <Link
            to={`/bookings/${booking._id}`}
            className="rounded border border-gray-300 p-2 text-black hover:bg-gray-100"
          >
            <Icon name="ChevronRight" className="h-4 w-4" />
          </Link>
        </div>
      );
    },
  },
];
