import React from "react";
import { NavLink } from "react-router-dom";
import { sideBarNav } from "../../config/navigation";
import { ROLES } from "../../config/roles";
import useAuth from "../../hooks/useAuth";
import useLockBody from "../../hooks/useLockBody";
import MainLogo from "../../img/MainLogo.png";
import { cn } from "../../lib/utils";
import Icon from "./Icon";

const MobileNav = ({ toggleMobileMenu }) => {
  const { auth } = useAuth();
  const roles = auth?.roles;

  let items = roles?.reduce((result, role) => {
    const roleKey = Object.keys(ROLES).find((key) => ROLES[key] === role);
    if (roleKey && Object.prototype.hasOwnProperty.call(sideBarNav, roleKey)) {
      result.push(...sideBarNav[roleKey]);
    }
    return result;
  }, []);

  if (items) {
    items.unshift(...sideBarNav["All"]);

    items = items.filter((item, index, self) => {
      return index === self.findIndex((i) => i.href === item.href);
    });
  }

  useLockBody();
  return (
    <div className="animate-in slide-in-from-bottom-80 fixed inset-0 top-16 z-20 grid h-[calc(100vh-4rem)] grid-flow-row auto-rows-max overflow-auto bg-gray-200/20 p-3 pb-32 shadow-md backdrop-blur-sm lg:hidden">
      <div className="text-popover-foreground relative z-50 grid gap-6 rounded border border-gray-200 bg-gray-50 p-4 shadow-md">
        <div className="flex flex-col justify-center gap-1.5">
          <img
            className="h-12 object-contain"
            src={MainLogo}
            alt="VergaNautic"
          />
          <div className="mx-auto text-lg font-semibold tracking-widest text-slate-500">
            MANAGEMENT
          </div>
        </div>
        <ul className="space-y-0.5">
          {items?.map((item) => {
            return !item.disabled ? (
              <li key={item.href} className="font-medium">
                <button
                  className="w-full focus:outline-none"
                  onClick={toggleMobileMenu}
                >
                  <NavLink
                    key={item.href}
                    to={item.href}
                    className={({ isActive }) =>
                      cn(
                        "flex flex-row items-center gap-x-1 rounded px-3 py-2 text-slate-700 transition hover:bg-slate-700 hover:text-white",
                        isActive && "bg-slate-700 text-white",
                      )
                    }
                  >
                    {!item.icon ? (
                      <Icon name="ChevronRight" className="mr-2 h-4 w-4" />
                    ) : (
                      <Icon name={item?.icon} className="mr-2 h-4 w-4" />
                    )}
                    {item.title}
                  </NavLink>
                </button>
              </li>
            ) : (
              <span
                key={item.href}
                className="flex cursor-not-allowed flex-row items-center gap-x-1 px-3 py-2 text-slate-400 opacity-60"
              >
                {!item.icon ? (
                  <Icon name="ChevronRight" className="mr-2 h-4 w-4" />
                ) : (
                  <Icon name={item?.icon} className="mr-2 h-4 w-4" />
                )}
                {item.title}
              </span>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default MobileNav;
