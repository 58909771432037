import React from "react";

const ErrorState = () => {
  return (
    <div className="bg-background flex flex-col items-center justify-center px-4 py-32 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-md text-center">
        <div className="mx-auto h-12 w-12 text-red-500" />
        <h1 className="text-foreground mt-4 text-3xl font-bold tracking-tight sm:text-4xl">
          Qualcosa è andato storto.
        </h1>
        <p className="text-muted-foreground mt-4">
          Prova a ricaricare la pagina. Se l&apos;errore persiste, riprova più
          tardi.
        </p>
      </div>
    </div>
  );
};

export default ErrorState;
