import { format } from "date-fns";
import { it } from "date-fns/locale";
import { Link } from "react-router-dom";
import { capitalize, formatCurrency } from "../../lib/utils";
import Icon from "../ui/Icon";
import { StatusBadge } from "../ui/StatusBadge";

export const rentalColumns = [
  {
    id: "attentionRequired",
    cell: ({ row }) => {
      const booking = row.original;

      return (
        booking.notes.fromPassenger?.description.length && (
          <div>
            <Icon name="AlertCircle" className="h-4 w-4 text-red-500" />
          </div>
        )
      );
    },
  },
  {
    accessorKey: "reference",
    header: "RIF.",
    cell: ({ row }) => {
      return <div className="w-8">{row.original.reference}</div>;
    },
  },
  {
    accessorKey: "from",
    header: "Data inizio",
    cell: ({ row }) => {
      const booking = row.original;
      return (
        <div>
          {capitalize(
            format(new Date(booking.from), "EEE dd MMM HH:mm", { locale: it }),
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "to",
    header: "Data fine",
    cell: ({ row }) => {
      const booking = row.original;
      return (
        <div>
          {capitalize(
            format(new Date(booking.to), "EEE dd MMM HH:mm", { locale: it }),
          )}
        </div>
      );
    },
  },
  {
    accessorFn: (row) => `${row.organizer.firstName} ${row.organizer.lastName}`,
    id: "organizer",
    header: "Nominativo",
    cell: ({ row }) => {
      const booking = row.original;
      return (
        <div>
          {[booking.organizer.firstName, booking.organizer.lastName].join(" ")}
        </div>
      );
    },
  },
  {
    id: "participants",
    header: "Pers.",
    cell: ({ row }) => {
      const booking = row.original;
      return (
        <div className="inline-flex items-center gap-1">
          <Icon name="Users" className="h-4 w-4 text-gray-400" />
          {booking.participants.list.length + 1}
        </div>
      );
    },
  },
  {
    id: "boat",
    header: "Barca",
    accessorFn: (row) => row.boat._id,
    cell: ({ row }) => {
      const booking = row.original;
      return (
        <div className="truncate">
          {booking.boat ? booking.boat.name : "Non assegnato"}
        </div>
      );
    },
  },
  {
    id: "port",
    header: "Porto",
    accessorFn: (row) => row.port?._id,
    cell: ({ row }) => {
      const booking = row.original;
      return (
        <div className="uppercase">
          {booking.port ? booking.port.name : "Non assegnato"}
        </div>
      );
    },
  },
  {
    accessorFn: (row) => `${row.status}`,
    id: "status",
    header: "Stato",
    cell: ({ row }) => {
      const booking = row.original;
      return <StatusBadge status={booking.status} />;
    },
  },
  {
    id: "accounting",
    header: "Saldo",
    cell: ({ row }) => {
      const booking = row.original;

      return (
        <div className="text-sm">
          {formatCurrency.format(booking.paymentInfo.balance)} /{" "}
          {formatCurrency.format(booking.paymentInfo.totalAmount)}
        </div>
      );
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const booking = row.original;

      return (
        <div className="flex flex-row items-center justify-end">
          <Link
            to={`/rentals/${booking._id}`}
            className="rounded border border-gray-300 p-2 text-black hover:bg-gray-100"
          >
            <Icon name="ChevronRight" className="h-4 w-4" />
          </Link>
        </div>
      );
    },
  },
];
