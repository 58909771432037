import { RadioGroupItem } from "@radix-ui/react-radio-group";
import React, { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../ui/AlertDialog";
import { buttonVariants } from "../../ui/Button";
import Checkbox from "../../ui/Checkbox";
import { RadioGroup, RadioGroupIndicator } from "../../ui/RadioGroup";

const CreateRefundModal = ({ open, onOpenChange, paymentData, confirmFn }) => {
  const [hasConfirmedAction, setHasConfirmedAction] = useState(false);
  const [refundMethod, setRefundMethod] = useState("original");
  const [refundData, setRefundData] = useState({
    method: paymentData.method,
    amount: null,
    paymentId: paymentData._id,
  });

  const handleChangeRefundMethod = (newValue) => {
    setHasConfirmedAction(false);
    if (newValue === "original") {
      setRefundData({
        ...refundData,
        method: paymentData.method,
      });
    } else {
      setRefundData({
        ...refundData,
        method: "voucher",
      });
    }
    setRefundMethod(newValue);
  };

  const toggleHasConfirmedAction = () => {
    setHasConfirmedAction(!hasConfirmedAction);
  };

  const handleChangeRefundAmount = (e) => {
    const { value } = e.target;
    setRefundData({
      ...refundData,
      amount: value,
    });
  };

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Emetti un rimborso</AlertDialogTitle>
        </AlertDialogHeader>
        <div className="space-y-3">
          <div className="space-y-1.5">
            <div className="text-sm font-medium">Metodo</div>
            <RadioGroup
              value={refundMethod}
              onValueChange={handleChangeRefundMethod}
              className="grid grid-cols-2 gap-3"
            >
              <div className="flex items-center gap-1.5 text-xs lg:text-sm">
                <RadioGroupItem
                  value="original"
                  className="flex h-4 w-4 items-center justify-center rounded-full border border-gray-200"
                >
                  <RadioGroupIndicator className="h-full w-full rounded-full bg-blue-500 p-1">
                    <div className="h-full w-full rounded-full bg-white" />
                  </RadioGroupIndicator>
                </RadioGroupItem>
                Originale del pagamento
              </div>
              <div className="flex items-center gap-1.5 text-xs lg:text-sm">
                <RadioGroupItem
                  value="voucher"
                  className="flex h-4 w-4 items-center justify-center rounded-full border border-gray-200"
                >
                  <RadioGroupIndicator className="h-full w-full rounded-full bg-blue-500 p-1">
                    <div className="h-full w-full rounded-full bg-white" />
                  </RadioGroupIndicator>
                </RadioGroupItem>
                Voucher
              </div>
            </RadioGroup>
          </div>
          <div className="space-y-1.5 text-sm">
            <div className="flex items-center justify-between">
              <div className="font-medium">Ammontare</div>
              <div>
                <CurrencyInput
                  allowNegativeValue={false}
                  min={0}
                  max={paymentData.amount}
                  value={refundData.amount}
                  onChange={handleChangeRefundAmount}
                  placeholder={((paymentData.amount * 90) / 100).toFixed(2)}
                  disableGroupSeparators={true}
                  className="mt-2 w-16 border-b border-gray-200 focus:outline-none"
                />
                €
              </div>
            </div>
          </div>
          <div className="space-y-1.5 text-sm">
            <div className="flex items-center justify-between">
              <div className="font-medium">Destinazione</div>
              <div>
                {refundMethod === "original" ? paymentData.method : "Voucher"}
              </div>
            </div>
          </div>
          <div className="flex items-center gap-1.5 text-sm">
            <Checkbox
              checked={hasConfirmedAction}
              onCheckedChange={toggleHasConfirmedAction}
            />
            Confermo la correttezza delle informazioni
          </div>
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel className={buttonVariants({ variant: "outline" })}>
            Annulla
          </AlertDialogCancel>
          <AlertDialogAction
            disabled={!hasConfirmedAction || !refundData.amount}
            onClick={() => confirmFn(refundData)}
            className={buttonVariants()}
          >
            Conferma
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CreateRefundModal;
