import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../ui/AlertDialog";
import { buttonVariants } from "../../ui/Button";
import Icon from "../../ui/Icon";
import { toast } from "../../ui/UseToast";

const ConfirmExpenseDeleteModal = ({ expenseId, open, onOpenChange }) => {
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleDeleteExpense = async () => {
    setIsLoading(true);

    axiosPrivate
      .delete(`/expenses/${expenseId}`)
      .then((response) => {
        if (response.status === 200) {
          toast({
            title: "🗑️ Spesa eliminata correttamente.",
            variant: "confirmed",
          });
          return new Promise((resolve) => setTimeout(resolve, 750));
        } else {
          throw new Error("Errore nell'eliminazione della spesa.");
        }
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Errore nell'eliminazione della spesa.",
          variant: "destructive",
        });
      })
      .finally(() => {
        setIsLoading(false);
        navigate("/expenses");
      });
  };

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Sei sicuro di voler eliminare questa spesa?
          </AlertDialogTitle>
        </AlertDialogHeader>
        Questa spesa non potrà più essere recuperata.
        <AlertDialogFooter>
          <AlertDialogCancel className={buttonVariants({ variant: "outline" })}>
            Annulla
          </AlertDialogCancel>
          <AlertDialogAction
            disabled={isLoading}
            onClick={handleDeleteExpense}
            className={buttonVariants({ variant: "destructive" })}
          >
            {!isLoading ? (
              "Elimina"
            ) : (
              <Icon name="Loader" className="h-4 w-4 animate-spin" />
            )}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ConfirmExpenseDeleteModal;
