import React from "react";
import InputMask from "react-input-mask";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../ui/AlertDialog";
import { buttonVariants } from "../../ui/Button";

const AddParticipantModal = ({
  open,
  onOpenChange,
  newParticipant,
  handleNewParticipantChange,
  handleAddNewParticipant,
  handleCancelAddNewParticipant,
}) => {
  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Aggiungi un nuovo partecipante</AlertDialogTitle>
        </AlertDialogHeader>
        <div className="space-y-3">
          <div>
            <div className="grid gap-3 lg:grid-cols-2">
              <div>
                <div className="text-sm font-medium">Nome</div>
                <input
                  name="firstName"
                  value={newParticipant.firstName}
                  onChange={handleNewParticipantChange}
                  placeholder="Nome"
                  className="mt-1 w-full rounded border border-gray-300 px-2 py-1 text-sm focus:outline-none focus:ring focus:ring-1 focus:ring-blue-600"
                />
              </div>
              <div>
                <div className="text-sm font-medium">Cognome</div>
                <input
                  name="lastName"
                  value={newParticipant.lastName}
                  onChange={handleNewParticipantChange}
                  placeholder="Cognome"
                  className="mt-1 w-full rounded border border-gray-300 px-2 py-1 text-sm focus:outline-none focus:ring focus:ring-1 focus:ring-blue-600"
                />
              </div>
            </div>
          </div>
          <div>
            <div className="grid gap-3 lg:grid-cols-2">
              <div>
                <div className="text-sm font-medium">Luogo di nascita</div>
                <input
                  name="pob"
                  value={newParticipant.pob}
                  onChange={handleNewParticipantChange}
                  className="mt-1 w-full rounded border border-gray-300 px-2 py-1 text-sm focus:outline-none focus:ring focus:ring-1 focus:ring-blue-600"
                />
              </div>
              <div>
                <div className="text-sm font-medium">Data di nascita</div>
                <InputMask
                  name="dob"
                  value={newParticipant.dob}
                  onChange={handleNewParticipantChange}
                  mask={"99/99/9999"}
                  maskChar={"_"}
                  permanents={[2, 5]}
                  className="w-full rounded border border-gray-300 px-2 py-1 text-sm focus:outline-none focus:ring focus:ring-1 focus:ring-blue-600"
                />
              </div>
            </div>
          </div>
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel
            onClick={handleCancelAddNewParticipant}
            className={buttonVariants({ variant: "outline" })}
          >
            Annulla
          </AlertDialogCancel>
          <AlertDialogAction
            //disabled={!newParticipant.firstName || !newParticipant.lastName || !newParticipant.dob || !newParticipant.pob}
            onClick={handleAddNewParticipant}
            className={buttonVariants()}
          >
            Conferma
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default AddParticipantModal;
