import React, { useEffect, useState } from "react";
import {
  useForm,
  Controller,
  useFormContext,
  FormProvider,
} from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../../config/roles";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Breadcrumb from "../ui/Breadcrumb";
import { toast } from "../ui/UseToast";
import { Button } from "../ui/Button";
import { Label } from "../ui/Label";
import { Input } from "../ui/Input";
import Checkbox from "../ui/Checkbox";

const RolesSelector = () => {
  const { register, watch, setValue, resetField } = useFormContext();
  const selectedRoles = watch("roles", {});

  const handleRoleChange = (role, isChecked) => {
    if (isChecked) {
      setValue(`roles.${role}`, ROLES[role]);
    } else {
      setValue(`roles.${role}`, undefined);
    }
  };

  return (
    <div className="grid grid-cols-4 gap-6 py-6">
      <div className="col-span-4 sm:col-span-2">
        <div>
          <h2
            id="roles-heading"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            Accessi
          </h2>
          <p className="mt-1 text-sm text-gray-500">
            Imposta gli accessi disponibili.
          </p>
        </div>
      </div>
      <div className="col-span-4 sm:col-span-2">
        <fieldset className="space-y-5">
          {Object.keys(ROLES).map((role) => (
            <div key={role} className="relative flex items-start">
              <div className="flex h-5 items-center">
                <Checkbox
                  className="h-4 w-4 rounded border-gray-300 text-slate-600 focus:ring-slate-500"
                  checked={selectedRoles[role] === ROLES[role]}
                  onCheckedChange={(isChecked) =>
                    handleRoleChange(role, isChecked)
                  }
                  id={`roles-${role}`}
                  value={ROLES[role]}
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor={`roles-${role}`}
                  className="font-medium text-gray-700"
                >
                  {role}
                </label>
              </div>
            </div>
          ))}
        </fieldset>
      </div>
    </div>
  );
};

const userSchema = z.object({
  username: z.string().max(16),
  displayName: z.object({
    firstName: z.string().min(1).max(16),
    lastName: z.string().min(1).max(24),
  }),
  password: z
    .union([z.string().length(0), z.string().min(6)])
    .optional()
    .transform((e) => (e === "" ? undefined : e)),
  roles: z
    .object({
      User: z.number().optional(),
      Skipper: z.number().optional(),
      TourOperator: z.number().optional(),
      Editor: z.number().optional(),
      Admin: z.number().optional(),
    })
    .optional(),
});

const UserView = ({ user, create }) => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  const methods = useForm({
    defaultValues: {
      username: user?.username || "",
      displayName: {
        firstName: user?.displayName.firstName || "",
        lastName: user?.displayName.lastName || "",
      },
      password: "",
      roles: user?.roles || {},
    },
    resolver: zodResolver(userSchema),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    setIsSaving(true);

    const request = create
      ? axiosPrivate.post("/users", data)
      : axiosPrivate.patch(`/users/${user?._id}`, data);

    request
      .then(() => {
        toast({
          title: "User successfully saved",
          variant: "confirmed",
        });
        create && navigate("/users");
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "There was an error updating the profile",
          variant: "destructive",
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleDelete = () => {
    setIsSaving(true);

    axiosPrivate
      .delete(`/users/${user?._id}`)
      .then(() => {
        toast({
          title: "User successfully deleted",
          variant: "confirmed",
        });
        navigate("/users");
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "There was an error deleting the profile",
          variant: "destructive",
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <div className="space-y-6">
      <Breadcrumb />
      <div className="space-y-3 border border-gray-300 bg-white px-6 py-4 shadow lg:rounded">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-semibold leading-6 text-gray-900">
            {user ? `Dettagli utente: ${user.username}` : "Crea nuovo utente"}
          </h1>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-4 gap-6 border-b border-gray-300 py-6">
              <div className="col-span-4 sm:col-span-2">
                <div>
                  <h2
                    id="profile-details-heading"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Profilo
                  </h2>
                  <p className="mt-1 text-sm text-gray-500">
                    Aggiorna le informazioni generali.
                  </p>
                </div>
              </div>
              <div className="col-span-4 sm:col-span-1">
                <Label htmlFor="first-name">Nome</Label>
                <Input type="text" {...register("displayName.firstName")} />
                {errors.displayName?.firstName && (
                  <p className="text-sm text-red-500">
                    {errors.displayName.firstName.message}
                  </p>
                )}
              </div>
              <div className="col-span-4 sm:col-span-1">
                <Label htmlFor="last-name">Cognome</Label>
                <Input type="text" {...register("displayName.lastName")} />
                {errors.displayName?.lastName && (
                  <p className="text-sm text-red-500">
                    {errors.displayName.lastName.message}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-4 gap-6 border-b border-gray-300 py-6">
              <div className="col-span-4 sm:col-span-2">
                <div>
                  <h2
                    id="security-details-heading"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Sicurezza
                  </h2>
                  <p className="mt-1 text-sm text-gray-500">
                    Configura la sicurezza.
                  </p>
                </div>
              </div>
              <div className="col-span-4 sm:col-span-1">
                <Label>Username</Label>
                <Input
                  type="text"
                  {...register("username")}
                  disabled={!create}
                />
              </div>
              <div className="col-span-4 sm:col-span-1">
                <Label
                  htmlFor="password"
                  className="flex items-center text-sm font-medium text-gray-700"
                >
                  Nuova password
                </Label>
                <Input type="password" {...register("password")} />
                {errors.password && (
                  <p className="text-sm text-red-500">
                    {errors.password.message}
                  </p>
                )}
              </div>
            </div>

            {[ROLES.Tech, ROLES.Admin].some((role) =>
              auth?.roles.includes(role),
            ) && <RolesSelector />}

            <div className="flex items-center justify-end gap-3">
              {!create && auth?.roles.includes(7777) && (
                <Button
                  variant="destructive"
                  onClick={handleDelete}
                  disabled={isSaving}
                >
                  Elimina utente
                </Button>
              )}
              <Button type="submit" disabled={isSaving}>
                Salva
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default UserView;
