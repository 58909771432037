import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "../../components/ui/UseToast";
import { Link } from "react-router-dom";
import { cn } from "../../lib/utils";
import { buttonVariants } from "../../components/ui/Button";
import { User2Icon } from "lucide-react";

const TourOperatorList = ({ data }) => {
  return (
    <div className="grid gap-6 lg:grid-cols-3">
      {data.map((item) => (
        <Link
          key={item._id}
          to={`/touroperators/${item._id}`}
          className={cn(
            buttonVariants({ variant: "outline", size: "lg" }),
            "justify-start gap-3 rounded border p-3",
          )}
        >
          <User2Icon
            className={cn("h-5 w-5", item.userId && "text-green-600")}
          />
          <p className="truncate">{item.name}</p>
        </Link>
      ))}
    </div>
  );
};

const TourOperatorListPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  const api = useAxiosPrivate();

  useEffect(() => {
    const fetchTourOperators = () => {
      api
        .get("/touroperators")
        .then((res) => setData(res.data))
        .catch((err) => {
          console.error(err);
          toast({ title: "Errore durante il caricamento dei TO, riprovare" });
        })
        .finally(() => setIsLoading(false));
    };

    fetchTourOperators();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (data !== undefined) {
    return (
      <div className="mx-auto max-w-7xl space-y-6">
        <div className="flex items-center justify-between">
          <h2>T.O.</h2>
          <Link to={"/touroperators/new"} className={buttonVariants()}>
            Aggiungi
          </Link>
        </div>
        <TourOperatorList data={data} />
      </div>
    );
  }
};

export default TourOperatorListPage;
