import { useState, useEffect } from "react";
import { addWeeks, addDays, startOfDay } from "date-fns";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { WeekCalendar } from "../../pages/Planning";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { calculateTimezonedDate } from "../../lib/utils";

const DayCardSkeleton = () => {
  return (
    <div className="box-border flex h-full animate-pulse flex-col border-r border-dashed border-gray-200">
      <div className="h-10 w-full bg-gray-100" />
      <div className="flex h-full w-full flex-1 flex-col items-start gap-1 p-1">
        <div className="mb-2 h-24 w-full rounded bg-gray-200" />
        <div className="mb-2 h-24 w-full rounded bg-gray-200" />
        <div className="h-24 w-full rounded bg-gray-200" />
      </div>
    </div>
  );
};

const WeekPlannerSkeleton = () => {
  return (
    <div className="grid-cols-7 rounded border border-gray-300 lg:grid">
      {Array.from({ length: 7 }).map((_, index) => (
        <DayCardSkeleton key={index} />
      ))}
    </div>
  );
};
const HomePageWeekPlanner = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState();
  const [weeksCount, setWeeksCount] = useState(0);

  const axiosPrivate = useAxiosPrivate();

  const handleAddWeeksCount = () => {
    setWeeksCount(weeksCount + 1);
  };

  const handleSubWeeksCount = () => {
    setWeeksCount(weeksCount - 1);
  };

  const startDate = calculateTimezonedDate(
    addWeeks(startOfDay(new Date()), weeksCount),
  );
  const endDate = calculateTimezonedDate(addDays(startDate, 6));

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      axiosPrivate
        .get("/sorties/v2", {
          params: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          },
        })
        .then((response) => {
          setData(response.data);
          setIsError(false);
        })
        .catch((err) => {
          console.error(err);
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetchData();
  }, [weeksCount, axiosPrivate]);

  return (
    <div className="min-h-[400px] space-y-3">
      <div className="flex items-center justify-between">
        <h3>Prossime uscite</h3>
        <div className="flex items-center divide-x divide-gray-200 rounded border border-gray-200">
          <button
            disabled={weeksCount === 0 || isLoading}
            onClick={handleSubWeeksCount}
            className={`rounded-l ${
              weeksCount !== 0
                ? "text-gray-400 hover:bg-gray-100"
                : "cursor-not-allowed text-gray-200"
            }`}
          >
            <ChevronLeftIcon className="h-6 w-6" />
          </button>
          <button
            disabled={isLoading}
            onClick={handleAddWeeksCount}
            className="rounded-r hover:bg-gray-100"
          >
            <ChevronRightIcon className="h-6 w-6 text-gray-400" />
          </button>
        </div>
      </div>

      {isLoading ? (
        <WeekPlannerSkeleton />
      ) : isError ? (
        <div>Something went wrong...</div>
      ) : (
        data !== undefined && (
          <WeekCalendar
            data={data}
            from={startDate}
            to={endDate}
            uniforms={true}
          />
        )
      )}
    </div>
  );
};

export default HomePageWeekPlanner;
