import { useNavigate } from "react-router-dom";
import ExpensesList from "../components/expenses/ExpensesList";
import { buttonVariants } from "../components/ui/Button";

const Expenses = () => {
  const navigate = useNavigate();

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h2>Spese</h2>
        <button onClick={() => navigate("new")} className={buttonVariants()}>
          Aggiungi
        </button>
      </div>
      <ExpensesList />
    </div>
  );
};

export default Expenses;
