import classNames from "classnames";
import * as React from "react";

const Table = React.forwardRef(({ className, children, ...props }, ref) => (
  <div className="w-full">
    <table
      ref={ref}
      className={classNames("w-full caption-bottom text-sm", className)}
      {...props}
    >
      {children}
    </table>
  </div>
));

const TableHeader = React.forwardRef(
  ({ className, children, ...props }, ref) => (
    <thead
      ref={ref}
      className={classNames("[&_tr]:border-b", className)}
      {...props}
    >
      {children}
    </thead>
  ),
);

const TableBody = React.forwardRef(({ className, children, ...props }, ref) => (
  <tbody
    ref={ref}
    className={classNames("[&_tr:last-child]:border-0", className)}
    {...props}
  >
    {children}
  </tbody>
));

const TableFooter = React.forwardRef(
  ({ className, children, ...props }, ref) => (
    <tfoot
      ref={ref}
      className={classNames(
        "bg-primary text-primary-foreground font-medium",
        className,
      )}
      {...props}
    >
      {children}
    </tfoot>
  ),
);

const TableRow = React.forwardRef(({ className, children, ...props }, ref) => (
  <tr
    ref={ref}
    className={classNames(
      "hover:bg-muted/50 data-[state=selected]:bg-muted border-b transition-colors",
      className,
    )}
    {...props}
  >
    {children}
  </tr>
));

const TableHead = React.forwardRef(({ className, children, ...props }, ref) => (
  <th
    ref={ref}
    className={classNames(
      "text-muted-foreground h-12 px-2 text-left align-middle font-medium [&:has([role=checkbox])]:pr-0",
      className,
    )}
    {...props}
  >
    {children}
  </th>
));

TableHead.Mobile = React.forwardRef(
  ({ className, children, ...props }, ref) => (
    <th
      ref={ref}
      className={classNames(
        "text-muted-foreground h-12 text-left align-middle font-medium [&:has([role=checkbox])]:pr-0",
        className,
      )}
      {...props}
    >
      {children}
    </th>
  ),
);

const TableCell = React.forwardRef(({ className, children, ...props }, ref) => (
  <td
    ref={ref}
    className={classNames(
      "px-2 py-4 align-middle [&:has([role=checkbox])]:pr-0",
      className,
    )}
    {...props}
  >
    {children}
  </td>
));

TableCell.Mobile = React.forwardRef(
  ({ className, children, ...props }, ref) => (
    <td
      ref={ref}
      className={classNames(
        "align-middle [&:has([role=checkbox])]:pr-0",
        className,
      )}
      {...props}
    >
      {children}
    </td>
  ),
);

const TableCaption = React.forwardRef(
  ({ className, children, ...props }, ref) => (
    <caption
      ref={ref}
      className={classNames("text-muted-foreground mt-4 text-sm", className)}
      {...props}
    >
      {children}
    </caption>
  ),
);

export {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
};
