import { format } from "date-fns";
import { it } from "date-fns/locale";
import React, { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { capitalize, cn } from "../../../lib/utils";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../ui/AlertDialog";
import { buttonVariants } from "../../ui/Button";
import { Calendar } from "../../ui/Calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../../ui/Popover";

const EditPaymentModal = ({ open, onOpenChange, callbackFn, payment }) => {
  const [amount, setAmount] = useState(payment.amount);
  const [date, setDate] = useState(new Date(payment.date));
  const [note, setNote] = useState(payment.note);

  const handleChangeAmount = (e) => {
    setAmount(e.target.value);
  };

  const handleChangeNote = (e) => {
    setNote(e.target.value);
  };

  const handleChangeDate = (newValue) => {
    setDate(newValue);
  };

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Aggiungi un pagamento</AlertDialogTitle>
        </AlertDialogHeader>
        <div className="space-y-3">
          <div className="grid grid-cols-2">
            <div>
              <div className="font-medium">Data</div>
              <div>
                <Popover>
                  <PopoverTrigger asChild>
                    <button
                      className={cn(
                        "w-[200px] border px-4 py-2 text-center text-sm text-black",
                      )}
                    >
                      {date ? (
                        capitalize(format(date, "PPP", { locale: it }))
                      ) : (
                        <span>Data</span>
                      )}
                    </button>
                  </PopoverTrigger>
                  <PopoverContent className="mt-3 w-auto p-0">
                    <Calendar
                      mode="single"
                      selected={date}
                      onSelect={handleChangeDate}
                      className="flex justify-center"
                      initialMonth={new Date()}
                    />
                  </PopoverContent>
                </Popover>
              </div>
            </div>
            <div>
              <div className="font-medium">Ammontare</div>
              <div className="flex items-center">
                <CurrencyInput
                  allowNegativeValue={false}
                  min={0}
                  value={amount}
                  onChange={handleChangeAmount}
                  disableGroupSeparators={true}
                  className="mt-2 w-16 border-b border-gray-200 focus:outline-none"
                />
                €
              </div>
            </div>
          </div>
          <div>
            <div className="font-medium">Note</div>
            <div>
              <textarea
                className="w-full rounded border border-gray-200 p-3 text-sm"
                value={note}
                onChange={handleChangeNote}
                placeholder="Nota"
              />
            </div>
          </div>
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel className={buttonVariants({ variant: "outline" })}>
            Annulla
          </AlertDialogCancel>
          <AlertDialogAction
            disabled={!amount || !date}
            onClick={() =>
              callbackFn({
                _id: payment._id,
                amount: amount,
                date: date,
                note: note,
                paidTo: "646cb53c81ac9bea7595e776",
              })
            }
            className={buttonVariants()}
          >
            Salva
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default EditPaymentModal;
