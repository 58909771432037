import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Loading from "../ui/Loading";
import ExpenseView from "./ExpenseView";
import { toast } from "../ui/UseToast";

const ExpensePage = () => {
  const { id } = useParams();
  const [expense, setExpense] = useState();
  const axiosPrivate = useAxiosPrivate();

  const refreshExpense = async () => {
    axiosPrivate
      .get(`/expenses/${id}`)
      .then((res) => {
        setExpense(res.data);
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "Errore durante l'aggiornamento della spesa",
        });
      })
      .finally(() => {});
  };

  useEffect(() => {
    const getExpense = async () => {
      axiosPrivate
        .get(`/expenses/${id}`)
        .then((res) => setExpense(res.data))
        .catch((err) => {
          console.error(err);
          toast({ title: "Errore durante l'aggiunta della spesa" });
        })
        .finally(() => {});
    };

    getExpense();
  }, []);

  return expense ? (
    <ExpenseView expense={expense} refreshFn={refreshExpense} />
  ) : (
    <Loading />
  );
};

export default ExpensePage;
