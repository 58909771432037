import Breadcrumb from "../components/ui/Breadcrumb";
import NewRentalView from "../components/rentals/new/NewRentalView";

const NewRentalPage = () => {
  return (
    <div className="mx-auto max-w-7xl space-y-6">
      <Breadcrumb />
      <h2>Inserisci un nuovo noleggio</h2>
      <NewRentalView />
    </div>
  );
};

export default NewRentalPage;
