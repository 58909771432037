import { endOfMonth, format, startOfMonth, subMonths } from "date-fns";
import { it } from "date-fns/locale";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/ui/Breadcrumb";
import { buttonVariants } from "../../components/ui/Button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../components/ui/Collapsible";
import Icon from "../../components/ui/Icon";
import Loading from "../../components/ui/Loading";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/Popover";
import { Calendar } from "../../components/ui/RangeCalendar";
import { toast } from "../../components/ui/UseToast";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { capitalize, cn, formatCurrency } from "../../lib/utils";

const ExpensesReportPage = () => {
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [expensesReport, setExpensesReport] = useState();
  const [period, setPeriod] = useState("last_month");

  const lastMonth = subMonths(new Date(), 1);
  const firstDateOfLastMonth = startOfMonth(lastMonth);
  const lastDateOfLastMonth = endOfMonth(lastMonth);

  const [expensesReportRange, setExpensesReportRange] = useState({
    from: firstDateOfLastMonth,
    to: lastDateOfLastMonth,
  });
  const [expensesReportFilters, setExpensesReportFilters] = useState({
    startDate: format(new Date(firstDateOfLastMonth), "yyyy-MM-dd"),
    endDate: format(new Date(lastDateOfLastMonth), "yyyy-MM-dd"),
  });

  const handleExpenseReportRangeChange = (newValue) => {
    if (newValue) {
      setExpensesReportRange(newValue);
      if (newValue.from && newValue.to) {
        setExpensesReportFilters({
          ...expensesReportFilters,
          startDate: format(new Date(newValue.from), "yyyy-MM-dd"),
          endDate: format(new Date(newValue.to), "yyyy-MM-dd"),
        });
      }
    } else {
      setExpensesReportRange();
      setExpensesReportFilters();
    }
  };

  async function getExpensesReports() {
    setIsLoading(true);
    setExpensesReport();
    try {
      toast({
        title: "Creazione report in corso...",
        description: "⌛ Attendere prego.",
      });
      const response = await axiosPrivate.get("/reports/expenses", {
        params: expensesReportFilters,
      });
      setExpensesReport(response.data);
      toast({
        description: "✔️ Report generato correttamente.",
        variant: "confirmed",
      });
    } catch (err) {
      console.log(err);
      toast({
        title: "Qualcosa è andato storto...",
        description:
          "❌ Non è stato possibile generare un report per gli expense.",
        variant: "destructive",
      });
    }
    setIsLoading(false);
  }

  const resetExpensesReportFilters = () => {
    setExpensesReportRange();
    setExpensesReportFilters();
  };

  const handleSelectPeriod = (e) => {
    setPeriod(e.target.value);
    if (!e.target.value) {
      resetExpensesReportFilters();
    }
    if (e.target.value === "last_month") {
      const lastMonth = subMonths(new Date(), 1);
      const firstDateOfLastMonth = startOfMonth(lastMonth);
      const lastDateOfLastMonth = endOfMonth(lastMonth);

      setExpensesReportRange({
        from: firstDateOfLastMonth,
        to: lastDateOfLastMonth,
      });

      setExpensesReportFilters({
        startDate: format(new Date(firstDateOfLastMonth), "yyyy-MM-dd"),
        endDate: format(new Date(lastDateOfLastMonth), "yyyy-MM-dd"),
      });
    }
    if (e.target.value === "month_start") {
      const firstDateOfThisMonth = startOfMonth(new Date());
      const today = new Date();
      setExpensesReportRange({
        from: firstDateOfThisMonth,
        to: today,
      });

      setExpensesReportFilters({
        startDate: format(new Date(firstDateOfThisMonth), "yyyy-MM-dd"),
        endDate: format(new Date(today), "yyyy-MM-dd"),
      });
    }
    if (e.target.value === "june") {
      const firstDateOfJune = startOfMonth(new Date("2023-06-01"));
      const lastDateOfJune = endOfMonth(new Date("2023-06-01"));
      setExpensesReportRange({
        from: firstDateOfJune,
        to: lastDateOfJune,
      });

      setExpensesReportFilters({
        startDate: format(new Date(firstDateOfJune), "yyyy-MM-dd"),
        endDate: format(new Date(lastDateOfJune), "yyyy-MM-dd"),
      });
    }
    if (e.target.value === "season_start") {
      const firstDateOfSeason = startOfMonth(new Date("2023-06-01"));
      const today = new Date();
      setExpensesReportRange({
        from: firstDateOfSeason,
        to: today,
      });

      setExpensesReportFilters({
        startDate: format(new Date(firstDateOfSeason), "yyyy-MM-dd"),
        endDate: format(new Date(today), "yyyy-MM-dd"),
      });
    }
  };

  useEffect(() => {
    getExpensesReports();
  }, [period]);

  return (
    <div className="mx-auto max-w-7xl space-y-6">
      <Breadcrumb />
      <div className="flex items-center justify-between">
        <div>
          <h2>Report sul carburante</h2>
        </div>
      </div>
      <div className="space-y-3">
        <div className="flex items-center gap-1.5">
          <select
            onChange={handleSelectPeriod}
            value={period}
            className="flex items-center gap-1 rounded border border-gray-200 px-2 py-1 text-xs font-medium"
          >
            <option value="">Personalizzato</option>
            <option value="month_start">Da inizio mese</option>
            <option value="season_start">Da inizio stagione</option>
            <option value="last_month">Ultimo mese</option>
            <option value="june">Giu 2023</option>
          </select>
          <Popover>
            <PopoverTrigger asChild>
              <button className="flex items-center gap-1 rounded border border-gray-200 px-2 py-1 text-xs font-medium">
                <Icon name="Calendar" className="h-3 w-3 text-gray-400" />
                {!expensesReportRange?.from &&
                  !expensesReportRange?.to &&
                  "Periodo"}
                {expensesReportRange?.from &&
                  format(new Date(expensesReportRange.from), "MMM dd", {
                    locale: it,
                  })}
                {expensesReportRange?.from && expensesReportRange?.to && " - "}
                {expensesReportRange?.to &&
                  format(new Date(expensesReportRange.to), "MMM dd", {
                    locale: it,
                  })}
              </button>
            </PopoverTrigger>
            <PopoverContent className="mt-3 w-auto p-0">
              <Calendar
                mode="range"
                selected={expensesReportRange}
                onSelect={handleExpenseReportRangeChange}
              />
              <div className="flex items-center gap-1.5 px-3 pb-3">
                <button
                  disabled={isLoading || !expensesReportFilters}
                  onClick={() => getExpensesReports()}
                  className={cn("flex-1", buttonVariants({ size: "sm" }))}
                >
                  {isLoading ? (
                    <Icon name="Loader" className="h-4 w-4 animate-spin" />
                  ) : (
                    "Genera"
                  )}
                </button>
                <button
                  onClick={() => resetExpensesReportFilters()}
                  className={cn(
                    "flex-1",
                    buttonVariants({ variant: "outline", size: "sm" }),
                  )}
                >
                  Azzera
                </button>
              </div>
            </PopoverContent>
          </Popover>
        </div>
        <div className="font-medium">
          Carburante registrato per imbarcazione
        </div>
        {isLoading && <Loading />}
        {expensesReport && (
          <div className="space-y-6">
            {expensesReport.map((item) => {
              return (
                <Collapsible
                  key={item.boat.name}
                  className="rounded border border-gray-200"
                >
                  <CollapsibleTrigger>
                    <div className="flex items-center justify-between p-3">
                      <div className="font-medium">{item.boat.name}</div>
                      <div>
                        <Icon name="ChevronDown" className="h-4 w-4" />
                      </div>
                    </div>
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <div className="space-y-1.5 bg-gray-50 p-1.5 text-sm lg:col-span-2">
                      {item.expensesByDate.map((day) => {
                        return (
                          <Collapsible
                            key={day}
                            className="rounded border border-gray-200 bg-white p-1.5"
                          >
                            <CollapsibleTrigger>
                              <div className="flex items-center justify-between">
                                <div className="flex items-center gap-3">
                                  <div className="font-medium">
                                    {capitalize(
                                      format(new Date(day.date), "dd MMM yy", {
                                        locale: it,
                                      }),
                                    )}
                                  </div>
                                </div>
                                {day.expenses.length !== 1 && (
                                  <div className="flex items-center gap-1.5 font-medium text-red-500">
                                    <Icon
                                      name="AlertTriangle"
                                      className="h-3 w-3"
                                    />
                                    Non presente
                                  </div>
                                )}
                              </div>
                            </CollapsibleTrigger>
                            <CollapsibleContent>
                              {day.expenses.map((expense) => {
                                return (
                                  <div
                                    key={expense.expenseId}
                                    className="flex items-center justify-between"
                                  >
                                    <div>
                                      {formatCurrency.format(expense.amount)}
                                    </div>
                                    <Link
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      to={`/expenses/${expense.expenseId}`}
                                      className="flex items-center gap-1.5 font-medium text-blue-700 transition-colors hover:text-blue-500"
                                    >
                                      Visualizza spesa
                                      <Icon
                                        name="ExternalLink"
                                        className="h-3 w-3"
                                      />
                                    </Link>
                                  </div>
                                );
                              })}
                              <div className="flex items-center justify-between">
                                <div />
                                {!day.expenses.length && (
                                  <Link
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    to={`/expenses/new?date=${format(new Date(day.date), "yyyy-MM-dd")}&type=related`}
                                    className="colors flex items-center gap-1.5 font-medium text-yellow-700 transition hover:text-yellow-500"
                                  >
                                    Risolvi
                                  </Link>
                                )}
                              </div>
                            </CollapsibleContent>
                          </Collapsible>
                        );
                      })}
                    </div>
                  </CollapsibleContent>
                </Collapsible>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpensesReportPage;
