import React, { useState } from "react";
import { buttonVariants } from "../ui/Button";
import EmptyState from "../ui/EmptyState";

import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { format } from "date-fns";
import { it } from "date-fns/locale";
import { capitalize, cn } from "../../lib/utils";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../ui/AlertDialog";
import { Calendar } from "../ui/Calendar";
import Icon from "../ui/Icon";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/Popover";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/Table";

const MobileDataTable = ({
  columns,
  data,
  date,
  handleDateChange,
  boat,
  handleBoatChange,
}) => {
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      columnFilters,
      globalFilter,
    },
  });

  return (
    <div className="w-full">
      <div className="space-y-1.5">
        <input
          placeholder="Cerca"
          value={globalFilter ?? ""}
          onChange={(event) => setGlobalFilter(String(event.target.value))}
          className="w-full rounded border border-gray-400 px-4 py-2 text-sm focus:outline-none focus:ring focus:ring-1 focus:ring-blue-600"
        />
        <div className="grid grid-cols-2 gap-3">
          <button
            onClick={() => setShowFiltersModal(true)}
            className={buttonVariants()}
          >
            <Icon name="SlidersHorizontal" className="h-4 w-4 text-gray-200" />
            Filtri
          </button>
        </div>
      </div>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow
              key={headerGroup.id}
              className="border-slate-700 uppercase"
            >
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead.Mobile key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead.Mobile>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell.Mobile key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell.Mobile>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                className="h-24 p-0 text-center"
              >
                <EmptyState title="Nessun risultato" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className="flex items-center justify-end space-x-2 py-4">
        <button
          className={buttonVariants({ variant: "link" })}
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Precedente
        </button>
        <button
          className={buttonVariants({ variant: "link" })}
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Successiva
        </button>
      </div>
      {showFiltersModal && (
        <AlertDialog open={showFiltersModal} onOpenChange={setShowFiltersModal}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Filtri</AlertDialogTitle>
            </AlertDialogHeader>
            <div className="space-y-3">
              <Popover>
                <PopoverTrigger asChild>
                  <button
                    className={cn(
                      "w-full rounded border border-gray-200 px-3 py-2 text-center font-normal text-black",
                      !date && "text-gray-400",
                    )}
                  >
                    {date ? (
                      capitalize(format(date, "PPP", { locale: it }))
                    ) : (
                      <div className="inline-flex items-center gap-1.5">
                        <Icon name="Calendar" className="h-4 w-4" />
                        Data
                      </div>
                    )}
                  </button>
                </PopoverTrigger>
                <PopoverContent className="mt-3 w-auto p-0">
                  <Calendar
                    mode="single"
                    selected={date}
                    onSelect={handleDateChange}
                  />
                </PopoverContent>
              </Popover>
              <div className="grid grid-cols-2 gap-3">
                <div className="space-y-1.5">
                  <div>Tour</div>
                  <select
                    value={table.getColumn("tour")?.getFilterValue() ?? ""}
                    onChange={(event) =>
                      table
                        .getColumn("tour")
                        ?.setFilterValue(event.target.value)
                    }
                    className={cn(
                      "w-full rounded border border-gray-400 px-3 py-2 text-sm focus:outline-none focus:ring focus:ring-1 focus:ring-blue-600",
                      !table.getColumn("tour")?.getFilterValue() &&
                        "text-gray-400",
                    )}
                  >
                    <option value="">Tutti</option>
                    <option value="tremiti">Tremiti</option>
                    <option value="costa">Costa</option>
                    <option value="croazia">Costa</option>
                    <option value="altro">Altro</option>
                  </select>
                </div>
                <div className="space-y-1.5">
                  <div>Mezzo</div>
                  <select
                    value={boat ?? ""}
                    onChange={handleBoatChange}
                    className={cn(
                      "w-full rounded border border-gray-400 px-3 py-2 text-sm focus:outline-none focus:ring focus:ring-1 focus:ring-blue-600",
                      (!boat || boat === "") && "text-gray-400",
                    )}
                  >
                    <option value="">Tutti</option>
                    <option value="64562849015d56d853675b30">Nathan 1</option>
                    <option value="646c983fb557800feb9ca17a">Nathan 2</option>
                    <option value="646c9851b557800feb9ca17c">Nathan 3</option>
                    <option value="646c998b642548af7a1d5b9f">Nathan 4</option>
                  </select>
                </div>
              </div>
            </div>
            <AlertDialogFooter>
              <AlertDialogCancel
                className={buttonVariants({ variant: "outline" })}
              >
                Chiudi
              </AlertDialogCancel>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </div>
  );
};

export default MobileDataTable;
