import { addDays, format, subDays } from "date-fns";
import { it } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { capitalize, cn } from "../../lib/utils";
import { Calendar } from "../ui/Calendar";
import Icon from "../ui/Icon";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/Popover";
import { columns } from "./Columns";
import DataTable from "./DataTable";
import { toast } from "../ui/UseToast";

const ExpensesList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dateParam = searchParams.get("date");
  const boatParam = searchParams.get("boat");
  const [date, setDate] = useState(dateParam ? new Date(dateParam) : null);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [expenses, setExpenses] = useState();
  const [users, setUsers] = useState();
  const [boat, setBoat] = useState(boatParam);

  const axiosPrivate = useAxiosPrivate();

  const handleBoatChange = (e) => {
    searchParams.delete("boat");
    setBoat(e.target.value);
    if (e.target.value && e.target.value !== "") {
      searchParams.set("boat", e.target.value);
    }
    setSearchParams(searchParams);
  };

  const handleDateChange = (newValue) => {
    searchParams.delete("date");
    setDate(newValue);
    console.log(newValue);
    if (newValue) {
      searchParams.set("date", format(newValue, "yyyy-MM-dd"));
    }
    setSearchParams(searchParams);
  };

  const handleAddDay = () => {
    console.log(date);
    if (!date) {
      handleDateChange(addDays(new Date(), 1));
    } else {
      let newDate = addDays(date, 1);
      handleDateChange(newDate);
    }
  };

  const handleSubDay = () => {
    if (!date) {
      handleDateChange(subDays(new Date(), 1));
    } else {
      let newDate = subDays(date, 1);
      handleDateChange(newDate);
    }
  };

  useEffect(() => {
    const getExpenses = () => {
      setIsLoading(true);
      axiosPrivate
        .get("/expenses", {
          params: searchParams,
        })
        .then((response) => {
          setExpenses(response.data);
        })
        .catch((err) => {
          console.error(err);
          toast({
            title: "Problema con il caricamento dei dati dal server. Riprovare",
            variant: "destructive",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    getExpenses();
  }, [date, boat, searchParams]); // Ensure searchParams is included in the dependency array

  return (
    <div className="space-y-6">
      <div className="flex gap-3">
        <div className="flex h-full items-center rounded border border-gray-400">
          <button onClick={() => handleSubDay()} className="p-1.5">
            <Icon name="ChevronLeft" className="h-4 w-4 text-slate-700" />
          </button>
          <Popover>
            <PopoverTrigger asChild>
              <button
                className={cn(
                  "w-[200px] px-4 py-2 text-center text-sm text-black",
                )}
              >
                {date ? (
                  capitalize(format(date, "PPP", { locale: it }))
                ) : (
                  <span>Data</span>
                )}
              </button>
            </PopoverTrigger>
            <PopoverContent className="mt-3 w-auto p-0">
              <Calendar
                mode="single"
                selected={date}
                onSelect={handleDateChange}
              />
            </PopoverContent>
          </Popover>

          <button onClick={() => handleAddDay()} className="p-1.5">
            <Icon name="ChevronRight" className="h-4 w-4 text-slate-700" />
          </button>
        </div>
        <div className="relative flex items-center">
          <Icon
            name="Sailboat"
            className="absolute ml-3 h-4 w-4 text-gray-400"
          />
          <select
            value={boat ?? ""}
            onChange={handleBoatChange}
            className={cn(
              "rounded border border-gray-400 py-2 pl-9 pr-4 text-sm focus:outline-none focus:ring focus:ring-1 focus:ring-blue-600",
            )}
          >
            <option value="">Mezzo: Tutti</option>
            <option value="64562849015d56d853675b30">Nathan 1</option>
            <option value="646c983fb557800feb9ca17a">Nathan 2</option>
            <option value="646c9851b557800feb9ca17c">Nathan 3</option>
            <option value="646c998b642548af7a1d5b9f">Nathan 4</option>
          </select>
        </div>
      </div>
      {expenses && (
        <DataTable
          columns={columns}
          data={expenses}
          date={date}
          handleDateChange={handleDateChange}
        />
      )}
    </div>
  );
};

export default ExpensesList;
