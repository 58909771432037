import { useLocation, useNavigate } from "react-router-dom";
import Icon from "./Icon";

const Breadcrumb = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = () => {
    if (location.key === window.history.state.key) {
      const pathArray = location.pathname.split("/");
      pathArray.pop();
      const newPath = pathArray.join("/") || "/";
      navigate(newPath, { state: { from: location }, replace: true });
    } else {
      navigate(-1, { state: { from: location }, replace: true });
    }
  };

  return (
    <div
      className="flex cursor-pointer items-center text-gray-400 transition hover:text-gray-700"
      onClick={handleNavigation}
    >
      <Icon name="ArrowLeft" className="h-5 w-5 flex-shrink-0" />
      <span className="ml-2 text-sm font-medium">Indietro</span>
    </div>
  );
};

export default Breadcrumb;
