import { format } from "date-fns";
import { it } from "date-fns/locale";
import React, { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { capitalize, formatCurrency } from "../../lib/utils";
import Breadcrumb from "../ui/Breadcrumb";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/DropdownMenu";
import EventsFeed from "../ui/EventsFeed";
import Icon from "../ui/Icon";
import NoteForm from "../ui/NoteForm";
import { toast } from "../ui/UseToast";
import ConfirmExpenseDeleteModal from "./modals/ConfirmExpenseDeleteModal";

const ExpenseView = ({ expense, refreshFn }) => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const allowed_roles = [7777, 5000, 2500];

  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isSplit, setIsSplit] = useState(expense.split);
  const [showConfirmExpenseDeleteModal, setShowConfirmExpenseDeleteModal] =
    useState(false);

  const [isAmountEditingEnabled, setIsAmountEditingEnabled] = useState(false);
  const [newExpense, setNewExpense] = useState(expense);

  const handleAmountChange = (newValue) => {
    setNewExpense({ ...newExpense, amount: newValue });
  };

  const handleEditAmount = () => {
    setIsAmountEditingEnabled(true);
  };

  const handleDeleteExpense = async () => {
    setShowConfirmExpenseDeleteModal(true);
  };

  const handleEditSplit = async (e) => {
    let newValue = newExpense.split ? false : true;
    setIsSplit(newValue);
    let editedNewExpense = newExpense;
    editedNewExpense.split = newValue;
    setNewExpense(editedNewExpense);
    await handleSaveExpense();
  };

  const handleSaveExpense = async () => {
    try {
      let update = {};
      console.log(newExpense);
      console.log(newExpense.split, expense.split);
      if (newExpense.amount !== expense.amount) {
        update.amount = parseFloat(newExpense.amount.replace(",", "."));
      }

      if (isSplit !== expense.split) {
        update.split = newExpense.split;
      }

      const response = await axiosPrivate.patch(
        `/expenses/${expense._id}`,
        update,
      );
      refreshFn();
      toast({
        description: "✔️ Modifica salvata.",
        variant: "confirmed",
      });
    } catch (err) {
      console.log(err);
      toast({
        title: "Qualcosa è andato storto...",
        description: "❌ La modifica non è andata a buon fine.",
        variant: "destructive",
      });
    }
    setIsAmountEditingEnabled(false);
  };

  const handleSettleExpense = async () => {
    try {
      let update = {};

      update = { settled: true };

      const response = await axiosPrivate.patch(
        `/expenses/${expense._id}`,
        update,
      );
      refreshFn();
      toast({
        description: "✔️ Modifica salvata.",
        variant: "confirmed",
      });
    } catch (err) {
      console.log(err);
      toast({
        title: "Qualcosa è andato storto...",
        description: "❌ La modifica non è andata a buon fine.",
        variant: "destructive",
      });
    }
  };

  return (
    <>
      <div className="mx-auto max-w-7xl space-y-6">
        <Breadcrumb />
        <div className="rounded border border-gray-300 shadow">
          <div className="border-b border-gray-300 px-6 py-4">
            <div className="flex items-center justify-between">
              <div className="space-y-1.5">
                <h1 className="text-lg font-semibold leading-6 text-gray-900">
                  Dettagli spesa
                </h1>
                <div className="flex items-center gap-2">
                  <div className="flex w-24 items-center gap-1.5 rounded border border-gray-200 p-1.5">
                    <Icon
                      name={expense.type === "related" ? "Fuel" : "Package"}
                      className="h-3 w-3 text-gray-400"
                    />
                    <div className="text-xs font-medium">
                      {expense.type === "related" ? "Carburante" : "Varie"}
                    </div>
                  </div>
                  {expense.settled ? (
                    <div className="flex w-24 items-center gap-1.5 rounded border border-gray-200 p-1.5">
                      <Icon
                        name={expense.settled ? "Check" : "BadgeAlert"}
                        className="h-3 w-3 text-gray-400"
                      />
                      <div className="text-xs font-medium text-gray-400">
                        Saldato
                      </div>
                    </div>
                  ) : (
                    <DropdownMenu>
                      <DropdownMenuTrigger>
                        <div className="flex w-24 items-center gap-1.5 rounded border border-gray-200 p-1.5 transition-colors hover:border-blue-500 focus:border-blue-500">
                          <Icon
                            name={
                              expense.type === "related"
                                ? "Check"
                                : "BadgeAlert"
                            }
                            className="h-3 w-3 text-gray-400"
                          />
                          <div className="text-xs font-medium">Da saldare</div>
                        </div>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuItem
                          asChild={true}
                          className="gap-1 text-xs transition-colors hover:bg-gray-100"
                        >
                          <button onClick={handleSettleExpense}>
                            <Icon
                              name="Check"
                              className="h-3 w-3 text-gray-400"
                            />
                            Segna come saldato
                          </button>
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  )}
                  {expense.type === "general" && (
                    <div className="flex items-center gap-1 text-sm">
                      <input
                        type="checkbox"
                        checked={isSplit}
                        onChange={handleEditSplit}
                      />
                      Diviso due
                    </div>
                  )}
                </div>
              </div>
              <div>
                <button
                  onClick={handleDeleteExpense}
                  disabled={
                    !auth.roles?.some((value) =>
                      allowed_roles.includes(value),
                    ) && auth.id !== expense.submittedBy._id
                  }
                >
                  <Icon
                    name="Trash"
                    className="h-4 w-4 text-gray-400 transition-colors hover:text-red-500"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="space-y-6">
            <div className="grid lg:grid-cols-3">
              <div className="col-span-2 space-y-3 p-3">
                <div className="font-medium">
                  {expense.type === "general" && expense.title
                    ? `"${expense.title}"`
                    : "Carburante"}
                </div>
                <div className="grid gap-3 lg:grid-cols-2">
                  {expense.timestamp && (
                    <div className="inline-flex items-center gap-1.5 text-sm">
                      <div className="font-medium text-gray-500">
                        Inserita il:{" "}
                      </div>
                      {capitalize(
                        format(
                          new Date(expense.timestamp),
                          "EEE dd MMM, HH:mm",
                          { locale: it },
                        ),
                      )}
                    </div>
                  )}
                  {expense.type === "general" &&
                    !!expense.description.length && (
                      <div className="inline-flex items-start gap-1.5 text-sm">
                        <div className="font-medium text-gray-500">
                          Descrizione{" "}
                        </div>
                        {expense.description}
                      </div>
                    )}
                  {expense.type === "related" && (
                    <>
                      <div className="inline-flex items-center gap-1.5 text-sm">
                        <div className="font-medium text-gray-500">
                          Imbarcazione{" "}
                        </div>
                        {expense.boat ? (
                          <div className="flex w-24 items-center gap-1.5 rounded border border-gray-200 p-1.5">
                            <Icon
                              name="Sailboat"
                              className="h-3 w-3 text-gray-400"
                            />
                            <div className="text-xs font-medium">
                              {expense.boat?.name}
                            </div>
                          </div>
                        ) : (
                          <button className="flex w-24 items-center justify-center gap-1.5 rounded border border-dashed border-gray-200 p-1.5 text-gray-400 transition-colors hover:border-slate-700 hover:text-slate-700 focus:outline-none">
                            <Icon name="Plus" className="h-3 w-3" />
                            <div className="text-xs font-medium">Mezzo</div>
                          </button>
                        )}
                      </div>
                      <div className="inline-flex items-center gap-1.5 text-sm">
                        <div className="font-medium text-gray-500">
                          Quantità{" "}
                        </div>
                        {expense.quantity}L
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="space-y-3 p-3">
                <div className="rounded border border-gray-200 bg-gray-50 p-3">
                  <div className="space-y-3">
                    <div>
                      <div className="font-medium">Totale</div>
                      <div className="flex items-center justify-start font-medium">
                        {isAmountEditingEnabled ? (
                          <button onClick={handleSaveExpense}>
                            <Icon
                              name="Check"
                              className="mr-1.5 h-4 w-4 text-green-200 transition-colors hover:text-green-500"
                            />
                          </button>
                        ) : (
                          <button onClick={handleEditAmount}>
                            <Icon
                              name="Edit"
                              className="mr-1.5 h-4 w-4 text-gray-400 transition-colors hover:text-blue-500"
                            />
                          </button>
                        )}
                        <div className="flex items-center text-2xl">
                          {isAmountEditingEnabled ? (
                            <>
                              <CurrencyInput
                                disabled={!isAmountEditingEnabled}
                                decimalsLimit={2}
                                allowNegativeValue={false}
                                min={0}
                                placeholder="0,00"
                                value={newExpense.amount}
                                onValueChange={handleAmountChange}
                                className="flex-0 w-16 border-b border-blue-500 bg-transparent focus:outline-none"
                                intlConfig={{ locale: "it-IT" }}
                              />
                              €
                            </>
                          ) : (
                            <div className="text-2xl font-medium">
                              {formatCurrency.format(expense.amount)}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="space-y-1.5">
                      <div className="flex items-center justify-between text-sm">
                        <div className="inline-flex items-center gap-1.5">
                          <Icon name="Calendar" className="h-4 w-4" />
                          Sostenuta il
                        </div>
                        {capitalize(
                          format(new Date(expense.date), "dd MMM, yyyy", {
                            locale: it,
                          }),
                        )}
                      </div>
                      <div className="flex items-center justify-between text-sm">
                        <div className="inline-flex items-center gap-1.5">
                          <Icon name="User" className="h-4 w-4" />
                          Registrata da
                        </div>
                        <div className="font-medium">
                          {expense.submittedBy.displayName.firstName}
                        </div>
                      </div>
                      {expense.paidForBy._id !== expense.submittedBy._id && (
                        <div className="flex items-center justify-between text-sm">
                          <div className="inline-flex items-center gap-1.5">
                            <Icon name="HelpingHand" className="h-4 w-4" />
                            Anticipata da
                          </div>
                          <div className="font-medium">
                            {expense.paidForBy.displayName.firstName}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {!!expense.events?.length && (
                  <div className="space-y-1.5">
                    <div className="font-medium">Storico</div>
                    <EventsFeed
                      entityId={expense._id}
                      events={expense.events}
                      entity="expense"
                      refreshFn={refreshFn}
                    />
                  </div>
                )}
                <NoteForm
                  id={expense._id}
                  entity="expense"
                  refreshFn={refreshFn}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showConfirmExpenseDeleteModal && (
        <ConfirmExpenseDeleteModal
          expenseId={expense._id}
          open={showConfirmExpenseDeleteModal}
          onOpenChange={setShowConfirmExpenseDeleteModal}
        />
      )}
    </>
  );
};

export default ExpenseView;
