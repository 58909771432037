import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/Dialog";
import Icon from "../../ui/Icon";
import Checkbox from "../../ui/Checkbox";
import { Button } from "../../ui/Button";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "../../ui/UseToast";

const ResendEmail = ({ booking }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [destinationEmail, setDestionationEmail] = useState(booking.email);
  const [notifyChanges, setNotifyChanges] = useState(false);

  const api = useAxiosPrivate();

  function resendEmail() {
    setIsLoading(true);

    api
      .post(
        `/bookings/${booking._id}/resend-email`,
        {},
        {
          params: {
            email: destinationEmail,
            modify: notifyChanges,
          },
        },
      )
      .then((response) => {
        toast({ description: "Email mandata correttamente" });
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "Errore nell'invio email",
        });
      })
      .finally(() => {
        setOpen(false);
        setIsLoading(false);
      });
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <button className="focus-visible:ring-ring ring-offset-background inline-flex h-10 items-center justify-between rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-slate-700 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">
          <Icon name="Mail" className="h-4 w-4 text-gray-300" />
          Rimanda e-mail
          <div />
        </button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Rimanda e-mail</DialogTitle>
          <DialogDescription>
            Manda una mail di accesso alla prenotazione per il cliente. &#200;
            possibile specificare un indirizzo diverso da quello associato alla
            prenotazione.
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-2 text-sm">
          <div className="flex flex-row items-center">
            <Icon name="Mail" className="absolute ml-2 h-4 w-4 text-gray-300" />
            <input
              autoComplete="off"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              id="email"
              type="email"
              value={destinationEmail}
              onChange={(e) => setDestionationEmail(e.target.value)}
              className="grow rounded border border-gray-300 px-2 py-1 pl-7 text-sm focus:outline-none focus:ring focus:ring-blue-600"
            />
          </div>
          <div className="flex items-center gap-1.5">
            <Checkbox
              checked={notifyChanges}
              onCheckedChange={setNotifyChanges}
            />
            <div className="text-sm text-slate-700">
              Fai sapere al cliente che ci sono state delle modifiche
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button onClick={() => setOpen(false)} variant="secondary">
            Annulla
          </Button>
          <Button onClick={resendEmail}>Conferma</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ResendEmail;
