import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Breadcrumb from "../ui/Breadcrumb";
import Loading from "../ui/Loading";
import BookingView from "./BookingView";
import { toast } from "../ui/UseToast";

const BookingPage = () => {
  const { id } = useParams();
  const [booking, setBooking] = useState();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const refreshBooking = async () => {
    axiosPrivate
      .get(`/bookings/${id}`)
      .then((response) => {
        setBooking(response.data);
      })
      .catch((err) => {
        console.error(err);
        navigate("/", { state: { from: location }, replace: true });
      });
  };

  useEffect(() => {
    axiosPrivate
      .get(`/bookings/${id}`)
      .then((response) => {
        setBooking(response.data);
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "Errore durante il caricamento della prenotazione",
        });
      });
  }, [id, axiosPrivate, navigate, location]);

  return (
    <div className="mx-auto max-w-7xl space-y-6">
      <Breadcrumb />
      {booking ? (
        <BookingView booking={booking} refreshBooking={refreshBooking} />
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default BookingPage;
