import { Link } from "react-router-dom";
import { buttonVariants } from "../components/ui/Button";
import UsersList from "../components/users/UsersList";
import { PlusIcon } from "lucide-react";
import useAuth from "../hooks/useAuth";
import { ROLES } from "../config/roles";

const Users = () => {
  const { auth } = useAuth();

  return (
    <div className="mx-auto max-w-7xl space-y-6">
      <div className="flex items-center justify-between">
        <h2>Utenti</h2>
        {[ROLES.Tech].some((role) => auth?.roles.includes(role)) && (
          <Link to="/users/new" className={buttonVariants()}>
            <PlusIcon className="h-3 w-3" />
            Aggiungi
          </Link>
        )}
      </div>
      <UsersList />
    </div>
  );
};

export default Users;
