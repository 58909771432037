import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import classNames from "classnames";
import React from "react";
import { cn } from "../../lib/utils";
import Icon from "./Icon";

export const DropdownMenu = DropdownMenuPrimitive.Root;

export const DropdownMenuTrigger = React.forwardRef(
  ({ className, children, ...props }, forwardedRef) => (
    <DropdownMenuPrimitive.Trigger
      ref={forwardedRef}
      className={classNames("focus:outline-none", className)}
      {...props}
    >
      {children}
    </DropdownMenuPrimitive.Trigger>
  ),
);

export const DropdownMenuGroup = DropdownMenuPrimitive.Group;

export const DropdownMenuPortal = DropdownMenuPrimitive.Portal;

export const DropdownMenuContent = React.forwardRef(
  ({ className, children, sideOffset = 4, ...props }, forwardedRef) => (
    <DropdownMenuPrimitive.Portal ref={forwardedRef}>
      <DropdownMenuPrimitive.Content
        ref={forwardedRef}
        sideOffset={sideOffset}
        className={classNames(
          "text-popover-foreground animate-in data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 min-w-[3rem] overflow-hidden rounded-md border border-gray-300 bg-white p-1 shadow-md",
          className,
        )}
        {...props}
      >
        {children}
      </DropdownMenuPrimitive.Content>
    </DropdownMenuPrimitive.Portal>
  ),
);

export const DropdownMenuItem = React.forwardRef(
  ({ className, children, ...props }, forwardedRef) => (
    <DropdownMenuPrimitive.Item
      className={classNames(
        "relative flex select-none items-center rounded-sm bg-white px-1.5 py-1 text-sm transition-colors focus:outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
        className,
      )}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </DropdownMenuPrimitive.Item>
  ),
);

export const DropdownMenuCheckboxItem = React.forwardRef(
  ({ className, children, checked, ...props }, forwardedRef) => (
    <DropdownMenuPrimitive.CheckboxItem
      className={classNames(
        "focus:bg-accent focus:text-accent-foreground relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
        className,
      )}
      checked={checked}
      ref={forwardedRef}
      {...props}
    >
      <span className="mr-1 flex h-4 w-4 items-center justify-center border border-slate-700">
        <DropdownMenuPrimitive.ItemIndicator>
          <Icon name="Check" className="h-4 w-4 text-white" />
        </DropdownMenuPrimitive.ItemIndicator>
      </span>
      {children}
    </DropdownMenuPrimitive.CheckboxItem>
  ),
);

export const DropdownMenuRadioItem = React.forwardRef(
  ({ className, children, ...props }, forwardedRef) => (
    <DropdownMenuPrimitive.RadioItem
      className={cn(
        "focus:bg-accent focus:text-accent-foreground relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
        className,
      )}
      ref={forwardedRef}
      {...props}
    >
      <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
        <DropdownMenuPrimitive.ItemIndicator>
          <Icon name="Circle" className="h-2 w-2 fill-current" />
        </DropdownMenuPrimitive.ItemIndicator>
      </span>
      {children}
    </DropdownMenuPrimitive.RadioItem>
  ),
);

export const DropdownMenuLabel = React.forwardRef(
  ({ className, children, ...props }, forwardedRef) => (
    <DropdownMenuPrimitive.Label
      className={cn("px-2 py-1.5 text-sm font-semibold", className)}
      ref={forwardedRef}
      {...props}
    />
  ),
);

export const DropdownMenuSeparator = React.forwardRef(
  ({ className, children, ...props }, forwardedRef) => (
    <DropdownMenuPrimitive.Separator
      className={cn("bg-muted -mx-1 my-1 h-px", className)}
      ref={forwardedRef}
      {...props}
    />
  ),
);
