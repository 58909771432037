import { useEffect, useState } from "react";
import Skeleton from "../ui/Skeleton";
import { formatCurrency } from "../../lib/utils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const FinancialStats = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsError(false);
      try {
        const res = await axiosPrivate.get("/feed");
        setData(res.data);
      } catch (err) {
        console.error(err);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [axiosPrivate]);

  if (isLoading) {
    return (
      <div className="space-y-3">
        <h3>Oggi</h3>
        <div className="grid gap-3 lg:grid-cols-4">
          {[...Array(4)].map((_, index) => (
            <div key={index} className="rounded border border-gray-200 p-3">
              <div>Loading</div>
              <Skeleton className="h-7 w-[45px]" />
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (isError) {
    return <div>Something went wrong...</div>;
  }

  return (
    <div className="space-y-3">
      <h3>Oggi</h3>
      <div className="grid gap-3 lg:grid-cols-4">
        <div className="rounded border border-gray-200 p-3">
          <div>Online</div>
          <div className="text-lg font-medium">
            {data
              ? formatCurrency.format(data.dailyIncome.stripe_online)
              : "N/A"}
          </div>
        </div>
        <div className="rounded border border-gray-200 p-3">
          <div>Da sumup</div>
          <div className="text-lg font-medium">
            {data ? formatCurrency.format(data.dailyIncome.sumup) : "N/A"}
          </div>
        </div>
        <div className="rounded border border-gray-200 p-3">
          <div>Da bonifico</div>
          <div className="text-lg font-medium">
            {data ? formatCurrency.format(data.dailyIncome.bonifico) : "N/A"}
          </div>
        </div>
        <div className="rounded border border-gray-200 p-3">
          <div>Da contanti</div>
          <div className="text-lg font-medium">
            {data ? formatCurrency.format(data.dailyIncome.contanti) : "N/A"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialStats;
