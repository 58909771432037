import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { useState } from "react";

import { calculateTimezonedDate, cn } from "../../lib/utils";
import { Button } from "./Button";
import { Calendar } from "./Calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./Popover";
import { it } from "date-fns/locale";

function DatePicker({ value, onValueChange, disabled, disabledDays }) {
  const [date, setDate] = useState(value);

  const onDateChange = (date) => {
    setDate(date);
    if (date) {
      onValueChange(calculateTimezonedDate(date));
    } else {
      onValueChange(date);
    }
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          disabled={disabled}
          variant={"outline"}
          className={cn(
            "flex justify-start whitespace-nowrap text-left font-normal",
            !value && "text-muted-foreground",
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, "PPP") : <span>Scegli una data</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={(value) => onDateChange(value)}
          initialFocus
          disabledDays={disabledDays}
        />
      </PopoverContent>
    </Popover>
  );
}

function DatePickerRange({ value, onValueChange }) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[280px] justify-start text-left font-normal",
            !value && "text-muted-foreground",
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {value?.from ? (
            value.to ? (
              <>
                {format(value.from, "LLL dd, y", { locale: it })} -{" "}
                {format(value.to, "LLL dd, y", { locale: it })}
              </>
            ) : (
              format(value.from, "LLL dd, y", { locale: it })
            )
          ) : (
            <span>Scegli le date</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="range"
          selected={value}
          onSelect={onValueChange}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
}

export { DatePicker, DatePickerRange };
