import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "../components/ui/Icon";
import useAuth from "../hooks/useAuth";
import MainLogo from "../img/MainLogo.png";

import axios from "../api/axios";
import { buttonVariants } from "../components/ui/Button";
import { cn } from "../lib/utils";
const LOGIN_URL = "/auth";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { setAuth, persist, setPersist } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ user, pwd }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        },
      );
      const accessToken = response?.data?.accessToken;
      const roles = response?.data?.roles;
      const username = response?.data?.username;
      const displayName = response?.data?.displayName;
      const id = response?.data?.id;
      setAuth({ user, pwd, id, username, displayName, roles, accessToken });
      setUser("");
      setPwd("");
      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        setErrMsg("Nessuna risposta dal server");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }

    setIsLoading(false);
  };

  const togglePersist = () => {
    setPersist((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist]);

  return (
    <div className="flex h-full flex-1 items-center justify-center">
      <div className="w-[360px] space-y-4 p-6">
        <div>
          <img
            className="mx-auto h-20 object-contain"
            src={MainLogo}
            alt="VergaNautic"
          />
        </div>
        <div className="text-center">
          <h1 className="text-2xl font-semibold tracking-tight text-slate-800">
            Accesso
          </h1>
          <p className="text-sm text-gray-400">
            Inserisci le tue credenziali per continuare
          </p>
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="grid gap-2" onSubmit={handleSubmit}>
            <p ref={errRef} className="text-xs font-medium text-red-600">
              {errMsg}
            </p>
            <div>
              <label
                htmlFor="username"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Nome utente
              </label>
              <div className="mt-2 flex flex-row items-center">
                <Icon
                  name="User"
                  className="absolute ml-2 h-4 w-4 text-gray-300"
                />
                <input
                  type="text"
                  id="username"
                  ref={userRef}
                  autoComplete="off"
                  onChange={(e) => setUser(e.target.value)}
                  value={user}
                  required
                  className="grow rounded border border-gray-400 px-2 py-1 pl-7 text-sm focus:outline-none focus:ring focus:ring-1 focus:ring-blue-600"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
              <div className="mt-2 flex flex-row items-center">
                <Icon
                  name="Lock"
                  className="absolute ml-2 h-4 w-4 text-gray-300"
                />
                <input
                  type="password"
                  id="password"
                  onChange={(e) => setPwd(e.target.value)}
                  value={pwd}
                  required
                  className="grow rounded border border-gray-400 px-2 py-1 pl-7 text-sm focus:outline-none focus:ring focus:ring-1 focus:ring-blue-600"
                />
              </div>
            </div>
            <div className="flex items-center gap-1">
              <input
                type="checkbox"
                id="persist"
                onChange={togglePersist}
                checked={persist}
              />
              <label className="text-sm" htmlFor="persist">
                Ricordami
              </label>
            </div>
            <div>
              <button
                disabled={isLoading || !user || !pwd}
                type="submit"
                className={cn(
                  buttonVariants(),
                  "w-full",
                  isLoading && "animate-pulse",
                )}
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
