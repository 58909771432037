import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { BoatForm } from "./CreateBoatPage";
import Breadcrumb from "../../components/ui/Breadcrumb";
import { toast } from "../../components/ui/UseToast";

const BoatPage = () => {
  const { id } = useParams();
  const [boat, setBoat] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const getBoat = () => {
      setIsLoading(true);
      axiosPrivate
        .get(`/boats/${id}`)
        .then((response) => {
          console.log(response.data);
          setBoat(response.data);
        })
        .catch((err) => {
          console.error(err);
          if (err.response?.data.status === 403) {
            navigate("/login", { state: { from: location }, replace: true });
          }
          toast({
            title: "Problema con il caricamento dei dati dal server. Riprovare",
            variant: "destructive",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    getBoat();
  }, [id]);

  if (isLoading) {
    return <div>Caricamento...</div>;
  }

  if (boat !== undefined) {
    return (
      <div className="space-y-6">
        <Breadcrumb />
        <div className="space-y-3 border border-gray-300 bg-white px-6 py-4 shadow lg:rounded">
          <div className="flex items-center justify-between">
            <h1 className="text-lg font-semibold leading-6 text-gray-900">
              Dettagli imbarcazione: {boat.name}
            </h1>
          </div>
          <BoatForm data={boat} />
        </div>
      </div>
    );
  } else {
    return <div>Erorre durante il caricamento. Riprovare piu tardi.</div>;
  }
};

export default BoatPage;
