import { Link } from "react-router-dom";
import Icon from "../components/ui/Icon";
import useAuth from "../hooks/useAuth";
import FinancialStats from "../components/home/FinancialStats";
import HomePageWeekPlanner from "../components/home/WeekPlanner";

const Home = () => {
  const { auth } = useAuth();

  return (
    <div className="mx-auto max-w-7xl space-y-6">
      <div>
        <h2>Ciao, {auth?.displayName.firstName} 👋</h2>
      </div>
      {/* Visualizzazione GESTIONALE + SKIPPER */}
      {/* MOBILE: Scorciatoie */}
      <div className="space-y-3">
        {(auth?.roles.includes(7777) ||
          auth?.roles.includes(5000) ||
          auth?.roles.includes(2500)) && <FinancialStats />}
        <div>
          <h3>Azioni rapide</h3>
        </div>
        <div className="grid grid-cols-2 gap-3 md:grid-cols-4">
          {(auth?.roles.includes(7777) ||
            auth?.roles.includes(5000) ||
            auth?.roles.includes(2500) ||
            auth?.roles.includes(2000)) && (
            <>
              <Link to={"/bookings/new"}>
                <button className="flex w-full flex-col gap-1.5 rounded border border-gray-200 p-3 transition-colors hover:bg-gray-100 focus:outline-none">
                  <div className="rounded-full bg-emerald-100 p-1">
                    <Icon name="Plus" className="h-5 w-5 text-emerald-400" />
                  </div>
                  <div className="truncate text-sm font-medium text-slate-700">
                    Nuova prenotazione
                  </div>
                </button>
              </Link>
              <Link to={"/rentals/new"}>
                <button className="flex w-full flex-col gap-1.5 rounded border border-gray-200 p-3 transition-colors hover:bg-gray-100 focus:outline-none">
                  <div className="rounded-full bg-emerald-100 p-1">
                    <Icon name="Plus" className="h-5 w-5 text-emerald-400" />
                  </div>
                  <div className="truncate text-sm font-medium text-slate-700">
                    Nuovo noleggio
                  </div>
                </button>
              </Link>
            </>
          )}
          {(auth?.roles.includes(7777) ||
            auth?.roles.includes(5000) ||
            auth?.roles.includes(2500) ||
            auth?.roles.includes(1500)) && (
            <Link to={"/expenses/new"}>
              <button className="flex w-full flex-col gap-1.5 rounded border border-gray-200 p-3 transition-colors hover:bg-gray-100 focus:outline-none">
                <div className="rounded-full bg-yellow-100 p-1">
                  <Icon name="Euro" className="h-5 w-5 text-yellow-400" />
                </div>
                <div className="truncate text-sm font-medium text-slate-700">
                  Inserisci spesa
                </div>
              </button>
            </Link>
          )}
        </div>
      </div>
      {(auth?.roles.includes(7777) ||
        auth?.roles.includes(5000) ||
        auth?.roles.includes(2500) ||
        auth?.roles.includes(1500)) && <HomePageWeekPlanner />}
    </div>
  );
};

export default Home;
