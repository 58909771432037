import classNames from "classnames";
import {
  addMonths,
  addWeeks,
  endOfMonth,
  format,
  startOfMonth,
  startOfWeek,
  subMonths,
} from "date-fns";
import it from "date-fns/locale/it";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddPaymentModal from "../../components/reports/modals/AddPaymentModal";
import EditPaymentModal from "../../components/reports/modals/EditPaymentModal";
import Breadcrumb from "../../components/ui/Breadcrumb";
import { buttonVariants } from "../../components/ui/Button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../components/ui/Collapsible";
import Icon from "../../components/ui/Icon";
import Loading from "../../components/ui/Loading";
import { toast } from "../../components/ui/UseToast";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { capitalize, cn, formatCurrency } from "../../lib/utils";
import useDeepCompareEffect from "use-deep-compare-effect";
import { CalendarDatePicker } from "../../components/ui/EnhancedDatePicker/Index";

const LuigiReportPage = () => {
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [luigiReport, setLuigiReport] = useState();
  const [payment, setPayment] = useState();
  const [isDeleting, setIsDeleting] = useState(false);

  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
  const [showEditPaymentModal, setShowEditPaymentModal] = useState(false);

  const lastMonth = subMonths(new Date(), 1);
  const firstDateOfLastMonth = startOfMonth(lastMonth);
  const lastDateOfLastMonth = endOfMonth(lastMonth);

  const [luigiReportFilters, setLuigiReportFilters] = useState({
    startDate: format(new Date(firstDateOfLastMonth), "yyyy-MM-dd"),
    endDate: format(new Date(lastDateOfLastMonth), "yyyy-MM-dd"),
  });

  const [selectedRange, setSelectedRange] = useState({
    from: startOfWeek(new Date("06-01-2024")),
    to: addMonths(startOfWeek(new Date("06-01-2024")), 4),
  });

  async function getLuigiReports() {
    setIsLoading(true);
    setLuigiReport();
    try {
      toast({
        title: "Creazione report in corso...",
        description: "⌛ Attendere prego.",
      });
      const response = await axiosPrivate.get("/reports/luigi", {
        params: {
          startDate: selectedRange.from.toISOString(),
          endDate: selectedRange.to.toISOString(),
        },
      });
      setLuigiReport(response.data);
      toast({
        description: "✔️ Report generato correttamente.",
        variant: "confirmed",
      });
    } catch (err) {
      console.log(err);
      toast({
        title: "Qualcosa è andato storto...",
        description:
          "❌ Non è stato possibile generare un report per gli skipper.",
        variant: "destructive",
      });
    }
    setIsLoading(false);
  }

  const handleAddPayment = () => {
    setShowAddPaymentModal(true);
  };

  const handleEditPayment = (payment) => {
    setPayment(payment);
    setShowEditPaymentModal(true);
  };

  const handleDeletion = (payment) => {
    if (isDeleting) {
      deletePayment(payment._id);
    } else {
      setIsDeleting(true);
    }
  };

  const addPayment = async (payment) => {
    try {
      await axiosPrivate.post("/payouts", payment);
      toast({
        description: "✔️ Pagamento aggiornato.",
        variant: "confirmed",
      });
      getLuigiReports();
    } catch (err) {
      toast({
        title: "Qualcosa è andato storto...",
        description: "❌ Non è stato possibile aggiungere il pagamento.",
        variant: "destructive",
      });
      console.log(err);
    }
  };

  const editPayment = async (payment) => {
    try {
      await axiosPrivate.patch(`/payouts/${payment._id}`, payment);
      toast({
        description: "✔️ Pagamento aggiornato.",
        variant: "confirmed",
      });
      getLuigiReports();
    } catch (err) {
      toast({
        title: "Qualcosa è andato storto...",
        description: "❌ Non è stato possibile modificare il pagamento.",
        variant: "destructive",
      });
      console.log(err);
    }
  };

  const deletePayment = async (paymentId) => {
    try {
      await axiosPrivate.delete(`/payouts/${paymentId}`);
      toast({
        description: "✔️ Pagamento eliminato.",
        variant: "confirmed",
      });
      getLuigiReports();
    } catch (err) {
      toast({
        title: "Qualcosa è andato storto...",
        description: "❌ Non è stato possibile eliminare il pagamento.",
        variant: "destructive",
      });
      console.log(err);
    }
  };

  useDeepCompareEffect(() => {
    if (selectedRange.from !== undefined && selectedRange.to !== undefined) {
      getLuigiReports();
    }
  }, [selectedRange]);

  return (
    <div className="mx-auto max-w-7xl space-y-6">
      <Breadcrumb />
      <div className="flex items-center justify-between">
        <div>
          <h2>Report personalizzato: Luigi</h2>
        </div>
      </div>
      <div className="space-y-3">
        <div className="flex items-center gap-1.5">
          Periodo:
          <CalendarDatePicker
            date={selectedRange}
            onDateSelect={setSelectedRange}
          />
        </div>
        {isLoading && <Loading />}
        {luigiReport && (
          <div className="grid gap-3 lg:grid-cols-3">
            <div className="space-y-3 rounded border border-gray-200 p-3">
              <div className="flex items-center justify-between">
                <div className="text-2xl font-medium">Introiti N1</div>
                <div className="font-medium">
                  {formatCurrency.format(luigiReport.income.total)}
                </div>
              </div>
              <div className="grid grid-cols-2">
                <div>
                  <div className="font-medium">Contanti</div>
                  <div>
                    {formatCurrency.format(luigiReport.income.contanti)}
                  </div>
                </div>
                <div>
                  <div className="font-medium">Carte</div>
                  <div>
                    {formatCurrency.format(
                      luigiReport.income.total - luigiReport.income.contanti,
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-3 rounded border border-gray-200 p-3">
              <div className="flex items-center justify-between">
                <div className="text-2xl font-medium">Spese N1</div>
                <div className="font-medium">
                  {formatCurrency.format(
                    luigiReport.expense +
                      luigiReport.countSortiesWithoutLuigi * 100 +
                      luigiReport.toExpenditure,
                  )}
                </div>
              </div>
              <div className="grid grid-cols-3">
                <div>
                  <div className="font-medium">Carburante</div>
                  <div>{formatCurrency.format(luigiReport.expense)}</div>
                </div>
                <div>
                  <div className="font-medium">Skipperaggi</div>
                  <div>
                    {formatCurrency.format(
                      luigiReport.countSortiesWithoutLuigi * 100,
                    )}
                  </div>
                </div>
                <div>
                  <div className="font-medium">Provvigioni</div>
                  <div>{formatCurrency.format(luigiReport.toExpenditure)}</div>
                </div>
              </div>
            </div>
            <div className="space-y-3 rounded border border-gray-200 p-3">
              <div className="flex items-center justify-between">
                <div className="text-2xl font-medium">Netto N1</div>
                <div className="font-medium text-green-700">
                  {formatCurrency.format(
                    luigiReport.income.total -
                      (luigiReport.expense +
                        luigiReport.countSortiesWithoutLuigi * 100 +
                        luigiReport.toExpenditure),
                  )}
                </div>
              </div>
              <div className="grid grid-cols-2">
                <div>
                  <div className="font-medium">Introiti</div>
                  <div>{formatCurrency.format(luigiReport.income.total)}</div>
                </div>
                <div>
                  <div className="font-medium">Spese</div>
                  <div>
                    {formatCurrency.format(
                      luigiReport.expense +
                        luigiReport.countSortiesWithoutLuigi * 100 +
                        luigiReport.toExpenditure,
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {luigiReport && (
          <Collapsible className="rounded border border-gray-200">
            <CollapsibleTrigger>
              <div className="flex items-center justify-between p-3">
                <div className="font-medium">
                  Uscite Nathan 1 ({luigiReport.sorties.boat.length}, di cui{" "}
                  {luigiReport.sorties.boat.length -
                    luigiReport.countSortiesWithoutLuigi}{" "}
                  in proprio)
                </div>
                <div className="flex items-center gap-1.5">
                  <div>{formatCurrency.format(luigiReport.income.total)}</div>
                  <Icon name="ChevronDown" className="h-4 w-4" />
                </div>
              </div>
            </CollapsibleTrigger>
            <CollapsibleContent>
              <div className="space-y-1.5 bg-gray-50 p-1.5 text-sm lg:col-span-2">
                {luigiReport.sorties.boat
                  .sort((a, b) => new Date(a.date) - new Date(b.date))
                  .map((sortie) => {
                    return (
                      <Collapsible
                        key={sortie.date}
                        className="rounded border border-gray-200 bg-white p-1.5"
                      >
                        <CollapsibleTrigger>
                          <div className="flex items-center justify-between">
                            <div className="flex items-center gap-3">
                              <div className="w-16">
                                {capitalize(
                                  format(new Date(sortie.date), "dd MMM yy", {
                                    locale: it,
                                  }),
                                )}
                              </div>
                              <div className="flex w-24 items-center gap-1.5 rounded border border-gray-200 p-1.5">
                                <Icon
                                  name={sortie.tour.icon}
                                  className="h-3 w-3 text-gray-400"
                                />
                                <div className="text-xs font-medium uppercase">
                                  {capitalize(sortie.tour.name)}
                                </div>
                              </div>
                              <div
                                className={cn(
                                  sortie.skipper.name === "Luigi" &&
                                    "font-medium",
                                )}
                              >
                                ({sortie.skipper.name})
                              </div>
                            </div>
                            <div className="flex items-center gap-3">
                              {!sortie.totalSum || !sortie.totalExpense ? (
                                <div className="flex items-center gap-1.5 font-medium text-yellow-700">
                                  <Icon
                                    name="AlertTriangle"
                                    className="h-3 w-3"
                                  />
                                  Incongruenze
                                </div>
                              ) : (
                                <div className="font-medium text-slate-700">
                                  {formatCurrency.format(
                                    sortie.totalSum -
                                      sortie.totalExpense -
                                      sortie.totalToExpenditure,
                                  )}
                                </div>
                              )}
                              <div>
                                <Icon
                                  name="Eye"
                                  className="h-4 w-4 text-gray-400"
                                />
                              </div>
                            </div>
                          </div>
                        </CollapsibleTrigger>
                        <CollapsibleContent className="space-y-3 py-3">
                          <div className="flex items-center justify-between">
                            <div>
                              <div className="font-medium">Entrate totali</div>
                              {sortie.totalSum ? (
                                <div className="font-medium text-green-700">
                                  + {formatCurrency.format(sortie.totalSum)}
                                </div>
                              ) : (
                                <div className="font-medium text-yellow-700">
                                  Incongruenze
                                </div>
                              )}
                            </div>
                            <div>
                              <div className="font-medium">Carburante</div>
                              {sortie.totalExpense ? (
                                <div className="font-medium text-red-700">
                                  - {formatCurrency.format(sortie.totalExpense)}
                                </div>
                              ) : (
                                <Link
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  to={`/expenses/new?date=${format(new Date(sortie.date), "yyyy-MM-dd")}&type=related`}
                                  className="flex items-center gap-1.5 font-medium text-yellow-700 transition-colors hover:text-yellow-500"
                                >
                                  Risolvi
                                  <Icon
                                    name="ExternalLink"
                                    className="h-3 w-3"
                                  />
                                </Link>
                              )}
                            </div>
                            {!!sortie.totalToExpenditure && (
                              <div>
                                <div className="font-medium">
                                  Provvigioni TO
                                </div>
                                <div className="font-medium text-red-700">
                                  -{" "}
                                  {formatCurrency.format(
                                    sortie.totalToExpenditure,
                                  )}
                                </div>
                              </div>
                            )}
                            <Link
                              target="_blank"
                              rel="noopener noreferrer"
                              to={`/bookings?date=${format(new Date(sortie.date), "yyyy-MM-dd")}&boat=64562849015d56d853675b30`}
                              className="flex items-center gap-1.5 font-medium text-blue-500"
                            >
                              Visualizza prenotazioni
                              <Icon name="ExternalLink" className="h-3 w-3" />
                            </Link>
                          </div>
                          <hr />
                          <div className="space-y-3">
                            <div className="font-medium">
                              Prenotazioni ({sortie.bookings.length})
                            </div>
                            <div className="space-y-1.5">
                              {sortie.bookings.map((booking) => {
                                return (
                                  <div
                                    key={booking._id}
                                    className="flex items-center justify-between"
                                  >
                                    <div className="flex items-center gap-1.5">
                                      {!!booking.tourOperatorName && (
                                        <span
                                          className={classNames(
                                            booking.tourOperatorName ===
                                              "garganogroup"
                                              ? "bg-orange-700"
                                              : "bg-amber-600",
                                            "inline-flex items-center gap-1 truncate rounded px-1 text-xs font-semibold uppercase leading-5 text-white transition",
                                          )}
                                        >
                                          <Icon
                                            name="Briefcase"
                                            className="h-3 w-3"
                                          />
                                          {booking.tourOperatorName}
                                        </span>
                                      )}
                                      {!booking.tourOperatorName && (
                                        <span className="inline-flex items-center gap-1 truncate rounded bg-sky-800 px-1 text-xs font-semibold uppercase leading-5 text-white transition">
                                          <Icon
                                            name="LampDesk"
                                            className="h-3 w-3"
                                          />
                                          DIRETTO
                                        </span>
                                      )}
                                      <div className="font-medium">
                                        {booking.reference}
                                      </div>
                                      <div>
                                        - {booking.organizer.firstName}{" "}
                                        {booking.organizer.lastName}
                                      </div>
                                      <div className="flex items-center gap-1">
                                        <Icon
                                          name="Users"
                                          className="h-3 w-3 text-gray-400"
                                        />
                                        {booking.adults + 1} adulti
                                        {!!booking.kids &&
                                          ` , ${booking.kids} bambini`}
                                      </div>
                                    </div>
                                    {!!booking.tourOperatorQuota && (
                                      <div>
                                        <div className="font-medium text-red-700">
                                          -{" "}
                                          {formatCurrency.format(
                                            booking.tourOperatorQuota,
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </CollapsibleContent>
                      </Collapsible>
                    );
                  })}
              </div>
            </CollapsibleContent>
          </Collapsible>
        )}
        {luigiReport && (
          <Collapsible className="rounded border border-gray-200">
            <CollapsibleTrigger>
              <div className="flex items-center justify-between p-3">
                <div className="flex items-center gap-1.5">
                  <div className="font-medium">
                    Skipperaggi ({luigiReport.sorties.skipper[0].sorties.length}
                    )
                  </div>
                  <div className="flex items-center gap-1.5 text-gray-400">
                    {luigiReport.sorties.skipper[0].tourCounts.map(
                      (elem, index) => (
                        <div key={index}>
                          {capitalize(elem.tour)} ({elem.count})
                          {index !==
                          luigiReport.sorties.skipper[0].tourCounts.length - 1
                            ? ","
                            : ""}
                        </div>
                      ),
                    )}
                  </div>
                </div>
                <div className="flex items-center gap-1.5">
                  <div>{formatCurrency.format(luigiReport.income.skipper)}</div>
                  <Icon name="ChevronDown" className="h-4 w-4" />
                </div>
              </div>
            </CollapsibleTrigger>
            <CollapsibleContent>
              <div className="space-y-1.5 bg-gray-50 p-3 text-sm lg:col-span-2">
                {luigiReport.sorties.skipper[0].sorties
                  .sort((a, b) => new Date(a.date) - new Date(b.date))
                  .map((sortie) => {
                    return (
                      <div
                        key={sortie.date}
                        className="flex items-center gap-3"
                      >
                        <div className="w-16">
                          {capitalize(
                            format(new Date(sortie.date), "dd MMM yy", {
                              locale: it,
                            }),
                          )}
                        </div>
                        <div className="flex w-24 items-center gap-1.5 rounded border border-gray-200 p-1.5">
                          <Icon
                            name="Sailboat"
                            className="h-3 w-3 text-gray-400"
                          />
                          <div className="text-xs font-medium">
                            {sortie.boat.name}
                          </div>
                        </div>
                        <div className="flex w-24 items-center gap-1.5 rounded border border-gray-200 p-1.5">
                          <Icon
                            name={sortie.tour.icon}
                            className="h-3 w-3 text-gray-400"
                          />
                          <div className="text-xs font-medium uppercase">
                            {capitalize(sortie.tour.name)}
                          </div>
                        </div>

                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          to={`/bookings?date=${format(new Date(sortie.date), "yyyy-MM-dd")}&boat=${sortie.boat._id}`}
                          className="flex items-center gap-1.5 font-medium text-blue-500"
                        >
                          Visualizza prenotazioni
                          <Icon name="ExternalLink" className="h-3 w-3" />
                        </Link>
                      </div>
                    );
                  })}
              </div>
            </CollapsibleContent>
          </Collapsible>
        )}
        {luigiReport && (
          <>
            <div className="grid w-full gap-3 rounded bg-gray-50 p-3 lg:grid-cols-3">
              <div className="col-span-2 space-y-3">
                <div className="space-y-3">
                  <div className="flex items-center justify-between">
                    <div className="text-xl font-medium">Pagamenti</div>
                    <button
                      onClick={handleAddPayment}
                      className={buttonVariants({ size: "sm" })}
                    >
                      Aggiungi
                    </button>
                  </div>

                  <table className="w-full rounded border border-gray-200 bg-white">
                    <thead className="text-left">
                      <tr>
                        <th>Data</th>
                        <th>Commento</th>
                        <th>Ammontare</th>
                        <th className="sr-only">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {luigiReport.payouts && luigiReport.payouts.length > 0 ? (
                        luigiReport.payouts.map((payout) => (
                          <tr key={payout._id}>
                            <td>
                              {format(new Date(payout.date), "dd MMM yyyy")}
                            </td>
                            <td>{payout.note || "Nessuna nota"}</td>
                            <td>
                              <div className="text-right">
                                {formatCurrency.format(payout.amount)}
                              </div>
                            </td>
                            <td>
                              <div className="flex items-center justify-end gap-3 px-2">
                                <button
                                  onClick={() => handleEditPayment(payout)}
                                >
                                  <Icon
                                    name="Edit3"
                                    className="h-4 w-4 text-gray-400 transition-colors hover:text-yellow-400"
                                  />
                                </button>
                                <button onClick={() => handleDeletion(payout)}>
                                  {isDeleting ? (
                                    <Icon
                                      name="Check"
                                      className="h-4 w-4 text-red-400 transition-colors hover:text-red-400/90"
                                    />
                                  ) : (
                                    <Icon
                                      name="Trash2"
                                      className="h-4 w-4 text-gray-400 transition-colors hover:text-red-400"
                                    />
                                  )}
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3" className="py-6 text-center">
                            Nessun pagamento
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="flex justify-end border-t border-black py-3">
                    <div className="font-medium">
                      Totale: {formatCurrency.format(luigiReport.payoutsSum)}
                    </div>
                  </div>
                </div>
                <div className="space-y-3">
                  <div className="flex items-center justify-between">
                    <div className="text-xl font-medium">
                      Spese anticipate e diviso due
                    </div>
                  </div>

                  <table className="w-full rounded border border-gray-200 bg-white">
                    <thead className="text-left">
                      <tr>
                        <th>Data</th>
                        <th>Nome</th>
                        <th>Ammontare</th>
                        <th className="sr-only">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {luigiReport.expenses.list &&
                      luigiReport.expenses.list.length > 0 ? (
                        luigiReport.expenses.list.map((expense) => (
                          <tr key={expense._id}>
                            <td>
                              {format(new Date(expense.date), "dd MMM yyyy")}
                            </td>
                            <td>{expense.title || "Spesa"}</td>
                            <td>
                              <div className="text-right">
                                {expense.split ? "- " : "+ "}
                                {formatCurrency.format(expense.amount)}
                              </div>
                            </td>
                            <td>
                              <div className="flex items-center justify-end gap-3 px-2">
                                <Link
                                  to={`/expenses/${expense._id}`}
                                  className="h-4 w-4 text-gray-400 transition-colors hover:text-blue-400"
                                >
                                  <Icon name="Eye" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3" className="py-6 text-center">
                            Nessuna spesa
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="flex justify-end border-t border-black py-3">
                    <div className="font-medium">
                      Totale:{" "}
                      {formatCurrency.format(
                        luigiReport.expenses.anticipated -
                          luigiReport.expenses.split,
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between space-y-3">
                <div>
                  <div className="text-xl font-medium">Resoconto</div>
                  <div>
                    <div className="flex items-center justify-between">
                      <div className="font-medium">Nathan I</div>
                    </div>
                    <div className="flex items-center justify-between text-gray-400">
                      <div>Introiti</div>
                      <div>
                        + {formatCurrency.format(luigiReport.income.total)}
                      </div>
                    </div>
                    <div className="flex items-center justify-between text-gray-400">
                      <div>Carburante</div>
                      <div>- {formatCurrency.format(luigiReport.expense)}</div>
                    </div>
                    <div className="flex items-center justify-between text-gray-400">
                      <div>Skipperaggi</div>
                      <div>
                        -{" "}
                        {formatCurrency.format(
                          luigiReport.countSortiesWithoutLuigi * 100,
                        )}
                      </div>
                    </div>
                    <div className="flex items-center justify-between text-gray-400">
                      <div>Provvigioni TO</div>
                      <div>
                        - {formatCurrency.format(luigiReport.toExpenditure)}
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="font-medium">Utile</div>
                      <div>
                        {formatCurrency.format(
                          luigiReport.income.total -
                            luigiReport.expense -
                            luigiReport.toExpenditure -
                            100 * luigiReport.countSortiesWithoutLuigi,
                        )}
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="font-medium">IVA</div>
                      <div>- {formatCurrency.format(luigiReport.iva)}</div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div>Diviso 2</div>
                      <div>
                        +{" "}
                        {formatCurrency.format(
                          (luigiReport.income.total -
                            luigiReport.expense -
                            luigiReport.toExpenditure -
                            100 * luigiReport.countSortiesWithoutLuigi) /
                            2,
                        )}
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="font-medium">Skipperaggi Luigi</div>
                      <div>
                        + {formatCurrency.format(luigiReport.income.skipper)}
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="font-medium">Spese anticipate</div>
                      <div>
                        +{" "}
                        {formatCurrency.format(
                          luigiReport.expenses.anticipated,
                        )}
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="font-medium">Spese diviso due</div>
                      <div>
                        - {formatCurrency.format(luigiReport.expenses.split)}
                      </div>
                    </div>
                    <div className="flex items-center justify-between text-gray-400">
                      <div>Pagamenti</div>
                      <div>
                        - {formatCurrency.format(luigiReport.payoutsSum)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <hr />
                  <div className="font-medium">
                    Parziale attuale (al netto dei pagamenti)
                  </div>
                  <div>{formatCurrency.format(luigiReport.total)}</div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {showAddPaymentModal && (
        <AddPaymentModal
          open={showAddPaymentModal}
          onOpenChange={setShowAddPaymentModal}
          addPayment={addPayment}
        />
      )}
      {showEditPaymentModal && (
        <EditPaymentModal
          open={showEditPaymentModal}
          onOpenChange={setShowEditPaymentModal}
          callbackFn={editPayment}
          payment={payment}
        />
      )}
    </div>
  );
};

export default LuigiReportPage;
