import { addDays, format, startOfDay } from "date-fns";
import { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import { calculateTimezonedDate, cn, formatCurrency } from "../../../lib/utils";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "../../ui/UseToast";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/Card";
import { ParticipantsSelect } from "../../bookings/new/components/ParticipantsSelect";
import RentalBoatsRadioGroup from "./ui/RentalBoatsRadioGroup";
import { OrganizerInfo } from "../../bookings/new/components/StandardOverview";
import CurrencyInput from "react-currency-input-field";
import { Button } from "../../ui/Button";
import { Label } from "../../ui/Label";
import { DateTimePicker } from "../../ui/DateTimePicker";
import { it } from "date-fns/locale";
import { Alert, AlertTitle } from "../../ui/Alert";

const RentalOverview = ({ selectedBoat, isSaving }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({
    pre: 0,
    full: 0,
  });

  const form = useFormContext();
  const participants = form.watch("participants");
  const customTotalAmount = form.watch("paymentInfo.totalAmount");

  const from = form.watch("from");
  const to = form.watch("to");

  const suggestedDownpayment =
    Math.round(((40 / 100) * customTotalAmount) / 10) * 10;

  const api = useAxiosPrivate();

  useEffect(() => {
    setIsLoading(true);
    api
      .post("/rentals/quote", {
        boat: selectedBoat._id,
        from: calculateTimezonedDate(from),
        to: calculateTimezonedDate(to),
      })
      .then((res) => {
        setData(res.data);
        setIsError(false);
      })
      .catch((err) => {
        console.error(err);
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  }, [from, to, selectedBoat]);

  return (
    <div className="flex h-full flex-1 flex-col space-y-3">
      <OrganizerInfo />
      <div>
        <p className="text-lg font-semibold">Noleggio ad uso esclusivo</p>
        <p>Data inizio e fine</p>
        <p className="font-medium">
          {format(from, "PPP HH:mm", { locale: it })} -{" "}
          {format(to, "PPP HH:mm", { locale: it })}
        </p>
        <p>
          Partenza da{" "}
          {selectedBoat.port[0].toUpperCase() + selectedBoat.port.slice(1)}
        </p>
        <p>{participants.adults} Adulti</p>
        {participants.kids > 0 && <p>{participants.kids} Ragazzi</p>}
      </div>
      <div>
        <p className="text-lg font-semibold">Imbarcazione</p>
        <p>{selectedBoat.name}</p>
      </div>
      <p className="text-lg font-semibold">Dettaglio prezzo</p>
      <div className="flex items-center justify-between">
        <p className="truncate">Noleggio ad uso esclusivo - </p>
        <div className="text-right">
          <span>{formatCurrency.format(data.full)}</span>
        </div>
      </div>
      <div>
        <p className="text-lg font-semibold">Pagamento</p>
        <div
          className={cn(
            "flex items-center justify-between",
            customTotalAmount !== undefined &&
              customTotalAmount !== "" &&
              parseFloat(customTotalAmount) !== data.full &&
              "text-gray-500",
          )}
        >
          <p>
            <span>Acconto:</span>
          </p>
          <p className="text-right">{formatCurrency.format(data.pre)}</p>
        </div>
        <div
          className={cn(
            "flex items-center justify-between",
            customTotalAmount !== undefined &&
              customTotalAmount !== "" &&
              parseFloat(customTotalAmount) !== data.full &&
              "text-gray-500",
          )}
        >
          <p>
            <span>Totale:</span>
          </p>
          <p className="text-right">{formatCurrency.format(data.full)}</p>
        </div>
        <div className="flex items-center justify-between">
          <p>
            <span
              className={cn(
                "mt-2",
                customTotalAmount === undefined && "text-gray-500",
                customTotalAmount === "" && "text-gray-500",
                parseFloat(customTotalAmount) === data.full && "text-gray-500",
              )}
            >
              Modifica prezzo
            </span>
          </p>
          <p className="text-right">
            <Controller
              name="paymentInfo.totalAmount"
              control={form.control}
              render={({ field }) => (
                <CurrencyInput
                  className="border text-right"
                  value={field.value}
                  onValueChange={field.onChange}
                  intlConfig={{ locale: "it-IT", currency: "EUR" }}
                />
              )}
            />
          </p>
        </div>
        {customTotalAmount !== undefined && customTotalAmount !== "" && (
          <div className="flex items-center justify-between">
            <p>Acconto suggerito</p>
            <p className="text-right">
              {formatCurrency.format(suggestedDownpayment)}
            </p>
          </div>
        )}
      </div>
      <div className="flex-1" />
      <div className="w-full space-y-3">
        {isError && (
          <Alert>
            <AlertTitle>Alcune informazioni non sono valide.</AlertTitle>
          </Alert>
        )}
        <Button
          type="submit"
          disabled={isSaving || isLoading || isError}
          className="w-full"
        >
          Conferma e crea
        </Button>
      </div>
    </div>
  );
};

const NewRentalView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const tomorrow = addDays(startOfDay(new Date()), 1);
  const [selectedBoat, setSelectedBoat] = useState();

  const form = useForm({
    defaultValues: {
      from: tomorrow,
      to: addDays(tomorrow, 1),
      participants: {
        adults: 2,
        kids: 0,
      },
      exclusive: true,
    },
  });

  const api = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const from = form.watch("from");
  const to = form.watch("to");

  const onSubmit = (data) => {
    data.from = calculateTimezonedDate(data.from);
    data.to = calculateTimezonedDate(data.to);

    setIsLoading(true);
    api
      .post("/rentals", data)
      .then((response) => {
        toast({
          title: "✔️ Noleggio creato, accesso in corso...",
          variant: "confirmed",
        });
        window.setTimeout(function () {
          navigate(`/rentals/${response.data._id}`, {
            state: { from: location },
            replace: true,
          });
        }, 500);
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "⚠️ Prenotazione non creata",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Card>
          <CardHeader>
            <CardTitle>Nuovo Noleggio</CardTitle>
          </CardHeader>
          <CardContent className="grid gap-6 lg:grid-cols-3">
            <div className="col-span-2 grid grid-cols-2 gap-6 lg:gap-y-12">
              <div className="space-y-3">
                <h2 className="text-lg font-medium tracking-tight">
                  1. Partecipanti
                </h2>
                <Controller
                  name="participants"
                  control={form.control}
                  render={({ field }) => (
                    <ParticipantsSelect
                      value={field.value}
                      onValueChange={field.onChange}
                    />
                  )}
                />
              </div>
              <div className="col-span-2 space-y-3">
                <h2 className="text-lg font-medium tracking-tight">
                  2. Imbarcazione
                </h2>
                <Controller
                  name="boat"
                  control={form.control}
                  render={({ field }) => (
                    <RentalBoatsRadioGroup
                      value={field.value}
                      onValueChange={field.onChange}
                      setSelectedBoat={setSelectedBoat}
                    />
                  )}
                />
              </div>
              <div className="space-y-3">
                <h2 className="text-lg font-medium tracking-tight">
                  3. Inizio e fine
                </h2>
                <div className="flex items-center gap-6">
                  <div className="space-y-3">
                    <Label>Data e ora d&apos;inizio</Label>
                    <Controller
                      name="from"
                      control={form.control}
                      render={({ field }) => (
                        <DateTimePicker
                          value={field.value}
                          onValueChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                  <div className="space-y-3">
                    <Label>Data e ora di fine</Label>
                    <Controller
                      name="to"
                      control={form.control}
                      render={({ field }) => (
                        <DateTimePicker
                          value={field.value}
                          onValueChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            {selectedBoat && from && to && (
              <RentalOverview
                selectedBoat={selectedBoat}
                isLoading={isLoading}
              />
            )}
          </CardContent>
        </Card>
      </form>
    </FormProvider>
  );
};

export default NewRentalView;
