import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "../../ui/Dialog";
import { DialogTitle } from "@radix-ui/react-dialog";
import { Loader2Icon, SailboatIcon } from "lucide-react";
import Skeleton from "../../ui/Skeleton";
import {
  RadioGroup,
  RadioGroupIndicator,
  RadioGroupItem,
} from "@radix-ui/react-radio-group";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "../../ui/UseToast";
import classNames from "classnames";
import { cn } from "../../../lib/utils";
import { Button } from "../../ui/Button";
import Icon from "../../ui/Icon";

const ModifyBoarding = ({ booking, callbackFn }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBoats, setIsLoadingBoats] = useState(false);
  const [isLoadingSkippers, setIsLoadingSkippers] = useState(false);
  const [selectedBoat, setSelectedBoat] = useState();
  const [selectedSkipper, setSelectedSkipper] = useState();
  const [selectedPort, setSelectedPort] = useState(booking.port?.name);
  const [availableBoats, setAvailableBoats] = useState();
  const [skippers, setSkippers] = useState();

  const api = useAxiosPrivate();

  function modifyBoarding() {
    setIsLoading(true);
    let update = {};

    if (selectedBoat._id !== booking.boat._id) {
      update.boat = selectedBoat._id;
    }

    if (
      !booking.skipper?._id ||
      selectedSkipper?._id !== booking.skipper?._id
    ) {
      update.skipper = selectedSkipper._id;
    }

    if (selectedPort !== booking.port?.name) {
      update.port = selectedPort;
    }

    api
      .patch(`/bookings/${booking._id}`, update)
      .then(() => {
        callbackFn();
        toast({
          description: "✔️ Modifica salvata.",
          variant: "confirmed",
        });
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Qualcosa è andato storto...",
          description: "❌ La modifica non è andata a buon fine.",
          variant: "destructive",
        });
      })
      .finally(() => {
        setIsLoading(false);
        setOpen(false);
      });
  }

  useEffect(() => {
    const fetchBoats = () => {
      setIsLoadingBoats(true);
      api
        .get(
          `/boats/${booking.tour.name}/boats-availability?displayOverboking=true`,
          {
            params: {
              date: booking.date,
              participants:
                booking.participants.adults + booking.participants.kids + 1,
              exclusive: booking.exclusive,
            },
          },
        )
        .then((res) => {
          setAvailableBoats(res.data);
          setSelectedBoat(
            res.data.find((boat) => boat._id === booking.boat._id),
          );
        })
        .catch((err) => {
          console.error(err);
          toast({
            title: "Errore durante il caricamento dei mezzi. Riprovare",
          });
        })
        .finally(() => setIsLoadingBoats(false));
    };

    const fetchSkippers = () => {
      setIsLoadingSkippers(true);
      api
        .get("/users/skippers")
        .then((res) => {
          setSkippers(res.data);
          setSelectedSkipper(
            res.data.find((skipper) => skipper._id === booking.skipper?._id),
          );
        })
        .catch((err) => {
          console.error(err);
          toast({
            title: "Errore durante il caricamento dei skipper. Riprovare",
          });
        })
        .finally(() => setIsLoadingSkippers(false));
    };

    fetchBoats();
    fetchSkippers();
  }, []);

  useEffect(() => {
    console.log(selectedSkipper, selectedBoat);
  }, [selectedSkipper, selectedBoat]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <button className="focus-visible:ring-ring ring-offset-background inline-flex h-10 items-center justify-between rounded-md bg-slate-700 px-4 py-2 text-sm font-medium text-white transition-colors hover:bg-slate-800 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">
          <SailboatIcon className="h-4 w-4 text-gray-300" />
          Modifica imbarco
          <div />
        </button>
      </DialogTrigger>
      <DialogContent className="max-h-[700px] overflow-y-scroll">
        <DialogHeader>
          <DialogTitle>Modifica imbarcazione/skipper</DialogTitle>
        </DialogHeader>
        <div>Sposta questa prenotazione su un&apos;imbarcazione diversa.</div>
        <div className="flex w-full flex-col justify-center space-y-3 rounded border border-gray-200 p-3">
          <div className="grid lg:grid-cols-3">
            <div>
              <div className="text-sm">Imbarcazione attuale:</div>
              <div className="text-lg font-medium">
                {booking.boat ? booking.boat.name : "Nessuna"}
              </div>
            </div>
            <div className="hidden items-center justify-center lg:flex">
              <Icon name="ArrowRight" className="h-8 w-8 text-gray-200" />
            </div>
            <div className="hidden lg:block">
              <div className="text-sm">Nuova imbarcazione:</div>

              <div className="text-lg font-medium">
                {isLoadingBoats ? (
                  <Skeleton className="h-[28px] w-[20px]" />
                ) : (
                  selectedBoat?.name
                )}
              </div>
            </div>
          </div>
          <RadioGroup
            value={selectedBoat}
            onValueChange={setSelectedBoat}
            className="grid grid-cols-2 gap-1"
          >
            {availableBoats?.map((boat) => {
              return (
                <RadioGroupItem
                  key={boat._id}
                  value={boat}
                  className={classNames(
                    "rounded border border-gray-200 p-0.5 lg:p-1",
                    selectedBoat === boat && "font-medium ring-2 ring-blue-600",
                  )}
                >
                  <div className="flex items-center gap-2 p-2">
                    <div className="rounded-full bg-gray-200 p-1 lg:p-2">
                      <Icon name="Sailboat" className="h-4 w-4 text-white" />
                    </div>
                    <div className="grow text-left">
                      <div className="text-xs font-medium lg:text-sm">
                        {boat.name}
                      </div>
                      <div className="text-xs">
                        Posti rimasti: {boat.freeSeats}
                      </div>
                      <div className="text-xs uppercase">{boat.port}</div>
                    </div>
                  </div>
                </RadioGroupItem>
              );
            })}
          </RadioGroup>
        </div>
        <div>Modifica lo skipper su questa prenotazione.</div>
        <div className="flex w-full flex-col justify-center space-y-3 rounded border border-gray-200 p-3">
          <div className="grid lg:grid-cols-3">
            <div>
              <div className="text-sm">Skipper attuale:</div>
              <div className="text-lg font-medium">
                {booking.skipper?.displayName.firstName
                  ? booking.skipper.displayName.firstName
                  : "Non assegnato"}
              </div>
            </div>
            <div className="hidden items-center justify-center lg:flex">
              <Icon name="ArrowRight" className="h-8 w-8 text-gray-200" />
            </div>
            <div className="hidden lg:block">
              <div className="text-sm">Nuovo skipper:</div>

              <div className="text-lg font-medium">
                {isLoadingSkippers ? (
                  <Skeleton className="h-[28px] w-[20px]" />
                ) : (
                  selectedSkipper?.displayName.firstName
                )}
              </div>
            </div>
          </div>
          <RadioGroup
            value={selectedSkipper}
            onValueChange={setSelectedSkipper}
            className="grid grid-cols-2 gap-1"
          >
            {skippers?.map((skipper) => {
              return (
                <RadioGroupItem
                  key={skipper._id}
                  value={skipper}
                  className={cn(
                    "rounded border border-gray-200 p-0.5 lg:p-1",
                    selectedSkipper === skipper &&
                      "font-medium ring-2 ring-blue-600",
                  )}
                >
                  <div className="flex items-center gap-2 p-2">
                    <div className="rounded-full bg-gray-200 p-1 lg:p-2">
                      <Icon name="User" className="h-4 w-4 text-white" />
                    </div>
                    <div className="grow text-left">
                      <div className="text-xs font-medium lg:text-sm">
                        {skipper.displayName.firstName}
                      </div>
                    </div>
                  </div>
                </RadioGroupItem>
              );
            })}
          </RadioGroup>
        </div>
        <div>Modifica il porto di partenza.</div>
        <RadioGroup
          value={selectedPort}
          onValueChange={setSelectedPort}
          className="grid grid-cols-2 gap-3"
        >
          <div className="flex items-center gap-1.5 text-xs lg:text-sm">
            <RadioGroupItem
              value="foce"
              className="flex h-4 w-4 items-center justify-center rounded-full border border-gray-200"
            >
              <RadioGroupIndicator className="h-full w-full rounded-full bg-blue-500 p-1">
                <div className="h-full w-full rounded-full bg-white" />
              </RadioGroupIndicator>
            </RadioGroupItem>
            Foce
          </div>
          <div className="flex items-center gap-1.5 text-xs lg:text-sm">
            <RadioGroupItem
              value="rodi"
              className="flex h-4 w-4 items-center justify-center rounded-full border border-gray-200"
            >
              <RadioGroupIndicator className="h-full w-full rounded-full bg-blue-500 p-1">
                <div className="h-full w-full rounded-full bg-white" />
              </RadioGroupIndicator>
            </RadioGroupItem>
            Rodi
          </div>
          <div className="flex items-center gap-1.5 text-xs lg:text-sm">
            <RadioGroupItem
              value="bluemarine_lido"
              className="flex h-4 w-4 items-center justify-center rounded-full border border-gray-200"
            >
              <RadioGroupIndicator className="h-full w-full rounded-full bg-blue-500 p-1">
                <div className="h-full w-full rounded-full bg-white" />
              </RadioGroupIndicator>
            </RadioGroupItem>
            Bluemarine
          </div>
        </RadioGroup>

        <DialogFooter>
          <Button onClick={() => setOpen(false)} variant="secondary">
            Annulla
          </Button>
          <Button
            disabled={
              (!selectedSkipper && !selectedBoat && !selectedPort) || isLoading
            }
            onClick={modifyBoarding}
          >
            {isLoading && <Loader2Icon className="h-3 w-3 animate-spin" />}
            Conferma
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ModifyBoarding;
