import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/Select";
import useAuth from "../hooks/useAuth";

const PaymentMethodSelect = ({ value, onValueChange }) => {
  const { auth } = useAuth();

  let paymentMethods = [];

  if (!auth?.roles.includes(2000)) {
    paymentMethods = [
      {
        name: "Contanti",
        value: "contanti",
      },
      {
        name: "Bonifico",
        value: "bonifico",
      },
      {
        name: "Sumup",
        value: "Sumup",
      },
      {
        name: "Stripe",
        value: "stripe",
      },
    ];
  } else {
    paymentMethods = [
      {
        name: "Stripe",
        value: "stripe",
      },
    ];
  }

  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger>
        <SelectValue placeholder="Seleziona un metodo di pagamento" />
      </SelectTrigger>
      <SelectContent>
        {paymentMethods.map((item) => (
          <SelectItem key={item.value} value={item.value}>
            {item.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default PaymentMethodSelect;
