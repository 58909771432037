import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../components/ui/EmptyState";
import { columns } from "../components/vouchers/Columns";
import DataTable from "../components/vouchers/DataTable";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { toast } from "../components/ui/UseToast";

const Vouchers = () => {
  const [vouchers, setVouchers] = useState();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const getVouchers = () => {
      axiosPrivate
        .get("/vouchers")
        .then((response) => {
          setVouchers(response.data);
        })
        .catch((err) => {
          console.error(err);
          toast({
            title: "Problema con il caricamento dei dati dal server. Riprovare",
            variant: "destructive",
          });
        });
    };

    getVouchers();
  }, []);

  return (
    <div className="mx-auto max-w-7xl space-y-6">
      <div>
        <h2>Voucher</h2>
      </div>
      {!vouchers ? (
        <Loading />
      ) : (
        <DataTable data={vouchers} columns={columns} />
      )}
    </div>
  );
};

export default Vouchers;
