import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";
import classNames from "classnames";
import React from "react";

export const AlertDialog = AlertDialogPrimitive.Root;

export const AlertDialogTrigger = AlertDialogPrimitive.Trigger;

export const AlertDialogPortal = React.forwardRef(
  ({ className, children, ...props }, forwardedRef) => (
    <AlertDialogPrimitive.Portal
      className={className}
      ref={forwardedRef}
      {...props}
    >
      <div className="fixed inset-0 z-50 flex items-end justify-center sm:items-center">
        {children}
      </div>
    </AlertDialogPrimitive.Portal>
  ),
);

export const AlertDialogOverlay = React.forwardRef(
  ({ className, children, ...props }, forwardedRef) => (
    <AlertDialogPrimitive.Overlay
      className={classNames(
        "bg-background/90 animate-in fade-in fixed inset-0 z-50 backdrop-blur-sm transition-opacity",
        className,
      )}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </AlertDialogPrimitive.Overlay>
  ),
);

export const AlertDialogContent = React.forwardRef(
  ({ className, children, ...props }, forwardedRef) => (
    <AlertDialogPortal>
      <AlertDialogOverlay />
      <AlertDialogPrimitive.Content
        className={classNames(
          "animate-in fade-in-90 slide-in-from-bottom-10 sm:zoom-in-90 sm:slide-in-from-bottom-0 fixed z-50 grid w-full max-w-lg scale-100 gap-4 border border-gray-200 bg-white p-6 opacity-100 shadow-lg sm:rounded-lg md:w-full",
          className,
        )}
        ref={forwardedRef}
        {...props}
      >
        {children}
      </AlertDialogPrimitive.Content>
    </AlertDialogPortal>
  ),
);

export const AlertDialogHeader = React.forwardRef(
  ({ className, children, ...props }, forwardedRef) => (
    <div
      className={classNames(
        "flex flex-col space-y-2 text-center sm:text-left",
        className,
      )}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </div>
  ),
);

export const AlertDialogFooter = React.forwardRef(
  ({ className, children, ...props }, forwardedRef) => (
    <div
      className={classNames(
        "flex flex-col-reverse gap-2 sm:flex-row sm:justify-end",
        className,
      )}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </div>
  ),
);

export const AlertDialogTitle = React.forwardRef(
  ({ className, children, ...props }, forwardedRef) => (
    <AlertDialogPrimitive.Title
      className={classNames("text-lg font-semibold", className)}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </AlertDialogPrimitive.Title>
  ),
);

export const AlertDialogDescription = React.forwardRef(
  ({ className, children, ...props }, forwardedRef) => (
    <AlertDialogPrimitive.Description
      className={classNames("text-muted-foreground text-sm", className)}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </AlertDialogPrimitive.Description>
  ),
);

export const AlertDialogAction = React.forwardRef(
  ({ className, children, ...props }, forwardedRef) => (
    <AlertDialogPrimitive.Cancel
      className={className}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </AlertDialogPrimitive.Cancel>
  ),
);

export const AlertDialogCancel = React.forwardRef(
  ({ className, children, ...props }, forwardedRef) => (
    <AlertDialogPrimitive.Cancel
      className={className}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </AlertDialogPrimitive.Cancel>
  ),
);
