import React from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../ui/AlertDialog";
import { buttonVariants } from "../../ui/Button";

const SendPaymentLinkAlert = ({ open, onOpenChange, sendMail }) => {
  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Rimanda e-mail</AlertDialogTitle>
          <AlertDialogDescription>
            Manda una mail di pagamento per il cliente sull&apos;indirizzo
            specificato nella prenotazione.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel className={buttonVariants({ variant: "outline" })}>
            Annulla
          </AlertDialogCancel>
          <AlertDialogAction onClick={sendMail} className={buttonVariants()}>
            Conferma
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default SendPaymentLinkAlert;
