import { useNavigate } from "react-router-dom";
import BookingsList from "../components/bookings/BookingsList";
import { buttonVariants } from "../components/ui/Button";
import Breadcrumb from "../components/ui/Breadcrumb";

const Bookings = () => {
  const navigate = useNavigate();

  return (
    <div className="space-y-6">
      <Breadcrumb />
      <div className="flex items-center justify-between">
        <h2>Prenotazioni</h2>
        <button onClick={() => navigate("new")} className={buttonVariants()}>
          Aggiungi
        </button>
      </div>
      <BookingsList />
    </div>
  );
};

export default Bookings;
