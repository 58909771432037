import { TabsContent } from "@radix-ui/react-tabs";
import classNames from "classnames";
import { format, isToday, parse } from "date-fns";
import { it } from "date-fns/locale";
import { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { Link } from "react-router-dom";
import { IDENTIFICATION_TYPES } from "../../config/aliases";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {
  arraysAreEqual,
  calculatePrice,
  capitalize,
  cn,
  formatCurrency,
  generateRandomString,
} from "../../lib/utils";
import { Button, buttonVariants } from "../ui/Button";
import { Calendar } from "../ui/Calendar";
import Checkbox from "../ui/Checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/DropdownMenu";
import EmptyState from "../ui/EmptyState";
import EventsFeed from "../ui/EventsFeed";
import Icon from "../ui/Icon";
import NoteForm from "../ui/NoteForm";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/Popover";
import { StatusBadge, statusBadgeVariants } from "../ui/StatusBadge";
import { Tabs, TabsList, TabsTrigger } from "../ui/Tabs";
import { toast } from "../ui/UseToast";
import BookingsOperations from "./BookingsOperations";
import AddParticipantModal from "./modals/AddParticipantModal";
import CreateRefundModal from "./modals/CreateRefundModal";
import DeletePaymentAlert from "./modals/DeletePaymentAlert";
import ModifyOrganizerDataModal from "./modals/ModifyOrganizerData";
import SendPaymentLinkAlert from "./modals/SendPaymentLinkAlert";

const BookingView = ({ booking, refreshBooking }) => {
  const { auth } = useAuth();

  const [onlinePayment, setOnlinePayment] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const [passengerNotes, setPassengerNotes] = useState(
    booking.notes?.fromPassenger?.description,
  );
  const [activeTab, setActiveTab] = useState("info");
  const [showAddParticipantModal, setShowAddParticipantModal] = useState(false);
  const [tourOperators, setTourOperators] = useState();

  const [participantsAdults, setParticipantsAdults] = useState(
    booking.participants.adults + 1,
  );
  const [participantsKids, setParticipantsKids] = useState(
    booking.participants.kids,
  );
  const [participantsList, setParticipantsList] = useState(
    booking.participants.list,
  );
  const [exclusive, setExclusive] = useState(booking.exclusive);
  const [newParticipant, setNewParticipant] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    dob: "",
    pob: "",
  });

  const [tourOperator, setTourOperator] = useState(
    booking.tourOperator?._id || "",
  );
  const [tourOperatorReference, setTourOperatorReference] = useState(
    booking.tourOperatorReference,
  );

  const [paymentMethod, setPaymentMethod] = useState("contanti");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [minPaymentAmount, setMinPaymentAmount] = useState(0);
  const [paymentDate, setPaymentDate] = useState(new Date());

  const [selectedPaymentId, setSelectedPaymentId] = useState();

  const [showDeletePaymentAlert, setShowDeletePaymentAlert] = useState(false);
  const [showSendPaymentLinkAlert, setShowSendPaymentLinkAlert] =
    useState(false);

  const [paymentInfo, setPaymentInfo] = useState({
    totalAmount: booking.paymentInfo?.totalAmount,
  });

  const [showCreateRefundModal, setShowCreateRefundModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(false);
  const [tourOperatorSettled, setTourOperatorSettled] = useState(false);

  const handleOpenCreateRefundModal = (payment) => {
    setSelectedPayment(payment);
    setShowCreateRefundModal(true);
  };

  const handleExclusiveCheckedChange = () => {
    setExclusive(!exclusive);
  };

  const handleAdultsChange = (newValue) => {
    setParticipantsAdults(newValue);
  };

  const handleKidsChange = (newValue) => {
    setParticipantsKids(newValue);
  };

  const handleTourOperatorChange = (e) => {
    console.log(e.target.value);
    setTourOperator(e.target.value);
  };

  const handleTourOperatorReferenceChange = (e) => {
    setTourOperatorReference(e.target.value);
  };

  const handlePaymentDateChange = (date) => {
    setPaymentDate(date);
  };

  const toggleTourOperatorSettled = () => {
    setTourOperatorSettled(!tourOperatorSettled);
  };

  const handleAddNewParticipant = () => {
    newParticipant._id = generateRandomString(24);
    setParticipantsList([...participantsList, newParticipant]);
    setNewParticipant({
      _id: "",
      firstName: "",
      lastName: "",
      dob: "",
      pob: "",
    });
  };

  const formatParticipantDob = (inputString) => {
    try {
      const result = format(parse(inputString, "dd/MM/yyyy"), "dd/MM/yyyy");
      return result;
    } catch {
      return inputString;
    }
  };

  const handleCancelAddNewParticipant = () => {
    setNewParticipant({
      _id: "",
      firstName: "",
      lastName: "",
      dob: "",
      pob: "",
    });
  };

  const handleDeleteParticipant = (id) => {
    setParticipantsList((prevParticipantsList) =>
      prevParticipantsList.filter((participant) => participant._id !== id),
    );
  };

  const handleNewParticipantChange = (e) => {
    const { name, value } = e.target;
    setNewParticipant((prevParticipant) => ({
      ...prevParticipant,
      [name]: value,
    }));
  };

  const handleResetParticipantsListChanges = () => {
    setParticipantsList(booking.participants.list);
  };

  const handlePaymentMethodChange = (e) => {
    if (e.target.value !== "stripe") {
      setOnlinePayment(false);
    }
    setPaymentMethod(e.target.value);
  };

  const handlePaymentAmountChange = (e) => {
    const { value } = e.target;
    const parsedValue = value.replace(/[^\d.]/gi, "");
    setPaymentAmount(parsedValue);
    setPaymentAmount(e.target.value);
  };

  const handlePaymentAmountOnBlur = () =>
    setPaymentAmount(Number(paymentAmount).toFixed(2));

  const handleTotalAmountChange = (e) => {
    setPaymentInfo({ ...paymentInfo, totalAmount: e.target.value });
  };

  const handleChangeActiveTab = (value) => {
    setActiveTab(value);
  };

  const handleToggleOnlinePayment = () => {
    setOnlinePayment(!onlinePayment);
  };

  const setPaymentAsRefunded = async (paymentId) => {
    try {
      await axiosPrivate.patch(`/payments/${paymentId}`, {
        status: "reimbursed",
      });
      refreshBooking();
      return toast({
        description: "✔️ Pagamento aggiornato.",
        variant: "confirmed",
      });
    } catch (err) {
      console.log(err);
      return toast({
        title: "Qualcosa è andato storto...",
        description: "❌ Non è stato possibile aggiornare il pagamento.",
        variant: "destructive",
      });
    }
  };

  function addPayment() {
    toast({
      description: "⌛ Creazione pagamento in corso...",
    });

    if (auth?.roles.includes(2000)) {
      setOnlinePayment(true);
    }

    axiosPrivate
      .post(`/bookings/${booking._id}/payments?online=${onlinePayment}`, {
        timestamp: isToday(paymentDate)
          ? paymentDate
          : format(paymentDate, "yyyy-MM-dd"),
        method: onlinePayment ? "stripe_link" : paymentMethod,
        amount: parseFloat(paymentAmount),
      })
      .then((response) => {
        refreshBooking();
        toast({
          description: "✔️ Pagamento aggiunto.",
          variant: "confirmed",
        });
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Qualcosa è andato storto...",
          description: "❌ Non è stato possibile aggiungere il pagamento.",
          variant: "destructive",
        });
      });
  }

  async function addRefund(refundData) {
    try {
      const response = await axiosPrivate.post(
        `/bookings/${booking._id}/refunds`,
        refundData,
      );

      refreshBooking();

      if (response?.status !== 200) {
        return toast({
          title: "Qualcosa è andato storto...",
          description: "❌ Non è stato possibile aggiungere il rimborso.",
          variant: "destructive",
        });
      }

      return toast({
        description: "✔️ Rimborso aggiunto.",
        variant: "confirmed",
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function updateAccountingInformation() {
    try {
      let update = {};
      if (tourOperator && booking.tourOperator !== tourOperator) {
        update.tourOperator = tourOperator;
      }
      if (tourOperatorReference) {
        update.tourOperatorReference = tourOperatorReference;
      }
      if (
        paymentInfo.totalAmount &&
        booking.paymentInfo.totalAmount !== paymentInfo.totalAmount
      ) {
        update.paymentInfo = { totalAmount: Number(paymentInfo.totalAmount) };
      }
      const response = await axiosPrivate.patch(
        `/bookings/${booking._id}`,
        update,
      );

      refreshBooking();

      if (response?.status !== 200) {
        return toast({
          title: "Qualcosa è andato storto...",
          description: "❌ La modifica non è andata a buon fine.",
          variant: "destructive",
        });
      }

      return toast({
        description: "✔️ Modifica salvata.",
        variant: "confirmed",
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function updateParticipants() {
    let adults = participantsAdults - 1;
    let kids = participantsKids;

    let update = {
      participants: {
        adults: adults,
        kids: kids,
        list: participantsList,
      },
    };

    if (exclusive !== booking.exclusive) {
      update.exclusive = exclusive;
    }

    try {
      await axiosPrivate.patch(`/bookings/${booking._id}`, update);

      refreshBooking();

      toast({
        description: "✔️ Modifica salvata.",
        variant: "confirmed",
      });
    } catch (err) {
      console.log(err);
      toast({
        title: "Qualcosa è andato storto...",
        description: "❌ La modifica non è andata a buon fine.",
        variant: "destructive",
      });
    }
  }

  async function handleDeletePayment(paymentId) {
    setSelectedPaymentId(paymentId);
    setShowDeletePaymentAlert(true);
  }

  function deletePayment() {
    axiosPrivate
      .delete(`/bookings/${booking._id}/payments/${selectedPaymentId}`)
      .then((response) => {
        refreshBooking();
        toast({
          description: "🗑️ Pagamento eliminato.",
          variant: "confirmed",
        });
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Qualcosa è andato storto...",
          description: "❌ La modifica non è andata a buon fine.",
          variant: "destructive",
        });
      });
  }

  const [showModifyOrganizerDataModal, setShowModifyOrganizerDataModal] =
    useState(false);

  function modifyPassengerNotes() {
    axiosPrivate
      .patch(`/bookings/${booking._id}`, {
        "notes.fromPassenger.description": passengerNotes,
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleChangePassengerNotes = (e) => {
    setPassengerNotes(e.target.value);
  };

  const handleSendPaymentLinkMail = (paymentId) => {
    setSelectedPaymentId(paymentId);
    setShowSendPaymentLinkAlert(true);
  };

  function sendMail() {
    axiosPrivate
      .post(`/bookings/${booking._id}/payments/${selectedPaymentId}/send-mail`)
      .then((response) => {
        toast({ description: "Email mandata correttamente" });
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Errore nell'invio email",
          description: err.toString(),
        });
      });
  }

  useEffect(() => {
    const getTourOperators = () => {
      axiosPrivate
        .get("/touroperators")
        .then((res) => setTourOperators(res.data))
        .catch((err) => console.error(err))
        .finally(() => {});
    };

    const handleTourOperator = () => {
      calculatePrice(booking, "pre").then((result) => {
        setPaymentMethod("stripe");
        setOnlinePayment(true);
        setPaymentAmount(result);
        setMinPaymentAmount(result);
      });
    };

    if (auth?.roles.includes(2000)) {
      handleTourOperator();
    } else {
      getTourOperators();
    }
  }, []);

  return (
    <>
      <Tabs value={activeTab} onValueChange={handleChangeActiveTab}>
        <TabsList className="flex gap-1.5 border-b border-gray-200 font-medium text-gray-400">
          <TabsTrigger
            value="info"
            className={cn(
              "px-2 pb-1 pt-3",
              activeTab === "info" &&
                "border-b-2 border-blue-500 text-blue-500",
            )}
          >
            Informazioni
          </TabsTrigger>
          <TabsTrigger
            value="finance"
            className={cn(
              "px-2 pb-1 pt-3",
              activeTab === "finance" &&
                "border-b-2 border-blue-500 text-blue-500",
            )}
          >
            Contabilità
          </TabsTrigger>
        </TabsList>
        <TabsContent value="info" className="space-y-6 pt-3">
          <div className="rounded border border-gray-300 bg-white shadow">
            <div className="border-b border-gray-300 px-6 py-4">
              <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div>
                  <h1 className="text-lg font-semibold leading-6 text-gray-900">
                    Dettagli prenotazione
                  </h1>
                  {/* TODO: Add click-to-copy */}
                  <div className="flex items-center gap-2">
                    <button
                      value={booking.reference}
                      onClick={(e) =>
                        navigator.clipboard.writeText(e.target.value)
                      }
                      className="inline-flex cursor-pointer items-center rounded bg-slate-100 px-1 text-xs font-semibold leading-5 text-slate-800 transition hover:bg-slate-700 hover:text-white"
                    >
                      <Icon name="Hash" className="h-3 w-3" />
                      {booking.reference}
                    </button>
                    <div className="flex items-center gap-1.5">
                      {booking.tourOperator && (
                        <span
                          className={classNames(
                            booking.tourOperator === "garganogroup"
                              ? "bg-orange-700"
                              : "bg-amber-600",
                            "inline-flex items-center gap-1 truncate rounded px-1 text-xs font-semibold uppercase leading-5 text-white transition",
                          )}
                        >
                          <Icon name="Briefcase" className="h-3 w-3" />
                          {booking.tourOperator.name}
                        </span>
                      )}
                      {booking.online && (
                        <span className="inline-flex items-center gap-1 truncate rounded bg-emerald-800 px-1 text-xs font-semibold uppercase leading-5 text-white transition">
                          <Icon name="Globe" className="h-3 w-3" />
                          ONLINE
                        </span>
                      )}
                      {!booking.tourOperator && !booking.online && (
                        <span className="inline-flex items-center gap-1 truncate rounded bg-sky-800 px-1 text-xs font-semibold uppercase leading-5 text-white transition">
                          <Icon name="LampDesk" className="h-3 w-3" />
                          UFFICIO
                        </span>
                      )}
                    </div>
                    {booking.tourOperatorReference && (
                      <span className="inline-flex items-center gap-1 rounded bg-slate-700 px-1 text-xs font-semibold uppercase leading-5 text-white">
                        <Icon name="Asterisk" className="h-3 w-3" />
                        {booking.tourOperatorReference}
                      </span>
                    )}
                    {/* TODO: Aggiungere n referenza del operator */}
                  </div>
                </div>
                <div className="text-right">
                  <StatusBadge status={booking.status} />
                  <p className="mt-1 text-xs text-gray-500 lg:hidden">
                    {new Date(booking.submittedOn).toLocaleDateString("it-IT")}
                  </p>
                  <p className="mt-1 hidden text-sm text-gray-500 lg:block">
                    {new Date(booking.submittedOn).toLocaleString("it-IT")}
                  </p>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3">
              {/* Left section */}
              <div className="col-span-1 lg:col-span-2">
                <dl className="divide-y divide-gray-200">
                  {/* Details */}
                  <div className="flex flex-col px-3 py-6">
                    <div className="flex justify-between">
                      <div className="text-sm font-medium text-slate-700">
                        Data e Tour
                      </div>
                      <div className="inline-flex items-center gap-3">
                        <div className="text-sm font-medium">
                          {capitalize(
                            format(new Date(booking.date), "EEEE dd MMM", {
                              locale: it,
                            }),
                          )}
                        </div>
                        {booking.tour ? (
                          <div className="flex w-24 items-center gap-1.5 rounded border border-gray-200 p-1.5">
                            <Icon
                              name={booking.tour.icon}
                              className="h-3 w-3 text-gray-400"
                            />
                            <div className="text-xs font-medium uppercase">
                              {capitalize(booking.tour.name)}
                            </div>
                          </div>
                        ) : (
                          <button className="flex w-24 items-center justify-center gap-1.5 rounded border border-dashed border-gray-200 p-1.5 text-gray-400 transition-colors hover:border-slate-700 hover:text-slate-700 focus:outline-none">
                            <Icon name="Plus" className="h-3 w-3" />
                            <div className="text-xs font-medium">Tour</div>
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="mt-4 flex items-center justify-between">
                      <div className="text-sm font-medium text-slate-700">
                        Imbarco
                      </div>
                      <div className="inline-flex items-center gap-1.5">
                        {booking.boat ? (
                          <div className="flex w-24 items-center gap-1.5 rounded border border-gray-200 p-1.5">
                            <Icon
                              name="Sailboat"
                              className="h-3 w-3 text-gray-400"
                            />
                            <div className="text-xs font-medium">
                              {booking.boat?.name}
                            </div>
                          </div>
                        ) : (
                          <button className="flex w-24 items-center justify-center gap-1.5 rounded border border-dashed border-gray-200 p-1.5 text-gray-400 transition-colors hover:border-slate-700 hover:text-slate-700 focus:outline-none">
                            <Icon name="Plus" className="h-3 w-3" />
                            <div className="text-xs font-medium">Mezzo</div>
                          </button>
                        )}
                        {booking.skipper ? (
                          <div className="flex w-24 items-center gap-1.5 rounded border border-gray-200 p-1.5">
                            <Icon
                              name="FerrisWheel"
                              className="h-3 w-3 text-gray-400"
                            />
                            <div className="text-xs font-medium">
                              {booking.skipper.displayName.firstName}
                            </div>
                          </div>
                        ) : (
                          <button className="flex w-24 items-center justify-center gap-1.5 rounded border border-dashed border-gray-200 p-1.5 text-gray-400 transition-colors hover:border-slate-700 hover:text-slate-700 focus:outline-none">
                            <Icon name="Plus" className="h-3 w-3" />
                            <div className="text-xs font-medium">Skipper</div>
                          </button>
                        )}
                        {booking.port ? (
                          <div className="flex w-24 items-center gap-1.5 rounded border border-gray-200 p-1.5">
                            <Icon
                              name="Anchor"
                              className="h-3 w-3 text-gray-400"
                            />
                            <div className="text-xs font-medium">
                              {capitalize(booking.port.name)}
                            </div>
                          </div>
                        ) : (
                          <button className="flex w-24 items-center justify-center gap-1.5 rounded border border-dashed border-gray-200 p-1.5 text-gray-400 transition-colors hover:border-slate-700 hover:text-slate-700 focus:outline-none">
                            <Icon name="Plus" className="h-3 w-3" />
                            <div className="text-xs font-medium">Porto</div>
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="mt-4 flex justify-between">
                      <div className="text-sm font-medium text-slate-700">
                        Saldo
                      </div>
                      <div className="text-sm">
                        {formatCurrency.format(booking.paymentInfo.balance)} /{" "}
                        {formatCurrency.format(booking.paymentInfo.totalAmount)}
                      </div>
                    </div>
                  </div>
                  <div className="space-y-6 px-3 py-6">
                    <div className="grid lg:grid-cols-3">
                      <dt className="text-sm font-medium text-gray-500">
                        Organizzatore
                      </dt>
                      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        <div className="flex-grow gap-3 space-y-1.5 divide-y divide-gray-200">
                          <div className="py-1.5">
                            <div className="inline-flex items-center gap-1.5">
                              <Icon
                                name="User"
                                className="h-3 w-3 text-gray-400"
                              />
                              <div>
                                {booking.organizer.firstName}{" "}
                                {booking.organizer.lastName}
                              </div>
                            </div>
                            {booking.organizer.pob || booking.organizer.dob ? (
                              <div>
                                {booking.organizer.pob ? (
                                  `${booking.organizer.pob},`
                                ) : (
                                  <span className="text-gray-400">
                                    Luogo di nascita da inserire,
                                  </span>
                                )}{" "}
                                {booking.organizer.dob
                                  ? format(
                                      new Date(booking.organizer.dob),
                                      "dd/MM/yyyy",
                                    )
                                  : "Data di nascita da inserire"}
                              </div>
                            ) : (
                              <div className="text-gray-400">
                                Informazioni anagrafiche assenti
                              </div>
                            )}
                          </div>
                          <div className="py-1.5">
                            <div className="inline-flex items-center gap-1.5">
                              <Icon
                                name="Mail"
                                className="h-3 w-3 text-gray-400"
                              />
                              {booking.organizer.contactInfo?.email ? (
                                <div>{booking.organizer.contactInfo.email}</div>
                              ) : (
                                <div className="text-gray-400">
                                  Email non specificata
                                </div>
                              )}
                            </div>
                            {booking.organizer.contactInfo?.phone?.number ? (
                              <div>
                                {
                                  booking.organizer.contactInfo.phone
                                    .internationalPrefix
                                }
                                {booking.organizer.contactInfo.phone.number}
                              </div>
                            ) : (
                              <div className="text-gray-400">
                                Numero di telefono non specificato
                              </div>
                            )}
                          </div>
                          <div>
                            <div>
                              {booking.organizer.identification?.type &&
                              booking.organizer.identification.type !==
                                "none" ? (
                                <div>
                                  {
                                    IDENTIFICATION_TYPES[
                                      booking.organizer.identification.type
                                    ]
                                  }
                                </div>
                              ) : (
                                <div className="text-gray-400">
                                  Tipo di documento non inserito
                                </div>
                              )}
                            </div>
                            {booking.organizer.identification?.number ? (
                              <div>
                                {booking.organizer.identification.number}
                              </div>
                            ) : (
                              <div className="text-gray-400">
                                Numero documento non inserito
                              </div>
                            )}
                            {booking.organizer?.identification?.expiry_date ? (
                              <div>
                                {format(
                                  new Date(
                                    booking.organizer.identification.expiry_date,
                                  ),
                                  "dd/MM/yyyy",
                                )}
                              </div>
                            ) : (
                              <div className="text-gray-400">
                                Data di scadenza non inserita
                              </div>
                            )}
                            {booking.organizer?.identification?.issuedBy ? (
                              <div>
                                {booking.organizer.identification.issuedBy}
                              </div>
                            ) : (
                              <div className="text-gray-400">
                                Ente rilascio non inserito
                              </div>
                            )}
                          </div>
                        </div>
                        {!auth?.roles.includes(1500) && (
                          <ModifyOrganizerDataModal
                            booking={booking}
                            refreshBooking={refreshBooking}
                          />
                        )}
                      </dd>
                    </div>
                    <div className="grid lg:grid-cols-3">
                      <dt className="text-sm font-medium text-gray-500">
                        Richieste
                      </dt>
                      <dd className="mt-1 text-sm italic text-gray-900 sm:col-span-2 sm:mt-0">
                        <div className="flex flex-col items-end gap-1.5">
                          <textarea
                            value={passengerNotes}
                            onChange={handleChangePassengerNotes}
                            placeholder="Nessuna nota da parte del cliente"
                            rows="4"
                            className="w-full rounded border border-gray-300 px-2 py-1 text-sm focus:outline-none focus:ring focus:ring-blue-600"
                          />
                          <button
                            onClick={modifyPassengerNotes}
                            disabled={
                              passengerNotes ===
                              booking.notes?.fromPassenger?.description
                            }
                            className={cn(
                              "w-16",
                              buttonVariants({ size: "sm" }),
                            )}
                          >
                            Salva
                          </button>
                        </div>
                      </dd>
                    </div>
                    <div className="grid gap-3 lg:grid-cols-3">
                      <dt className="space-y-1.5">
                        <div className="text-sm font-medium text-gray-500">
                          Numero partecipanti
                        </div>
                        <div className="border-box space-y-1.5 border border-gray-200 p-1.5">
                          <div className="flex items-center justify-between">
                            <div className="text-sm font-medium">Adulti</div>
                            {!auth?.roles.includes(2000) && (
                              <div className="inline-flex items-center gap-1.5">
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleAdultsChange(participantsAdults - 1)
                                  }
                                  disabled={participantsAdults === 1}
                                  className="flex items-center justify-center rounded-full border border-gray-400 focus:outline-none"
                                >
                                  <Icon
                                    name="Minus"
                                    className="h-4 w-4 text-slate-700"
                                  />
                                </button>
                                <span>{participantsAdults}</span>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleAdultsChange(participantsAdults + 1)
                                  }
                                  className="flex items-center justify-center rounded-full border border-gray-400 focus:outline-none"
                                >
                                  <Icon
                                    name="Plus"
                                    className="h-4 w-4 text-slate-700"
                                  />
                                </button>
                              </div>
                            )}
                          </div>
                          <div className="flex items-center justify-between">
                            <div className="text-sm font-medium">Bambini</div>
                            {!auth?.roles.includes(2000) && (
                              <div className="inline-flex items-center gap-1.5">
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleKidsChange(participantsKids - 1)
                                  }
                                  disabled={participantsKids === 0}
                                  className="flex items-center justify-center rounded-full border border-gray-400 focus:outline-none"
                                >
                                  <Icon
                                    name="Minus"
                                    className="h-4 w-4 text-slate-700"
                                  />
                                </button>
                                <span>{participantsKids}</span>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleKidsChange(participantsKids + 1)
                                  }
                                  className="flex items-center justify-center rounded-full border border-gray-400 focus:outline-none"
                                >
                                  <Icon
                                    name="Plus"
                                    className="h-4 w-4 text-slate-700"
                                  />
                                </button>
                              </div>
                            )}
                          </div>
                          <div className="border-b border-gray-200" />
                          <div className="flex items-center justify-between gap-1 text-sm font-medium text-indigo-600">
                            Esclusiva
                            <Checkbox
                              checked={exclusive}
                              onCheckedChange={handleExclusiveCheckedChange}
                            />
                          </div>
                        </div>
                      </dt>
                      <dd className="col-span-2 mt-1 text-sm text-gray-900 sm:mt-0">
                        <div className="flex items-center justify-between">
                          <div className="text-sm font-medium text-gray-500">
                            Informazioni sui passeggeri
                          </div>
                          <button>
                            <Icon
                              name="Download"
                              className="h-4 w-4 text-gray-400 transition-colors hover:text-black"
                            />
                          </button>
                        </div>
                        <div className="flex flex-col items-end">
                          <ul className="w-full divide-y divide-gray-200">
                            <li
                              key={booking.organizer}
                              className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                            >
                              <div className="flex w-0 flex-1 items-center justify-between">
                                <Icon
                                  name="Flag"
                                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                                <span className="ml-2 w-0 flex-1 truncate font-medium">
                                  {booking.organizer.firstName}{" "}
                                  {booking.organizer.lastName}
                                </span>
                              </div>
                            </li>
                            {/* If booking is CONFIRMED */}
                            {booking.status > 100 &&
                              participantsList.map((participant) => (
                                <li
                                  key={participant._id}
                                  className="flex items-center justify-between gap-3 p-3 text-sm"
                                >
                                  <div className="flex w-0 flex-1 items-center">
                                    {/* TODO: DIFFERENTIATE KIDS */}
                                    <Icon
                                      name="User"
                                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                                      aria-hidden="true"
                                    />
                                    <span className="ml-2 w-0 flex-1 truncate">
                                      {participant.firstName}{" "}
                                      {participant.lastName}
                                    </span>
                                  </div>
                                  <div className="flex flex-col gap-0.5">
                                    {!!participant.pob && (
                                      <div className="text-right text-xs text-gray-400">
                                        {participant.pob}
                                      </div>
                                    )}
                                    {!!participant.dob && (
                                      <div className="text-right text-xs text-gray-400">
                                        {formatParticipantDob(participant.dob)}
                                      </div>
                                    )}
                                  </div>
                                  <button
                                    onClick={() =>
                                      handleDeleteParticipant(participant._id)
                                    }
                                    className="focus:outline-none"
                                  >
                                    <Icon
                                      name="X"
                                      className="h-6 w-6 text-gray-400 transition-colors hover:text-red-400"
                                    />
                                  </button>
                                </li>
                              ))}
                            {booking.status > 100 ? (
                              <li
                                key="add-participant"
                                className="flex justify-center p-3"
                              >
                                <div>
                                  <button
                                    onClick={() =>
                                      setShowAddParticipantModal(true)
                                    }
                                    className="flex w-full items-center gap-1.5 text-gray-400 transition-colors hover:text-green-500 focus:outline-none"
                                  >
                                    <Icon name="Plus" className="h-6 w-6" />
                                    Aggiungi partecipante
                                  </button>
                                </div>
                              </li>
                            ) : null}
                          </ul>
                          <div className="flex items-center gap-1.5">
                            <button
                              onClick={handleResetParticipantsListChanges}
                              disabled={arraysAreEqual(
                                participantsList,
                                booking.participants.list,
                              )}
                              className={cn(
                                "w-16",
                                buttonVariants({
                                  variant: "outline",
                                  size: "sm",
                                }),
                              )}
                            >
                              Annulla
                            </button>
                            <button
                              onClick={updateParticipants}
                              disabled={
                                exclusive === booking.exclusive &&
                                arraysAreEqual(
                                  participantsList,
                                  booking.participants.list,
                                ) &&
                                participantsAdults ===
                                  booking.participants.adults + 1 &&
                                participantsKids === booking.participants.kids
                              }
                              className={cn(
                                "w-16",
                                buttonVariants({ size: "sm" }),
                              )}
                            >
                              Salva
                            </button>
                          </div>
                        </div>
                      </dd>
                    </div>
                  </div>
                </dl>
              </div>
              {/* Right section */}
              <div className="border-box space-y-3 border-l border-gray-200 bg-gray-50 p-3">
                {!auth?.roles.includes(2000) && (
                  <NoteForm
                    id={booking._id}
                    entity="booking"
                    refreshFn={refreshBooking}
                  />
                )}
                {!auth?.roles.includes(2000) && !!booking.events?.length ? (
                  <EventsFeed
                    entityId={booking._id}
                    events={booking.events}
                    entity="booking"
                    refreshFn={refreshBooking}
                  />
                ) : (
                  <EmptyState title="Non ci sono ancora attività registrate per questa prenotazione." />
                )}
                <BookingsOperations
                  booking={booking}
                  refreshBooking={refreshBooking}
                />
              </div>
            </div>
          </div>
        </TabsContent>
        <TabsContent value="finance" className="space-y-6 pt-3">
          <div className="rounded border border-gray-300 bg-white shadow">
            <div className="border-b border-gray-300 px-6 py-4">
              <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div>
                  <h1 className="text-lg font-semibold leading-6 text-gray-900">
                    Contabilità
                  </h1>
                  {/* TODO: Add click-to-copy */}
                  <div className="flex items-center gap-2">
                    <button
                      value={booking.reference}
                      onClick={(e) =>
                        navigator.clipboard.writeText(e.target.value)
                      }
                      className="inline-flex cursor-pointer items-center rounded bg-slate-100 px-1 text-xs font-semibold leading-5 text-slate-800 transition hover:bg-slate-700 hover:text-white"
                    >
                      <Icon name="Hash" className="h-3 w-3" />
                      {booking.reference}
                    </button>
                    {!!booking.tourOperator && (
                      <span
                        className={classNames(
                          booking.tourOperator.name === "garganogroup"
                            ? "bg-orange-700"
                            : "bg-amber-600",
                          "inline-flex items-center gap-1 truncate rounded px-1 text-xs font-semibold uppercase leading-5 text-white transition",
                        )}
                      >
                        <Icon name="Briefcase" className="h-3 w-3" />
                        {booking.tourOperator.name}
                      </span>
                    )}
                    {booking.online && (
                      <span className="inline-flex items-center gap-1 truncate rounded bg-emerald-800 px-1 text-xs font-semibold uppercase leading-5 text-white transition">
                        <Icon name="Globe" className="h-3 w-3" />
                        ONLINE
                      </span>
                    )}
                    {!booking.tourOperator && (
                      <span className="inline-flex items-center gap-1 truncate rounded bg-sky-800 px-1 text-xs font-semibold uppercase leading-5 text-white transition">
                        <Icon name="LampDesk" className="h-3 w-3" />
                        UFFICIO
                      </span>
                    )}
                  </div>
                </div>
                <div className="text-right">
                  <div className="flex gap-1.5 text-lg">
                    <div className="font-medium">Saldo:</div>
                    {formatCurrency.format(booking.paymentInfo.balance)}
                  </div>

                  <div className="mt-1 text-xs text-gray-500">
                    su totale:{" "}
                    {formatCurrency.format(booking.paymentInfo.totalAmount)}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-3 divide-y divide-gray-200 p-3 lg:grid-cols-3 lg:divide-x lg:divide-y-0">
              <div className="flex flex-col gap-1.5 px-6 py-4">
                <div className="text-lg font-medium">Registra un pagamento</div>
                <div className="flex h-full flex-col justify-between">
                  <div className="flex flex-col space-y-1.5">
                    <div>
                      <div className="text-sm">Data</div>
                      <Popover>
                        <PopoverTrigger asChild>
                          <Button
                            variant={"input"}
                            className={cn(
                              "mt-1 w-full justify-start text-left font-normal text-black",
                              !paymentDate && "text-gray-400",
                            )}
                          >
                            <Icon name="Calendar" className="mr-2 h-4 w-4" />
                            {paymentDate ? (
                              format(paymentDate, "PPP", { locale: it })
                            ) : (
                              <span>Seleziona una data</span>
                            )}
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                          <Calendar
                            mode="single"
                            selected={paymentDate}
                            onSelect={handlePaymentDateChange}
                          />
                        </PopoverContent>
                      </Popover>
                    </div>
                    <div className="flex items-start justify-between">
                      <div>
                        <div className="text-sm">Metodo</div>
                        <select
                          value={paymentMethod}
                          onChange={handlePaymentMethodChange}
                          className="mt-1 grow rounded border border-gray-300 px-2 py-1 text-sm focus:outline-none focus:ring focus:ring-blue-600"
                        >
                          {!auth?.roles.includes(2000) && (
                            <>
                              <option value="contanti">Contanti</option>
                              <option value="bonifico">Bonifico</option>
                              <option value="sumup">Sumup</option>
                            </>
                          )}
                          <option value="stripe">Stripe</option>
                        </select>
                      </div>
                      <div>
                        <div className="text-sm">Ammontare</div>
                        <CurrencyInput
                          allowNegativeValue={false}
                          min={minPaymentAmount}
                          value={paymentAmount}
                          onChange={handlePaymentAmountChange}
                          onBlur={handlePaymentAmountOnBlur}
                          placeholder={paymentAmount}
                          className="mt-2 w-16 border-b border-gray-200 focus:outline-none"
                        />
                        €
                      </div>
                    </div>
                    {auth?.roles.includes(2000) ? (
                      <div className="text-xs">
                        Verrà generato un link di pagamento.
                      </div>
                    ) : (
                      <div className="inline-flex items-center gap-1.5 text-xs">
                        <input
                          disabled={paymentMethod !== "stripe"}
                          type="checkbox"
                          checked={onlinePayment}
                          value={onlinePayment}
                          onChange={handleToggleOnlinePayment}
                        />
                        Crea un link di pagamento
                      </div>
                    )}
                  </div>
                  <div className="w-full pt-3 text-center">
                    <button
                      onClick={addPayment}
                      disabled={
                        !paymentAmount ||
                        (auth?.roles.includes(2000) &&
                          paymentAmount < minPaymentAmount)
                      }
                      className={cn("w-full", buttonVariants({ size: "sm" }))}
                    >
                      Registra
                    </button>
                  </div>
                </div>
              </div>
              {!auth?.roles.includes(2000) && (
                <div className="flex flex-col gap-1.5 px-6 py-4">
                  <div className="text-lg font-medium">
                    Modifica informazioni
                  </div>
                  <div className="flex h-full flex-col justify-between">
                    <div className="space-y-1.5">
                      <div className="flex items-center justify-between">
                        <div className="font-medium">Totale</div>
                        <div>
                          <CurrencyInput
                            allowNegativeValue={false}
                            min={0}
                            value={paymentInfo.totalAmount}
                            onChange={handleTotalAmountChange}
                            placeholder={booking.paymentInfo.totalAmount}
                            className="w-16 border-b border-gray-200 focus:outline-none"
                          />
                          €
                        </div>
                      </div>
                      <div className="flex items-start gap-3">
                        <div className="flex flex-1 flex-col gap-1.5">
                          <div className="text-sm">Tour Operator</div>
                          <select
                            disabled={!tourOperators}
                            value={tourOperator || ""}
                            onChange={handleTourOperatorChange}
                            className="rounded border border-gray-300 px-2 py-1 text-sm focus:outline-none focus:ring focus:ring-blue-600"
                          >
                            <option value="">
                              {tourOperators ? "Nessuno" : "Caricamento..."}
                            </option>
                            {tourOperators?.map((tourOperator) => {
                              return (
                                <option
                                  key={tourOperator._id}
                                  value={tourOperator._id}
                                >
                                  {tourOperator.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="flex-0 flex w-24 flex-col gap-1.5">
                          <div className="text-sm">Numero</div>
                          <input
                            value={tourOperatorReference}
                            onChange={handleTourOperatorReferenceChange}
                            className="rounded border border-gray-300 px-2 py-1 text-sm focus:outline-none focus:ring focus:ring-blue-600"
                          />
                        </div>
                      </div>
                      {(auth?.roles.includes(2500) ||
                        auth?.roles.includes(5000) ||
                        auth?.roles.includes(7777)) && (
                        <div className="inline-flex items-center gap-1.5 text-xs">
                          <input
                            disabled={!tourOperator}
                            type="checkbox"
                            checked={tourOperatorSettled}
                            value={tourOperatorSettled}
                            onChange={toggleTourOperatorSettled}
                          />
                          Saldato con il TO
                        </div>
                      )}
                    </div>
                    <div className="w-full pt-3">
                      <button
                        onClick={updateAccountingInformation}
                        className={cn("w-full", buttonVariants({ size: "sm" }))}
                      >
                        Salva
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="space-y-3">
            {!booking.payments?.length && (
              <div className="flex h-32 w-full items-center justify-center">
                <div className="text-sm text-gray-400">
                  Nessun pagamento registrato per questa prenotazione
                </div>
              </div>
            )}
            <div className="flex gap-3">
              <div className="flex-auto space-y-1.5">
                {booking.payments?.map((payment) => {
                  return (
                    <div
                      key={payment._id}
                      className="flex items-center justify-between rounded border border-gray-200 p-3"
                    >
                      <div className="flex items-center gap-3">
                        <div className="rounded-full bg-gray-200 p-1">
                          <Icon name="Euro" className="h-6 w-6 text-gray-400" />
                        </div>
                        <div>
                          <div className="flex items-center gap-1.5 text-sm font-medium">
                            <div>Pagamento</div>
                            <span
                              className={statusBadgeVariants({
                                variant:
                                  payment.status === "confirmed"
                                    ? "positive"
                                    : "warning",
                              })}
                            >
                              {payment.status === "confirmed"
                                ? "Completato"
                                : payment.status === "reimbursed"
                                  ? "Rimborsato"
                                  : "Non confermato"}
                              <Icon
                                name={
                                  payment.status === "confirmed"
                                    ? "Check"
                                    : payment.status === "reimbursed"
                                      ? "Undo2"
                                      : "Clock"
                                }
                                className="h-3 w-3"
                              />
                            </span>
                          </div>
                          <div className="inline-flex items-center gap-1.5 text-xs">
                            <div>{formatCurrency.format(payment.amount)}</div>
                            <div>&#x2022;</div>
                            <div className="uppercase">{payment.method}</div>
                            <div className="text-gray-400">
                              {capitalize(
                                format(
                                  new Date(payment.timestamp),
                                  "EEEE dd MMM, HH:mm",
                                  { locale: it },
                                ),
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center gap-1.5">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <button className="text-gray-400 transition-colors hover:text-slate-700">
                              <Icon name="MoreVertical" className="h-4 w-4" />
                            </button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent
                            align="end"
                            className="bg-background-menu min-w-[150px] p-1"
                          >
                            {payment.url && payment.status !== "confirmed" && (
                              <DropdownMenuItem
                                className="flex cursor-pointer flex-row gap-1 px-2 py-1 transition-colors hover:bg-gray-100"
                                onClick={() => {
                                  navigator.clipboard.writeText(payment.url);
                                  toast({
                                    description: "📋 Link copiato.",
                                  });
                                }}
                              >
                                <Icon name="Clipboard" className="h-4 w-4" />
                                Copia link
                              </DropdownMenuItem>
                            )}
                            {payment.url && payment.status !== "confirmed" && (
                              <DropdownMenuItem
                                onClick={() =>
                                  handleSendPaymentLinkMail(payment._id)
                                }
                                className="flex cursor-pointer flex-row gap-1 px-2 py-1 transition-colors hover:bg-gray-100"
                              >
                                <Icon name="Mail" className="h-4 w-4" />
                                Manda via e-mail
                              </DropdownMenuItem>
                            )}
                            {((payment.url && payment.status !== "confirmed") ||
                              (!payment.url &&
                                payment.method !== "stripe_online")) && (
                              <DropdownMenuItem
                                onClick={() => handleDeletePayment(payment._id)}
                                className="flex cursor-pointer flex-row gap-1 px-2 py-1 transition-colors hover:bg-gray-100"
                              >
                                <Icon name="Trash" className="h-4 w-4" />
                                Cancella pagamento
                              </DropdownMenuItem>
                            )}
                            {payment.stripePaymentId && (
                              <Link
                                to={`https://dashboard.stripe.com/payments/${payment.stripePaymentId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <DropdownMenuItem className="flex cursor-pointer flex-row gap-1 px-2 py-1 transition-colors hover:bg-gray-100">
                                  <Icon
                                    name="ExternalLink"
                                    className="h-4 w-4"
                                  />
                                  Visualizza su Stripe
                                </DropdownMenuItem>
                              </Link>
                            )}
                            {!payment.stripePaymentId && (
                              <DropdownMenuItem
                                onClick={() =>
                                  setPaymentAsRefunded(payment._id)
                                }
                                className="flex cursor-pointer flex-row gap-1 px-2 py-1 transition-colors hover:bg-gray-100"
                              >
                                <Icon name="ExternalLink" className="h-4 w-4" />
                                Imposta come rimborsato
                              </DropdownMenuItem>
                            )}
                            {(payment.method === "stripe" ||
                              payment.method === "stripe_online" ||
                              payment.method === "stripe_link") &&
                              payment.stripePaymentId &&
                              payment.status === "confirmed" &&
                              !auth?.roles.includes(2000) && (
                                <DropdownMenuItem
                                  onClick={() =>
                                    handleOpenCreateRefundModal(payment)
                                  }
                                  className="flex cursor-pointer flex-row gap-1 px-2 py-1 transition-colors hover:bg-gray-100"
                                >
                                  <Icon name="Undo2" className="h-4 w-4" />
                                  Emetti un rimborso
                                </DropdownMenuItem>
                              )}
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                    </div>
                  );
                })}
              </div>
              {!!booking.refunds.length && (
                <div className="flex-auto">
                  {booking.refunds?.map((refund) => {
                    return (
                      <div
                        key={refund._id}
                        className="flex items-center justify-between rounded border border-gray-200 p-3"
                      >
                        <div className="flex items-center gap-3">
                          <div className="rounded-full bg-gray-200 p-1">
                            <Icon
                              name="Undo2"
                              className="h-6 w-6 text-red-300"
                            />
                          </div>
                          <div>
                            <div className="flex items-center gap-1.5">
                              <div className="text-sm font-medium">
                                Rimborso
                              </div>
                              <span
                                className={statusBadgeVariants({
                                  variant:
                                    refund.status === "confirmed"
                                      ? "positive"
                                      : "warning",
                                })}
                              >
                                {refund.status === "confirmed"
                                  ? "Completato"
                                  : "Non confermato"}
                                <Icon
                                  name={
                                    refund.status === "confirmed"
                                      ? "Check"
                                      : "Clock"
                                  }
                                  className="h-3 w-3"
                                />
                              </span>
                            </div>

                            <div className="inline-flex items-center gap-1.5 text-xs">
                              <div>{formatCurrency.format(refund.amount)}</div>
                              <div>&#x2022;</div>
                              <div className="uppercase">{refund.method}</div>
                              <div className="text-gray-400">
                                {capitalize(
                                  format(
                                    new Date(refund.timestamp),
                                    "EEEE dd MMM, HH:mm",
                                    { locale: it },
                                  ),
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center gap-1.5">
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <button className="text-gray-400 transition-colors hover:text-slate-700">
                                <Icon name="MoreVertical" className="h-4 w-4" />
                              </button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent
                              align="end"
                              className="bg-background-menu min-w-[150px] p-1"
                            >
                              {refund.stripePaymentId && (
                                <Link
                                  to={`https://dashboard.stripe.com/payments/${refund.stripePaymentId}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <DropdownMenuItem className="flex cursor-pointer flex-row gap-1 px-2 py-1 transition-colors hover:bg-gray-100">
                                    <Icon
                                      name="ExternalLink"
                                      className="h-4 w-4"
                                    />
                                    Visualizza su Stripe
                                  </DropdownMenuItem>
                                </Link>
                              )}
                              {refund.voucher && (
                                <DropdownMenuItem
                                  className="flex cursor-pointer flex-row gap-1 px-2 py-1 transition-colors hover:bg-gray-100"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      booking.reference,
                                    );
                                    toast({
                                      description: "📋 Codice copiato.",
                                    });
                                  }}
                                >
                                  <Icon name="Clipboard" className="h-4 w-4" />
                                  Copia codice
                                </DropdownMenuItem>
                              )}
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </TabsContent>
      </Tabs>
      {/* Modify organizer data */}
      {showModifyOrganizerDataModal && (
        <ModifyOrganizerDataModal
          booking={booking}
          open={showModifyOrganizerDataModal}
          onOpenChange={setShowModifyOrganizerDataModal}
          refreshBooking={refreshBooking}
        />
      )}
      {/* Add participant */}
      {showAddParticipantModal && (
        <AddParticipantModal
          open={showAddParticipantModal}
          onOpenChange={setShowAddParticipantModal}
          newParticipant={newParticipant}
          handleNewParticipantChange={handleNewParticipantChange}
          handleAddNewParticipant={handleAddNewParticipant}
          handleCancelAddNewParticipant={handleCancelAddNewParticipant}
        />
      )}
      {/* Confirm payment deletion */}
      {showDeletePaymentAlert && (
        <DeletePaymentAlert
          open={showDeletePaymentAlert}
          onOpenChange={setShowDeletePaymentAlert}
          deletePayment={deletePayment}
        />
      )}
      {showSendPaymentLinkAlert && (
        <SendPaymentLinkAlert
          open={showSendPaymentLinkAlert}
          onOpenChange={setShowSendPaymentLinkAlert}
          sendMail={sendMail}
        />
      )}
      {showCreateRefundModal && (
        <CreateRefundModal
          open={showCreateRefundModal}
          onOpenChange={setShowCreateRefundModal}
          paymentData={selectedPayment}
          confirmFn={addRefund}
        />
      )}
    </>
  );
};

export default BookingView;
