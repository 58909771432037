import classNames from "classnames";

const Skeleton = ({ className, ...props }) => {
  return (
    <div
      className={classNames("animate-pulse rounded bg-gray-300", className)}
      {...props}
    />
  );
};

export default Skeleton;
