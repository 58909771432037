import DisplayListItem from "./DisplayListItem";

const UsersListBlock = ({ users }) => {
  return (
    <ul className="rounded border border-gray-300">
      {users.map((user) => {
        return (
          <DisplayListItem
            key={user._id}
            title={`${user.displayName.firstName}`}
            caption={user.username}
            href={`/users/${user._id}`}
          />
        );
      })}
    </ul>
  );
};

export default UsersListBlock;
