import { format } from "date-fns";
import { it } from "date-fns/locale";
import { DOCUMENT_TYPES } from "../../../config/aliases";
import { capitalize, formatCurrency } from "../../../lib/utils";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../AlertDialog";
import { buttonVariants } from "../Button";

const ViewLogDetailsModal = ({ open, onOpenChange, log }) => {
  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Dettagli</AlertDialogTitle>
        </AlertDialogHeader>
        <div className="space-y-3">
          <div className="text-sm text-gray-400">
            Modificata da: {log.user.displayName.firstName} il{" "}
            {format(new Date(log.timestamp), "dd/MM/yyyy HH:mm")}
          </div>
          {log.changes.paymentInfo?.totalAmount && (
            <div>
              <div className="text-lg font-medium">Totale</div>
              <div className="grid grid-cols-2 rounded border border-gray-200 p-3">
                <div>
                  <div className="font-medium">Originale</div>
                  <div className="font-medium text-slate-700">
                    {formatCurrency.format(
                      log.changes.paymentInfo.totalAmount.old,
                    )}
                  </div>
                </div>
                <div>
                  <div className="font-medium">Nuovo</div>
                  <div className="font-medium text-blue-700">
                    {formatCurrency.format(
                      log.changes.paymentInfo.totalAmount.new,
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {log.changes.tourOperator && (
            <div>
              <div className="text-lg font-medium">TO</div>
              <div className="grid grid-cols-2 rounded border border-gray-200 p-3">
                <div>
                  <div className="font-medium">Originale</div>
                  <div className="font-medium text-slate-700">
                    {capitalize(log.changes.tourOperator.old)}
                  </div>
                </div>
                <div>
                  <div className="font-medium">Nuovo</div>
                  <div className="font-medium text-blue-700">
                    {capitalize(log.changes.tourOperator.new)}
                  </div>
                </div>
              </div>
            </div>
          )}
          {log.changes.exclusive && (
            <div>
              <div className="text-lg font-medium">Esclusiva</div>
              <div className="grid grid-cols-2 rounded border border-gray-200 p-3">
                <div>
                  <div className="font-medium">Originale</div>
                  <div className="font-medium text-slate-700">
                    {log.changes.exclusive.old ? "Si" : "No"}
                  </div>
                </div>
                <div>
                  <div className="font-medium">Nuovo</div>
                  <div className="font-medium text-blue-700">
                    {log.changes.exclusive.new ? "Si" : "No"}
                  </div>
                </div>
              </div>
            </div>
          )}
          {log.changes.date && (
            <div>
              <div className="text-lg font-medium">Data</div>
              <div className="grid grid-cols-2 rounded border border-gray-200 p-3">
                <div>
                  <div className="font-medium">Originale</div>
                  <div className="font-medium text-slate-700">
                    {capitalize(
                      format(new Date(log.changes.date.old), "dd MMM", {
                        locale: it,
                      }),
                    )}
                  </div>
                </div>
                <div>
                  <div className="font-medium">Nuovo</div>
                  <div className="font-medium text-blue-700">
                    {capitalize(
                      format(new Date(log.changes.date.new), "dd MMM", {
                        locale: it,
                      }),
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {log.changes.tour && (
            <div>
              <div className="text-lg font-medium">Data</div>
              <div className="grid grid-cols-2 rounded border border-gray-200 p-3">
                <div>
                  <div className="font-medium">Originale</div>
                  <div className="font-medium text-slate-700">
                    {capitalize(log.changes.tour.old)}
                  </div>
                </div>
                <div>
                  <div className="font-medium">Nuovo</div>
                  <div className="font-medium text-blue-700">
                    {capitalize(log.changes.tour.new)}
                  </div>
                </div>
              </div>
            </div>
          )}
          {log.changes.port && (
            <div>
              <div className="text-lg font-medium">Data</div>
              <div className="grid grid-cols-2 rounded border border-gray-200 p-3">
                <div>
                  <div className="font-medium">Originale</div>
                  <div className="font-medium text-slate-700">
                    {capitalize(log.changes.port.old)}
                  </div>
                </div>
                <div>
                  <div className="font-medium">Nuovo</div>
                  <div className="font-medium text-blue-700">
                    {capitalize(log.changes.port.new)}
                  </div>
                </div>
              </div>
            </div>
          )}
          {log.changes.boat && (
            <div>
              <div className="text-lg font-medium">Data</div>
              <div className="grid grid-cols-2 rounded border border-gray-200 p-3">
                <div>
                  <div className="font-medium">Originale</div>
                  <div className="font-medium text-slate-700">
                    {log.changes.boat.old}
                  </div>
                </div>
                <div>
                  <div className="font-medium">Nuovo</div>
                  <div className="font-medium text-blue-700">
                    {log.changes.boat.new}
                  </div>
                </div>
              </div>
            </div>
          )}
          {log.changes.organizer?.firstName && (
            <div>
              <div className="text-lg font-medium">Nome</div>
              <div className="grid grid-cols-2 rounded border border-gray-200 p-3">
                <div>
                  <div className="font-medium">Originale</div>
                  <div className="font-medium text-slate-700">
                    {log.changes.organizer.firstName.old}
                  </div>
                </div>
                <div>
                  <div className="font-medium">Nuovo</div>
                  <div className="font-medium text-blue-700">
                    {log.changes.organizer.firstName.new}
                  </div>
                </div>
              </div>
            </div>
          )}
          {log.changes.organizer?.lastName && (
            <div>
              <div className="text-lg font-medium">Cognome</div>
              <div className="grid grid-cols-2 rounded border border-gray-200 p-3">
                <div>
                  <div className="font-medium">Originale</div>
                  <div className="font-medium text-slate-700">
                    {log.changes.organizer.lastName.old}
                  </div>
                </div>
                <div>
                  <div className="font-medium">Nuovo</div>
                  <div className="font-medium text-blue-700">
                    {log.changes.organizer.lastName.new}
                  </div>
                </div>
              </div>
            </div>
          )}
          {log.changes.organizer?.pob && (
            <div>
              <div className="text-lg font-medium">Luogo di nascita</div>
              <div className="grid grid-cols-2 rounded border border-gray-200 p-3">
                <div>
                  <div className="font-medium">Originale</div>
                  <div className="font-medium text-slate-700">
                    {log.changes.organizer.pob.old}
                  </div>
                </div>
                <div>
                  <div className="font-medium">Nuovo</div>
                  <div className="font-medium text-blue-700">
                    {log.changes.organizer.pob.new}
                  </div>
                </div>
              </div>
            </div>
          )}
          {log.changes.organizer?.dob && (
            <div>
              <div className="text-lg font-medium">Data di nascita</div>
              <div className="grid grid-cols-2 rounded border border-gray-200 p-3">
                <div>
                  <div className="font-medium">Originale</div>
                  <div className="font-medium text-slate-700">
                    {log.changes.organizer.dob.old
                      ? capitalize(
                          format(
                            new Date(log.changes.organizer.dob.old),
                            "dd/MM/yyyy",
                            { locale: it },
                          ),
                        )
                      : "Nessuna"}
                  </div>
                </div>
                <div>
                  <div className="font-medium">Nuovo</div>
                  <div className="font-medium text-blue-700">
                    {capitalize(
                      format(
                        new Date(log.changes.organizer.dob.new),
                        "dd/MM/yyyy",
                        { locale: it },
                      ),
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {log.changes.organizer?.identification?.type && (
            <div>
              <div className="text-lg font-medium">Tipo di documento</div>
              <div className="grid grid-cols-2 rounded border border-gray-200 p-3">
                <div>
                  <div className="font-medium">Originale</div>
                  <div className="font-medium text-slate-700">
                    {
                      DOCUMENT_TYPES[
                        log.changes.organizer.identification.type.old
                      ]
                    }
                  </div>
                </div>
                <div>
                  <div className="font-medium">Nuovo</div>
                  <div className="font-medium text-blue-700">
                    {
                      DOCUMENT_TYPES[
                        log.changes.organizer.identification.type.new
                      ]
                    }
                  </div>
                </div>
              </div>
            </div>
          )}
          {log.changes.organizer?.identification?.number && (
            <div>
              <div className="text-lg font-medium">Numero del documento</div>
              <div className="grid grid-cols-2 rounded border border-gray-200 p-3">
                <div>
                  <div className="font-medium">Originale</div>
                  <div className="font-medium text-slate-700">
                    {log.changes.organizer.identification.number.old}
                  </div>
                </div>
                <div>
                  <div className="font-medium">Nuovo</div>
                  <div className="font-medium text-blue-700">
                    {log.changes.organizer.identification.number.new}
                  </div>
                </div>
              </div>
            </div>
          )}
          {log.changes.organizer?.identification?.expiry_date && (
            <div>
              <div className="text-lg font-medium">Scadenza del documento</div>
              <div className="grid grid-cols-2 rounded border border-gray-200 p-3">
                <div>
                  <div className="font-medium">Originale</div>
                  <div className="font-medium text-slate-700">
                    {log.changes.organizer.identification.expiry_date.old
                      ? capitalize(
                          format(
                            new Date(
                              log.changes.organizer.identification.expiry_date.old,
                            ),
                            "dd/MM/yyyy",
                            { locale: it },
                          ),
                        )
                      : "Nessuna"}
                  </div>
                </div>
                <div>
                  <div className="font-medium">Nuovo</div>
                  <div className="font-medium text-blue-700">
                    {capitalize(
                      format(
                        new Date(
                          log.changes.organizer.identification.expiry_date.new,
                        ),
                        "dd/MM/yyyy",
                        { locale: it },
                      ),
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {log.changes.organizer?.identification?.issuedBy && (
            <div>
              <div className="text-lg font-medium">Rilascio del documento</div>
              <div className="grid grid-cols-2 rounded border border-gray-200 p-3">
                <div>
                  <div className="font-medium">Originale</div>
                  <div className="font-medium text-slate-700">
                    {log.changes.organizer.identification.issuedBy.old}
                  </div>
                </div>
                <div>
                  <div className="font-medium">Nuovo</div>
                  <div className="font-medium text-blue-700">
                    {log.changes.organizer.identification.issuedBy.new}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel className={buttonVariants({ variant: "outline" })}>
            Chiudi
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ViewLogDetailsModal;
