import { format } from "date-fns";
import { it } from "date-fns/locale";
import { Link } from "react-router-dom";
import { capitalize, formatCurrency } from "../../lib/utils";
import Icon from "../ui/Icon";

export const columns = [
  {
    accessorKey: "timestamp",
    header: "Creato il",
    cell: ({ row }) => {
      const voucher = row.original;
      return capitalize(
        format(new Date(voucher.timestamp), "EEE dd MMM HH:ss", { locale: it }),
      );
    },
  },
  {
    accessorKey: "code",
    header: "Codice",
    cell: ({ row }) => {
      const voucher = row.original;
      return voucher.code;
    },
  },
  {
    accessorKey: "used",
    header: "Usato",
    cell: ({ row }) => {
      const voucher = row.original;
      return voucher.used ? "Si" : "No";
    },
  },
  {
    accessorKey: "active",
    header: "Stato",
    cell: ({ row }) => {
      const voucher = row.original;
      return voucher.active ? "Attivo" : "Inattivo";
    },
  },
  {
    accessorKey: "amount",
    header: "Totale",
    cell: ({ row }) => {
      const voucher = row.original;
      return formatCurrency.format(voucher.amount);
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const voucher = row.original;

      return (
        <div className="flex flex-row items-center justify-end">
          {voucher.booking && (
            <Link
              to={`/bookings/${voucher.booking._id}`}
              className="h-4 w-4 text-gray-400 transition-colors hover:text-blue-400"
            >
              <Icon name="Eye" />
            </Link>
          )}
        </div>
      );
    },
  },
  // TODO: Voucher View link
];
