import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "../../ui/Dialog";
import { Button } from "../../ui/Button";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { organizerDataSchema } from "../../bookings/modals/ModifyOrganizerData";
import { format } from "date-fns";
import { Input, InputMask } from "../../ui/Input";
import { toast } from "../../ui/UseToast";
import DocumentSelect from "../../bookings/ui/DocumentSelect";
import { Loader2 } from "lucide-react";

const ModifyOrganizerData = ({ data, callbackFn }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const { organizer } = data;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(organizerDataSchema),
    defaultValues: {
      organizer: {
        firstName: organizer.firstName || "",
        lastName: organizer.lastName || "",
        dob: organizer.dob ? format(organizer.dob, "dd/MM/yyyy") : "",
        pob: organizer.pob || "",
        contactInfo: {
          email: organizer.contactInfo?.email || "",
          phone: {
            number: organizer.contactInfo?.phone?.number || "",
          },
        },
        identification: {
          type: organizer.identification?.type || "",
          number: organizer.identification?.number || "",
          expiry_date: organizer.identification?.expiry_date
            ? format(organizer.identification.expiry_date, "dd/MM/yyyy")
            : "",
          issuedBy: organizer.identification?.issuedBy || "",
        },
      },
    },
  });

  const onSubmit = (values) => {
    setIsLoading(true);

    if (values.organizer.dob) {
      const newDob = values.organizer.dob;
      const [day, month, year] = newDob.split("/");
      newDob = new Date(year, month - 1, day);
    }

    if (values.organizer.identification?.expiryDate) {
      const newExpiry = values.organizer.identification?.expiryDate;
      const [day, month, year] = newExpiry.split("/");
      newExpiry = new Date(year, month - 1, day);
    }

    axiosPrivate
      .patch(`/rentals/${data._id}`, values)
      .then((response) => {
        callbackFn();
        setOpen(false);
        toast({ description: "✔️ Modifica salvata.", variant: "confirmed" });
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Qualcosa è andato storto...",
          description: "❌ La modifica non è andata a buon fine.",
          variant: "destructive",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="link" className="p-0">
          Modifica
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>
          Modifica dati personali dell&apos;organizzatore
        </DialogTitle>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid gap-3 lg:grid-cols-2">
            <div>
              <div className="text-sm font-medium">Nome</div>
              <Input {...register("organizer.firstName")} />
              <p className="text-xs text-gray-500">
                {errors.organizer?.firstName?.message}
              </p>
            </div>
            <div>
              <div className="text-sm font-medium">Cognome</div>
              <Input {...register("organizer.lastName")} />
              {errors.organizer?.lastName && (
                <p className="text-xs text-gray-500">
                  {errors.organizer?.lastName?.message}
                </p>
              )}
            </div>
            <div>
              <div className="text-sm font-medium">Luogo di nascita</div>
              <Input {...register("organizer.pob")} />
              <p className="text-xs text-gray-500">
                {errors.organizer?.pob?.message}
              </p>
            </div>
            <div>
              <div className="text-sm font-medium">Data di nascita</div>
              <Controller
                control={control}
                name="organizer.dob"
                render={({ field }) => (
                  <InputMask
                    mask="99/99/9999"
                    maskChar="_"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <p className="text-xs text-gray-500">
                {errors.organizer?.dob?.message}
              </p>
            </div>
          </div>
          <div className="grid gap-3 lg:grid-cols-2">
            <div>
              <div className="text-sm font-medium">Email</div>
              <Input {...register("organizer.contactInfo.email")} />
              <p className="text-xs text-gray-500">
                {errors.organizer?.contactInfo?.email?.message}
              </p>
            </div>
            <div>
              <div className="text-sm font-medium">Numero di telefono</div>
              <Input {...register("organizer.contactInfo.phone.number")} />
              <p className="text-xs text-gray-500">
                {errors.organizer?.contactInfo?.phone?.number?.message}
              </p>
            </div>
          </div>
          <h3 className="py-6">Documento</h3>
          <div className="grid gap-3 lg:grid-cols-2">
            <div>
              <div className="text-sm font-medium">Tipo</div>
              <Controller
                control={control}
                name="organizer.identification.type"
                render={({ field }) => (
                  <DocumentSelect
                    value={field.value}
                    onValueChange={field.onChange}
                  />
                )}
              />
              <p className="text-xs text-gray-500">
                {errors.organizer?.identification?.type?.message}
              </p>
            </div>
            <div>
              <div className="text-sm font-medium">Numero</div>
              <Input {...register("organizer.identification.number")} />
              <p className="text-xs text-gray-500">
                {errors.organizer?.identification?.number?.message}
              </p>
            </div>
            <div>
              <div className="text-sm font-medium">Scadenza</div>
              <Controller
                control={control}
                name="organizer.identification.expiry_date"
                render={({ field }) => (
                  <InputMask
                    mask="99/99/9999"
                    maskChar="_"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <p className="text-xs text-gray-500">
                {errors.organizer?.identification?.expiry_date?.message}
              </p>
            </div>
            <div>
              <div className="text-sm font-medium">Rilasciata da</div>
              <Input {...register("organizer.identification.issuedBy")} />
              <p className="text-xs text-gray-500">
                {errors.organizer?.identification?.issuedBy?.message}
              </p>
            </div>
          </div>
        </form>
        <DialogFooter>
          <Button onClick={() => setOpen(false)} variant="outline">
            Annulla
          </Button>
          <Button
            type="submit"
            disabled={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {!isLoading ? (
              "Conferma"
            ) : (
              <Loader2 className="h-4 w-4 animate-spin" />
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ModifyOrganizerData;
