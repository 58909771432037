import React, { useState } from "react";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "../../ui/UseToast";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "../../ui/Dialog";
import { ArrowRight, Pencil } from "lucide-react";
import { DialogDescription, DialogTitle } from "@radix-ui/react-dialog";
import { StatusBadge } from "../../ui/StatusBadge";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/Select";
import { bookingStatuses } from "../../bookings/BookingOperations/ModifyStatus";
import { Button } from "../../ui/Button";

const ModifyStatus = ({ data, callbackFn }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(data.status?.toString());

  const api = useAxiosPrivate();

  function modifyStatus() {
    setIsLoading(true);

    api
      .patch(`/rentals/${data._id}`, {
        $set: { status: parseInt(status) },
      })
      .then((response) => {
        callbackFn();
        toast({
          description: "✔️ Modifica salvata.",
          variant: "confirmed",
        });
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Qualcosa è andato storto...",
          description: "❌ La modifica non è andata a buon fine.",
          variant: "destructive",
        });
      })
      .finally(() => {
        setIsLoading(false);
        setOpen(false);
      });
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <button className="focus-visible:ring-ring ring-offset-background inline-flex h-10 w-full items-center justify-between rounded-md bg-slate-700 px-4 py-2 text-sm font-medium text-white transition-colors hover:bg-slate-800 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">
          <Pencil className="h-4 w-4 text-gray-300" />
          Cambia stato
          <div />
        </button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Modifica stato</DialogTitle>
          <DialogDescription>
            Modifica lo stato di questa prenotazione.
          </DialogDescription>
        </DialogHeader>
        <div className="flex w-full flex-col justify-center space-y-3 rounded border border-gray-200 p-3">
          <div className="grid lg:grid-cols-3">
            <div>
              <div className="text-sm">Stato attuale:</div>
              <div className="text-lg font-medium">
                <StatusBadge status={data.status} />
              </div>
            </div>
            <div className="hidden items-center justify-center lg:flex">
              <ArrowRight className="h-8 w-8 text-gray-200" />
            </div>
            <div>
              <div className="text-sm">Nuovo stato:</div>
              <Select value={status} onValueChange={setStatus}>
                <SelectTrigger>
                  <SelectValue placeholder="Stato" />
                </SelectTrigger>
                <SelectContent>
                  {bookingStatuses.map((item) => (
                    <SelectItem key={item.value} value={item.value.toString()}>
                      {item.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button
            disabled={isLoading}
            onClick={() => setOpen(false)}
            variant="outline"
          >
            Annulla
          </Button>
          <Button disabled={isLoading} onClick={modifyStatus}>
            Conferma
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ModifyStatus;
