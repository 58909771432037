import { RadioGroup, RadioGroupItem } from "@radix-ui/react-radio-group";
import {
  calculateTimezonedDate,
  cn,
  formatCurrency,
} from "../../../../lib/utils";
import useAuth from "../../../../hooks/useAuth";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { toast } from "../../../ui/UseToast";

const BoatsRadioGroup = ({
  value,
  onValueChange,
  className,
  tour,
  date,
  participants,
  exclusive,
  setSelectedBoat,
}) => {
  const [boats, setBoats] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { auth } = useAuth();
  const api = useAxiosPrivate();

  const handleOnValueChange = (value) => {
    const foundItem = boats.find((item) => item._id === value);
    if (foundItem !== undefined) {
      setSelectedBoat(foundItem);
    } else {
      setSelectedBoat();
    }
    onValueChange(value);
  };

  useEffect(() => {
    const fetchData = () => {
      const localDate = calculateTimezonedDate(date);

      setIsLoading(true);
      api
        .get(`/boats/${tour}/boats-availability/?displayOverbooking=true`, {
          params: {
            date: localDate,
            participants: participants.adults + participants.kids,
            exclusive,
          },
        })
        .then((response) => {
          setBoats(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          toast({
            title: "Errore durante il caricamento delle barche",
          });
        })
        .finally(() => setIsLoading(false));
    };

    if (date) {
      fetchData();
    } else {
      setBoats();
    }
  }, [tour, date, participants, exclusive]);

  const BoatsRadioGroupSkeleton = () => {
    return (
      <div className="flex w-full animate-pulse items-center justify-between rounded border px-3 py-2 text-start">
        <div className="space-y-3">
          <div className="h-6 w-48 rounded bg-gray-300"></div>
          <div className="h-4 w-32 rounded bg-gray-300"></div>
        </div>
        <div className="h-6 w-16 rounded bg-gray-300"></div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="col-span-2 grid-cols-2 gap-6 md:grid">
        {Array.from({ length: 6 }, (v, i) => (
          <BoatsRadioGroupSkeleton key={i} />
        ))}
      </div>
    );
  }

  if (boats !== undefined) {
    if (boats.length === 0) {
      return (
        <div className="w-full py-16 text-center text-gray-500">
          Nessuna imbarcazione disponbiile.
        </div>
      );
    }

    return (
      <RadioGroup
        value={value}
        onValueChange={handleOnValueChange}
        className={cn("grid grid-cols-2 gap-6", className)}
      >
        {boats.map((boat) => (
          <RadioGroupItem
            value={boat._id}
            disabled={auth?.roles.includes(2000) && !boat.available}
            key={boat._id}
            className={cn(
              "flex items-center justify-between rounded border px-3 py-2 text-start ring-blue-600 data-[state=checked]:ring",
              !boat.available && "border border-red-600",
            )}
          >
            <div className="space-y-1">
              <div className="text-lg font-medium">{boat.name}</div>
              <p className={cn(!boat.available && "text-red-600")}>
                Posti rimasti: {boat.freeSeats}
              </p>
            </div>
            <div>
              <div
                className={cn(
                  "text-right text-xl",
                  !boat.available && "text-gray-500",
                )}
              >
                {exclusive
                  ? formatCurrency.format(
                      boat.customPrices.exclusive.full.amount,
                    )
                  : formatCurrency.format(boat.customPrices.adults.full.amount)}
              </div>
              <span className="text-end text-xs">
                {exclusive ? "Prezzo base" : "A persona"}
              </span>
            </div>
          </RadioGroupItem>
        ))}
      </RadioGroup>
    );
  }
};

export { BoatsRadioGroup };
