import { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/Select";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { toast } from "./ui/UseToast";

const TourOperatorSelect = ({ value, onValueChange }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const api = useAxiosPrivate();

  const fetchData = () => {
    setIsLoading(true);
    api
      .get("/touroperators")
      .then((res) => setData(res.data))
      .catch((err) => {
        console.error(err);
        toast({ title: "Errore durante il caricamento dei TO" });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Select disabled={isLoading} value={value} onValueChange={onValueChange}>
      <SelectTrigger>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectItem>Nessuno</SelectItem>
        {data.map((item) => (
          <SelectItem key={item._id} value={item._id}>
            {item.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default TourOperatorSelect;
