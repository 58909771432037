import { HashIcon } from "lucide-react";

const ReferenceBadge = ({ value }) => {
  return (
    <button
      value={value}
      onClick={(e) => navigator.clipboard.writeText(e.target.value)}
      className="inline-flex cursor-pointer items-center rounded bg-slate-100 px-1 text-xs font-semibold leading-5 text-slate-800 transition hover:bg-slate-700 hover:text-white"
    >
      <HashIcon className="h-3 w-3" />
      {value}
    </button>
  );
};

export default ReferenceBadge;
