import { format } from "date-fns";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { cn } from "../../lib/utils";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../ui/AlertDialog";
import { buttonVariants } from "../ui/Button";
import Checkbox from "../ui/Checkbox";
import Icon from "../ui/Icon";
import { toast } from "../ui/UseToast";
import ModifyBoarding from "./BookingOperations/ModifyBoarding";
import ModifyStatus from "./BookingOperations/ModifyStatus";
import ResendEmail from "./BookingOperations/ResendEmail";
import ModifyDateAndTour from "./BookingOperations/ModifyDateAndTour";
import { ROLES } from "../../config/roles";
import DeleteBooking from "./BookingOperations/DeleteBooking";

const BookingsOperations = ({ booking, refreshBooking }) => {
  const { auth } = useAuth();

  const [showManualConfirmAlert, setShowManualConfirmAlert] = useState(false);
  const [showDeletionAlert, setShowDeletionAlert] = useState(false);
  const [showTerminationAlert, setShowTerminationAlert] = useState(false);
  const [showCheckInModal, setShowCheckInModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const handleCheckedInChange = (e) => {
    console.log(e);
  };

  function manualConfirmBooking() {
    setIsLoading(true);

    axiosPrivate
      .patch(`/bookings/${booking._id}`, { status: 200 })
      .then(() => {
        refreshBooking();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function terminateBooking() {
    setIsLoading(true);

    axiosPrivate
      .post("/public/cancel-checkout-session", {
        stripeSessionId: booking.stripeSessionId,
      })
      .then(() => {
        refreshBooking();
        toast({
          description: "✔️ Modifica salvata.",
          variant: "confirmed",
        });
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Qualcosa è andato storto...",
          description: "❌ La modifica non è andata a buon fine.",
          variant: "destructive",
        });
      })
      .finally(() => {
        setIsLoading(false);
        navigate("/bookings");
      });
  }

  function deleteBooking() {
    setIsLoading(true);

    axiosPrivate
      .patch(`/bookings/${booking._id}`, { status: 0 })
      .then((response) => {
        refreshBooking();
        toast({
          description: "✔️ Modifica salvata.",
          variant: "confirmed",
        });
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Qualcosa è andato storto...",
          description: "❌ La modifica non è andata a buon fine.",
          variant: "destructive",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-3">
        {(auth.roles?.includes(7777) ||
          auth.roles?.includes(5000) ||
          auth.roles?.includes(2500)) && (
          <ModifyStatus booking={booking} callbackFn={refreshBooking} />
        )}

        {(auth.roles?.includes(7777) ||
          auth.roles?.includes(5000) ||
          auth.roles?.includes(2500)) &&
          (booking.status > 100 || auth?.roles.includes(7777)) && (
            <ModifyDateAndTour booking={booking} callbackFn={refreshBooking} />
          )}

        {(auth.roles?.includes(7777) ||
          ((auth.roles?.includes(5000) || auth.roles?.includes(2500)) &&
            booking.status !== 0)) && (
          <ModifyBoarding booking={booking} callbackFn={refreshBooking} />
        )}

        {(auth.roles?.includes(7777) ||
          ((auth.roles?.includes(5000) ||
            auth.roles?.includes(2500) ||
            auth.roles?.includes(2000)) &&
            booking.status > 100)) && (
          <>
            <ResendEmail booking={booking} />
          </>
        )}

        {auth.roles?.includes(7777) &&
          booking.status === 100 &&
          booking.stripeSessionId && (
            <>
              <button
                onClick={() => setShowTerminationAlert(true)}
                className={cn(buttonVariants({ variant: "destructive" }))}
              >
                Cancella checkout stripe
              </button>
            </>
          )}

        {[ROLES.Tech, ROLES.Admin, ROLES.Editor].some((role) =>
          auth?.roles.includes(role),
        ) &&
          booking.status !== 0 && (
            <DeleteBooking booking={booking} callbackFn={refreshBooking} />
          )}

        {[ROLES.Tech, ROLES.Admin, ROLES.Editor, ROLES.TourOperator].some(
          (role) => auth?.roles.includes(role),
        ) &&
          ![0, 100].includes(booking.status) && (
            <button
              onClick={() => setShowDeletionAlert(true)}
              className={cn(buttonVariants({ variant: "destructive" }))}
            >
              Cancella prenotazione
            </button>
          )}
      </div>
      {/* Manual confirmation */}
      <AlertDialog
        open={showManualConfirmAlert}
        onOpenChange={setShowManualConfirmAlert}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Sei sicuro di voler approvare manualmente questa prenotazione?
            </AlertDialogTitle>
            <AlertDialogDescription>
              Il cliente non dovrà saldarla elettronicamente.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              className={buttonVariants({ variant: "outline" })}
            >
              Annulla
            </AlertDialogCancel>
            <AlertDialogAction
              className={buttonVariants()}
              onClick={manualConfirmBooking}
            >
              Conferma
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {/* Deletion */}
      <AlertDialog open={showDeletionAlert} onOpenChange={setShowDeletionAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Sei sicuro di voler cancellare manualmente questa prenotazione?
            </AlertDialogTitle>
            <AlertDialogDescription>
              Assicurarsi che non ci siano pagamenti in corso.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              className={buttonVariants({ variant: "outline" })}
            >
              Annulla
            </AlertDialogCancel>
            <AlertDialogAction
              className={buttonVariants({ variant: "destructive" })}
              onClick={deleteBooking}
            >
              Conferma
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {/* Termination */}
      <AlertDialog
        open={showTerminationAlert}
        onOpenChange={setShowTerminationAlert}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Sei sicuro di voler cancellare manualmente questa prenotazione?
            </AlertDialogTitle>
            <AlertDialogDescription>
              Il cliente non dovrà saldarla elettronicamente. Assicurarsi che il
              pagamento non sia in corso.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              className={buttonVariants({ variant: "outline" })}
            >
              Annulla
            </AlertDialogCancel>
            <AlertDialogAction
              className={buttonVariants({ variant: "destructive" })}
              onClick={terminateBooking}
            >
              Conferma
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {/* TODO: Checkin Modal */}
      <AlertDialog open={showCheckInModal} onOpenChange={setShowCheckInModal}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Check-in</AlertDialogTitle>
            <AlertDialogDescription>
              Effettua il check-in.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
            <li
              key={booking.organizer}
              className="flex items-center justify-between p-3 text-sm"
            >
              <div className="flex w-0 flex-1 items-center">
                <Icon
                  name="Flag"
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-2 w-0 flex-1 truncate font-medium">
                  {booking.organizer.firstName} {booking.organizer.lastName}
                </span>
              </div>
              <input type="checkbox" disabled checked />
            </li>
            {booking.participants.list.map((participant) => {
              return (
                <li
                  key={participant._id}
                  className="flex items-center justify-between p-3 text-sm"
                >
                  <div className="flex items-center gap-2">
                    <Icon
                      name="User"
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span>
                      {participant.firstName} {participant.lastName}
                    </span>
                  </div>
                  <Checkbox
                    value={participant._id}
                    checked={participant.checkedIn}
                    onCheckedChange={handleCheckedInChange}
                  />
                </li>
              );
            })}
          </ul>
          <AlertDialogFooter>
            <AlertDialogCancel
              className={buttonVariants({ variant: "outline" })}
            >
              Annulla
            </AlertDialogCancel>
            <AlertDialogAction className={buttonVariants()}>
              Conferma
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default BookingsOperations;
