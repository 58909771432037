import { format, isFuture, isPast, isValid, parse } from "date-fns";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTrigger,
} from "../../ui/Dialog";
import Icon from "../../ui/Icon";
import { toast } from "../../ui/UseToast";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input, InputMask } from "../../ui/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/Select";
import { Button } from "../../ui/Button";
import { DialogTitle } from "@radix-ui/react-dialog";

const emailSchema = z
  .string()
  .optional()
  .refine(
    (email) => {
      if (email === "" || email === undefined) {
        return true; // Allow empty string or undefined
      }
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email); // Validate email format
    },
    {
      message: "Email non valida",
    },
  );

const parseDateString = (dateString) => {
  const parsedDate = parse(dateString, "dd/MM/yyyy", new Date());
  return isValid(parsedDate) ? parsedDate : undefined;
};

const preprocessStringDate = z.string().refine(
  (val) => {
    if (val === "" || val === undefined) return true;
    const parsedDate = parseDateString(val);
    return !!parsedDate && isValid(parsedDate);
  },
  {
    message: "Formato data non valido, usa gg/mm/aaaa",
  },
);

export const organizerDataSchema = z.object({
  organizer: z.object({
    firstName: z.string().min(1, { message: "Nome è obbligatorio" }),
    lastName: z.string().min(1, { message: "Cognome è obbligatorio" }),
    dob: preprocessStringDate.optional().refine(
      (val) => {
        if (val === "") return true; // Allow empty string for optional field
        const parsedDate = parseDateString(val);
        return !!parsedDate && isPast(parsedDate);
      },
      {
        message: "La data di nascita deve essere valida",
      },
    ),
    pob: z
      .string()
      .max(32, { message: "Luogo di nascita non può superare i 32 caratteri" })
      .optional(),
    contactInfo: z
      .object({
        email: emailSchema,
        phone: z
          .object({
            number: z.string().optional(),
          })
          .optional(),
      })
      .optional(),
    identification: z.object({
      type: z
        .enum(
          [
            "government_id",
            "passport",
            "drivers_license",
            "nautical_license",
            "",
          ],
          {
            errorMap: () => ({ message: "Tipo di documento non valido" }),
          },
        )
        .optional(),
      number: z.string().optional(),
      expiry_date: preprocessStringDate
        .refine(
          (val) => {
            if (val === "" || val === undefined) return true; // Allow empty string for optional field
            const parsedDate = parseDateString(val);
            return !!parsedDate && isFuture(parsedDate);
          },
          {
            message: "La data di scadenza deve essere nel futuro",
          },
        )
        .optional(),
      issuedBy: z
        .string()
        .max(32, { message: "Rilasciato da non può superare i 32 caratteri" })
        .optional(),
    }),
  }),
});

const ModifyOrganizerDataModal = ({ booking, refreshBooking }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const organizerData = booking.organizer;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(organizerDataSchema),
    defaultValues: {
      organizer: {
        firstName: organizerData.firstName || "",
        lastName: organizerData.lastName || "",
        dob: organizerData.dob ? format(organizerData.dob, "dd/MM/yyyy") : "",
        pob: organizerData.pob || "",
        contactInfo: {
          email: organizerData.contactInfo?.email || "",
          phone: {
            number: organizerData.contactInfo?.phone?.number || "",
          },
        },
        identification: {
          type: organizerData.identification?.type || "government_id",
          number: organizerData.identification?.number || "",
          expiry_date: organizerData.identification?.expiry_date
            ? format(organizerData.identification.expiry_date, "dd/MM/yyyy")
            : "",
          issuedBy: organizerData.identification?.issuedBy || "",
        },
      },
    },
  });

  const onSubmit = (data) => {
    setIsLoading(true);

    if (data.organizer.dob) {
      let newDob = data.organizer.dob;
      const [day, month, year] = newDob.split("/");
      newDob = new Date(year, month - 1, day);
      data.organizer.dob = newDob;
    }

    if (data.organizer.identification?.expiryDate) {
      let newExpiry = data.organizer.identification?.expiryDate;
      const [day, month, year] = newExpiry.split("/");
      newExpiry = new Date(year, month - 1, day);
      data.organizer.identification.expiryDate = newExpiry;
    }

    axiosPrivate
      .patch(`/bookings/${booking._id}`, data)
      .then((response) => {
        refreshBooking();
        setOpen(false);
        toast({ description: "✔️ Modifica salvata.", variant: "confirmed" });
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Qualcosa è andato storto...",
          description: "❌ La modifica non è andata a buon fine.",
          variant: "destructive",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="link" className="px-0">
          Modifica
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>
          Modifica dati personali dell&apos;organizzatore
        </DialogTitle>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid gap-3 lg:grid-cols-2">
            <div>
              <div className="text-sm font-medium">Nome</div>
              <Input {...register("organizer.firstName")} />
              <p className="text-xs text-gray-500">
                {errors.organizer?.firstName?.message}
              </p>
            </div>
            <div>
              <div className="text-sm font-medium">Cognome</div>
              <Input {...register("organizer.lastName")} />
              {errors.organizer?.lastName && (
                <p className="text-xs text-gray-500">
                  {errors.organizer?.lastName?.message}
                </p>
              )}
            </div>
            <div>
              <div className="text-sm font-medium">Luogo di nascita</div>
              <Input {...register("organizer.pob")} />
              <p className="text-xs text-gray-500">
                {errors.organizer?.pob?.message}
              </p>
            </div>
            <div>
              <div className="text-sm font-medium">Data di nascita</div>
              <Controller
                control={control}
                name="organizer.dob"
                render={({ field }) => (
                  <InputMask
                    mask="99/99/9999"
                    maskChar="_"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <p className="text-xs text-gray-500">
                {errors.organizer?.dob?.message}
              </p>
            </div>
          </div>
          <div className="grid gap-3 lg:grid-cols-2">
            <div>
              <div className="text-sm font-medium">Email</div>
              <Input {...register("organizer.contactInfo.email")} />
              <p className="text-xs text-gray-500">
                {errors.organizer?.contactInfo?.email?.message}
              </p>
            </div>
            <div>
              <div className="text-sm font-medium">Numero di telefono</div>
              <Input {...register("organizer.contactInfo.phone.number")} />
              <p className="text-xs text-gray-500">
                {errors.organizer?.contactInfo?.phone?.number?.message}
              </p>
            </div>
          </div>
          <h3 className="py-6">Documento</h3>
          <div className="grid gap-3 lg:grid-cols-2">
            <div>
              <div className="text-sm font-medium">Tipo</div>
              <Controller
                control={control}
                name="organizer.identification.type"
                render={({ field }) => (
                  <Select value={field.value} onValueChange={field.onChange}>
                    <SelectTrigger>
                      <SelectValue placeholder="Seleziona il tipo di documento" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="government_id">
                        Carta di Identità
                      </SelectItem>
                      <SelectItem value="drivers_license">
                        Patente di guida
                      </SelectItem>
                      <SelectItem value="passport">Passaporto</SelectItem>
                    </SelectContent>
                  </Select>
                )}
              />
              <p className="text-xs text-gray-500">
                {errors.organizer?.identification?.type?.message}
              </p>
            </div>
            <div>
              <div className="text-sm font-medium">Numero</div>
              <Input {...register("organizer.identification.number")} />
              <p className="text-xs text-gray-500">
                {errors.organizer?.identification?.number?.message}
              </p>
            </div>
            <div>
              <div className="text-sm font-medium">Scadenza</div>
              <Controller
                control={control}
                name="organizer.identification.expiry_date"
                render={({ field }) => (
                  <InputMask
                    mask="99/99/9999"
                    maskChar="_"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <p className="text-xs text-gray-500">
                {errors.organizer?.identification?.expiry_date?.message}
              </p>
            </div>
            <div>
              <div className="text-sm font-medium">Rilasciata da</div>
              <Input {...register("organizer.identification.issuedBy")} />
              <p className="text-xs text-gray-500">
                {errors.organizer?.identification?.issuedBy?.message}
              </p>
            </div>
          </div>
        </form>
        <DialogFooter>
          <Button onClick={() => setOpen(false)} variant="outline">
            Annulla
          </Button>
          <Button
            type="submit"
            disabled={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {!isLoading ? (
              "Conferma"
            ) : (
              <Icon name="Loader" className="h-4 w-4 animate-spin" />
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ModifyOrganizerDataModal;
