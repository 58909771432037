import React, { useState } from "react";
import Icon from "./Icon";
import MobileNav from "./MobileNav";
import MobileUserBlock from "./MobileUserBlock";

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <>
      <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 flex-row items-center justify-between border-b border-gray-200 bg-white px-4 md:px-6 lg:hidden">
        <div>
          <button
            onClick={() => setShowMobileMenu(!showMobileMenu)}
            className="items-center justify-center rounded border border-gray-200 p-2"
          >
            <Icon name={showMobileMenu ? "X" : "Menu"} className="h-6 w-6" />
          </button>
        </div>
        <MobileUserBlock />
      </div>
      {showMobileMenu && <MobileNav toggleMobileMenu={toggleMobileMenu} />}
    </>
  );
};

export default Header;
