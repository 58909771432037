import { Controller, useFormContext } from "react-hook-form";
import { cn, formatCurrency } from "../../../../lib/utils";
import { Button } from "../../../ui/Button";
import CurrencyInput from "react-currency-input-field";
import { Label } from "../../../ui/Label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/Select";
import { DOCUMENT_TYPES } from "../../../../config/aliases";
import { Input } from "../../../ui/Input";
import { format } from "date-fns";
import { it } from "date-fns/locale";

const OrganizerIdentificationTypeSelect = ({ value, onValueChange }) => {
  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger>
        <SelectValue placeholder="Tipo documento" />
      </SelectTrigger>
      <SelectContent>
        {Object.keys(DOCUMENT_TYPES).map((item) => (
          <SelectItem key={item} value={item}>
            {DOCUMENT_TYPES[item]}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export const OrganizerInfo = () => {
  const form = useFormContext();
  const errors = form.formState.errors;

  return (
    <div className="grid gap-3 lg:grid-cols-2">
      <div className="space-y-1">
        <Label>Nome *</Label>
        <Controller
          name="organizer.firstName"
          control={form.control}
          rules={{ required: true }}
          render={({ field }) => (
            <Input
              {...field}
              className={cn(
                errors.organizer?.firstName !== undefined && "border-red-600",
              )}
            />
          )}
        />
      </div>
      <div className="space-y-1">
        <Label>Cognome *</Label>
        <Controller
          name="organizer.lastName"
          rules={{ required: true }}
          control={form.control}
          render={({ field }) => (
            <Input
              {...field}
              className={cn(
                errors.organizer?.lastName !== undefined && "border-red-600",
              )}
            />
          )}
        />
      </div>
      <div className="space-y-1">
        <Label>Email</Label>
        <Controller
          name="organizer.contactInfo.email"
          control={form.control}
          render={({ field }) => <Input {...field} />}
        />
      </div>
      <div className="space-y-1">
        <Label>Telefono *</Label>
        <Controller
          name="organizer.contactInfo.phone.number"
          rules={{ required: true }}
          control={form.control}
          render={({ field }) => (
            <Input
              {...field}
              className={cn(
                errors.organizer?.contactInfo?.phone?.number !== undefined &&
                  "border-red-600",
              )}
            />
          )}
        />
      </div>
      <div className="space-y-1">
        <Label>Documento</Label>
        <Controller
          name="organizer.identification.type"
          control={form.control}
          render={({ field }) => (
            <OrganizerIdentificationTypeSelect {...field} />
          )}
        />
      </div>
      <div className="space-y-1">
        <Label className="truncate">Numero del documento</Label>
        <Controller
          name="organizer.identification.number"
          control={form.control}
          render={({ field }) => <Input {...field} />}
        />
      </div>
    </div>
  );
};

const Overview = ({ isLoading, selectedBoat }) => {
  const form = useFormContext();
  const exclusive = form.watch("exclusive");
  const participants = form.watch("participants");

  const preAmount = exclusive
    ? selectedBoat.customPrices.exclusive.pre.amount
    : selectedBoat.customPrices.adults.pre.amount * participants.adults +
      selectedBoat.customPrices.kids.pre.amount * participants.kids;

  const fullAmount = exclusive
    ? selectedBoat.customPrices.exclusive.full.amount
    : selectedBoat.customPrices.adults.full.amount * participants.adults +
      selectedBoat.customPrices.kids.full.amount * participants.kids;

  const customTotalAmount = form.watch("paymentInfo.totalAmount");

  const suggestedDownpayment =
    Math.round(((40 / 100) * customTotalAmount) / 10) * 10;

  return (
    <div className="flex h-full flex-1 flex-col space-y-3">
      <OrganizerInfo />
      <div>
        <p className="text-lg font-semibold">Escursione a Isole Tremiti</p>
        <p>
          {format(form.getValues("date"), "EEEE dd MMM, yyyy", { locale: it })}
        </p>
        <p>
          Partenza da{" "}
          {selectedBoat.port[0].toUpperCase() + selectedBoat.port.slice(1)}
        </p>
        <p>{participants.adults} Adulti</p>
        {participants.kids > 0 && <p>{participants.kids} Ragazzi</p>}
      </div>
      {selectedBoat && (
        <div>
          <p className="text-lg font-semibold">Imbarcazione</p>
          <p>{selectedBoat.name}</p>
        </div>
      )}
      <div>
        <p className="text-lg font-semibold">Uso esclusivo</p>
        <p>{form.getValues("exclusive") ? "Sí" : "No"}</p>
      </div>
      <div>
        <p className="text-lg font-semibold">Dettaglio prezzo</p>
        {form.getValues("exclusive") ? (
          <div className="flex items-center justify-between">
            <p>Noleggio ad uso esclusivo</p>
            <div className="text-right">
              <span>
                {formatCurrency.format(
                  selectedBoat.customPrices.exclusive.full.amount,
                )}
              </span>
            </div>
          </div>
        ) : (
          <>
            <div className="flex items-center justify-between">
              <p>
                <span>Adulti:</span> {form.getValues("participants.adults")}
              </p>
              <div className="text-right">
                <span>
                  {formatCurrency.format(
                    selectedBoat.customPrices.adults.full.amount,
                  )}
                </span>
                <p className="text-xs">a persona</p>
              </div>
            </div>
            {form.getValues("participants.kids") > 0 && (
              <div className="flex items-center justify-between">
                <p>
                  <span>Ragazzi:</span> {form.getValues("participants.kids")}
                </p>
                <div className="text-right">
                  <span>
                    {formatCurrency.format(
                      selectedBoat.customPrices.kids.full.amount,
                    )}
                  </span>
                  <p className="text-xs">a persona</p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <hr />
      <div>
        <p className="text-lg font-semibold">Pagamento</p>
        <div
          className={cn(
            "flex items-center justify-between",
            customTotalAmount !== undefined &&
              customTotalAmount !== "" &&
              parseFloat(customTotalAmount) !== fullAmount &&
              "text-gray-500",
          )}
        >
          <p>
            <span>Acconto:</span>
          </p>
          <p className="text-right">{formatCurrency.format(preAmount)}</p>
        </div>
        <div
          className={cn(
            "flex items-center justify-between",
            customTotalAmount !== undefined &&
              customTotalAmount !== "" &&
              parseFloat(customTotalAmount) !== fullAmount &&
              "text-gray-500",
          )}
        >
          <p>
            <span>Totale:</span>
          </p>
          <p className="text-right">{formatCurrency.format(fullAmount)}</p>
        </div>
        <div className="flex items-center justify-between">
          <p>
            <span
              className={cn(
                "mt-2",
                customTotalAmount === undefined && "text-gray-500",
                customTotalAmount === "" && "text-gray-500",
                parseFloat(customTotalAmount) === fullAmount && "text-gray-500",
              )}
            >
              Modifica prezzo
            </span>
          </p>
          <p className="text-right">
            <Controller
              name="paymentInfo.totalAmount"
              control={form.control}
              render={({ field }) => (
                <CurrencyInput
                  className="border text-right"
                  value={field.value}
                  onValueChange={field.onChange}
                  intlConfig={{ locale: "it-IT", currency: "EUR" }}
                />
              )}
            />
          </p>
        </div>
        {customTotalAmount !== undefined && customTotalAmount !== "" && (
          <div className="flex items-center justify-between">
            <p>Acconto suggerito</p>
            <p className="text-right">
              {formatCurrency.format(suggestedDownpayment)}
            </p>
          </div>
        )}
      </div>
      <div className="flex-1" />
      <div className="w-full space-y-3">
        <Button type="submit" disabled={isLoading} className="w-full">
          Conferma e crea
        </Button>
      </div>
    </div>
  );
};

export { Overview };
