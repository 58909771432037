import React from "react";

const Loading = ({ title }) => {
  return (
    <div className="rounded py-32 text-center font-semibold text-gray-400">
      {title || "Caricamento..."}
    </div>
  );
};

export default Loading;
