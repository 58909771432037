import clsx from "clsx";
import {
  differenceInDays,
  differenceInYears,
  eachDayOfInterval,
  format,
  isToday,
  isYesterday,
} from "date-fns";
import { twMerge } from "tailwind-merge";
import {
  getYear,
  set,
  isWithinInterval,
  eachWeekendOfMonth,
  lastDayOfMonth,
} from "date-fns";

export function calculateTimezonedDate(date) {
  const tzoffset = date.getTimezoneOffset() * 60000; // Get offset from the date, not current time
  const result = new Date(date.getTime() - tzoffset);
  return result;
}

const getFirstSaturdayOfAugust = (year) => {
  const firstDayOfAugust = set(new Date(), { year, month: 7, date: 1 }); // August is month 7 (0-indexed)
  const weekends = eachWeekendOfMonth(firstDayOfAugust);
  return weekends.find((date) => date.getDay() === 6); // 6 is Saturday
};

const getLastSundayOfAugust = (year) => {
  const lastDayOfAugust = lastDayOfMonth(set(new Date(), { year, month: 7 }));
  const weekends = eachWeekendOfMonth(lastDayOfAugust);
  return weekends.reverse().find((date) => date.getDay() === 0); // 0 is Sunday
};

export const generateWeekDates = (from, to) => {
  console.log(from, to);
  // Generate all dates between start and end
  const dates = eachDayOfInterval({
    start: from,
    end: to,
  });

  return dates;
};

const isFerragosto = (date) => {
  const year = getYear(date);
  return isWithinInterval(date, {
    start: new Date(year, 7, 14),
    end: new Date(year, 7, 16),
  });
};

export const getSeason = (date) => {
  const year = getYear(date);
  if (isFerragosto(date)) {
    return "ferragosto";
  }

  const firstSaturday = getFirstSaturdayOfAugust(year);
  const lastSunday = getLastSundayOfAugust(year);
  const highSeasonStart = firstSaturday;
  const highSeasonEnd = lastSunday;

  if (isWithinInterval(date, { start: highSeasonStart, end: highSeasonEnd })) {
    return "high";
  }

  return "low";
};

export function stringifyWithoutQuotes(obj) {
  return JSON.stringify(obj, (key, value) => {
    if (typeof key === "string") {
      return key; // Return the key as a plain string
    }
    return value;
  });
}

export const formatCurrency = new Intl.NumberFormat("it-IT", {
  style: "currency",
  currency: "EUR",
});

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function arraysAreEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Sort the arrays
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    const obj1 = sortedArr1[i];
    const obj2 = sortedArr2[i];

    if (JSON.stringify(obj1) !== JSON.stringify(obj2)) {
      return false;
    }
  }

  return true;
}

export function generateRandomString(length) {
  const characters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
}

export const formatDate = (date) => {
  if (isToday(date)) {
    return "Oggi, " + format(date, "HH:mm");
  } else if (isYesterday(date)) {
    return "Ieri, " + format(date, "HH:mm");
  } else {
    return format(date, "dd/MM, HH:mm");
  }
};

export function getInitials(data) {
  const result = data.split(" ");
  if (result.length > 1) {
    return `${result[0].charAt(0)}${result[1].charAt(0)}`;
  } else {
    return data.charAt(0);
  }
}

export async function calculatePrice(booking, type) {
  try {
    const tour = booking.tour;
    const boat = booking.boat;
    let date = new Date(booking.date).setUTCHours(0, 0, 0, 0);

    const season = getSeason(date);

    if (booking.exclusive) {
      return 12 * tour.boats[boat.type].prices.adults[season][type].amount;
    }

    let result =
      0 +
      booking.participants.kids *
        tour.boats[boat.type].prices.kids[season][type].amount +
      (booking.participants.adults + 1) *
        tour.boats[boat.type].prices.adults[season][type].amount;

    return result;
  } catch (err) {
    return 0;
  }
}

export const calculateAge = (birthDate) => {
  const currentDate = new Date();

  const age = differenceInYears(currentDate, new Date(birthDate));
  return age;
};

export function capitalize(str) {
  return str.replace(/\b\w/g, (match) => match.toUpperCase());
}
