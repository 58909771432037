import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import UserView from "./UserView";
import { toast } from "../ui/UseToast";

const UserPage = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState();
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const getUser = () => {
      axiosPrivate
        .get(`/users/${id}`)
        .then((response) => {
          setUser(response.data);
        })
        .catch((err) => {
          console.error(err);
          toast({
            title: "Problema con il caricamento dei dati dal server. Riprovare",
            variant: "destructive",
          });
        });
    };
    if (id) {
      getUser();
    }
  }, [id]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!id) {
    return <UserView create />;
  }

  if (user) {
    return <UserView user={user} create={!id} />;
  }
};

export default UserPage;
