import { format } from "date-fns";
import { it } from "date-fns/locale";
import { Link } from "react-router-dom";
import { capitalize, formatCurrency } from "../../lib/utils";
import Icon from "../ui/Icon";

export const columns = [
  {
    accessorKey: "timestamp",
    header: "Aggiunta il",
    cell: ({ row }) => {
      const expense = row.original;
      return capitalize(
        format(new Date(expense.timestamp), "EEE dd MMM HH:ss", { locale: it }),
      );
    },
  },
  {
    accessorKey: "date",
    header: "Data",
    cell: ({ row }) => {
      const expense = row.original;
      return capitalize(
        format(new Date(expense.date), "EEE dd MMM", { locale: it }),
      );
    },
  },
  {
    accessorKey: "type",
    header: "Tipo",
    cell: ({ row }) => {
      const expense = row.original;

      if (expense.type === "related") {
        return `Carburante - ${expense.boat.name}`;
      } else {
        if (expense.title) {
          return `Varie - ${expense.title}`;
        } else {
          return `Varie`;
        }
      }
    },
  },
  {
    accessorKey: "submittedBy",
    header: "Aggiunto da",
    cell: ({ row }) => {
      const expense = row.original;
      return expense.submittedBy.displayName.firstName;
    },
  },
  {
    accessorKey: "amount",
    header: "Totale",
    cell: ({ row }) => {
      const expense = row.original;
      return formatCurrency.format(expense.amount);
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const expense = row.original;

      return (
        <div className="flex flex-row items-center justify-end">
          <Link
            to={`/expenses/${expense._id}`}
            className="rounded border border-gray-300 p-2 text-black text-gray-700 hover:bg-gray-100"
          >
            <Icon name="ChevronRight" className="h-4 w-4" />
          </Link>
        </div>
      );
    },
  },
];
