const Unauthorized = () => {
  return (
    <section className="grid min-h-full w-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-indigo-600">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Pagina non trovata
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          Mi dispiace, non abbiamo trovato la pagina che stai cercando.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a href="/" className="text-sm font-semibold text-gray-900">
            Torna alla home
          </a>
        </div>
      </div>
    </section>
  );
};

export default Unauthorized;
