import classNames from "classnames";
import { format } from "date-fns";
import React, { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { formatCurrency, getInitials } from "../../lib/utils";
import { toast } from "../ui/UseToast";
import Icon from "./Icon";
import ViewLogDetailsModal from "./modals/ViewLogDetailsModal";

const EventsFeed = ({ entityId, events, entity, refreshFn }) => {
  return (
    <div className="max-h-[400px] overflow-y-scroll border border-gray-200 bg-white p-3">
      <ul className="-mb-8">
        {events.map((event, eventIdx) => (
          <li key={eventIdx}>
            <div className="relative pb-8">
              {eventIdx !== events.length - 1 ? (
                <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" />
              ) : null}
              <EventsFeedElement
                entityId={entityId}
                element={event}
                entity={entity}
                refreshFn={refreshFn}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const EventsFeedElement = ({ entityId, element, entity, refreshFn }) => {
  const axiosPrivate = useAxiosPrivate();
  const [isHovered, setIsHovered] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showViewLogDetailsModal, setShowViewLogDetailsModal] = useState(false);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const deleteNote = async () => {
    try {
      const updateBooking = await axiosPrivate.delete(
        `/${entity}s/${entityId}/notes/${element.object._id}`,
      );
      refreshFn();
      return toast({
        description: "Nota eliminata.",
        variant: "confirmed",
      });
    } catch (err) {
      console.log(err);
      return toast({
        title: "Qualcosa è andato storto...",
        description: "❌ Non è stato possibile eliminare la nota.",
        variant: "destructive",
      });
    }
  };

  const handleDeletion = () => {
    if (isDeleting) {
      deleteNote();
    } else {
      setIsDeleting(true);
    }
  };

  if (element.type === "log") {
    let icon = "";
    if (element.object.action === "modify") {
      icon = "Edit3";
    }
    if (element.object.action === "create") {
      icon = "PlusCircle";
    }
    if (element.object.action === "notify") {
      icon = "Mail";
    }
    return (
      <>
        <div className="relative flex space-x-3">
          <div>
            <button
              disabled={
                element.object.action !== "modify" || !element.object.changes
              }
              onClick={() => setShowViewLogDetailsModal(true)}
              className={classNames(
                element.object.action === "modify" && "bg-blue-500",
                element.object.action === "create" && "bg-gray-300",
                element.object.action === "notify" && "bg-blue-200",
                "flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white",
              )}
            >
              <Icon name={icon} className="h-5 w-5 text-white" />
            </button>
          </div>
          <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
            <div>
              <p className="text-sm text-gray-500">
                <span className="font-medium text-gray-900">
                  {element.object.user.displayName.firstName}
                </span>{" "}
                {element.object.action === "modify" &&
                  "ha apportato una modifica"}
                {element.object.action === "notify" &&
                  "ha mandato una mail di notifica"}
                {element.object.action === "create" &&
                  element.object.entity === "Booking" &&
                  "ha creato la prenotazione"}
                {element.object.action === "create" &&
                  element.object.entity === "Rental" &&
                  "ha creato il noleggio"}
                {element.object.action === "create" &&
                  element.object.entity === "Payment" &&
                  "ha aggiunto un pagamento"}
                .
              </p>
            </div>
            <div className="whitespace-nowrap text-right text-sm text-gray-500">
              <p>{format(new Date(element.timestamp), "dd/MM")}</p>
              <p>{format(new Date(element.timestamp), "HH:mm")}</p>
            </div>
          </div>
        </div>
        {showViewLogDetailsModal && (
          <ViewLogDetailsModal
            open={showViewLogDetailsModal}
            onOpenChange={setShowViewLogDetailsModal}
            log={element.object}
          />
        )}
      </>
    );
  }
  if (element.type === "note") {
    return (
      <div className="relative flex space-x-3">
        <div>
          <button
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}
            onClick={handleDeletion}
            className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-500 ring-8 ring-white transition hover:bg-red-500"
          >
            {isDeleting ? (
              <Icon name="Check" className="h-5 w-5 text-white" />
            ) : isHovered ? (
              <Icon name="X" className="h-5 w-5 text-white" />
            ) : (
              <span className="text-xs font-medium text-white">
                {getInitials(
                  `${element.object.submittedBy.displayName?.firstName} ${element.object.submittedBy.displayName?.lastName}`,
                )}
              </span>
            )}
          </button>
        </div>
        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
          <div>
            <p className="text-sm text-gray-500">
              <span className="font-medium text-gray-900">
                {element.object.submittedBy.displayName?.firstName}:
              </span>{" "}
              {element.object.content}
            </p>
          </div>
          <div className="whitespace-nowrap text-right text-sm text-gray-500">
            <p>{format(new Date(element.timestamp), "dd/MM")}</p>
            <p>{format(new Date(element.timestamp), "HH:mm")}</p>
          </div>
        </div>
      </div>
    );
  }
  if (element.type === "payment") {
    return (
      <div className="relative flex space-x-3">
        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-green-500 ring-8 ring-white">
          <Icon name="Euro" className="h-5 w-5 text-white" />
        </div>
        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
          <div>
            <p className="text-sm text-gray-500">
              <span className="font-medium text-gray-900">Pagamento</span>{" "}
              {formatCurrency.format(element.object.amount)} (
              {element.object.stripeSessionId ? (
                <a
                  className="inline-flex items-center gap-1 underline transition-colors hover:text-blue-500"
                  href={`https://dashboard.stripe.com/payments/${element.object.stripeSessionId}`}
                >
                  <Icon name="ExternalLink" className="h-3 w-3" />
                  {element.object.method}
                </a>
              ) : (
                <span>{element.object.method}</span>
              )}
              )
            </p>
          </div>
          <div className="whitespace-nowrap text-right text-xs text-gray-500">
            <p>{format(new Date(element.timestamp), "dd/MM")}</p>
            <p>{format(new Date(element.timestamp), "HH:mm")}</p>
          </div>
        </div>
      </div>
    );
  }
  if (element.type === "refund") {
    return (
      <div className="relative flex space-x-3">
        <div>
          <span className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-200 ring-8 ring-white">
            <Icon name="Euro" className="h-5 w-5 text-gray-400" />
          </span>
        </div>
        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
          <div>
            <p className="text-sm text-gray-500">
              <span className="font-medium text-gray-900">Rimborso</span>{" "}
              {formatCurrency.format(element.object.amount)}
            </p>
          </div>
          <div className="whitespace-nowrap text-right text-sm text-gray-500">
            <p>{format(new Date(element.timestamp), "dd/MM")}</p>
            <p>{format(new Date(element.timestamp), "HH:mm")}</p>
          </div>
        </div>
      </div>
    );
  }
};
export default EventsFeed;
