import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import DisplayListItem from "../ui/DisplayListItem";
import { toast } from "../ui/UseToast";
import { SailboatIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { cn } from "../../lib/utils";

const BoatItem = ({ data }) => {
  return (
    <Link
      to={`/boats/${data._id}`}
      className={cn(
        "flex items-center gap-2 rounded border-2 p-2 transition hover:border-black",
        data.deleted === true && "text-gray-500 hover:border-gray-400",
      )}
    >
      {data.image.thumbnail?.src != null ? (
        <img
          src={data.image.thumbnail.src}
          alt={data.displayName}
          className="h-12 w-12 rounded-full object-cover"
        />
      ) : (
        <div className="grid h-12 w-12 place-items-center rounded-full border">
          <SailboatIcon className="text-muted-foreground" />
        </div>
      )}

      <div className="flex flex-1 items-center gap-1">
        <div className="grow text-left">
          <div className="flex items-center gap-1">
            <span className="font-medium">{data.name}</span>
            {data.needsLicenseForRenting && (
              <span className="text-destructive/75 text-xs uppercase">
                Pat. Naut.
              </span>
            )}
          </div>
          <p className="text-muted-foreground text-sm">
            Porto {data.port[0].toUpperCase() + data.port.slice(1)}
            <svg
              viewBox="0 0 2 2"
              className="mx-1 inline h-0.5 w-0.5 fill-current"
              aria-hidden="true"
            >
              <circle cx="1" cy="1" r="1" />
            </svg>{" "}
            {data.specs.power}cv
          </p>
        </div>
      </div>
    </Link>
  );
};

const BoatsListView = ({ data }) => {
  return (
    <div className="grid-cols-2 gap-3 lg:grid">
      <div className="space-y-2">
        <h2 className="text-lg font-medium">Escursioni</h2>
        <div className="grid gap-2">
          {data
            .filter((item) => !item.rentable)
            .map((item) => (
              <BoatItem key={item._id} data={item} />
            ))}
        </div>
      </div>
      <div className="space-y-2">
        <h2 className="text-lg font-medium">Noleggio</h2>
        <div className="grid gap-2">
          {data
            .filter((item) => item.rentable)
            .map((item) => (
              <BoatItem key={item._id} data={item} />
            ))}
        </div>
      </div>
    </div>
  );
};

const BoatsList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [boats, setBoats] = useState();
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    setIsLoading(true);
    axiosPrivate
      .get("/boats")
      .then((response) => {
        setBoats(response.data);
      })
      .catch((err) => {
        console.error(err);
        toast({ title: "Error while loading boats" });
      })
      .finally(() => setIsLoading(false));
  }, [axiosPrivate]);

  return isLoading ? (
    <ul className="rounded border border-gray-300">
      <DisplayListItem.Skeleton />
      <DisplayListItem.Skeleton />
      <DisplayListItem.Skeleton />
      <DisplayListItem.Skeleton />
    </ul>
  ) : boats !== undefined ? (
    <BoatsListView data={boats} />
  ) : (
    <p>No boats to display</p>
  );
};

export default BoatsList;
