import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/Dialog";
import { Input, InputMask } from "../../ui/Input";
import { Button } from "../../ui/Button";
import { PlusIcon } from "lucide-react";

const AddParticipant = ({ callbackFn }) => {
  const [open, setOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState();
  const [pob, setPob] = useState("");

  const handleResetValues = () => {
    setFirstName("");
    setLastName("");
    setDob();
    setPob("");
  };

  const handleClose = () => {
    setOpen(!open);
    handleResetValues();
  };

  const handleAddParticipant = () => {
    callbackFn({
      firstName,
      lastName,
      dob,
      pob,
    });
    handleClose();
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogTrigger asChild>
        <button className="flex w-full items-center justify-center gap-1.5 text-gray-400 transition-colors hover:text-green-500 focus:outline-none">
          <PlusIcon className="h-6 w-6" />
          Aggiungi partecipante
        </button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Aggiungi nuovo partecipante</DialogTitle>
        </DialogHeader>
        <div className="grid gap-3 lg:grid-cols-2">
          <div>
            <div className="text-sm font-medium">Nome</div>
            <Input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div>
            <div className="text-sm font-medium">Cognome</div>
            <Input
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div>
            <div className="text-sm font-medium">Luogo di nascita</div>
            <Input value={pob} onChange={(e) => setPob(e.target.value)} />
          </div>
          <div>
            <div className="text-sm font-medium">Data di nascita</div>
            <InputMask
              mask="99/99/9999"
              maskChar="_"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
            />
          </div>
        </div>
        <DialogFooter>
          <Button
            onClick={() => {
              setOpen(false);
              handleResetValues(false);
            }}
            variant="outline"
          >
            Annulla
          </Button>
          <Button
            disabled={!pob || !firstName || !lastName || !dob}
            onClick={() => handleAddParticipant()}
          >
            Conferma
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddParticipant;
