import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import React from "react";

export const RadioGroup = React.forwardRef(
  ({ className, children, ...props }, forwardedRef) => (
    <RadioGroupPrimitive.Root
      className={className}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </RadioGroupPrimitive.Root>
  ),
);
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

export const RadioGroupItem = React.forwardRef(
  ({ className, children, ...props }, forwardedRef) => (
    <RadioGroupPrimitive.Item
      className={className}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </RadioGroupPrimitive.Item>
  ),
);

export const RadioGroupIndicator = React.forwardRef(
  ({ className, children, ...props }, forwardedRef) => (
    <RadioGroupPrimitive.Indicator
      className={className}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </RadioGroupPrimitive.Indicator>
  ),
);
