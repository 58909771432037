const { z } = require("zod");

const preprocessNumber = z.preprocess(
  (val) => parseFloat(val),
  z.number().nonnegative().optional(),
);

const PriceSchema = z.object({
  amount: preprocessNumber,
});

const PriceModelSchema = z.object({
  pre: PriceSchema.optional(),
  full: PriceSchema.optional(),
});

const CustomPricesSchema = z.object({
  low: PriceModelSchema.optional(),
  high: PriceModelSchema.optional(),
  ferragosto: PriceModelSchema.optional(),
});

const BoatSchema = z.object({
  name: z.string(),
  displayName: z.string().optional(),
  type: z.enum(["inflatable", "boat", "small_inflatable", "yacht", "other"]),
  image: z
    .object({
      thumbnail: z
        .object({
          src: z.string().optional(),
        })
        .optional(),
      large: z
        .object({
          src: z.string().optional(),
        })
        .optional(),
    })
    .optional(),
  seats: preprocessNumber,
  skipper: z
    .string()
    .regex(/^[0-9a-fA-F]{24}$/)
    .optional(), // ObjectId validation
  specs: z
    .object({
      manufacturer: z.string().optional(),
      model: z.string().optional(),
      power: preprocessNumber,
      description: z.string().optional(),
      engine_description: z.string().optional(),
    })
    .optional(),
  port: z.enum(["rodi", "foce"]),
  twColor: z
    .enum(["emerald-500", "purple-500", "orange-500", "rose-500", "cyan-500"])
    .optional(),
  active: z.boolean(),
  deleted: z.boolean().optional(),
  order: preprocessNumber,
  rentable: z.boolean(),
  canBeRentedWithoutSkipper: z.boolean(),
  needsLicenseForRenting: z.boolean(),
  followsCustomPricing: z.boolean(),
  customPrices: z
    .object({
      tremiti: z
        .object({
          active: z.boolean().optional(),
          adults: CustomPricesSchema.optional(),
          kids: CustomPricesSchema.optional(),
          exclusive: CustomPricesSchema.optional(),
        })
        .optional(),
      costa: z
        .object({
          active: z.boolean().optional(),
          adults: CustomPricesSchema.optional(),
          kids: CustomPricesSchema.optional(),
          exclusive: CustomPricesSchema.optional(),
        })
        .optional(),
      croazia: z
        .object({
          active: z.boolean().optional(),
          adults: CustomPricesSchema.optional(),
          kids: CustomPricesSchema.optional(),
          exclusive: CustomPricesSchema.optional(),
        })
        .optional(),
      notturno: z
        .object({
          active: z.boolean().optional(),
          adults: CustomPricesSchema.optional(),
          kids: CustomPricesSchema.optional(),
          exclusive: CustomPricesSchema.optional(),
        })
        .optional(),
      rental: z
        .object({
          daily: z
            .object({
              halfDay: z
                .object({
                  active: z.boolean().optional(),
                  exclusive: z
                    .object({
                      low: PriceModelSchema.optional(),
                      high: PriceModelSchema.optional(),
                      ferragosto: PriceModelSchema.optional(),
                    })
                    .optional(),
                })
                .optional(),
              fullDay: z
                .object({
                  active: z.boolean().optional(),
                  exclusive: z
                    .object({
                      low: PriceModelSchema.optional(),
                      high: PriceModelSchema.optional(),
                      ferragosto: PriceModelSchema.optional(),
                    })
                    .optional(),
                })
                .optional(),
            })
            .optional(),
        })
        .optional(),
    })
    .optional(),
});

module.exports = BoatSchema;
