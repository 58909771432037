"use client";
import { add, format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { TimePickerDemo } from "./TimePickerDemo";
import { it } from "date-fns/locale";
import { Popover, PopoverContent, PopoverTrigger } from "./Popover";
import { Button } from "./Button";
import { cn } from "../../lib/utils";
import { Calendar } from "./Calendar";

export function DateTimePicker({ value, onValueChange }) {
  const handleSelect = (newDay) => {
    if (!newDay) return;
    if (!value) {
      onValueChange(newDay);
      return;
    }
    const diff = newDay.getTime() - value.getTime();
    const diffInDays = diff / (1000 * 60 * 60 * 24);
    const newDateFull = add(value, { days: Math.ceil(diffInDays) });
    onValueChange(newDateFull);
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[280px] justify-start text-left font-normal",
            !value && "text-muted-foreground",
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {value ? (
            format(value, "PPP HH:mm", { locale: it })
          ) : (
            <span>Scegli una data</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={value}
          onSelect={(d) => handleSelect(d)}
          initialFocus
        />
        <div className="border-border border-t p-3">
          <TimePickerDemo setDate={onValueChange} date={value} />
        </div>
      </PopoverContent>
    </Popover>
  );
}
