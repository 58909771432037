import React from "react";

const CroatianFlag = (props) => {
  return (
    <div {...props}>
      <img src="./img/flags/hr.png" alt="HR" />
    </div>
  );
};

export default CroatianFlag;
