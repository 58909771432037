import React from "react";
import Icon from "./Icon";

const Loading = () => {
  return (
    <div className="flex items-center justify-center">
      <Icon name="Loader2" className="h-8 w-8 animate-spin text-gray-400" />
    </div>
  );
};

export default Loading;
