import React from "react";
import BoatsList from "../../components/boats/BoatsList";

const Boats = () => {
  return (
    <div className="mx-auto max-w-7xl space-y-6">
      <h2>Imbarcazioni</h2>
      <BoatsList />
    </div>
  );
};

export default Boats;
