import React from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../ui/AlertDialog";
import { buttonVariants } from "../../ui/Button";

const DeletePaymentAlert = ({ open, onOpenChange, deletePayment }) => {
  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Sei sicuro di voler cancellare questo pagamento?
          </AlertDialogTitle>
        </AlertDialogHeader>
        Non potrà più essere recuperato.
        <AlertDialogFooter>
          <AlertDialogCancel className={buttonVariants({ variant: "outline" })}>
            Annulla
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={() => deletePayment()}
            className={buttonVariants()}
          >
            Conferma
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeletePaymentAlert;
