import { NavLink } from "react-router-dom";
import { sideBarNav } from "../../config/navigation";
import { ROLES } from "../../config/roles";
import useAuth from "../../hooks/useAuth";
import MainLogo from "../../img/MainLogo.png";
import { cn } from "../../lib/utils";
import Icon from "./Icon";

const Sidebar = () => {
  const { auth } = useAuth();
  const roles = auth?.roles;

  let items = roles?.reduce((result, role) => {
    const roleKey = Object.keys(ROLES).find((key) => ROLES[key] === role);
    if (roleKey && Object.prototype.hasOwnProperty.call(sideBarNav, roleKey)) {
      result.push(...sideBarNav[roleKey]);
    }
    return result;
  }, []);

  if (items) {
    items.unshift(...sideBarNav["All"]);

    items = items.filter((item, index, self) => {
      return index === self.findIndex((i) => i.href === item.href);
    });
  }

  return (
    <div className="flex min-h-0 flex-1 flex-col gap-2">
      <div className="flex flex-col items-center gap-2 bg-gray-900 py-2">
        <img className="h-16 object-contain" src={MainLogo} alt="VergaNautic" />
        {process.env.REACT_APP_TEST_MODE ? (
          <div className="text-lg font-semibold tracking-widest text-red-500">
            TEST MODE {process.env.REACT_APP_VERSION}
          </div>
        ) : (
          <div className="text-lg font-semibold tracking-widest text-gray-100">
            MANAGEMENT
          </div>
        )}
      </div>
      <nav className="grid items-start gap-4 p-2 text-sm font-medium">
        {items?.map((item) =>
          !item.disabled ? (
            <NavLink
              key={item.href}
              to={item.href}
              className={({ isActive }) =>
                cn(
                  "flex flex-row items-center gap-x-1 rounded px-3 py-2 text-gray-400 transition hover:bg-slate-700 hover:text-white",
                  isActive && "bg-slate-700 text-white",
                )
              }
            >
              {!item.icon ? (
                <Icon name="ChevronRight" className="mr-2 h-4 w-4" />
              ) : (
                <Icon name={item?.icon} className="mr-2 h-4 w-4" />
              )}
              {item.title}
            </NavLink>
          ) : (
            <span
              key={item.href}
              className="flex cursor-not-allowed flex-row items-center gap-x-1 px-3 py-2 text-gray-400 opacity-60"
            >
              {!item.icon ? (
                <Icon name="ChevronRight" className="mr-2 h-4 w-4" />
              ) : (
                <Icon name={item?.icon} className="mr-2 h-4 w-4" />
              )}
              {item.title}
            </span>
          ),
        )}
      </nav>
    </div>
  );
};

export default Sidebar;
