import { cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "../../lib/utils";
import Icon from "./Icon";

const icons = {
  default: "AlertTriangle",
  destructive: "AlertTriangle",
  informational: "Info",
  positive: "Check",
};

const alertVariants = cva("relative w-full rounded p-3", {
  variants: {
    variant: {
      default: "bg-yellow-50 text-yellow-700",
      destructive: "bg-red-50 text-red-500",
      informational: "bg-blue-50 text-blue-700",
      positive: "bg-green-50 text-green-700",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

const Alert = React.forwardRef(
  ({ className, variant, children, ...props }, ref) => (
    <div
      ref={ref}
      role="alert"
      className={cn(alertVariants({ variant }), className, "flex gap-3")}
      {...props}
    >
      <div className="flex-shrink-0">
        <Icon
          name={icons[variant] || "AlertTriangle"}
          className="h-5 w-5"
          aria-hidden="true"
        />
      </div>
      <div>{children}</div>
    </div>
  ),
);
Alert.displayName = "Alert";

const AlertTitle = React.forwardRef(({ className, ...props }, ref) => (
  <h5 ref={ref} className={cn("text-sm font-medium", className)} {...props} />
));
AlertTitle.displayName = "AlertTitle";

const AlertDescription = React.forwardRef(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("text-sm", className)} {...props} />
));
AlertDescription.displayName = "AlertDescription";

export { Alert, AlertDescription, AlertTitle };
