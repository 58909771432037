import { useEffect, useState } from "react";
import useAuth from "../../../../hooks/useAuth";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { cn, getSeason } from "../../../../lib/utils";
import { useFormContext } from "react-hook-form";
import { toast } from "../../../ui/UseToast";
import { RadioGroup, RadioGroupItem } from "@radix-ui/react-radio-group";

const calculateSeasonFromRange = (from, to) => {
  const fromSeason = getSeason(from);
  const toSeason = getSeason(to);

  if (fromSeason === toSeason) {
    return fromSeason;
  }

  if (fromSeason === "low" && ["ferragosto", "high"].includes(toSeason)) {
    return toSeason;
  }

  if (["ferragosto", "high"].includes(fromSeason) && toSeason === "low") {
    return fromSeason;
  }

  return fromSeason;
};

const retrievePricingLabel = (priceTable, from, to) => {
  if (priceTable.halfDay.active && priceTable.fullDay.active) {
    return `${priceTable.halfDay.exclusive[calculateSeasonFromRange(from, to)].full.amount} - ${priceTable.fullDay.exclusive[calculateSeasonFromRange(from, to)].full.amount}`;
  }
  if (priceTable.halfDay.active && !priceTable.fullDay.active) {
    return `${priceTable.halfDay.exclusive[calculateSeasonFromRange(from, to)].full.amount}`;
  }
  if (!priceTable.halfDay.active && priceTable.fullDay.active) {
    return `${priceTable.fullDay.exclusive[calculateSeasonFromRange(from, to)].full.amount}`;
  }
};

const RentalBoatsRadioGroup = ({
  value,
  onValueChange,
  setSelectedBoat,
  className,
}) => {
  const [boats, setBoats] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { auth } = useAuth();
  const api = useAxiosPrivate();

  const form = useFormContext();

  const participants = form.watch("participants");

  const handleOnValueChange = (value) => {
    const foundItem = boats.find((item) => item._id === value);
    if (foundItem !== undefined) {
      setSelectedBoat(foundItem);
    } else {
      setSelectedBoat();
    }
    onValueChange(value);
  };

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      api
        .get(`/boats/rentable`)
        .then((response) => {
          setBoats(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          toast({
            title: "Errore durante il caricamento delle barche",
          });
        })
        .finally(() => setIsLoading(false));
    };

    fetchData();
  }, []);

  const BoatsRadioGroupSkeleton = () => {
    return (
      <div className="flex w-full animate-pulse items-center justify-between rounded border px-3 py-2 text-start">
        <div className="space-y-3">
          <div className="h-6 w-48 rounded bg-gray-300"></div>
          <div className="h-4 w-32 rounded bg-gray-300"></div>
        </div>
        <div className="h-6 w-16 rounded bg-gray-300"></div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="col-span-2 grid-cols-2 gap-6 md:grid">
        {Array.from({ length: 6 }, (v, i) => (
          <BoatsRadioGroupSkeleton key={i} />
        ))}
      </div>
    );
  }

  if (boats !== undefined) {
    if (boats.length === 0) {
      return (
        <div className="w-full py-16 text-center text-gray-500">
          Nessuna imbarcazione disponbiile.
        </div>
      );
    }

    return (
      <RadioGroup
        value={value}
        onValueChange={handleOnValueChange}
        className={cn("grid grid-cols-2 gap-6", className)}
      >
        {boats.map((boat) => (
          <RadioGroupItem
            value={boat._id}
            disabled={auth?.roles.includes(2000) && !boat.rentable}
            key={boat._id}
            className={cn(
              "flex items-center justify-between rounded border px-3 py-2 text-start ring-blue-600 data-[state=checked]:ring",
            )}
          >
            <div className="space-y-1">
              <div className="text-lg font-medium">{boat.name}</div>
              <p
                className={cn(
                  participants.kids + participants.adults > boat.seats &&
                    "text-red-600",
                )}
              >
                Posti: {boat.seats}
              </p>
            </div>
            <div>
              <div
                className={cn(
                  "text-right text-xl",
                  !boat.available && "text-gray-500",
                )}
              >
                {retrievePricingLabel(boat.customPrices.rental.daily)}
              </div>
            </div>
          </RadioGroupItem>
        ))}
      </RadioGroup>
    );
  }
};

export default RentalBoatsRadioGroup;
