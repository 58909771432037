export const IDENTIFICATION_TYPES = {
  government_id: "Carta di Identità",
  passport: "Passaporto",
  drivers_license: "Patente",
};

export const UNIFORMS = {
  Monday: "Polo bianca, Pantaloncino blu",
  Tuesday: "Polo blu chiaro, Pantaloncino bianco",
  Wednesday: "T-shirt bianca, Pantaloncino blu",
  Thursday: "Polo blu scuro, Pantaloncino bianco",
  Friday: "Polo bianca, Pantaloncino blu",
  Saturday: "Polo blu chiaro, Pantaloncino bianco",
  Sunday: "Camicia bianca, Pantaloncino blu",
};

export const DOCUMENT_TYPES = {
  government_id: "Carta di Identità",
  drivers_license: "Patente di guida",
  passport: "Passaporto",
  nautical_license: "Patente Nautica",
};

export const BOOKING_PATHS = {
  date: "Data",
  exclusive: "Esclusiva",
  boat: "Mezzo",
  port: "Porto",
  skipper: "Skipper",
  "paymentInfo.totalAmount": "Totale",
  "participants.adults": "Adulti",
  "participants.kids": "Bambini",
  "organizer.firstName": "Organizzatore: Nome",
  "organizer.lastName": "Organizzatore: Cognome",
  "organizer.pob": "Organizzatore: Luogo di nascita",
  "organizer.dob": "Organizzatore: Date di nascita",
  "organizer.contactInfo.email": "Organizzatore: Luogo di nascita",
  "organizer.contactInfo.phone.number": "Organizzatore: numero di telefono",
  "organizer.identification.type": "Organizzatore: tipo di documento",
  "organizer.identification.number": "Organizzatore: numero del documento",
  "organizer.identification.expiry_date":
    "Organizzatore: scadenza del documento",
  "organizer.identification.issued_by": "Organizzatore: rilascio del documento",
};
