import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import BoatSchema from "../../lib/validators/boats";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import { toast } from "../../components/ui/UseToast";
import { Button } from "../../components/ui/Button";
import Checkbox from "../../components/ui/Checkbox";
import { Input } from "../../components/ui/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/Select";
import { Label } from "../../components/ui/Label";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/Card";
import { CircleIcon } from "lucide-react";
import { cn } from "../../lib/utils";

const ports = ["rodi", "foce"];

const seasons = ["low", "high", "ferragosto"];

const seasonLabels = {
  low: "Bassa",
  high: "Alta",
  ferragosto: "Ferragosto",
};

const bookingTours = ["tremiti", "croazia", "costa", "notturno"];

const priceLabels = {
  adults: "Adulti",
  kids: "Ragazzi",
  exclusive: "Esclusiva",
};

const colors = {
  "red-600": "Rosso",
  "blue-600": "Blu",
  "green-600": "Verde",
  "yellow-600": "Giallo",
  "purple-600": "Viola",
  "orange-600": "Arancione",
  "pink-600": "Rosa",
  "cyan-600": "Ciano",
};

export const typeOptions = {
  inflatable: "Gommone",
  boat: "Barca",
  yacht: "Yacht",
  small_inflatable: "Gommoncino",
  other: "Altro",
};

const defaultValues = (data) => ({
  name: data?.name || "",
  displayName: data?.displayName || "",
  type: data?.type || "other",
  image: {
    thumbnail: {
      src: data?.image?.thumbnail?.src || "",
    },
    large: {
      src: data?.image?.large?.src || "",
    },
  },
  seats: data?.seats || 0,
  skipper: data?.skipper || "",
  specs: {
    manufacturer: data?.specs?.manufacturer || "",
    model: data?.specs?.model || "",
    power: data?.specs?.power || 0,
    description: data?.specs?.description || "",
    engine_description: data?.specs?.engine_description || "",
  },
  port: data?.port || "rodi",
  twColor: data?.twColor || "emerald-500",
  active: data?.active ?? true,
  deleted: data?.deleted ?? false,
  order: data?.order || 0,
  rentable: data?.rentable ?? false,
  canBeRentedWithoutSkipper: data?.canBeRentedWithoutSkipper ?? false,
  needsLicenseForRenting: data?.needsLicenseForRenting ?? false,
  followsCustomPricing: data?.followsCustomPricing ?? false,
  customPrices: {
    tremiti: {
      active: data?.customPrices?.tremiti?.active || false,
      adults: {
        low: {
          pre: {
            amount: data?.customPrices?.tremiti?.adults?.low?.pre?.amount || 0,
          },
          full: {
            amount: data?.customPrices?.tremiti?.adults?.low?.full?.amount || 0,
          },
        },
        high: {
          pre: {
            amount: data?.customPrices?.tremiti?.adults?.high?.pre?.amount || 0,
          },
          full: {
            amount:
              data?.customPrices?.tremiti?.adults?.high?.full?.amount || 0,
          },
        },
        ferragosto: {
          pre: {
            amount:
              data?.customPrices?.tremiti?.adults?.ferragosto?.pre?.amount || 0,
          },
          full: {
            amount:
              data?.customPrices?.tremiti?.adults?.ferragosto?.full?.amount ||
              0,
          },
        },
      },
      kids: {
        low: {
          pre: {
            amount: data?.customPrices?.tremiti?.kids?.low?.pre?.amount || 0,
          },
          full: {
            amount: data?.customPrices?.tremiti?.kids?.low?.full?.amount || 0,
          },
        },
        high: {
          pre: {
            amount: data?.customPrices?.tremiti?.kids?.high?.pre?.amount || 0,
          },
          full: {
            amount: data?.customPrices?.tremiti?.kids?.high?.full?.amount || 0,
          },
        },
        ferragosto: {
          pre: {
            amount:
              data?.customPrices?.tremiti?.kids?.ferragosto?.pre?.amount || 0,
          },
          full: {
            amount:
              data?.customPrices?.tremiti?.kids?.ferragosto?.full?.amount || 0,
          },
        },
      },
      exclusive: {
        low: {
          pre: {
            amount:
              data?.customPrices?.tremiti?.exclusive?.low?.pre?.amount || 0,
          },
          full: {
            amount:
              data?.customPrices?.tremiti?.exclusive?.low?.full?.amount || 0,
          },
        },
        high: {
          pre: {
            amount:
              data?.customPrices?.tremiti?.exclusive?.high?.pre?.amount || 0,
          },
          full: {
            amount:
              data?.customPrices?.tremiti?.exclusive?.high?.full?.amount || 0,
          },
        },
        ferragosto: {
          pre: {
            amount:
              data?.customPrices?.tremiti?.exclusive?.ferragosto?.pre?.amount ||
              0,
          },
          full: {
            amount:
              data?.customPrices?.tremiti?.exclusive?.ferragosto?.full
                ?.amount || 0,
          },
        },
      },
    },
    costa: {
      active: data?.customPrices?.costa?.active || false,
      adults: {
        low: {
          pre: {
            amount: data?.customPrices?.costa?.adults?.low?.pre?.amount || 0,
          },
          full: {
            amount: data?.customPrices?.costa?.adults?.low?.full?.amount || 0,
          },
        },
        high: {
          pre: {
            amount: data?.customPrices?.costa?.adults?.high?.pre?.amount || 0,
          },
          full: {
            amount: data?.customPrices?.costa?.adults?.high?.full?.amount || 0,
          },
        },
        ferragosto: {
          pre: {
            amount:
              data?.customPrices?.costa?.adults?.ferragosto?.pre?.amount || 0,
          },
          full: {
            amount:
              data?.customPrices?.costa?.adults?.ferragosto?.full?.amount || 0,
          },
        },
      },
      kids: {
        low: {
          pre: {
            amount: data?.customPrices?.costa?.kids?.low?.pre?.amount || 0,
          },
          full: {
            amount: data?.customPrices?.costa?.kids?.low?.full?.amount || 0,
          },
        },
        high: {
          pre: {
            amount: data?.customPrices?.costa?.kids?.high?.pre?.amount || 0,
          },
          full: {
            amount: data?.customPrices?.costa?.kids?.high?.full?.amount || 0,
          },
        },
        ferragosto: {
          pre: {
            amount:
              data?.customPrices?.costa?.kids?.ferragosto?.pre?.amount || 0,
          },
          full: {
            amount:
              data?.customPrices?.costa?.kids?.ferragosto?.full?.amount || 0,
          },
        },
      },
      exclusive: {
        low: {
          pre: {
            amount: data?.customPrices?.costa?.exclusive?.low?.pre?.amount || 0,
          },
          full: {
            amount:
              data?.customPrices?.costa?.exclusive?.low?.full?.amount || 0,
          },
        },
        high: {
          pre: {
            amount:
              data?.customPrices?.costa?.exclusive?.high?.pre?.amount || 0,
          },
          full: {
            amount:
              data?.customPrices?.costa?.exclusive?.high?.full?.amount || 0,
          },
        },
        ferragosto: {
          pre: {
            amount:
              data?.customPrices?.costa?.exclusive?.ferragosto?.pre?.amount ||
              0,
          },
          full: {
            amount:
              data?.customPrices?.costa?.exclusive?.ferragosto?.full?.amount ||
              0,
          },
        },
      },
    },
    croazia: {
      active: data?.customPrices?.croazia?.active || false,
      adults: {
        low: {
          pre: {
            amount: data?.customPrices?.croazia?.adults?.low?.pre?.amount || 0,
          },
          full: {
            amount: data?.customPrices?.croazia?.adults?.low?.full?.amount || 0,
          },
        },
        high: {
          pre: {
            amount: data?.customPrices?.croazia?.adults?.high?.pre?.amount || 0,
          },
          full: {
            amount:
              data?.customPrices?.croazia?.adults?.high?.full?.amount || 0,
          },
        },
        ferragosto: {
          pre: {
            amount:
              data?.customPrices?.croazia?.adults?.ferragosto?.pre?.amount || 0,
          },
          full: {
            amount:
              data?.customPrices?.croazia?.adults?.ferragosto?.full?.amount ||
              0,
          },
        },
      },
      kids: {
        low: {
          pre: {
            amount: data?.customPrices?.croazia?.kids?.low?.pre?.amount || 0,
          },
          full: {
            amount: data?.customPrices?.croazia?.kids?.low?.full?.amount || 0,
          },
        },
        high: {
          pre: {
            amount: data?.customPrices?.croazia?.kids?.high?.pre?.amount || 0,
          },
          full: {
            amount: data?.customPrices?.croazia?.kids?.high?.full?.amount || 0,
          },
        },
        ferragosto: {
          pre: {
            amount:
              data?.customPrices?.croazia?.kids?.ferragosto?.pre?.amount || 0,
          },
          full: {
            amount:
              data?.customPrices?.croazia?.kids?.ferragosto?.full?.amount || 0,
          },
        },
      },
      exclusive: {
        low: {
          pre: {
            amount:
              data?.customPrices?.croazia?.exclusive?.low?.pre?.amount || 0,
          },
          full: {
            amount:
              data?.customPrices?.croazia?.exclusive?.low?.full?.amount || 0,
          },
        },
        high: {
          pre: {
            amount:
              data?.customPrices?.croazia?.exclusive?.high?.pre?.amount || 0,
          },
          full: {
            amount:
              data?.customPrices?.croazia?.exclusive?.high?.full?.amount || 0,
          },
        },
        ferragosto: {
          pre: {
            amount:
              data?.customPrices?.croazia?.exclusive?.ferragosto?.pre?.amount ||
              0,
          },
          full: {
            amount:
              data?.customPrices?.croazia?.exclusive?.ferragosto?.full
                ?.amount || 0,
          },
        },
      },
    },
    notturno: {
      active: data?.customPrices?.notturno?.active || false,
      adults: {
        low: {
          pre: {
            amount: data?.customPrices?.notturno?.adults?.low?.pre?.amount || 0,
          },
          full: {
            amount:
              data?.customPrices?.notturno?.adults?.low?.full?.amount || 0,
          },
        },
        high: {
          pre: {
            amount:
              data?.customPrices?.notturno?.adults?.high?.pre?.amount || 0,
          },
          full: {
            amount:
              data?.customPrices?.notturno?.adults?.high?.full?.amount || 0,
          },
        },
        ferragosto: {
          pre: {
            amount:
              data?.customPrices?.notturno?.adults?.ferragosto?.pre?.amount ||
              0,
          },
          full: {
            amount:
              data?.customPrices?.notturno?.adults?.ferragosto?.full?.amount ||
              0,
          },
        },
      },
      kids: {
        low: {
          pre: {
            amount: data?.customPrices?.notturno?.kids?.low?.pre?.amount || 0,
          },
          full: {
            amount: data?.customPrices?.notturno?.kids?.low?.full?.amount || 0,
          },
        },
        high: {
          pre: {
            amount: data?.customPrices?.notturno?.kids?.high?.pre?.amount || 0,
          },
          full: {
            amount: data?.customPrices?.notturno?.kids?.high?.full?.amount || 0,
          },
        },
        ferragosto: {
          pre: {
            amount:
              data?.customPrices?.notturno?.kids?.ferragosto?.pre?.amount || 0,
          },
          full: {
            amount:
              data?.customPrices?.notturno?.kids?.ferragosto?.full?.amount || 0,
          },
        },
      },
      exclusive: {
        low: {
          pre: {
            amount:
              data?.customPrices?.notturno?.exclusive?.low?.pre?.amount || 0,
          },
          full: {
            amount:
              data?.customPrices?.notturno?.exclusive?.low?.full?.amount || 0,
          },
        },
        high: {
          pre: {
            amount:
              data?.customPrices?.notturno?.exclusive?.high?.pre?.amount || 0,
          },
          full: {
            amount:
              data?.customPrices?.notturno?.exclusive?.high?.full?.amount || 0,
          },
        },
        ferragosto: {
          pre: {
            amount:
              data?.customPrices?.notturno?.exclusive?.ferragosto?.pre
                ?.amount || 0,
          },
          full: {
            amount:
              data?.customPrices?.notturno?.exclusive?.ferragosto?.full
                ?.amount || 0,
          },
        },
      },
    },
    rental: {
      daily: {
        halfDay: {
          active: data?.customPrices?.rental?.daily?.halfDay?.active || false,
          exclusive: {
            low: {
              pre: {
                amount:
                  data?.customPrices?.rental?.daily?.halfDay?.exclusive?.low
                    ?.pre?.amount || 0,
              },
              full: {
                amount:
                  data?.customPrices?.rental?.daily?.halfDay?.exclusive?.low
                    ?.full?.amount || 0,
              },
            },
            high: {
              pre: {
                amount:
                  data?.customPrices?.rental?.daily?.halfDay?.exclusive?.high
                    ?.pre?.amount || 0,
              },
              full: {
                amount:
                  data?.customPrices?.rental?.daily?.halfDay?.exclusive?.high
                    ?.full?.amount || 0,
              },
            },
            ferragosto: {
              pre: {
                amount:
                  data?.customPrices?.rental?.daily?.halfDay?.exclusive
                    ?.ferragosto?.pre?.amount || 0,
              },
              full: {
                amount:
                  data?.customPrices?.rental?.daily?.halfDay?.exclusive
                    ?.ferragosto?.full?.amount || 0,
              },
            },
          },
        },
        fullDay: {
          active: data?.customPrices?.rental?.daily?.fullDay?.active || false,
          exclusive: {
            low: {
              pre: {
                amount:
                  data?.customPrices?.rental?.daily?.fullDay?.exclusive?.low
                    ?.pre?.amount || 0,
              },
              full: {
                amount:
                  data?.customPrices?.rental?.daily?.fullDay?.exclusive?.low
                    ?.full?.amount || 0,
              },
            },
            high: {
              pre: {
                amount:
                  data?.customPrices?.rental?.daily?.fullDay?.exclusive?.high
                    ?.pre?.amount || 0,
              },
              full: {
                amount:
                  data?.customPrices?.rental?.daily?.fullDay?.exclusive?.high
                    ?.full?.amount || 0,
              },
            },
            ferragosto: {
              pre: {
                amount:
                  data?.customPrices?.rental?.daily?.fullDay?.exclusive
                    ?.ferragosto?.pre?.amount || 0,
              },
              full: {
                amount:
                  data?.customPrices?.rental?.daily?.fullDay?.exclusive
                    ?.ferragosto?.full?.amount || 0,
              },
            },
          },
        },
      },
    },
  },
});

const SkipperSelect = ({ value, onValueChange }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      axiosPrivate
        .get("/users/skippers")
        .then((res) => setData(res.data))
        .catch((err) => {
          console.error(err);
        })
        .finally(setIsLoading(false));
    };

    fetchData();
  }, [axiosPrivate]);

  return (
    <Select disabled={isLoading} value={value} onValueChange={onValueChange}>
      <SelectTrigger>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        {data.map((item) => (
          <SelectItem key={item._id} value={item._id}>
            {item.displayName.firstName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

const CustomPricingController = ({ category }) => {
  const methods = useFormContext();

  return (
    <Card>
      <CardHeader>
        <CardTitle className="uppercase">{category}</CardTitle>
        <div className="flex items-center gap-2">
          <Controller
            name={`customPrices.${category}.active`}
            control={methods.control}
            render={({ field }) => (
              <Checkbox
                checked={field.value}
                onCheckedChange={field.onChange}
              />
            )}
          />
          <Label>Attivo</Label>
        </div>
      </CardHeader>
      <CardContent>
        <div className="mt-2 font-semibold">{priceLabels[category]}</div>
        {["adults", "kids", "exclusive"].map((group) => (
          <div key={group}>
            <div className="mt-2 text-center font-bold">
              {priceLabels[group]}
            </div>
            {seasons.map((season) => (
              <div key={season} className="mt-2">
                <div className="font-medium">{seasonLabels[season]}</div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label className="text-muted-foreground">Acconto</Label>
                    <Controller
                      name={`customPrices.${category}.${group}.${season}.pre.amount`}
                      control={methods.control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          label={`Pre (${season})`}
                          type="number"
                          placeholder={`Pre (${season})`}
                        />
                      )}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label className="text-muted-foreground">Intero</Label>
                    <Controller
                      name={`customPrices.${category}.${group}.${season}.full.amount`}
                      control={methods.control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          label={`Full (${season})`}
                          type="number"
                          placeholder={`Full (${season})`}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </CardContent>
    </Card>
  );
};

const DailyPricingController = () => {
  const methods = useFormContext();

  return (
    <div>
      <div className="mt-2 font-semibold">Daily</div>
      {["halfDay", "fullDay"].map((duration) => (
        <div key={duration}>
          <div className="mt-2 font-bold">
            {duration.charAt(0).toUpperCase() + duration.slice(1)}
          </div>
          <div className="flex items-center gap-2">
            <Controller
              name={`customPrices.rental.daily.${duration}.active`}
              control={methods.control}
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <Label>Attivo</Label>
          </div>

          {seasons.map((season) => (
            <div key={season} className="mt-2">
              <div className="text-sm font-medium">{seasonLabels[season]}</div>
              <div className="grid grid-cols-2 gap-4">
                <Controller
                  name={`customPrices.rental.daily.${duration}.exclusive.${season}.pre.amount`}
                  control={methods.control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      label={`Pre (${season})`}
                      type="number"
                      placeholder={`Pre (${season})`}
                    />
                  )}
                />
                <Controller
                  name={`customPrices.rental.daily.${duration}.exclusive.${season}.full.amount`}
                  control={methods.control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      label={`Full (${season})`}
                      type="number"
                      placeholder={`Full (${season})`}
                    />
                  )}
                />
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export const BoatForm = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm({
    resolver: zodResolver(BoatSchema),
    defaultValues: defaultValues(data),
  });

  const followsCustomPricing = methods.watch("followsCustomPricing");
  const rentable = methods.watch("rentable");
  const canBeRentedWithoutSkipper = methods.watch("canBeRentedWithoutSkipper");

  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const onSubmit = (values) => {
    if (!values.rentable) {
      values.canBeRentedWithoutSkipper = false;
      values.needsLicenseForRenting = false;
    }

    setIsLoading(true);

    const request =
      data !== undefined
        ? axiosPrivate.patch(`/boats/${data._id}`, { ...values })
        : axiosPrivate.post("/boats", { ...values });

    request
      .then((res) => {
        toast({
          title: `Mezzo ${data !== undefined ? "aggiornato" : "creato"} con successo.`,
        });
        data === undefined && navigate(res.data.redirectUrl);
      })
      .catch((err) => {
        console.error(err);
        toast({
          title:
            data !== undefined
              ? "Errore durante l'aggiornamento del mezzo"
              : "Errore durante la creazione del mezzo",
          description: err.response?.data.message,
        });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    console.log(methods.formState.errors);
  }, [methods.formState.errors]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="grid gap-4 lg:grid-cols-2"
      >
        <div>
          <Label>Nome</Label>
          <Input {...methods.register("name")} className="mt-1 block w-full" />
        </div>
        <div>
          <Label>Nome Pubblico (Online)</Label>
          <Input
            {...methods.register("displayName")}
            className="mt-1 block w-full"
          />
        </div>
        <div className="space-y-1">
          <Label>Tipo</Label>
          <Controller
            name="type"
            control={methods.control}
            render={({ field }) => (
              <Select value={field.value} onValueChange={field.onChange}>
                <SelectTrigger>
                  <SelectValue placeholder="Select a type..." />
                </SelectTrigger>
                <SelectContent>
                  {Object.keys(typeOptions).map((option) => (
                    <SelectItem key={option} value={option}>
                      {typeOptions[option]}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
          />
        </div>
        <div>
          <Label>Posti</Label>
          <Input
            {...methods.register("seats")}
            type="number"
            className="mt-1 block w-full"
          />
        </div>
        <div className="space-y-1">
          <Label>Skipper</Label>
          <Controller
            name="skipper"
            control={methods.control}
            render={({ field }) => (
              <SkipperSelect
                value={field.value}
                onValueChange={field.onChange}
              />
            )}
          />
        </div>
        <div>
          <Label>Produttore</Label>
          <Input
            {...methods.register("specs.manufacturer")}
            className="mt-1 block w-full"
          />
        </div>
        <div>
          <Label>Modello</Label>
          <Input
            {...methods.register("specs.model")}
            className="mt-1 block w-full"
          />
        </div>
        <div>
          <Label>Potenza (cv)</Label>
          <Input
            {...methods.register("specs.power")}
            type="number"
            className="mt-1 block w-full"
          />
        </div>
        <div>
          <Label>Descrizione</Label>
          <Input
            {...methods.register("specs.description")}
            className="mt-1 block w-full"
          />
        </div>
        <div>
          <Label>Motore</Label>
          <Input
            {...methods.register("specs.engine_description")}
            className="mt-1 block w-full"
          />
        </div>
        <div>
          <Label>Url Immagine Thumbnail</Label>
          <Input
            {...methods.register("image.thumbnail.src")}
            className="mt-1 block w-full"
          />
        </div>
        <div>
          <Label>Url Immagine grande</Label>
          <Input
            {...methods.register("image.large.src")}
            className="mt-1 block w-full"
          />
        </div>
        <div className="space-y-1">
          <Label>Porto</Label>
          <Controller
            name="port"
            control={methods.control}
            render={({ field }) => (
              <Select value={field.value} onValueChange={field.onChange}>
                <SelectTrigger>
                  <SelectValue placeholder="Select a port..." />
                </SelectTrigger>
                <SelectContent>
                  {ports.map((port) => (
                    <SelectItem key={port} value={port}>
                      {port}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
          />
        </div>
        <div className="space-y-1">
          <Label>Colore</Label>
          <Controller
            name="twColor"
            control={methods.control}
            render={({ field }) => (
              <Select value={field.value} onValueChange={field.onChange}>
                <SelectTrigger>
                  <SelectValue placeholder="Select a color..." />
                </SelectTrigger>
                <SelectContent>
                  {Object.keys(colors).map((colorKey) => {
                    const fullColor = "text-" + colorKey;
                    return (
                      <SelectItem key={colorKey} value={colorKey}>
                        <div className="flex items-center gap-1">
                          <CircleIcon className={cn(fullColor, "size-3")} />
                          <span>{colorKey}</span>
                        </div>
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
            )}
          />
        </div>
        <div>
          <Label>Ordine</Label>
          <Input
            {...methods.register("order")}
            type="number"
            className="mt-1 block w-full"
          />
        </div>
        <div className="col-span-2 space-y-2">
          <div className="text-lg font-bold">Impostazioni</div>
          <div className="flex items-center gap-2">
            <Controller
              name="deleted"
              control={methods.control}
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <Label>Dimessa</Label>
          </div>
          <div className="flex items-center gap-2">
            <Controller
              name="active"
              control={methods.control}
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <Label>Prenotabile Online</Label>
          </div>
          <div className="flex items-center gap-2">
            <Controller
              name="rentable"
              control={methods.control}
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <Label>Locazione</Label>
          </div>
          {rentable && (
            <>
              <div className="flex items-center gap-2">
                <Controller
                  name="canBeRentedWithoutSkipper"
                  control={methods.control}
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  )}
                />
                <Label>Prenotabile senza skipper</Label>
              </div>
              <div className="flex items-center gap-2">
                <Controller
                  name="needsLicenseForRenting"
                  control={methods.control}
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  )}
                />
                <Label>Richiede patente nautica</Label>
              </div>
            </>
          )}
          <div className="flex items-center gap-2">
            <Controller
              name="followsCustomPricing"
              control={methods.control}
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <Label>Prezzi custom</Label>
          </div>
        </div>

        {followsCustomPricing && (
          <>
            <div className="col-span-2 mt-4 text-lg font-bold">
              Dettagli prezzi
            </div>
            {canBeRentedWithoutSkipper ? (
              <DailyPricingController />
            ) : (
              <>
                {bookingTours.map((item) => (
                  <div key={item}>
                    <CustomPricingController category={item} />
                  </div>
                ))}
              </>
            )}
          </>
        )}
        {/* Custom prices would be nested inputs similarly handled */}
        <div className="col-span-2 flex items-center justify-end gap-4">
          <Button disabled={isLoading} type="submit">
            Salva
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

const CreateBoatPage = () => {
  return (
    <div>
      <BoatForm />
    </div>
  );
};

export default CreateBoatPage;
