import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import DisplayListItem from "../ui/DisplayListItem";
import { toast } from "../ui/UseToast";

const ToursList = () => {
  const [tours, setTours] = useState();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const getTours = () => {
      axiosPrivate
        .get("/tours")
        .then((response) => {
          setTours(response.data);
        })
        .catch((err) => {
          console.error(err);
          if (err.response?.data.status === 403) {
            navigate("/login", { state: { from: location }, replace: true });
          }
          toast({
            title: "Problema con il caricamento dei dati dal server. Riprovare",
            variant: "destructive",
          });
        });
    };

    getTours();
  }, []);

  return !tours ? (
    <ul className="rounded border border-gray-300">
      <DisplayListItem.Skeleton />
      <DisplayListItem.Skeleton />
      <DisplayListItem.Skeleton />
      <DisplayListItem.Skeleton />
    </ul>
  ) : tours?.length ? (
    <ul className="rounded border border-gray-300">
      {tours.map((tour) => {
        return (
          <DisplayListItem
            key={tour._id}
            title={tour.displayName}
            href={`/tours/${tour._id}`}
          />
        );
      })}
    </ul>
  ) : (
    <p>No tours to display</p>
  );
};

export default ToursList;
