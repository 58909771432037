import { endOfMonth, format, startOfMonth, subMonths } from "date-fns";
import { it } from "date-fns/locale";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Breadcrumb from "../../components/ui/Breadcrumb";
import { buttonVariants } from "../../components/ui/Button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../components/ui/Collapsible";
import Icon from "../../components/ui/Icon";
import Loading from "../../components/ui/Loading";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/Popover";
import { Calendar } from "../../components/ui/RangeCalendar";
import { toast } from "../../components/ui/UseToast";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { capitalize, cn, formatCurrency } from "../../lib/utils";

const BoatsReportPage = () => {
  const axiosPrivate = useAxiosPrivate();
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const [isLoading, setIsLoading] = useState(false);
  const [boatsReport, setBoatsReport] = useState();
  const [financialReport, setFinancialReport] = useState();
  const [period, setPeriod] = useState("last_month");

  const lastMonth = subMonths(new Date(), 1);
  const firstDateOfLastMonth = startOfMonth(lastMonth);
  const lastDateOfLastMonth = endOfMonth(lastMonth);

  const [boatsReportRange, setBoatsReportRange] = useState({
    from: firstDateOfLastMonth,
    to: lastDateOfLastMonth,
  });
  const [boatsReportFilters, setBoatsReportFilters] = useState({
    startDate: format(new Date(firstDateOfLastMonth), "yyyy-MM-dd"),
    endDate: format(new Date(lastDateOfLastMonth), "yyyy-MM-dd"),
  });

  const handleBoatReportRangeChange = (newValue) => {
    if (newValue) {
      setBoatsReportRange(newValue);
      if (newValue.from && newValue.to) {
        setBoatsReportFilters({
          ...boatsReportFilters,
          startDate: format(new Date(newValue.from), "yyyy-MM-dd"),
          endDate: format(new Date(newValue.to), "yyyy-MM-dd"),
        });
      }
    } else {
      setBoatsReportRange();
      setBoatsReportFilters();
    }
  };

  async function getBoatsReports() {
    setIsLoading(true);
    setBoatsReport();
    try {
      toast({
        title: "Creazione report in corso...",
        description: "⌛ Attendere prego.",
      });
      const response = await axiosPrivate.get("/reports/boats", {
        params: boatsReportFilters,
      });
      setBoatsReport(response.data);
      toast({
        description: "✔️ Report generato correttamente.",
        variant: "confirmed",
      });
    } catch (err) {
      console.log(err);
      toast({
        title: "Qualcosa è andato storto...",
        description:
          "❌ Non è stato possibile generare un report per gli boat.",
        variant: "destructive",
      });
    }
    setIsLoading(false);
  }

  async function getBoatsFinancialReport() {
    setIsLoading(true);
    setFinancialReport();
    try {
      toast({
        title: "Creazione report in corso...",
        description: "⌛ Attendere prego.",
      });
      const response = await axiosPrivate.get("/reports/boats", {
        params: boatsReportFilters,
      });
      setBoatsReport(response.data);
      toast({
        description: "✔️ Report generato correttamente.",
        variant: "confirmed",
      });
    } catch (err) {
      console.log(err);
      toast({
        title: "Qualcosa è andato storto...",
        description:
          "❌ Non è stato possibile generare un report per gli boat.",
        variant: "destructive",
      });
    }
    setIsLoading(false);
  }

  const resetBoatsReportFilters = () => {
    setBoatsReportRange();
    setBoatsReportFilters();
  };

  const handleSelectPeriod = (e) => {
    setPeriod(e.target.value);
    if (!e.target.value) {
      resetBoatsReportFilters();
    }
    if (e.target.value === "last_month") {
      const lastMonth = subMonths(new Date(), 1);
      const firstDateOfLastMonth = startOfMonth(lastMonth);
      const lastDateOfLastMonth = endOfMonth(lastMonth);

      setBoatsReportRange({
        from: firstDateOfLastMonth,
        to: lastDateOfLastMonth,
      });

      setBoatsReportFilters({
        startDate: format(new Date(firstDateOfLastMonth), "yyyy-MM-dd"),
        endDate: format(new Date(lastDateOfLastMonth), "yyyy-MM-dd"),
      });
    }
    if (e.target.value === "month_start") {
      const firstDateOfThisMonth = startOfMonth(new Date());
      const today = new Date();
      setBoatsReportRange({
        from: firstDateOfThisMonth,
        to: today,
      });

      setBoatsReportFilters({
        startDate: format(new Date(firstDateOfThisMonth), "yyyy-MM-dd"),
        endDate: format(new Date(today), "yyyy-MM-dd"),
      });
    }
    if (e.target.value === "june") {
      const firstDateOfJune = startOfMonth(new Date("2023-06-01"));
      const lastDateOfJune = endOfMonth(new Date("2023-06-01"));
      setBoatsReportRange({
        from: firstDateOfJune,
        to: lastDateOfJune,
      });

      setBoatsReportFilters({
        startDate: format(new Date(firstDateOfJune), "yyyy-MM-dd"),
        endDate: format(new Date(lastDateOfJune), "yyyy-MM-dd"),
      });
    }
    if (e.target.value === "july") {
      const firstDateOfJune = startOfMonth(new Date("2023-07-01"));
      const lastDateOfJune = endOfMonth(new Date("2023-07-01"));
      setBoatsReportRange({
        from: firstDateOfJune,
        to: lastDateOfJune,
      });

      setBoatsReportFilters({
        startDate: format(new Date(firstDateOfJune), "yyyy-MM-dd"),
        endDate: format(new Date(lastDateOfJune), "yyyy-MM-dd"),
      });
    }
    if (e.target.value === "season_start") {
      const firstDateOfSeason = startOfMonth(new Date("2023-06-01"));
      const today = new Date();
      setBoatsReportRange({
        from: firstDateOfSeason,
        to: today,
      });

      setBoatsReportFilters({
        startDate: format(new Date(firstDateOfSeason), "yyyy-MM-dd"),
        endDate: format(new Date(today), "yyyy-MM-dd"),
      });
    }
  };

  useEffect(() => {
    getBoatsReports();
  }, [period]);

  return (
    <div className="mx-auto max-w-7xl space-y-6">
      <Breadcrumb />
      <div className="flex items-center justify-between">
        <div>
          <h2>Report sulle imbarcazioni</h2>
        </div>
      </div>
      <div className="space-y-3">
        <div className="flex items-center gap-1.5">
          <select
            onChange={handleSelectPeriod}
            value={period}
            className="flex items-center gap-1 rounded border border-gray-200 px-2 py-1 text-xs font-medium"
          >
            <option value="">Personalizzato</option>
            <option value="month_start">Da inizio mese</option>
            <option value="season_start">Da inizio stagione</option>
            <option value="last_month">Ultimo mese</option>
            <option value="june">Giu 2023</option>
            <option value="july">Lug 2023</option>
          </select>
          <Popover>
            <PopoverTrigger asChild>
              <button className="flex items-center gap-1 rounded border border-gray-200 px-2 py-1 text-xs font-medium">
                <Icon name="Calendar" className="h-3 w-3 text-gray-400" />
                {!boatsReportRange?.from && !boatsReportRange?.to && "Periodo"}
                {boatsReportRange?.from &&
                  format(new Date(boatsReportRange.from), "MMM dd", {
                    locale: it,
                  })}
                {boatsReportRange?.from && boatsReportRange?.to && " - "}
                {boatsReportRange?.to &&
                  format(new Date(boatsReportRange.to), "MMM dd", {
                    locale: it,
                  })}
              </button>
            </PopoverTrigger>
            <PopoverContent className="mt-3 w-auto p-0">
              <Calendar
                mode="range"
                selected={boatsReportRange}
                onSelect={handleBoatReportRangeChange}
              />
              <div className="flex items-center gap-1.5 px-3 pb-3">
                <button
                  disabled={isLoading || !boatsReportFilters}
                  onClick={() => getBoatsReports()}
                  className={cn("flex-1", buttonVariants({ size: "sm" }))}
                >
                  {isLoading ? (
                    <Icon name="Loader" className="h-4 w-4 animate-spin" />
                  ) : (
                    "Genera"
                  )}
                </button>
                <button
                  onClick={() => resetBoatsReportFilters()}
                  className={cn(
                    "flex-1",
                    buttonVariants({ variant: "outline", size: "sm" }),
                  )}
                >
                  Azzera
                </button>
              </div>
            </PopoverContent>
          </Popover>
        </div>
        <div className="font-medium">Report su tutte le uscite dei mezzi</div>
        {isLoading && <Loading />}
        {boatsReport && (
          <div className="space-y-6">
            {boatsReport.map((item) => {
              return (
                <Collapsible
                  key={item.boat?.name}
                  className="rounded border border-gray-200"
                >
                  <CollapsibleTrigger>
                    <div className="flex items-center justify-between p-3">
                      <div className="font-medium">
                        {item.boat?.name} ({item.sorties.length})
                      </div>
                      <div>
                        <Icon name="ChevronDown" className="h-4 w-4" />
                      </div>
                    </div>
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <div className="space-y-1.5 bg-gray-50 p-1.5 text-sm lg:col-span-2">
                      {item.sorties
                        .sort((a, b) => new Date(a.date) - new Date(b.date))
                        .map((sortie) => {
                          return (
                            <Collapsible
                              key={sortie.date}
                              className="rounded border border-gray-200 bg-white p-1.5"
                            >
                              <CollapsibleTrigger>
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center gap-3">
                                    <div className="w-16">
                                      {capitalize(
                                        format(
                                          new Date(sortie.date),
                                          "dd MMM yy",
                                          { locale: it },
                                        ),
                                      )}
                                    </div>
                                    <div className="flex w-24 items-center gap-1.5 rounded border border-gray-200 p-1.5">
                                      <Icon
                                        name={sortie.tour.icon}
                                        className="h-3 w-3 text-gray-400"
                                      />
                                      <div className="text-xs font-medium uppercase">
                                        {capitalize(sortie.tour.name)}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-3">
                                    {!sortie.totalSum ||
                                    !sortie.totalExpense ? (
                                      <div className="flex items-center gap-1.5 font-medium text-yellow-700">
                                        <Icon
                                          name="AlertTriangle"
                                          className="h-3 w-3"
                                        />
                                        Incongruenze
                                      </div>
                                    ) : (
                                      <div className="font-medium text-slate-700">
                                        {formatCurrency.format(
                                          sortie.totalSum - sortie.totalExpense,
                                        )}
                                      </div>
                                    )}
                                    <div>
                                      <Icon
                                        name="Eye"
                                        className="h-4 w-4 text-gray-400"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </CollapsibleTrigger>
                              <CollapsibleContent className="flex items-center justify-between">
                                <div>
                                  <div className="font-medium">
                                    Entrate totali
                                  </div>
                                  {sortie.totalSum ? (
                                    <div className="font-medium text-green-700">
                                      + {formatCurrency.format(sortie.totalSum)}
                                    </div>
                                  ) : (
                                    <div className="font-medium text-yellow-700">
                                      Incongruenze
                                    </div>
                                  )}
                                </div>
                                <div>
                                  <div className="font-medium">Carburante</div>
                                  {sortie.totalExpense ? (
                                    <div className="font-medium text-red-700">
                                      -{" "}
                                      {formatCurrency.format(
                                        sortie.totalExpense,
                                      )}
                                    </div>
                                  ) : (
                                    <Link
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      to={`/expenses/new?date=${format(new Date(sortie.date), "yyyy-MM-dd")}&type=related`}
                                      className="flex items-center gap-1.5 font-medium text-yellow-700 transition-colors hover:text-yellow-500"
                                    >
                                      Risolvi
                                      <Icon
                                        name="ExternalLink"
                                        className="h-3 w-3"
                                      />
                                    </Link>
                                  )}
                                </div>
                                <div className="space-y-1.5 text-end">
                                  <Link
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    to={`/bookings?date=${format(new Date(sortie.date), "yyyy-MM-dd")}&boat=${item.boat._id}`}
                                    className="flex items-center gap-1.5 font-medium text-blue-500"
                                  >
                                    Visualizza prenotazioni
                                    <Icon
                                      name="ExternalLink"
                                      className="h-3 w-3"
                                    />
                                  </Link>
                                  <Link
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    to={`/expenses?date=${format(new Date(sortie.date), "yyyy-MM-dd")}&boat=${item.boat._id}`}
                                    className="flex items-center gap-1.5 font-medium text-blue-500"
                                  >
                                    Visualizza spese
                                    <Icon
                                      name="ExternalLink"
                                      className="h-3 w-3"
                                    />
                                  </Link>
                                </div>
                              </CollapsibleContent>
                            </Collapsible>
                          );
                        })}
                    </div>
                  </CollapsibleContent>
                </Collapsible>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default BoatsReportPage;
