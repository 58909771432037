import { endOfMonth, format, startOfMonth, subMonths } from "date-fns";
import { it } from "date-fns/locale";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/ui/Breadcrumb";
import { buttonVariants } from "../../components/ui/Button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../components/ui/Collapsible";
import Icon from "../../components/ui/Icon";
import Loading from "../../components/ui/Loading";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/Popover";
import { Calendar } from "../../components/ui/RangeCalendar";
import { toast } from "../../components/ui/UseToast";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { capitalize, cn, formatCurrency } from "../../lib/utils";
import { CalendarDatePicker } from "../../components/ui/EnhancedDatePicker/Index";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/Alert";

const TourOperatorsReportPage = () => {
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [tourOperatorsReport, setTourOperatorsReport] = useState();
  const [period, setPeriod] = useState("last_month");

  const lastMonth = subMonths(new Date(), 1);
  const firstDateOfLastMonth = startOfMonth(lastMonth);
  const lastDateOfLastMonth = endOfMonth(lastMonth);

  const [tourOperatorsReportRange, setTourOperatorsReportRange] = useState({
    from: firstDateOfLastMonth,
    to: lastDateOfLastMonth,
  });

  useEffect(() => {
    async function getTourOperatorsReports() {
      setIsLoading(true);
      setTourOperatorsReport();
      try {
        toast({
          title: "Creazione report in corso...",
          description: "⌛ Attendere prego.",
        });
        const response = await axiosPrivate.get("/reports/touroperators", {
          params: tourOperatorsReportRange,
        });
        setTourOperatorsReport(response.data);
        toast({
          description: "✔️ Report generato correttamente.",
          variant: "confirmed",
        });
      } catch (err) {
        console.log(err);
        toast({
          title: "Qualcosa è andato storto...",
          description:
            "❌ Non è stato possibile generare un report per gli tourOperator.",
          variant: "destructive",
        });
      }
      setIsLoading(false);
    }

    getTourOperatorsReports();
  }, [tourOperatorsReportRange]);

  return (
    <div className="mx-auto max-w-7xl space-y-6">
      <Breadcrumb />
      <Alert>
        <AlertTitle>Attenzione</AlertTitle>
        <AlertDescription>
          Non considerare HOLIDOIT (importi già aggiunti nelle prenotazioni al
          netto delle loro tasse)
        </AlertDescription>
      </Alert>
      <div className="flex items-center justify-between">
        <div>
          <h2>Report sui TO</h2>
        </div>
      </div>
      <div className="space-y-3">
        <div className="flex items-center gap-1.5">
          <CalendarDatePicker
            date={tourOperatorsReportRange}
            onDateSelect={setTourOperatorsReportRange}
          />
        </div>
        <div className="font-medium">Passeggeri imbarcati tramite TO</div>
        {isLoading && <Loading />}
        {tourOperatorsReport && (
          <div className="space-y-6">
            {tourOperatorsReport.map((item) => {
              return (
                <Collapsible
                  key={item._id}
                  className="rounded border border-gray-200"
                >
                  <CollapsibleTrigger>
                    <div className="flex items-center justify-between p-3">
                      <div className="font-medium">
                        {item._id.toUpperCase()} - {item.totalAdults} adulti (
                        {formatCurrency.format(item.totalOwedAmountAdults)})
                        {!!item.totalKids &&
                          ` , ${item.totalKids} bambini (${formatCurrency.format(item.totalOwedAmountKids)})`}
                      </div>
                      <div>
                        <Icon name="ChevronDown" className="h-4 w-4" />
                      </div>
                    </div>
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <div className="space-y-1.5 bg-gray-50 p-1.5 text-sm lg:col-span-2">
                      {item.bookings[0]
                        .sort((a, b) => new Date(a.date) - new Date(b.date))
                        .map((booking) => {
                          return (
                            <Collapsible
                              key={booking._id}
                              className="rounded border border-gray-200 bg-white p-1.5"
                            >
                              <CollapsibleTrigger>
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center gap-3">
                                    <div className="w-16">
                                      {capitalize(
                                        format(
                                          new Date(booking.date),
                                          "dd MMM yy",
                                          { locale: it },
                                        ),
                                      )}
                                    </div>
                                    <div className="w-16 text-start font-medium">
                                      {booking.reference}
                                    </div>
                                    <div className="w-48 truncate text-start">
                                      {booking.organizerFirstName}{" "}
                                      {booking.organizerLastName}
                                    </div>
                                    <div className="flex items-center gap-1.5">
                                      <Icon name="User" className="h-3 w-3" />
                                      {booking.participantsAdults + 1} adulti
                                      {!!booking.participantsKids &&
                                        `, ${booking.participantsKids} bambini`}
                                    </div>
                                  </div>
                                  <div>
                                    <Link
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      to={`/bookings/${booking.bookingId}`}
                                      className="flex items-center gap-1.5 font-medium text-blue-700 transition-colors hover:text-blue-500"
                                    >
                                      Visualizza prenotazione
                                      <Icon
                                        name="ExternalLink"
                                        className="h-3 w-3"
                                      />
                                    </Link>
                                  </div>
                                </div>
                              </CollapsibleTrigger>
                              <CollapsibleContent className="flex items-center justify-between"></CollapsibleContent>
                            </Collapsible>
                          );
                        })}
                    </div>
                  </CollapsibleContent>
                </Collapsible>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default TourOperatorsReportPage;
