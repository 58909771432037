import { Route, Routes } from "react-router-dom";
import BoatPage from "./pages/Boats/BoatPage";
import BookingPage from "./components/bookings/BookingPage";
import ExpensePage from "./components/expenses/ExpensePage";
import DashboardLayout from "./components/layout/DashboardLayout";
import RootLayout from "./components/layout/RootLayout";
import TourOperatorReport from "./components/reports/TourOperatorReport";
import TourPage from "./components/tours/TourPage";
import PersistLogin from "./components/ui/PersistLogin";
import UserPage from "./components/users/UserPage";
import { ROLES } from "./config/roles";
import Boats from "./pages/Boats/Boats";
import Bookings from "./pages/Bookings";
import Expenses from "./pages/Expenses";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Missing from "./pages/Missing";
import NewBooking from "./pages/NewBooking";
import NewExpense from "./pages/NewExpense";
import Reports from "./pages/Reports";
import ReportsAnalytics from "./pages/Reports/Analytics";
import BoatsReportPage from "./pages/Reports/Boats";
import ExpensesReportPage from "./pages/Reports/Expenses";
import LuigiReportPage from "./pages/Reports/Luigi";
import SkippersReportPage from "./pages/Reports/Skippers";
import TourOperatorsReportPage from "./pages/Reports/TourOperators";
import RequireAuth from "./pages/RequireAuth";
import Tours from "./pages/Tours";
import Unauthorized from "./pages/Unauthorized";
import Users from "./pages/Users";
import Vouchers from "./pages/Vouchers";
import { PlanningPage } from "./pages/Planning";
import CreateBoatPage from "./pages/Boats/CreateBoatPage";
import TourOperatorListPage from "./pages/TourOperators/TourOperatorList";
import TourOperatorViewPage from "./pages/TourOperators/TourOperatorPage";
import RentalListPage from "./pages/RentalListPage";
import NewRentalPage from "./pages/NewRental";
import RentalPage from "./components/rentals/RentalPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<RootLayout />}>
        {/* public routes */}
        <Route path="login" element={<Login />} />
        <Route path="unauthorized" element={<Unauthorized />} />

        {/* we want to protect these routes */}
        <Route element={<PersistLogin />}>
          <Route element={<DashboardLayout />}>
            <Route element={<RequireAuth />}>
              <Route path="/" element={<Home />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[ROLES.Editor, ROLES.Admin, ROLES.Tech]}
                />
              }
            >
              <Route path="boats" element={<Boats />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[ROLES.Editor, ROLES.Admin, ROLES.Tech]}
                />
              }
            >
              <Route path="boats/new" element={<CreateBoatPage />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[ROLES.Editor, ROLES.Admin, ROLES.Tech]}
                />
              }
            >
              <Route path="boats/:id" element={<BoatPage />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.Skipper,
                    ROLES.TourOperator,
                    ROLES.Editor,
                    ROLES.Admin,
                    ROLES.Tech,
                  ]}
                />
              }
            >
              <Route path="bookings" element={<Bookings />} />
              <Route path="rentals" element={<RentalListPage />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.TourOperator,
                    ROLES.Editor,
                    ROLES.Admin,
                    ROLES.Tech,
                  ]}
                />
              }
            >
              <Route path="bookings/new" element={<NewBooking />} />
              <Route path="rentals/new" element={<NewRentalPage />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.Skipper,
                    ROLES.TourOperator,
                    ROLES.Editor,
                    ROLES.Admin,
                    ROLES.Tech,
                  ]}
                />
              }
            >
              <Route path="bookings/:id" element={<BookingPage />} />
              <Route path="rentals/:id" element={<RentalPage />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.TourOperator,
                    ROLES.Editor,
                    ROLES.Admin,
                    ROLES.Tech,
                  ]}
                />
              }
            >
              <Route path="reports" element={<Reports />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[ROLES.Editor, ROLES.Admin, ROLES.Tech]}
                />
              }
            >
              <Route path="reports/analytics" element={<ReportsAnalytics />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[ROLES.Editor, ROLES.Admin, ROLES.Tech]}
                />
              }
            >
              <Route
                path="reports/touroperators/:tourOperator"
                element={<TourOperatorReport />}
              />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[ROLES.Editor, ROLES.Admin, ROLES.Tech]}
                />
              }
            >
              <Route path="reports/skippers" element={<SkippersReportPage />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[ROLES.Editor, ROLES.Admin, ROLES.Tech]}
                />
              }
            >
              <Route path="reports/boats" element={<BoatsReportPage />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.TourOperator,
                    ROLES.Editor,
                    ROLES.Admin,
                    ROLES.Tech,
                  ]}
                />
              }
            >
              <Route
                path="reports/touroperators"
                element={<TourOperatorsReportPage />}
              />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[ROLES.Editor, ROLES.Admin, ROLES.Tech]}
                />
              }
            >
              <Route path="reports/expenses" element={<ExpensesReportPage />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[ROLES.Editor, ROLES.Admin, ROLES.Tech]}
                />
              }
            >
              <Route path="reports/luigi" element={<LuigiReportPage />} />
            </Route>

            <Route
              element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Tech]} />}
            >
              <Route path="users" element={<Users />} />
            </Route>

            <Route element={<RequireAuth />}>
              <Route path="users/new" element={<UserPage />} />
            </Route>

            <Route element={<RequireAuth />}>
              <Route path="users/:id" element={<UserPage />} />
            </Route>

            <Route
              element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Tech]} />}
            >
              <Route path="tours" element={<Tours />} />
            </Route>

            <Route
              element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Tech]} />}
            >
              <Route path="tours/:id" element={<TourPage />} />
            </Route>

            <Route
              element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Tech]} />}
            >
              <Route path="touroperators" element={<TourOperatorListPage />} />
            </Route>

            <Route
              element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Tech]} />}
            >
              <Route
                path="touroperators/new"
                element={<TourOperatorViewPage create />}
              />
            </Route>

            <Route
              element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Tech]} />}
            >
              <Route
                path="touroperators/:id"
                element={<TourOperatorViewPage />}
              />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.Skipper,
                    ROLES.Editor,
                    ROLES.Admin,
                    ROLES.Tech,
                  ]}
                />
              }
            >
              <Route path="expenses" element={<Expenses />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.Skipper,
                    ROLES.Editor,
                    ROLES.Admin,
                    ROLES.Tech,
                  ]}
                />
              }
            >
              <Route path="expenses/new" element={<NewExpense />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.Skipper,
                    ROLES.Editor,
                    ROLES.Admin,
                    ROLES.Tech,
                  ]}
                />
              }
            >
              <Route path="expenses/:id" element={<ExpensePage />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.Skipper,
                    ROLES.Editor,
                    ROLES.Admin,
                    ROLES.Tech,
                  ]}
                />
              }
            >
              <Route path="vouchers" element={<Vouchers />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.Skipper,
                    ROLES.Editor,
                    ROLES.Admin,
                    ROLES.Tech,
                  ]}
                />
              }
            >
              <Route path="planning" element={<PlanningPage />} />
            </Route>
          </Route>
        </Route>

        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;
