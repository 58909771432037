import { endOfMonth, format, parseISO, startOfMonth } from "date-fns";
import { it } from "date-fns/locale";
import {
  AnchorIcon,
  FerrisWheelIcon,
  GemIcon,
  MoonStarIcon,
  UsersIcon,
  XCircleIcon,
} from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";
import { CalendarDatePicker } from "../components/ui/EnhancedDatePicker/Index";
import { UNIFORMS } from "../config/aliases";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { capitalize, cn, generateWeekDates } from "../lib/utils";

const bookingsLink = (date, boatId, status) => {
  const params = [
    `date=${format(date, "yyyy-MM-dd")}`,
    boatId ? `boat=${boatId}` : null,
    `status=${status}`,
  ]
    .filter((param) => param !== null)
    .join("&");

  return `/bookings?${params}`;
};

const PlannerCalendar = ({ data, from, to, uniforms = false }) => {
  let weekDates = [];

  if (from !== undefined && to !== undefined) {
    weekDates = generateWeekDates(from, to);
  }

  return (
    <div className="grid-cols-7 rounded border border-gray-300 lg:grid">
      {weekDates.map((date, index) => {
        const dateString = format(date, "yyyy-MM-dd");
        const dayData = data?.find(
          (d) => format(parseISO(d.date), "yyyy-MM-dd") === dateString,
        );
        const dayOfWeek = format(date, "EEEE");
        const uniform = UNIFORMS[dayOfWeek];

        return (
          <div key={index} className="flex flex-col">
            <DayCard date={date} data={dayData} />
            {uniforms && (
              <div className="bg-gray-50 p-2 text-sm font-medium uppercase text-gray-600">
                {uniform}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const DayCard = ({ date, data }) => {
  const formattedDate = format(date, "EEE dd MMM", { locale: it });

  return (
    <div className="box-border flex h-full flex-col border-r border-dashed border-gray-200">
      <div className="bg-gray-100 transition-colors hover:bg-gray-200">
        <Link
          to={`/bookings?date=${format(date, "yyyy-MM-dd")}&status=confirmed`}
          className="w-full"
        >
          <div className="p-1 text-center font-medium">{formattedDate}</div>
        </Link>
      </div>
      <div className="flex h-full w-full flex-1 flex-col items-start gap-1 p-1">
        {data ? (
          data.sorties
            .sort((a, b) => a.boat.order - b.boat.order)
            .sort((a, b) => a.tour.order - b.tour.order)
            .map((sortie, idx) => (
              <Link
                className="w-full"
                key={idx}
                to={bookingsLink(date, sortie.boat?._id, "confirmed")}
              >
                <div
                  className={cn(
                    "flex-1 rounded border border-gray-200 p-1 transition-colors hover:bg-gray-100",
                    sortie.tour.type === "night" &&
                      "border-indigo-200 bg-slate-700 text-white transition-colors hover:bg-black",
                  )}
                >
                  <div className="flex items-center justify-between">
                    <div className="text-sm font-medium">
                      {sortie.boat?.name || "Barca mancante"}
                    </div>
                    <div className="flex items-center gap-1.5">
                      {sortie.totalParticipants >= sortie.boat?.seats &&
                        !sortie.bookingsDetails.find(
                          (item) => item.exclusive,
                        ) && (
                          <div className="text-xs font-medium text-orange-500">
                            FULL
                          </div>
                        )}
                      {sortie.tour.night && (
                        <MoonStarIcon className="h-4 w-4 text-blue-600" />
                      )}
                      {sortie.bookingsDetails.find(
                        (item) => item.exclusive,
                      ) && <GemIcon className="h-4 w-4 text-indigo-600" />}
                      {!sortie.skipper && (
                        <FerrisWheelIcon className="h-4 w-4 text-red-500" />
                      )}
                      {!sortie.boat?.port && (
                        <AnchorIcon className="h-4 w-4 text-red-500" />
                      )}
                    </div>
                  </div>
                  <div className="text-xs uppercase text-gray-400">
                    {sortie.boat?.port || "N/A"}
                  </div>
                  <div className="flex items-center gap-1">
                    <div className="inline-flex items-center gap-1 truncate text-xs">
                      <UsersIcon className="h-3 w-3" />
                      {sortie.totalParticipants}{" "}
                      {sortie.tour
                        ? ` - ${capitalize(sortie.tour.type)}`
                        : "N/A"}
                    </div>
                    {sortie.skipper && (
                      <div className="ml-auto text-xs text-gray-400">
                        {sortie.skipper.displayName.firstName}
                      </div>
                    )}
                  </div>
                </div>
              </Link>
            ))
        ) : (
          <div className="flex w-full flex-1 items-center justify-center p-1 py-6">
            <XCircleIcon className="h-8 w-8 text-gray-200" />
          </div>
        )}
      </div>
    </div>
  );
};

const PlanningPage = () => {
  const today = new Date();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [selectedRange, setSelectedRange] = useState({
    from: startOfMonth(today),
    to: endOfMonth(today),
  });

  const [data, setData] = useState();
  const axiosPrivate = useAxiosPrivate();

  useDeepCompareEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      axiosPrivate
        .get("/sorties/v2", {
          params: {
            startDate: selectedRange.from.toISOString(),
            endDate: selectedRange.to.toISOString(),
          },
        })
        .then((res) => setData(res.data))
        .catch((err) => {
          console.error(err);
          setIsError(true);
        })
        .finally(() => setIsLoading(false));
    };

    if (selectedRange.from !== undefined && selectedRange.to !== undefined) {
      fetchData();
    }
  }, [axiosPrivate, selectedRange]);

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold">Planning</h2>
      <div>
        <CalendarDatePicker
          date={selectedRange}
          onDateSelect={setSelectedRange}
        />
      </div>
      {isLoading && data === undefined && <div>Loading...</div>}
      {isError && <div>Errore, riprovare piu tardi</div>}
      {!isLoading && !isError && data !== undefined && (
        <PlannerCalendar
          data={data}
          from={selectedRange.from}
          to={selectedRange.to}
        />
      )}
    </div>
  );
};

export { DayCard, PlanningPage, PlannerCalendar as WeekCalendar };
