import { MinusIcon, PlusIcon } from "lucide-react";
import { Button } from "../../../ui/Button";

const ParticipantsSelect = ({ value, onValueChange }) => {
  const handleOperation = (category, operation) => {
    const maxLimit = 20;
    const minLimit = category === "adults" ? 1 : 0;

    if (operation === "sub" && value[category] > minLimit) {
      onValueChange({
        ...value,
        [category]: value[category] - 1,
      });
    } else if (operation === "add" && value[category] < maxLimit) {
      onValueChange({
        ...value,
        [category]: value[category] + 1,
      });
    }
  };

  return (
    <div className="grid grid-cols-2 gap-1">
      <p className="font-medium">Adulti</p>
      <div className="flex items-center gap-3">
        <Button
          type="button"
          variant="outline"
          size="icon"
          onClick={() => handleOperation("adults", "sub")}
        >
          <MinusIcon />
        </Button>
        <span className="w-8 text-center text-lg">{value.adults}</span>
        <Button
          type="button"
          variant="outline"
          size="icon"
          onClick={() => handleOperation("adults", "add")}
        >
          <PlusIcon />
        </Button>
      </div>
      <p className="font-medium">Ragazzi</p>
      <div className="flex items-center gap-3">
        <Button
          type="button"
          variant="outline"
          size="icon"
          onClick={() => handleOperation("kids", "sub")}
        >
          <MinusIcon />
        </Button>
        <span className="w-8 text-center text-lg">{value.kids}</span>
        <Button
          type="button"
          variant="outline"
          size="icon"
          onClick={() => handleOperation("kids", "add")}
        >
          <PlusIcon />
        </Button>
      </div>
    </div>
  );
};

export { ParticipantsSelect };
