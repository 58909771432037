export const sideBarNav = {
  All: [
    {
      title: "Home",
      href: "/",
      icon: "Home",
    },
  ],
  Skipper: [
    {
      title: "Spese",
      href: "/expenses",
      icon: "Euro",
      disabled: false,
    },
  ],
  TourOperator: [
    {
      title: "Prenotazioni",
      href: "/bookings",
      icon: "ClipboardList",
    },
    {
      title: "Report",
      href: "/reports",
      icon: "FileBarChart",
      disabled: false,
    },
    {
      title: "Noleggi",
      href: "/rentals",
      icon: "ClipboardList",
    },
  ],
  Editor: [
    {
      title: "Prenotazioni",
      href: "/bookings",
      icon: "ClipboardList",
    },
    {
      title: "Spese",
      href: "/expenses",
      icon: "Euro",
      disabled: false,
    },
    {
      title: "Report",
      href: "/reports",
      icon: "FileBarChart",
      disabled: false,
    },
    {
      title: "Voucher",
      href: "/vouchers",
      icon: "Ticket",
      disabled: false,
    },
    {
      title: "Planning ",
      href: "/planning",
      icon: "Calendar",
      disabled: false,
    },
    {
      title: "Noleggi",
      href: "/rentals",
      icon: "ClipboardList",
    },
  ],
  Admin: [
    {
      title: "Prenotazioni",
      href: "/bookings",
      icon: "ClipboardList",
    },
    {
      title: "Report",
      href: "/reports",
      icon: "FileBarChart",
      disabled: false,
    },
    {
      title: "Flotta",
      href: "/boats",
      icon: "Ship",
    },
    {
      title: "Utenti",
      href: "/users",
      icon: "Users",
    },
    {
      title: "Spese",
      href: "/expenses",
      icon: "Euro",
      disabled: false,
    },
    {
      title: "Voucher",
      href: "/vouchers",
      icon: "Ticket",
      disabled: false,
    },
    {
      title: "Planning ",
      href: "/planning",
      icon: "Calendar",
      disabled: false,
    },
    {
      title: "Noleggi",
      href: "/rentals",
      icon: "ClipboardList",
    },
  ],
  Tech: [
    {
      title: "Prenotazioni",
      href: "/bookings",
      icon: "ClipboardList",
    },
    {
      title: "Report",
      href: "/reports",
      icon: "FileBarChart",
    },
    {
      title: "Utenti",
      href: "/users",
      icon: "Users",
    },
    {
      title: "Planning",
      href: "/planning",
      icon: "Calendar",
      disabled: false,
    },
    {
      title: "Spese",
      href: "/expenses",
      icon: "Euro",
      disabled: false,
    },
    {
      title: "Voucher",
      href: "/vouchers",
      icon: "Ticket",
      disabled: false,
    },
    {
      title: "TO",
      href: "/touroperators",
      icon: "Briefcase",
      disabled: false,
    },
    {
      title: "Flotta",
      href: "/boats",
      icon: "Sailboat",
      disabled: false,
    },
  ],
};
