import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "../../components/ui/UseToast";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Checkbox from "../../components/ui/Checkbox";
import { Label } from "../../components/ui/Label";
import { Input } from "../../components/ui/Input";
import Breadcrumb from "../../components/ui/Breadcrumb";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/Select";

const tourOperatorSchema = z.object({
  name: z.string().nonempty("Name is required"),
  userId: z.string().optional(),
  allowedVehicles: z.array(z.string()),
  quotas: z
    .object({
      adults: z
        .preprocess(
          (val) => parseFloat(val),
          z
            .number()
            .min(0, "Adults quota must be at least 0")
            .min(0, "Adults quota must be at least 0")
            .default(10),
        )
        .optional(),
      kids: z
        .preprocess(
          (val) => parseFloat(val),
          z.number().min(0, "Kids quota must be at least 0").default(10),
        )
        .optional(),
    })
    .refine(
      (data) => data.adults > 0 && data.kids > 0,
      "Both quotas must be positive",
    ),
});

const SelectUser = ({ value, onValueChange }) => {
  const [tourOperatorUsers, setTourOperatorUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const api = useAxiosPrivate();

  useEffect(() => {
    setIsLoading(true);
    api
      .get("/users/touroperators")
      .then((response) => {
        setTourOperatorUsers(response.data);
      })
      .catch((error) => {
        console.error("Error loading TOs:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger>
        <SelectValue placeholder="Seleziona un utente" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem>Nessuno</SelectItem>
        {tourOperatorUsers.map((item) => (
          <SelectItem key={item._id} value={item._id}>
            {item.displayName.firstName} {item.displayName.lastName} -{" "}
            <span className="text-gray-500">{item._id}</span>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

const SelectAllowedVehicles = () => {
  const { control, watch } = useFormContext();
  const [boats, setBoats] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const selectedVehicles = watch("allowedVehicles", []);

  const api = useAxiosPrivate();

  useEffect(() => {
    setIsLoading(true);
    api
      .get("/boats")
      .then((response) => {
        setBoats(response.data);
      })
      .catch((error) => {
        console.error("Error loading boats:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="grid gap-6 md:grid-cols-2">
      {isLoading ? (
        <div>Loading boats...</div>
      ) : (
        <>
          <div className="space-y-2">
            <Label>Standard (escursioni)</Label>
            {boats
              .filter((item) => !item.rentable)
              .map((boat) => (
                <Controller
                  key={boat._id}
                  name="allowedVehicles"
                  control={control}
                  render={({ field }) => (
                    <div className="flex items-center">
                      <Checkbox
                        className="h-4 w-4 rounded border-gray-300 text-slate-600 focus:ring-slate-500"
                        checked={selectedVehicles.includes(boat._id)}
                        onCheckedChange={(isChecked) => {
                          if (isChecked) {
                            field.onChange([...field.value, boat._id]);
                          } else {
                            field.onChange(
                              field.value.filter((id) => id !== boat._id),
                            );
                          }
                        }}
                        id={`boat-${boat._id}`}
                      />

                      <label
                        htmlFor={`boat-${boat._id}`}
                        className="ml-3 text-sm text-gray-700"
                      >
                        {boat.name}
                      </label>
                    </div>
                  )}
                />
              ))}
          </div>
          <div className="space-y-2">
            <Label>Noleggio</Label>
            {boats
              .filter((item) => item.rentable)
              .map((boat) => (
                <Controller
                  key={boat._id}
                  name="allowedVehicles"
                  control={control}
                  render={({ field }) => (
                    <div className="flex items-center">
                      <Checkbox
                        className="h-4 w-4 rounded border-gray-300 text-slate-600 focus:ring-slate-500"
                        checked={selectedVehicles.includes(boat._id)}
                        onCheckedChange={(isChecked) => {
                          if (isChecked) {
                            field.onChange([...field.value, boat._id]);
                          } else {
                            field.onChange(
                              field.value.filter((id) => id !== boat._id),
                            );
                          }
                        }}
                        id={`boat-${boat._id}`}
                      />

                      <label
                        htmlFor={`boat-${boat._id}`}
                        className="ml-3 text-sm text-gray-700"
                      >
                        {boat.name}
                      </label>
                    </div>
                  )}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};

const TourOperatorView = ({ data, create }) => {
  const methods = useForm({
    defaultValues: {
      name: data?.name || "",
      userId: data?.userId || "",
      allowedVehicles: data?.allowedVehicles || [],
      quotas: {
        adults: data?.quotas?.adults || 10,
        kids: data?.quotas?.kids || 10,
      },
    },
    resolver: zodResolver(tourOperatorSchema),
  });

  const api = useAxiosPrivate();

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = methods;
  const [isSaving, setIsSaving] = useState(false);

  const navigate = useNavigate();

  const onSubmit = (values) => {
    setIsSaving(true);
    const request = create
      ? api.post("/touroperators", values)
      : api.patch(`/touroperators/${data._id}`, values);

    request
      .then((res) => {
        toast({ title: "TO Aggiornato con successo" });
        create && navigate(`/touroperators/${res.data._id}`);
      })
      .catch((error) => {
        console.error("Error saving tour operator:", error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <div className="mx-auto max-w-7xl space-y-6">
      <Breadcrumb />
      <h2>{create ? "Crea nuovo T.O" : `Modifica T.O.: ${data.name}`}</h2>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid gap-6 md:grid-cols-2">
            <div>
              <Label htmlFor="name">Name</Label>
              <Input type="text" id="name" {...register("name")} />
              {errors.name && (
                <p className="mt-2 text-sm text-red-600">
                  {errors.name.message}
                </p>
              )}
            </div>

            <div>
              <Label htmlFor="userId">User ID</Label>
              <Controller
                control={methods.control}
                name="userId"
                render={({ field }) => (
                  <SelectUser
                    value={field.value}
                    onValueChange={field.onChange}
                  />
                )}
              />
              {errors.userId && (
                <p className="mt-2 text-sm text-red-600">
                  {errors.userId.message}
                </p>
              )}
            </div>

            <div className="col-span-2 space-y-2">
              <Label htmlFor="allowedVehicles">Imbarcazioni abilitate</Label>
              <SelectAllowedVehicles />
              {errors.allowedVehicles && (
                <p className="mt-2 text-sm text-red-600">
                  {errors.allowedVehicles.message}
                </p>
              )}
            </div>

            <div>
              <Label htmlFor="adults">Adults Quota</Label>
              <Input type="number" id="adults" {...register("quotas.adults")} />
              {errors.quotas?.adults && (
                <p className="mt-2 text-sm text-red-600">
                  {errors.quotas.adults.message}
                </p>
              )}
            </div>

            <div>
              <Label htmlFor="kids">Kids Quota</Label>
              <Input type="number" id="kids" {...register("quotas.kids")} />
              {errors.quotas?.kids && (
                <p className="mt-2 text-sm text-red-600">
                  {errors.quotas.kids.message}
                </p>
              )}
            </div>
          </div>
          <div className="mt-6 flex justify-end">
            <button
              type="submit"
              disabled={isSaving}
              className={`inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm ${
                isSaving
                  ? "cursor-not-allowed bg-gray-400"
                  : "bg-slate-600 hover:bg-slate-700"
              } focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2`}
            >
              {isSaving ? "Salvando..." : "Salva"}
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

const TourOperatorViewPage = ({ create }) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState();
  const [data, setData] = useState();

  const api = useAxiosPrivate();

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(false);
      api
        .get(`/touroperators/${id}`)
        .then((res) => setData(res.data))
        .catch((err) => {
          console.error(err);
          toast({ title: "Errore durante il caricamento" });
        })
        .finally(() => setIsLoading(false));
    };

    if (!create) {
      fetchData();
    }
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (data !== undefined && !create) {
    return <TourOperatorView data={data} />;
  }

  if (create) {
    return <TourOperatorView create />;
  }
};

export default TourOperatorViewPage;
