import React, { useState } from "react";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Breadcrumb from "../ui/Breadcrumb";

const TourView = ({ tour }) => {
  const axiosPrivate = useAxiosPrivate();
  const [isSaving, setIsSaving] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      displayName: tour.displayName || null,
      image: {
        thumbnail: {
          src: tour.image.thumbnail.src || null,
        },
        large: {
          src: tour.image.thumbnail.src || null,
        },
      },
    },
  });

  async function onSubmit(data) {
    setIsSaving(true);

    try {
      const updateTour = await axiosPrivate.patch(`/tours/${tour._id}`, {
        data,
      });
    } catch (err) {
      console.err("There was an error updating the profile");
      setIsSaving(false);
    }

    setTimeout(() => {
      setIsSaving(false);
    }, 750);
  }

  async function handleDelete() {
    setIsSaving(true);
    try {
      // TODO
    } catch (err) {
      console.log("There was an error deleting the profile");
      setIsSaving(false);
    }
    setTimeout(() => {
      setIsSaving(false);
    }, 750);
  }

  return (
    <div className="space-y-6">
      <Breadcrumb />
      <div className="space-y-3 border border-gray-300 bg-white px-6 py-4 shadow lg:rounded">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-semibold leading-6 text-gray-900">
            Dettagli tour: {tour.name}
          </h1>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid gap-6 border-b border-gray-300 py-6 lg:grid-cols-2">
            <div>
              <div>
                <h2
                  id="payment-details-heading"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Visualizzazione
                </h2>
                <p className="mt-1 text-sm text-gray-500">
                  Aggiorna le informazioni generali.
                </p>
              </div>
            </div>
            <div className="grid gap-3 lg:grid-cols-2">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Nome completo
                </label>
                <input
                  type="text"
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
                  {...register("displayName", {
                    required: true,
                    minLength: 1,
                    maxLength: 24,
                  })}
                />
              </div>
            </div>
          </div>
          <div className="grid gap-6 border-b border-gray-300 py-6 lg:grid-cols-2">
            <div>
              <div>
                <h2
                  id="payment-details-heading"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Prezzi
                </h2>
              </div>
            </div>
            <div className="grid grid-cols-3 divide-x divide-gray-200 border border-gray-200">
              <div className="flex flex-col divide-y divide-gray-200">
                <div className="flex h-16 items-center justify-center"></div>
                <div className="flex h-16 items-center justify-center">
                  Bassa
                </div>
                <div className="flex h-16 items-center justify-center">
                  Alta
                </div>
                <div className="flex h-16 items-center justify-center">
                  Ferragosto
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex h-16 flex-col justify-between border-b border-gray-200">
                  <div className="flex items-center justify-center">
                    Gommone
                  </div>
                  <div className="grid grid-cols-2 divide-x divide-gray-200">
                    <div className="text-center">Bambini</div>
                    <div className="text-center">Adulti</div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex h-16 flex-col justify-between border-b border-gray-200">
                  <div className="flex items-center justify-center">Barca</div>
                  <div className="grid grid-cols-2 divide-x divide-gray-200">
                    <div className="text-center">Bambini</div>
                    <div className="text-center">Adulti</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid gap-6 border-b border-gray-300 py-6 lg:grid-cols-2">
            <div>
              <div>
                <h2
                  id="payment-details-heading"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Immagini
                </h2>
                <p className="mt-1 text-sm text-gray-500">
                  Imposta le immagini.
                </p>
              </div>
            </div>
            <div className="grid gap-3 lg:grid-cols-2">
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Anteprima
                </label>
                <input
                  type="text"
                  placeholder="https:// ... .png/.jpeg"
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
                  {...register("image.thumbnail.src")}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Larga
                </label>
                <input
                  type="text"
                  placeholder="https:// ... .png/.jpeg"
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
                  {...register("image.large.src")}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end pt-3">
            <button
              onClick={handleDelete}
              className={`${isSaving ? "cursor-not-allowed bg-red-200" : "bg-red-600 hover:bg-red-700"} relative ml-4 inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm transition focus:outline-none`}
              disabled={isSaving}
            >
              Elimina
            </button>
            <button
              type="submit"
              disabled={isSaving}
              className={`${isSaving ? "cursor-not-allowed bg-slate-200" : "bg-slate-600 hover:bg-slate-700"} relative ml-4 inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm transition`}
            >
              Salva
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TourView;
