import { cva } from "class-variance-authority";

const statusBadgeVariants = cva(
  "inline-flex gap-0.5 items-center rounded border font-medium text-xs px-1",
  {
    variants: {
      variant: {
        default: "bg-slate-700 border-slate-700/75 text-white",
        positive: "bg-green-200 border-green-500/90 text-green-700",
        negative: "bg-red-700 border-red-700/90 text-red-700",
        warning: "bg-yellow-200 border-yellow-200/90 text-yellow-700",
        confirm: "bg-blue-700 border-blue-700/90",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

const StatusBadge = ({ status }) => {
  switch (status) {
    case 0:
      return (
        <span
          className={`cursor-default truncate rounded bg-gray-400 px-2 py-1 text-xs font-bold uppercase text-gray-600`}
        >
          Archiviata
        </span>
      );
    case 100:
      return (
        <span
          className={`cursor-default truncate rounded bg-gray-200 px-2 py-1 text-xs font-bold uppercase text-gray-600`}
        >
          In attesa di pagamento
        </span>
      );
    case 200:
      return (
        <span
          className={`cursor-default truncate rounded bg-sky-200 px-2 py-1 text-xs font-bold uppercase text-sky-800`}
        >
          Attesa info
        </span>
      );
    case 300:
      return (
        <span
          className={`cursor-default truncate rounded bg-yellow-200 px-2 py-1 text-xs font-bold uppercase text-yellow-800`}
        >
          In coda
        </span>
      );
    case 400:
      return (
        <span
          className={`cursor-default truncate rounded bg-green-100 px-2 py-1 text-xs font-bold uppercase text-green-800`}
        >
          Confermata
        </span>
      );
    case 500:
      return (
        <span
          className={`cursor-default truncate rounded bg-orange-100 px-2 py-1 text-xs font-bold uppercase text-orange-800`}
        >
          Cancellata
        </span>
      );
    case 600:
      return (
        <span
          className={`cursor-default truncate rounded bg-gray-400 px-2 py-1 text-xs font-bold uppercase text-white`}
        >
          Annullata
        </span>
      );
    case 700:
      return (
        <span
          className={`cursor-default truncate rounded bg-slate-700 px-2 py-1 text-xs font-bold uppercase text-white`}
        >
          Conclusa
        </span>
      );
    default:
      return null;
  }
};

export { StatusBadge, statusBadgeVariants };
