import { Link } from "react-router-dom";
import { Alert, AlertDescription, AlertTitle } from "../components/ui/Alert";
import Icon from "../components/ui/Icon";
import useAuth from "../hooks/useAuth";

const Reports = () => {
  const { auth } = useAuth();
  return (
    <div className="mx-auto max-w-7xl space-y-6">
      <h2>Report</h2>
      <Alert variant="informational">
        <AlertTitle>Attenzione</AlertTitle>
        <AlertDescription>
          La generazione dei report è un processo che potrebbe richiedere fino a
          un paio di minuti.
        </AlertDescription>
      </Alert>
      <Alert variant="destructive">
        <AlertTitle>
          Ci sono dei problemi relativi alla contabilizzazione.
        </AlertTitle>
        <AlertDescription>
          Alcune prenotazioni di uscite già effettuate ad oggi risultano avere
          bilancio non nullo. Visualizza la{" "}
          <Link className="underline" to="/reports/analytics">
            lista completa
          </Link>
          .
        </AlertDescription>
      </Alert>
      <div className="grid gap-6 lg:grid-cols-3">
        {(auth?.roles.includes(5000) || auth?.roles.includes(2500)) && (
          <Link to="/reports/skippers">
            <div className="rounded border border-gray-200 p-3">
              <div className="h-[120px] space-y-3">
                <div className="flex items-center gap-1.5 font-medium text-slate-700">
                  <Icon name="FerrisWheel" className="h-6 w-6" />
                  Skipper
                </div>
                <div className="text-sm">
                  Visualizza le giornate degli skipper.
                </div>
              </div>
            </div>
          </Link>
        )}
        {(auth?.roles.includes(5000) || auth?.roles.includes(2500)) && (
          <Link to="/reports/boats">
            <div className="rounded border border-gray-200 p-3">
              <div className="h-[120px] space-y-3">
                <div className="flex items-center gap-1.5 font-medium text-slate-700">
                  <Icon name="Ship" className="h-6 w-6" />
                  Imbarcazioni
                </div>
                <div className="text-sm">
                  Visualizza le uscite e i dettagli finanziari dei mezzi.
                </div>
              </div>
            </div>
          </Link>
        )}
        {(auth?.roles.includes(5000) ||
          auth?.roles.includes(2500) ||
          auth?.roles.includes(2000)) && (
          <Link to="/reports/touroperators">
            <div className="rounded border border-gray-200 p-3">
              <div className="h-[120px] space-y-3">
                <div className="flex items-center gap-1.5 font-medium text-slate-700">
                  <Icon name="Briefcase" className="h-6 w-6" />
                  Tour Operator
                </div>
                <div className="text-sm">Rendiconto dei TO.</div>
              </div>
            </div>
          </Link>
        )}
        {(auth?.roles.includes(5000) || auth?.roles.includes(2500)) && (
          <Link to="/reports/expenses">
            <div className="rounded border border-gray-200 p-3">
              <div className="h-[120px] space-y-3">
                <div className="flex items-center gap-1.5 font-medium text-slate-700">
                  <Icon name="Fuel" className="h-6 w-6" />
                  Carburante
                </div>
                <div className="text-sm">
                  Report sulle spese per il carburante.
                </div>
              </div>
            </div>
          </Link>
        )}
        {(auth?.roles.includes(5000) || auth?.roles.includes(7777)) && (
          <Link to="/reports/luigi">
            <div className="rounded border border-gray-200 p-3">
              <div className="h-[120px] space-y-3">
                <div className="flex items-center gap-1.5 font-medium text-slate-700">
                  <Icon name="Anchor" className="h-6 w-6" />
                  Luigi
                </div>
              </div>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Reports;
