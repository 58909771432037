import { it } from "date-fns/locale";
import { DayPicker } from "react-day-picker";

import { cn } from "../../lib/utils";
import Icon from "./Icon";
import { buttonVariants } from "./Button";

function Calendar({ className, classNames, showOutsideDays = true, ...props }) {
  const disabledDays = [
    { from: new Date("2024-10-30"), to: new Date("2024-12-31") },
  ];

  return (
    <DayPicker
      disabled={disabledDays}
      locale={it}
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-sm font-medium",
        nav: "space-x-1 flex items-center",
        nav_button: cn(
          buttonVariants({ variant: "outline" }),
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
        ),
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell: "text-gray-500 rounded-md w-8 font-normal text-[0.8rem]",
        row: "flex w-full mt-2",
        cell: cn(
          "relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-gray-500 [&:has([aria-selected].day-outside)]:bg-gray-500/50 [&:has([aria-selected].day-range-end)]:rounded-r-md",
          props.mode === "range"
            ? "[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md"
            : "[&:has([aria-selected])]:rounded-md",
        ),
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "h-8 w-8 p-0 font-normal aria-selected:opacity-100",
        ),
        day_range_start: "bg-gray-500 text-white day-range-start",
        day_range_end: "bg-gray-500 text-white day-range-end",
        day_selected:
          "bg-slate-700 text-white hover:bg-slate-700 hover:text-slate-700 focus:bg-slate-700 focus:text-white",
        day_today: "border text-accent",
        day_outside:
          "day-outside text-gray-500 opacity-50  aria-selected:bg-gray-500/50 aria-selected:text-gray-500 aria-selected:opacity-30",
        day_disabled: "text-gray-500 opacity-50",
        day_range_middle:
          "aria-selected:bg-gray-500/25 aria-selected:text-accent",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => (
          <Icon name="ChevronLeft" className="h-4 w-4" {...props} />
        ),
        IconRight: ({ ...props }) => (
          <Icon name="ChevronRight" className="h-4 w-4" {...props} />
        ),
      }}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
