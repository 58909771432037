import { addDays, format, parseISO, startOfDay } from "date-fns";
import { it } from "date-fns/locale";
import { UsersIcon, XCircleIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";
import ErrorState from "../components/layout/ErrorState";
import LoadingState from "../components/layout/LoadingState";
import { rentalColumns } from "../components/rentals/Columns";
import RentalListView from "../components/rentals/RentalListView";
import Breadcrumb from "../components/ui/Breadcrumb";
import { Button } from "../components/ui/Button";
import { DatePicker } from "../components/ui/DatePicker";
import { CalendarDatePicker } from "../components/ui/EnhancedDatePicker/Index";
import { Input } from "../components/ui/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/Select";
import { toast } from "../components/ui/UseToast";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { calculateTimezonedDate, generateWeekDates } from "../lib/utils";

const BoatItem = ({ data }) => {
  return (
    <Link
      to={`/rentals/${data._id}`}
      className="flex w-full flex-1 gap-2 rounded border border-gray-200 p-1 transition-colors hover:bg-gray-100"
    >
      <div className="flex flex-col justify-between py-1">
        <div className="text-xs">
          {format(new Date(data.fromTime), "HH:mm")}
        </div>
        <div className="text-xs"> {format(new Date(data.toTime), "HH:mm")}</div>
      </div>
      <div className="flex-1 truncate">
        <div className="flex items-center justify-between">
          <div className="truncate text-sm font-medium">{data.displayName}</div>
        </div>
        <div className="flex items-center justify-between">
          <div className="text-xs uppercase">{data.reference}</div>
          <div className="text-xs uppercase text-gray-400">{data.portName}</div>
        </div>
        <div className="flex items-center justify-between">
          <div className="inline-flex items-center gap-1 truncate text-xs">
            <UsersIcon className="h-3 w-3" />
            {data.totalParticipants}
          </div>
        </div>
      </div>
    </Link>
  );
};

const DayColumn = ({ date, data }) => {
  const formattedDate = format(date, "EEE dd MMM", { locale: it });

  return (
    <div className="box-border flex flex-col border-r border-dashed border-gray-200">
      <div className="bg-gray-100 transition-colors hover:bg-gray-200">
        <div className="p-1 text-center font-medium">{formattedDate}</div>
      </div>
      <div className="flex h-full w-full flex-1 flex-col items-start gap-1 p-1">
        {data ? (
          data.rentals.map((rental) => (
            <BoatItem key={data._id} data={rental} />
          ))
        ) : (
          <div className="flex w-full flex-1 items-center justify-center p-1 py-6">
            <XCircleIcon className="h-8 w-8 text-gray-200" />
          </div>
        )}
      </div>
    </div>
  );
};

const RentalsWeekView = ({ data, from, to }) => {
  let weekDates = [];

  if (from !== undefined && to !== undefined) {
    weekDates = generateWeekDates(from, to);
  }

  return (
    <div className="rounded border border-gray-300 lg:grid lg:grid-cols-7">
      {weekDates.map((date, index) => {
        const dateString = format(date, "yyyy-MM-dd");
        const dayData = data?.find(
          (d) => format(parseISO(d._id), "yyyy-MM-dd") === dateString,
        );
        return (
          <div key={index} className="flex flex-col">
            <DayColumn date={date} data={dayData} />
          </div>
        );
      })}
    </div>
  );
};

const RentalsPlanner = () => {
  const today = startOfDay(new Date());

  const [isLoading, setIsLoading] = useState();
  const [isError, setIsError] = useState(false);

  const [data, setData] = useState();

  const [selectedRange, setSelectedRange] = useState({
    from: today,
    to: addDays(today, 6),
  });

  const axiosPrivate = useAxiosPrivate();

  useDeepCompareEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      axiosPrivate
        .get("/rentals/calendar", {
          params: {
            from: calculateTimezonedDate(selectedRange.from).toISOString(),
            to: calculateTimezonedDate(selectedRange.to).toISOString(),
          },
        })
        .then((res) => setData(res.data))
        .catch((err) => {
          console.error(err);
          setIsError(true);
        })
        .finally(() => setIsLoading(false));
    };

    if (selectedRange.from !== undefined && selectedRange.to !== undefined) {
      fetchData();
    }
  }, [axiosPrivate, selectedRange]);

  return (
    <div className="space-y-3">
      <CalendarDatePicker
        date={selectedRange}
        onDateSelect={setSelectedRange}
      />
      {isLoading && data === undefined && <div>Loading...</div>}
      {isError && <div>Errore, riprovare piu tardi</div>}
      {!isLoading && !isError && data !== undefined && (
        <>
          <div className="text-lg font-medium">🛥️ Yacht</div>
          <RentalsWeekView
            data={data.yacht}
            from={selectedRange.from}
            to={selectedRange.to}
          />
          <div className="text-lg font-medium">🚤 Altro</div>

          <RentalsWeekView
            data={data.other}
            from={selectedRange.from}
            to={selectedRange.to}
          />
        </>
      )}
    </div>
  );
};

function serializeFilters(filters) {
  const serialized = {};
  Object.keys(filters)
    .sort()
    .forEach((key) => {
      if (filters[key] !== undefined && filters[key] !== "") {
        // Check if the value is undefined
        serialized[key] = filters[key];
      }
    });
  return serialized;
}

const RentalBoatsSelect = ({ value, onValueChange }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const api = useAxiosPrivate();

  useEffect(() => {
    setIsLoading(true);
    api
      .get("/boats", {
        params: {
          rentable: true,
          displayDeleted: true,
        },
      })
      .then((res) => setData(res.data))
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Select value={value} onValueChange={onValueChange} disabled={isLoading}>
      <SelectTrigger>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectItem>Nessuna</SelectItem>
        {data.map((item) => (
          <SelectItem key={item._id} value={item._id}>
            {item.displayName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

const RentalsFilters = ({ onValueChange }) => {
  const handleFilterUpdate = (name, value) => {
    onValueChange({ [name]: value });
  };

  return (
    <div className="grid gap-3 lg:grid-cols-3">
      <Input
        type="text"
        name="search"
        placeholder="Nominativo"
        onChange={(e) => handleFilterUpdate("search", e.target.value)}
      />
      <RentalBoatsSelect
        onValueChange={(value) => handleFilterUpdate("boat", value)}
      />
      <DatePicker
        onValueChange={(value) =>
          handleFilterUpdate(
            "date",
            value ? value.toISOString().split("T")[0] : undefined,
          )
        }
      />
    </div>
  );
};

const RentalListPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});

  const api = useAxiosPrivate();
  const navigate = useNavigate();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setIsLoading(true);
      setIsError(false);

      const cleanedFilters = serializeFilters(filters);
      const params = new URLSearchParams(cleanedFilters);

      api
        .get(`/rentals`, { params })
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.error(err);
          setIsError(true);
          toast({
            title: "Problema durante il caricamento dei noleggi.",
          });
        })
        .finally(() => setIsLoading(false));
    }, 750);

    return () => clearTimeout(delayDebounceFn);
  }, [filters]);

  return (
    <div className="space-y-6">
      <Breadcrumb />
      <div className="flex items-center justify-between">
        <h2>Noleggi</h2>
        <Button onClick={() => navigate("new")}>Aggiungi</Button>
      </div>
      <RentalsPlanner />
      <RentalsFilters
        onValueChange={(value) => setFilters({ ...filters, ...value })}
      />
      {isLoading ? (
        <LoadingState />
      ) : isError ? (
        <ErrorState />
      ) : (
        <RentalListView columns={rentalColumns} data={data} />
      )}
    </div>
  );
};

export default RentalListPage;
