import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import LoadingState from "../layout/LoadingState";
import ErrorState from "../layout/ErrorState";
import Breadcrumb from "../ui/Breadcrumb";
import RentalView from "./RentalView";
import { Alert, AlertDescription, AlertTitle } from "../ui/Alert";

const RentalPage = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState();

  const api = useAxiosPrivate();

  const fetchData = () => {
    setIsError(false);
    setIsLoading(true);
    api
      .get(`/rentals/${id}`)
      .then((res) => setData(res.data))
      .catch((err) => {
        console.error(err);
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="mx-auto max-w-7xl space-y-6">
      <Alert>
        <AlertTitle>Attenzione</AlertTitle>
        <AlertDescription>
          La pagina si riaggiornerà qualora verranno fatti cambiamenti alla
          contabilità del noleggio. Questo comportamento verrà risolto
          nell&#39;aggiornamento successivo. Le informazioni vengono comunque
          salvate.
        </AlertDescription>
      </Alert>
      <Breadcrumb />
      {isLoading ? (
        <LoadingState />
      ) : isError ? (
        <ErrorState />
      ) : data ? (
        <RentalView data={data} callbackFn={fetchData} />
      ) : (
        <ErrorState />
      )}
    </div>
  );
};

export default RentalPage;
