import { format } from "date-fns";
import { it } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { capitalize, cn } from "../../lib/utils";
import Breadcrumb from "../ui/Breadcrumb";
import { buttonVariants } from "../ui/Button";
import Icon from "../ui/Icon";
import Loading from "../ui/Loading";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/Popover";
import { Calendar } from "../ui/RangeCalendar";

const TourOperatorReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { tourOperator } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const axiosPrivate = useAxiosPrivate();

  const [bookings, setBookings] = useState();

  const [reportRange, setReportRange] = useState({
    from: new Date(startDate),
    to: new Date(endDate),
  });
  const [reportFilters, setReportFilters] = useState({
    startDate: startDate,
    endDate: endDate,
  });

  const handleReportRangeChange = (newValue) => {
    if (newValue) {
      setReportRange(newValue);
      if (newValue.from && newValue.to) {
        setReportFilters({
          ...reportFilters,
          startDate: format(new Date(newValue.from), "yyyy-MM-dd"),
          endDate: format(new Date(newValue.to), "yyyy-MM-dd"),
        });
      }
    } else {
      setReportRange();
      setReportFilters();
    }
  };

  const refreshData = async () => {
    setIsLoading(true);
    setBookings();
    try {
      const response = await axiosPrivate.get(
        `/reports/touroperators/${tourOperator}`,
        { params: reportFilters },
      );
      setBookings(response.data);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      try {
        const response = await axiosPrivate.get(
          `/reports/touroperators/${tourOperator}`,
          { params: reportFilters },
        );
        setBookings(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    getData();

    setIsLoading(false);
  }, []);

  return (
    <div className="mx-auto max-w-7xl space-y-6">
      <Breadcrumb />
      <div className="flex items-center justify-between">
        <h2 className="inline-flex items-center">
          Report per {tourOperator.toUpperCase()}
        </h2>
        <div>
          <Popover>
            <PopoverTrigger asChild>
              <button
                className={cn(
                  "inline-flex items-center gap-1 rounded border border-gray-200 px-2 py-1 text-xs font-medium",
                )}
              >
                <Icon name="Calendar" className="h-3 w-3 text-gray-400" />
                {!reportRange?.from && !reportRange?.to && "Periodo"}
                {reportRange?.from &&
                  format(new Date(reportRange.from), "MMM dd", { locale: it })}
                {reportRange?.from && reportRange?.to && " - "}
                {reportRange?.to &&
                  format(new Date(reportRange.to), "MMM dd", { locale: it })}
              </button>
            </PopoverTrigger>
            <PopoverContent className="mt-3 w-auto p-0">
              <Calendar
                mode="range"
                selected={reportRange}
                onSelect={handleReportRangeChange}
              />
              <div className="p-3">
                <button
                  className={cn("w-full", buttonVariants())}
                  onClick={refreshData}
                >
                  Cerca
                </button>
              </div>
            </PopoverContent>
          </Popover>
        </div>
      </div>
      {isLoading && <Loading />}
      {bookings && (
        <div className="space-y-3">
          <div>
            <h3>Prenotazioni</h3>
          </div>
          <div className="grid grid-cols-4 gap-3">
            {bookings.map((booking) => {
              return (
                <Link
                  key={booking._id}
                  to={`/bookings/${booking._id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="rounded border border-gray-200 p-3 transition-colors hover:bg-gray-50">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-1.5 font-medium">
                        {booking.reference}
                      </div>
                      <div className="flex items-center gap-1.5 text-sm">
                        <Icon name="Users" className="h-4 w-4 text-gray-400" />
                        {booking.participants}
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div>{capitalize(booking.tour)}</div>
                      <div>
                        {format(new Date(booking.date), "dd MMM", {
                          locale: it,
                        })}
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default TourOperatorReport;
