import React, { useState } from "react";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "../ui/UseToast";
import { buttonVariants } from "./Button";

const NoteForm = ({ id, entity, refreshFn }) => {
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  async function onSubmit(data) {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.post(`/${entity}s/${id}/notes`, {
        content: data.content,
      });
      refreshFn();
      toast({
        description: "✔️ Nota aggiunta.",
        variant: "confirmed",
      });
    } catch (err) {
      console.log(err);
      toast({
        title: "Qualcosa è andato storto...",
        description: "❌ Non è stato possibile aggiungere la nota.",
        variant: "destructive",
      });
    }
    setIsLoading(false);
    reset();
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4 w-full border border-gray-200 bg-gray-50">
          <div className="bg-white p-3">
            <textarea
              rows="4"
              className="w-full border-0 bg-white px-0 text-sm text-gray-900 focus:outline-none"
              placeholder="Scrivi una nota..."
              {...register("content")}
              required
            />
          </div>
          <div className="flex items-center justify-end border-t px-3 py-2">
            <button className={buttonVariants({ size: "sm" })}>Invia</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NoteForm;
