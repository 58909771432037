import useAuth from "../../hooks/useAuth";
import { format } from "date-fns";
import { Anchor, IdCard, Mail, Phone, Sailboat, User } from "lucide-react";
import ReferenceBadge from "../ReferenceBadge";
import { StatusBadge, statusBadgeVariants } from "../ui/StatusBadge";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/Tabs";
import NoteForm from "../ui/NoteForm";
import EventsFeed from "../ui/EventsFeed";
import ModifyStatus from "./operations/ModifyStatus";
import ModifyOrganizerData from "./operations/ModifyOrganizerData";
import { capitalize, cn, formatCurrency } from "../../lib/utils";
import Icon from "../ui/Icon";
import { buttonVariants } from "../ui/Button";
import { DateTimePicker } from "../ui/DateTimePicker";
import { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import Checkbox from "../ui/Checkbox";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "../ui/UseToast";
import TourOperatorSelect from "../TourOperatorSelect";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/DropdownMenu";
import { Link } from "react-router-dom";
import PaymentMethodSelect from "../PaymentMethodSelect";
import { it } from "date-fns/locale";
import { ROLES } from "../../config/roles";
import { documentOptions } from "../bookings/ui/DocumentSelect";
import AddParticipant from "./operations/AddParticipant";
import { Input } from "../ui/Input";
import DeleteRental from "./operations/DeleteRental";
import { formatInTimeZone } from "date-fns-tz";

const formatDate = (date) => format(new Date(date), "dd/MM/yyyy");

const NotesFromOrganizer = ({ data, callbackFn }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [passengerNotes, setPassengerNotes] = useState(
    data.notes.fromPassenger?.description,
  );

  const api = useAxiosPrivate();

  const handleEditPassengerNotes = () => {
    setIsLoading(true);
    api
      .patch(`/rentals/${data._id}`, {
        $set: {
          notes: {
            fromPassenger: {
              description: passengerNotes,
            },
          },
        },
      })
      .then(() => {
        toast({ title: "Nota salvata con successo", variant: "confirmed" });
        callbackFn();
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "Non è stato possibile aggiornare le note",
          variant: "destructive",
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="grid lg:grid-cols-3">
      <dt className="text-sm font-medium text-gray-500">Richieste</dt>
      <dd className="mt-1 text-sm italic text-gray-900 sm:col-span-2 sm:mt-0">
        <div className="flex flex-col items-end gap-1.5">
          <textarea
            value={passengerNotes}
            onChange={(e) => setPassengerNotes(e.target.value)}
            placeholder="Nessuna nota da parte del cliente"
            rows="4"
            className="w-full rounded border border-gray-300 px-2 py-1 text-sm focus:outline-none focus:ring focus:ring-blue-600"
          />
          <button
            onClick={() => handleEditPassengerNotes()}
            disabled={passengerNotes === data.notes?.fromPassenger?.description}
            className={cn("w-16", buttonVariants({ size: "sm" }))}
          >
            Salva
          </button>
        </div>
      </dd>
    </div>
  );
};

const ParticipantsList = ({ list, callbackFn }) => {
  return list.map((participant) => (
    <li
      key={participant._id}
      className="flex items-center justify-between gap-3 p-3 text-sm"
    >
      <div className="flex w-0 flex-1 items-center">
        {/* TODO: DIFFERENTIATE KIDS */}
        <Icon
          name="User"
          className="h-5 w-5 flex-shrink-0 text-gray-400"
          aria-hidden="true"
        />
        <span className="ml-2 w-0 flex-1 truncate">
          {participant.firstName} {participant.lastName}
        </span>
      </div>
      <div className="flex flex-col gap-0.5">
        {!!participant.pob && (
          <div className="text-right text-xs text-gray-400">
            {participant.pob}
          </div>
        )}
        {!!participant.dob && (
          <div className="text-right text-xs text-gray-400">
            {participant.dob}
          </div>
        )}
      </div>
      <button
        onClick={() => callbackFn(participant._id)}
        className="focus:outline-none"
      >
        <Icon
          name="X"
          className="h-6 w-6 text-gray-400 transition-colors hover:text-red-400"
        />
      </button>
    </li>
  ));
};

const ParticipantsData = ({ data, callbackFn }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [participantsList, setParticipantsList] = useState(
    data.participants.list.length ? data.participants.list : [],
  );

  const handleAddParticipant = (participant) => {
    const result = [...participantsList, participant];
    setParticipantsList(result);
  };

  const handleDeleteParticipant = (id) => {
    setParticipantsList(participantsList.filter((item) => item._id !== id));
  };

  const api = useAxiosPrivate();

  const updateParticipants = () => {
    setIsLoading(true);
    api
      .patch(`/rentals/${data._id}`, {
        $set: {
          participants: {
            list: participantsList,
          },
        },
      })
      .then((res) =>
        toast({
          title: "Partecipanti aggiornati con successo",
          variant: "confirmed",
        }),
      )
      .catch((err) => {
        console.error(err);
        toast({
          title: "Problema durante l'aggiornamento dei passeggeri",
          variant: "destructive",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="grid lg:grid-cols-3">
      <dt className="text-sm font-medium text-gray-500">
        Informazioni sui passeggeri
      </dt>
      <dd className="col-span-2 mt-1 text-sm text-gray-900 sm:mt-0">
        <div className="flex w-full flex-col items-end">
          <ul className="w-full divide-y divide-gray-200">
            <li
              key={data.organizer._id}
              className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
            >
              <div className="flex w-0 flex-1 items-center justify-between">
                <Icon
                  name="Flag"
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-2 w-0 flex-1 truncate font-medium">
                  {data.organizer.firstName} {data.organizer.lastName}
                </span>
              </div>
            </li>
            {/* If booking is CONFIRMED */}
            {data.status > 100 && (
              <ParticipantsList
                list={participantsList}
                callbackFn={handleDeleteParticipant}
              />
            )}
            {data.status > 100 ? (
              <li key="add-participant" className="flex justify-center p-3">
                <AddParticipant callbackFn={handleAddParticipant} />
              </li>
            ) : null}
          </ul>
          <div className="flex items-center gap-1.5">
            <button
              className={cn(
                "w-16",
                buttonVariants({
                  variant: "outline",
                  size: "sm",
                }),
              )}
            >
              Annulla
            </button>
            <button
              onClick={updateParticipants}
              className={cn("w-16", buttonVariants({ size: "sm" }))}
            >
              Salva
            </button>
          </div>
        </div>
      </dd>
    </div>
  );
};

const OrganizerDetails = ({ data, callbackFn }) => {
  const { auth } = useAuth();

  const { organizer } = data;

  return (
    <div className="space-y-6 px-3 py-6">
      <div className="grid lg:grid-cols-3">
        <dt className="text-sm font-medium text-gray-500">Organizzatore</dt>
        <dd className="relative mt-1 w-full text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <div className="flex flex-col gap-1.5">
            <div className="flex items-center gap-1.5">
              <User className="h-3 w-3 text-gray-400" />
              <div>
                {organizer.firstName} {organizer.lastName}
              </div>
            </div>
            <div className="flex items-center gap-1.5">
              <Mail className="h-3 w-3 text-gray-400" />
              <div>
                {organizer.contactInfo.email || "Email non specificata"}
              </div>
            </div>
            <div className="flex items-center gap-1.5">
              <Phone className="h-3 w-3 text-gray-400" />
              <div>
                {organizer.contactInfo.phone.number ||
                  "Numero di telefono non specificato"}
              </div>
            </div>
            <div className="flex items-center gap-1.5">
              <IdCard className="h-3 w-3 text-gray-400" />
              <div>
                {!organizer.identification?.type
                  ? "Tipo di documento non inserito"
                  : documentOptions[organizer.identification.type]}{" "}
                -{" "}
                {organizer.identification?.number ||
                  "Numero documento non inserito"}
              </div>
            </div>
          </div>
          <div className="absolute right-0 top-0">
            {!auth?.roles.includes(1500) && (
              <ModifyOrganizerData data={data} callbackFn={callbackFn} />
            )}
          </div>
        </dd>
      </div>
      <NotesFromOrganizer data={data} callbackFn={callbackFn} />
      {[ROLES.Tech, ROLES.Admin, ROLES.Editor].some((role) =>
        auth?.roles.includes(role),
      ) && <ParticipantsData data={data} callbackFn={callbackFn} />}
    </div>
  );
};

const RentalOperations = ({ data, callbackFn }) => {
  return (
    <div className="w-full space-y-3">
      <ModifyStatus data={data} callbackFn={callbackFn} />
      <DeleteRental data={data} callbackFn={callbackFn} />
    </div>
  );
};

const RentalPaymentOperations = ({ data, payment, callbackFn }) => {
  const { auth } = useAuth();

  const [showCreateRefundModal, setShowCreateRefundModal] = useState(false);

  const handleSendPaymentLinkMail = () => {};
  const handleDeletePayment = () => {};

  const api = useAxiosPrivate();

  const setPaymentAsRefunded = async () => {
    api
      .patch(`/payments/${payment._id}`, {
        status: "reimbursed",
      })
      .then(() => {
        toast({
          title: "Pagamento impostato come rimborsato.",
          variant: "confirmed",
        });
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "Problema durante l'aggiornamento del pagamento.",
          variant: "destructive",
        });
      })
      .finally(() => {});
  };

  return (
    <div className="flex items-center gap-1.5">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button className="text-gray-400 transition-colors hover:text-slate-700">
            <Icon name="MoreVertical" className="h-4 w-4" />
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="end"
          className="bg-background-menu min-w-[150px] p-1"
        >
          {payment.url && payment.status !== "confirmed" && (
            <DropdownMenuItem
              className="flex cursor-pointer flex-row gap-1 px-2 py-1 transition-colors hover:bg-gray-100"
              onClick={() => {
                navigator.clipboard.writeText(payment.url);
                toast({
                  description: "📋 Link copiato.",
                });
              }}
            >
              <Icon name="Clipboard" className="h-4 w-4" />
              Copia link
            </DropdownMenuItem>
          )}
          {payment.url && payment.status !== "confirmed" && (
            <DropdownMenuItem
              onClick={() => handleSendPaymentLinkMail(payment._id)}
              className="flex cursor-pointer flex-row gap-1 px-2 py-1 transition-colors hover:bg-gray-100"
            >
              <Icon name="Mail" className="h-4 w-4" />
              Manda via e-mail
            </DropdownMenuItem>
          )}
          {((payment.url && payment.status !== "confirmed") ||
            (!payment.url && payment.method !== "stripe_online")) && (
            <DropdownMenuItem
              onClick={() => handleDeletePayment(payment._id)}
              className="flex cursor-pointer flex-row gap-1 px-2 py-1 transition-colors hover:bg-gray-100"
            >
              <Icon name="Trash" className="h-4 w-4" />
              Cancella pagamento
            </DropdownMenuItem>
          )}
          {payment.stripePaymentId && (
            <Link
              to={`https://dashboard.stripe.com/payments/${payment.stripePaymentId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DropdownMenuItem className="flex cursor-pointer flex-row gap-1 px-2 py-1 transition-colors hover:bg-gray-100">
                <Icon name="ExternalLink" className="h-4 w-4" />
                Visualizza su Stripe
              </DropdownMenuItem>
            </Link>
          )}
          {!payment.stripePaymentId && (
            <DropdownMenuItem
              onClick={() => setPaymentAsRefunded(payment._id)}
              className="flex cursor-pointer flex-row gap-1 px-2 py-1 transition-colors hover:bg-gray-100"
            >
              <Icon name="ExternalLink" className="h-4 w-4" />
              Imposta come rimborsato
            </DropdownMenuItem>
          )}
          {(payment.method === "stripe" ||
            payment.method === "stripe_online" ||
            payment.method === "stripe_link") &&
            payment.stripePaymentId &&
            payment.status === "confirmed" &&
            !auth?.roles.includes(2000) && (
              <DropdownMenuItem
                onClick={() => setShowCreateRefundModal(true)}
                className="flex cursor-pointer flex-row gap-1 px-2 py-1 transition-colors hover:bg-gray-100"
              >
                <Icon name="Undo2" className="h-4 w-4" />
                Emetti un rimborso
              </DropdownMenuItem>
            )}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

const RentalRefundItem = ({ data, refund, callbackFn }) => {
  return (
    <div
      key={refund._id}
      className="flex items-center justify-between rounded border border-gray-200 p-3"
    >
      <div className="flex items-center gap-3">
        <div className="rounded-full bg-gray-200 p-1">
          <Icon name="Undo2" className="h-6 w-6 text-red-300" />
        </div>
        <div>
          <div className="flex items-center gap-1.5">
            <div className="text-sm font-medium">Rimborso</div>
            <span
              className={statusBadgeVariants({
                variant: refund.status === "confirmed" ? "positive" : "warning",
              })}
            >
              {refund.status === "confirmed" ? "Completato" : "Non confermato"}
              <Icon
                name={refund.status === "confirmed" ? "Check" : "Clock"}
                className="h-3 w-3"
              />
            </span>
          </div>

          <div className="inline-flex items-center gap-1.5 text-xs">
            <div>{formatCurrency.format(refund.amount)}</div>
            <div>&#x2022;</div>
            <div className="uppercase">{refund.method}</div>
            <div className="text-gray-400">
              {capitalize(
                format(new Date(refund.timestamp), "EEEE dd MMM, HH:mm", {
                  locale: it,
                }),
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-1.5">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <button className="text-gray-400 transition-colors hover:text-slate-700">
              <Icon name="MoreVertical" className="h-4 w-4" />
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            align="end"
            className="bg-background-menu min-w-[150px] p-1"
          >
            {refund.stripePaymentId && (
              <Link
                to={`https://dashboard.stripe.com/payments/${refund.stripePaymentId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <DropdownMenuItem className="flex cursor-pointer flex-row gap-1 px-2 py-1 transition-colors hover:bg-gray-100">
                  <Icon name="ExternalLink" className="h-4 w-4" />
                  Visualizza su Stripe
                </DropdownMenuItem>
              </Link>
            )}
            {refund.voucher && (
              <DropdownMenuItem
                className="flex cursor-pointer flex-row gap-1 px-2 py-1 transition-colors hover:bg-gray-100"
                onClick={() => {
                  navigator.clipboard.writeText(data.reference);
                  toast({
                    description: "📋 Codice copiato.",
                  });
                }}
              >
                <Icon name="Clipboard" className="h-4 w-4" />
                Copia codice
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

const RentalPaymentItem = ({ data, payment, callbackFn }) => {
  return (
    <div
      key={payment._id}
      className="flex items-center justify-between rounded border border-gray-200 p-3"
    >
      <div className="flex items-center gap-3">
        <div className="rounded-full bg-gray-200 p-1">
          <Icon name="Euro" className="h-6 w-6 text-gray-400" />
        </div>
        <div>
          <div className="flex items-center gap-1.5 text-sm font-medium">
            <div>Pagamento</div>
            <span
              className={statusBadgeVariants({
                variant:
                  payment.status === "confirmed" ? "positive" : "warning",
              })}
            >
              {payment.status === "confirmed"
                ? "Completato"
                : payment.status === "reimbursed"
                  ? "Rimborsato"
                  : "Non confermato"}
              <Icon
                name={
                  payment.status === "confirmed"
                    ? "Check"
                    : payment.status === "reimbursed"
                      ? "Undo2"
                      : "Clock"
                }
                className="h-3 w-3"
              />
            </span>
          </div>
          <div className="inline-flex items-center gap-1.5 text-xs">
            <div>{formatCurrency.format(payment.amount)}</div>
            <div>&#x2022;</div>
            <div className="uppercase">{payment.method}</div>
            <div className="text-gray-400">
              {capitalize(
                format(new Date(payment.timestamp), "EEEE dd MMM, HH:mm", {
                  locale: it,
                }),
              )}
            </div>
          </div>
        </div>
      </div>
      <RentalPaymentOperations
        payment={payment}
        data={data}
        callbackFn={callbackFn}
      />
    </div>
  );
};

const RentalModifyAccountingInfo = ({ data, callbackFn }) => {
  const { auth } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(data.paymentInfo.totalAmount);
  const [numberAmount, setNumberAmount] = useState(
    Number(data.paymentInfo.totalAmount ?? 0),
  );
  const [tourOperator, setTourOperator] = useState(data.tourOperator?._id);
  const [tourOperatorSettled, setTourOperatorSettled] = useState(
    data.tourOperatorSettled,
  );
  const [tourOperatorReference, setTourOperatorReference] = useState(
    data.tourOperatorReference,
  );

  const api = useAxiosPrivate();

  async function updateAccountingInformation() {
    try {
      let update = {};
      if (tourOperator && data.tourOperator !== tourOperator) {
        update.tourOperator = tourOperator;
      }
      if (tourOperatorReference) {
        update.tourOperatorReference = tourOperatorReference;
      }
      if (numberAmount && data.paymentInfo.totalAmount !== numberAmount) {
        update.paymentInfo = { totalAmount: numberAmount };
      }
      setIsLoading(true);
      api
        .patch(`/rentals/${data._id}`, update)
        .then((res) => {
          toast({ title: "Contabilità aggiornata con successo." });
        })
        .catch((err) => {
          setAmount(data.paymentInfo.amount);
          setTourOperator(data.tourOperator);
          setTourOperatorReference(data.tourOperatorReference);
          setTourOperatorSettled(data.tourOperatorSettled);
          console.error(err);
          toast({
            title: "Problemi durante l'aggiornamento della contabilità.",
          });
        })
        .finally(() => setIsLoading(false));
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="flex flex-col gap-1.5 px-6 py-4">
      <div className="text-lg font-medium">Modifica informazioni</div>
      <div className="flex h-full flex-col justify-between">
        <div className="space-y-1.5">
          <div className="flex items-center justify-between">
            <div className="font-medium">Totale</div>
            <CurrencyInput
              intlConfig={{ locale: "it-IT", currency: "EUR" }}
              allowNegativeValue={false}
              min={0}
              decimalsLimit={2}
              value={amount}
              onValueChange={(value, name, values) => {
                setAmount(value);
                setNumberAmount(values.float);
              }}
              placeholder={data.paymentInfo.totalAmount}
              className="w-16 border-b border-gray-200 focus:outline-none"
            />
          </div>
          <div className="flex items-start gap-3">
            <div className="flex flex-1 flex-col gap-1.5">
              <div className="text-sm">Tour Operator</div>
              <TourOperatorSelect
                value={tourOperator}
                onValueChange={setTourOperator}
              />
            </div>
            <div className="flex-0 flex w-24 flex-col gap-1.5">
              <div className="text-sm">Numero</div>
              <Input
                value={tourOperatorReference}
                onChange={(e) => setTourOperatorReference(e.target.value)}
              />
            </div>
          </div>
          {(auth?.roles.includes(2500) ||
            auth?.roles.includes(5000) ||
            auth?.roles.includes(7777)) && (
            <div className="inline-flex items-center gap-1.5 text-xs">
              <Checkbox
                checked={tourOperatorSettled}
                onCheckedChange={setTourOperatorSettled}
              />
              Saldato con il TO
            </div>
          )}
        </div>
        <div className="w-full pt-3">
          <button
            disabled={!amount}
            onClick={updateAccountingInformation}
            className={cn("w-full", buttonVariants({ size: "sm" }))}
          >
            Salva
          </button>
        </div>
      </div>
    </div>
  );
};

const RentalAddPayment = ({ data, callbackFn }) => {
  const { auth } = useAuth();

  const [paymentDate, setPaymentDate] = useState(new Date());
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("contanti");
  const [onlinePayment, setOnlinePayment] = useState(false);

  const handlePaymentMethodChange = (value) => {
    setPaymentMethod(value);
    if (value !== "stripe") {
      setOnlinePayment(false);
    }
  };

  const handlePaymentAmountChange = (e) => {
    const { value } = e.target;
    const parsedValue = value.replace(/[^\d.]/gi, "");
    setPaymentAmount(parsedValue);
    setPaymentAmount(e.target.value);
  };

  const handlePaymentAmountOnBlur = () =>
    setPaymentAmount(Number(paymentAmount).toFixed(2));

  const api = useAxiosPrivate();

  function addPayment() {
    toast({
      description: "⌛ Creazione pagamento in corso...",
    });

    if (auth?.roles.includes(2000)) {
      setOnlinePayment(true);
    }

    const timestamp = paymentDate.toISOString();

    api
      .post(`/rentals/${data._id}/payments?online=${onlinePayment}`, {
        timestamp,
        method: onlinePayment ? "stripe_link" : paymentMethod,
        amount: parseFloat(paymentAmount),
      })
      .then(() => {
        callbackFn();
        toast({
          description: "✔️ Pagamento aggiunto.",
          variant: "confirmed",
        });
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Qualcosa è andato storto...",
          description: "❌ Non è stato possibile aggiungere il pagamento.",
          variant: "destructive",
        });
      });
  }

  return (
    <div className="flex flex-col gap-1.5 px-6 py-4">
      <div className="text-lg font-medium">Registra un pagamento</div>
      <div className="flex h-full flex-col justify-between">
        <div className="flex flex-col space-y-1.5">
          <div>
            <div className="text-sm">Data</div>
            <DateTimePicker
              value={paymentDate}
              onValueChange={setPaymentDate}
            />
          </div>
          <div className="flex items-start justify-between">
            <div>
              <div className="text-sm">Metodo</div>
              <PaymentMethodSelect
                value={paymentMethod}
                onValueChange={handlePaymentMethodChange}
              />
            </div>
            <div>
              <div className="text-sm">Ammontare</div>
              <CurrencyInput
                allowNegativeValue={false}
                min={0}
                value={paymentAmount}
                onChange={handlePaymentAmountChange}
                onBlur={handlePaymentAmountOnBlur}
                placeholder={paymentAmount}
                className="mt-2 w-16 border-b border-gray-200 focus:outline-none"
              />
              €
            </div>
          </div>
          {auth?.roles.includes(2000) ? (
            <div className="text-xs">Verrà generato un link di pagamento.</div>
          ) : (
            <div className="inline-flex items-center gap-1.5 text-xs">
              <Checkbox
                disabled={paymentMethod !== "stripe"}
                checked={onlinePayment}
                onCheckedChange={setOnlinePayment}
              />
              Crea un link di pagamento
            </div>
          )}
        </div>
        <div className="w-full pt-3 text-center">
          <button
            onClick={addPayment}
            disabled={
              !paymentAmount ||
              (auth?.roles.includes(2000) && paymentAmount < 0)
            }
            className={cn("w-full", buttonVariants({ size: "sm" }))}
          >
            Registra
          </button>
        </div>
      </div>
    </div>
  );
};

const RentalHeader = ({ data }) => {
  return (
    <div className="border-b border-gray-300 px-6 py-4">
      <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div>
          <h1 className="text-lg font-semibold leading-6 text-gray-900">
            Dettagli noleggio
          </h1>
          <div className="flex items-center gap-2">
            <ReferenceBadge value={data.reference} />
            {data.tourOperator && (
              <span
                className={cn(
                  data.tourOperator === "garganogroup"
                    ? "bg-orange-700"
                    : "bg-amber-600",
                  "inline-flex items-center gap-1 truncate rounded px-1 text-xs font-semibold uppercase leading-5 text-white transition",
                )}
              >
                <Icon name="Briefcase" className="h-3 w-3" />
                {data.tourOperator.name}
              </span>
            )}
            {data.online && (
              <span className="inline-flex items-center gap-1 truncate rounded bg-emerald-800 px-1 text-xs font-semibold uppercase leading-5 text-white transition">
                <Icon name="Globe" className="h-3 w-3" />
                ONLINE
              </span>
            )}
            {!data.tourOperator && !data.online && (
              <span className="inline-flex items-center gap-1 truncate rounded bg-sky-800 px-1 text-xs font-semibold uppercase leading-5 text-white transition">
                <Icon name="LampDesk" className="h-3 w-3" />
                UFFICIO
              </span>
            )}
          </div>
        </div>
        <div className="text-right">
          <StatusBadge status={data.status} />
          <p className="mt-1 text-xs text-gray-500 lg:hidden">
            {formatDate(data.submittedOn)}
          </p>
          <p className="mt-1 hidden text-sm text-gray-500 lg:block">
            {formatDate(data.submittedOn)}
          </p>
        </div>
      </div>
    </div>
  );
};

const RentalAccountingCard = ({ data, callbackFn }) => {
  const { auth } = useAuth();

  return (
    <div className="space-y-3">
      <div className="rounded border border-gray-300 bg-white shadow">
        <RentalHeader data={data} />
        <div className="grid grid-cols-1 gap-3 divide-y divide-gray-200 p-3 lg:grid-cols-3 lg:divide-x lg:divide-y-0">
          <RentalAddPayment data={data} callbackFn={callbackFn} />
          {!auth?.roles.includes(2000) && (
            <RentalModifyAccountingInfo data={data} callbackFn={callbackFn} />
          )}
        </div>
      </div>
      <div className="space-y-3">
        {!data.payments?.length && (
          <div className="flex h-32 w-full items-center justify-center">
            <div className="text-sm text-gray-400">
              Nessun pagamento registrato per questa prenotazione
            </div>
          </div>
        )}
        <div className="flex gap-3">
          <div className="flex-auto space-y-1.5">
            {data.payments?.map((payment) => {
              return (
                <RentalPaymentItem
                  key={payment._id}
                  payment={payment}
                  data={data}
                  callbackFn={callbackFn}
                />
              );
            })}
          </div>
          {!!data.refunds.length && (
            <div className="flex-auto">
              {data.refunds?.map((refund) => (
                <RentalRefundItem
                  key={refund._id}
                  data={data}
                  refund={refund}
                  callbackFn={callbackFn}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const RentalInformationCard = ({ data, callbackFn }) => {
  const { auth } = useAuth();
  return (
    <div className="rounded border border-gray-300 bg-white shadow">
      <RentalHeader data={data} />
      <div className="grid grid-cols-1 lg:grid-cols-3">
        {/* Left section */}
        <div className="col-span-1 lg:col-span-2">
          <dl className="divide-y divide-gray-200">
            {/* Rental Details */}
            <div className="flex flex-col px-3 py-6">
              <div className="flex justify-between">
                <div className="text-sm font-medium text-slate-700">
                  Data e Orario
                </div>
                <div className="text-sm font-medium">
                  {format(new Date(data.from), "dd MMM yy HH:mm")} -{" "}
                  {format(new Date(data.to), "dd MMM yy HH:mm")}
                </div>
              </div>
              <div className="mt-4 flex items-center justify-between">
                <div className="text-sm font-medium text-slate-700">
                  Imbarco
                </div>
                <div className="flex items-center gap-1.5">
                  {data.boat && (
                    <div className="flex items-center gap-1.5 rounded border border-gray-200 p-1.5">
                      <Sailboat className="h-3 w-3 text-gray-400" />
                      <div className="truncate text-xs font-medium">
                        {data.boat.name}
                      </div>
                    </div>
                  )}
                  {data.port && (
                    <div className="flex items-center gap-1.5 rounded border border-gray-200 p-1.5">
                      <Anchor className="h-3 w-3 text-gray-400" />
                      <div className="text-xs font-medium">
                        {data.port.name}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-4 flex justify-between">
                <div className="text-sm font-medium text-slate-700">
                  Da saldare
                </div>
                <div className="text-sm">
                  {formatCurrency.format(data.paymentInfo.balance)} /{" "}
                  {formatCurrency.format(data.paymentInfo.totalAmount)}
                </div>
              </div>
            </div>
            {/* Organizer Details */}
            <OrganizerDetails data={data} callbackFn={callbackFn} />
          </dl>
        </div>
        {/* Right section */}
        <div className="border-box space-y-3 border-l border-gray-200 bg-gray-50 p-3">
          {!auth?.roles.includes(2000) && (
            <NoteForm id={data._id} entity="rental" refreshFn={callbackFn} />
          )}
          {!auth?.roles.includes(2000) && !!data.events?.length ? (
            <EventsFeed
              entityId={data._id}
              events={data.events}
              entity="rental"
              refreshFn={callbackFn}
            />
          ) : (
            <p className="text-xs text-gray-600">
              Non ci sono ancora attività registrate per questo noleggio.
            </p>
          )}
          <RentalOperations data={data} callbackFn={callbackFn} />
        </div>
      </div>
    </div>
  );
};

const RentalView = ({ data, callbackFn }) => {
  return (
    <Tabs defaultValue="info">
      <TabsList>
        <TabsTrigger value="info">Informazioni</TabsTrigger>
        <TabsTrigger value="accounting">Contabilità</TabsTrigger>
      </TabsList>
      <TabsContent value="info">
        <RentalInformationCard data={data} callbackFn={callbackFn} />
      </TabsContent>
      <TabsContent value="accounting">
        <RentalAccountingCard data={data} callbackFn={callbackFn} />
      </TabsContent>
    </Tabs>
  );
};

export default RentalView;
