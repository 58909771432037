import { Outlet } from "react-router-dom";
import Header from "../ui/Header";
import Sidebar from "../ui/Sidebar";
import { Toaster } from "../ui/Toaster";
import UserBlock from "../ui/UserBlock";

const DashboardLayout = () => {
  return (
    <div className="flex h-full">
      <div className="hidden justify-between bg-gray-800 lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
        <Sidebar />
        <UserBlock />
      </div>
      <main className="h-full flex-1 lg:pl-64">
        <Header />
        <div className="space-y-6 px-4 py-6">
          <Outlet />
        </div>
      </main>
      <Toaster />
    </div>
  );
};

export default DashboardLayout;
