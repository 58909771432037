import {
  Select,
  SelectItem,
  SelectTrigger,
  SelectContent,
  SelectValue,
} from "../../ui/Select";

export const documentOptions = {
  government_id: "Documento d'identità",
  drivers_license: "Patente di guida",
  passport: "Passaporto",
  nautical_license: "Patente nautica",
};

const DocumentSelect = ({ value, onValueChange }) => {
  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger>
        <SelectValue placeholder="Documento" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem>Nessuno</SelectItem>
        {Object.keys(documentOptions).map((item) => (
          <SelectItem key={item} value={item}>
            {documentOptions[item]}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default DocumentSelect;
