import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useLogout from "../../hooks/useLogout";
import { getInitials } from "../../lib/utils";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./DropdownMenu";
import Icon from "./Icon";

const MobileUserBlock = () => {
  const { auth } = useAuth();
  const displayName = auth?.displayName;
  const username = auth?.username;
  const { menuOpen, setMenuOpen } = useState(false);

  const navigate = useNavigate();
  const logout = useLogout();

  const signOut = async () => {
    await logout();
    navigate("/");
  };

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger>
        <div className="flex h-9 w-9 items-center justify-center rounded-full bg-gray-500 p-2 font-semibold transition hover:ring-1 hover:ring-white hover:ring-offset-1">
          <Icon name="User" className="text-white" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        sideOffset={8}
        className="w-[180px] bg-white"
        align="end"
      >
        <div className="flex items-center justify-start gap-x-1.5 border-b border-gray-200 p-2">
          <div className="flex h-9 w-9 items-center justify-center rounded-full bg-gray-500 font-semibold text-white">
            {getInitials(`${displayName?.firstName} ${displayName?.lastName}`)}
          </div>
          <div>{username && <p className="font-medium">{username}</p>}</div>
        </div>
        <div className="py-2">
          <DropdownMenuItem className="hover:bg-brand-secondary gap-x-2 px-2 py-1 text-slate-700">
            <Icon name="Settings" className="h-4 w-4" />
            <Link to={`/users/${auth?.id}`}>Impostazioni</Link>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="hover:bg-brand-secondary cursor-pointer gap-x-2 px-2 py-1 text-slate-700"
            onSelect={signOut}
          >
            <Icon name="LogOut" className="h-4 w-4" />
            Logout
          </DropdownMenuItem>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default MobileUserBlock;
