import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ROLES } from "../../config/roles";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { cn } from "../../lib/utils";
import Loading from "../ui/Loading";
import { RadioGroup, RadioGroupItem } from "../ui/RadioGroup";
import { columns } from "./Columns";
import DataTable from "./DataTable";
import { mobileColumns } from "./MobileColumns";
import MobileDataTable from "./MobileDataTable";
import { Button } from "../ui/Button";
import { RotateCcw } from "lucide-react";

const BookingsList = () => {
  const { auth } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();
  const dateParam = searchParams.get("date");
  const boatParam = searchParams.get("boat");
  const statusParam = searchParams.get("status");

  const [isLoading, setIsLoading] = useState(false);
  const [bookings, setBookings] = useState();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedDate = dateParam ? new Date(dateParam) : null;
  const selectedBoat = boatParam || null;
  const [date, setDate] = useState(selectedDate);
  const [boat, setBoat] = useState(selectedBoat);
  const [statusFilter, setStatusFilter] = useState(statusParam ?? null);
  const [tourOperators, setTourOperators] = useState();
  const [boats, setBoats] = useState();

  const handleDateChange = (newValue) => {
    searchParams.delete("date");
    setDate(newValue);
    if (newValue) {
      searchParams.set("date", format(newValue, "yyyy-MM-dd"));
    }
    setSearchParams(searchParams);
  };

  const handleBoatChange = (e) => {
    searchParams.delete("boat");
    setBoat(e.target.value);
    if (e.target.value && e.target.value !== "") {
      searchParams.set("boat", e.target.value);
    }
    setSearchParams(searchParams);
  };

  const handleStatusFilterChange = (newValue) => {
    searchParams.delete("status");
    setStatusFilter(newValue);
    if (newValue) {
      searchParams.set("status", statusFilter);
    }
  };

  const fetchData = () => {
    setIsLoading(true);
    axiosPrivate
      .get("/bookings", {
        params: searchParams,
      })
      .then((response) => {
        setBookings(response.data);
      })
      .catch((err) => {
        console.error(err);
        navigate("/", { state: { from: location }, replace: true });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    let isMounted = true;

    axiosPrivate
      .get("/touroperators")
      .then((response) => {
        if (isMounted) {
          setTourOperators(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    setIsLoading(true);

    axiosPrivate
      .get("/boats?displayDeleted=true")
      .then((response) => {
        if (isMounted) {
          setBoats(response.data);
        }
      })
      .catch((err) => {
        setBoats([]);
        console.log(err);
      });

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [boat, date, statusFilter, searchParams, navigate, location]);

  useEffect(() => {
    if (statusFilter) {
      searchParams.set("status", statusFilter);
    } else {
      searchParams.delete("status");
    }
    setSearchParams(searchParams);
  }, [statusFilter]);

  return (
    <div className="space-y-3">
      <div className="flex items-center justify-between">
        <RadioGroup
          className="flex border-b border-gray-200 text-sm font-medium"
          disabled={isLoading}
          value={statusFilter}
          onValueChange={handleStatusFilterChange}
        >
          <RadioGroupItem
            disabled={isLoading}
            value={null}
            className={cn(
              "box-border px-3 py-3 text-left",
              !statusFilter && "border-b-2 border-blue-500 text-blue-500",
            )}
          >
            Tutto
          </RadioGroupItem>
          <RadioGroupItem
            disabled={isLoading}
            className={cn(
              "box-border px-3 py-3 text-left",
              statusFilter === "confirmed" &&
                "border-b-2 border-blue-500 text-blue-500",
            )}
            value="confirmed"
          >
            Attive
          </RadioGroupItem>
          <RadioGroupItem
            disabled={isLoading}
            className={cn(
              "box-border px-3 py-3 text-left",
              statusFilter === "info" &&
                "border-b-2 border-blue-500 text-blue-500",
            )}
            value="info"
          >
            Attesa info
          </RadioGroupItem>
          <RadioGroupItem
            disabled={isLoading}
            className={cn(
              "box-border px-3 py-3 text-left",
              statusFilter === "payment" &&
                "border-b-2 border-blue-500 text-blue-500",
            )}
            value="payment"
          >
            Attesa pagamento
          </RadioGroupItem>
          <RadioGroupItem
            disabled={isLoading}
            className={cn(
              "box-border px-3 py-3 text-left",
              statusFilter === "archived" &&
                "border-b-2 border-blue-500 text-blue-500",
            )}
            value="archived"
          >
            Archiviate
          </RadioGroupItem>
          <RadioGroupItem
            disabled={isLoading}
            className={cn(
              "box-border px-3 py-3 text-left",
              statusFilter === "calledOff" &&
                "border-b-2 border-blue-500 text-blue-500",
            )}
            value="calledOff"
          >
            Annullate
          </RadioGroupItem>
          {auth?.roles.includes(ROLES.Tech) && (
            <RadioGroupItem
              disabled={isLoading}
              className={cn(
                "box-border px-3 py-3 text-left",
                statusFilter === "test" &&
                  "border-b-2 border-blue-500 text-blue-500",
              )}
              value="test"
            >
              Test
            </RadioGroupItem>
          )}
        </RadioGroup>
        <Button onClick={() => fetchData()} variant="icon">
          <RotateCcw className={cn("h-5 w-5", isLoading && "animate-spin")} />
        </Button>
      </div>

      {!bookings ? (
        <Loading />
      ) : (
        <>
          <div className="hidden lg:flex">
            <DataTable
              date={date}
              handleDateChange={handleDateChange}
              boat={boat}
              handleBoatChange={handleBoatChange}
              columns={columns}
              data={bookings}
              tourOperators={tourOperators}
              boats={boats}
            />
          </div>
          {/* TODO: update mobile to be same as deskptop */}
          <div className="flex lg:hidden">
            <MobileDataTable
              date={date}
              handleDateChange={handleDateChange}
              boat={boat}
              handleBoatChange={handleBoatChange}
              columns={mobileColumns}
              data={bookings}
              tourOperators={tourOperators}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default BookingsList;
