import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import Breadcrumb from "../../components/ui/Breadcrumb";
import { toast } from "../../components/ui/UseToast";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { formatCurrency } from "../../lib/utils";

const ReportsAnalytics = () => {
  const axiosPrivate = useAxiosPrivate();

  const [analytics, setAnalytics] = useState();

  const [isAnalyticsLoading, setIsAnalyticsLoading] = useState();

  useEffect(() => {
    setIsAnalyticsLoading(true);

    const getAnalytics = async () => {
      try {
        const response = await axiosPrivate.get("/reports/analytics");
        setAnalytics(response.data);
      } catch (err) {
        console.log(err);
        toast({
          title: "Qualcosa è andato storto...",
          description:
            "❌ Non è stato possibile caricare l'analisi di eventuali problemi.",
          variant: "destructive",
        });
      }
    };

    getAnalytics();
    setIsAnalyticsLoading(false);
  }, [axiosPrivate]);

  return (
    <div className="mx-auto max-w-7xl space-y-6">
      <Breadcrumb />
      <div className="space-y-6">
        <h2>Analisi dei problemi</h2>
        <div className="space-y-6 divide-y divide-gray-200">
          <div className="space-y-3">
            <h3 className="text-base">
              Prenotazioni con problemi di contabilità{" "}
              {!!analytics?.issues.accounting.outstandingBalanceBookings &&
                `(${analytics?.issues.accounting.outstandingBalanceBookings.length})`}
            </h3>
            <p className="text-sm">
              Queste prenotazioni di uscite già effettuate presentano saldo
              positivo ad oggi.
            </p>
            {isAnalyticsLoading && <Loading />}
            {analytics && (
              <div className="grid grid-cols-3">
                <div className="col-span-2 divide-y divide-gray-200">
                  {analytics.issues.accounting.outstandingBalanceBookings.map(
                    (item) => {
                      return (
                        <Link key={item._id} to={`/bookings/${item._id}`}>
                          <div className="flex items-center justify-between p-3 text-xs hover:bg-gray-50">
                            <div className="font-medium text-blue-500">
                              Prenotazione {item.reference}
                            </div>
                            <div>{formatCurrency.format(item.balance)}</div>
                          </div>
                        </Link>
                      );
                    },
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportsAnalytics;
